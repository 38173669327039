import { useCallback } from 'react';
import { get } from 'lodash';
import { useTranslation } from '@wearehelpful/ui-localization';
import { HomeMode } from './Home.types';
import { SECTION_TITLE_MAPPING } from './Home.const';

interface GetSectionHeaderParams {
  key: string;
  mode: HomeMode;
}

export const useHomeCopy = () => {
  const { t } = useTranslation();

  const getToggleLabel = useCallback(
    (mode: HomeMode) => {
      return mode === HomeMode.Reorder ? t('actions.done') : t('actions.sort');
    },
    [t]
  );

  const getSectionHeader = useCallback(
    ({ mode, key }: GetSectionHeaderParams) => {
      if (key === 'null' && mode === HomeMode.Reorder) {
        return t('hints.dragIconToSort');
      }
      return t(get(SECTION_TITLE_MAPPING, key));
    },
    [t]
  );

  return { getToggleLabel, getSectionHeader };
};
