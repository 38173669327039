import { PropsWithChildren, useCallback, useState, Ref } from 'react';
import { useMap } from 'react-use';
import { noop, toNumber } from 'lodash';
import classNames from 'classnames';
import { Formik, Form as FormikForm } from 'formik';
import { FormInput } from './FormInput/FormInput';
import { FormPassword } from './FormPassword/FormPassword';
import { FormSelect } from './FormSelect/FormSelect';
import { FormSubmit } from './FormSubmit/FormSubmit';
import { FormRadioGroup } from './FormRadioGroup/FormRadioGroup';
import { FormCheckbox } from './FormCheckbox/FormCheckbox';
import { FormRadioGroupItem } from './FormRadioGroupItem/FormRadioGroupItem';
import { FormDatePicker } from './FormDatePicker/FormDatePicker';
import { FormCheckboxGroup } from './FormCheckboxGroup/FormCheckboxGroup';
import { FormCheckboxGroupItem } from './FormCheckboxGroup/FormCheckboxGroupItem/FormCheckboxGroupItem';
import { FormSection } from './FormSection/FormSection';
import { FormTextarea } from './FormTextarea/FormTextarea';
import { FormDivider } from './FormDivider/FormDivider';
import { FormActions } from './FormActions/FormActions';
import { FormContext } from './Form.context';
import { FormInstance, FormDataIndex } from './Form.types';
import { FormFileUpload } from './FormFileUpload/FormFileUpload';

type FormProps<T extends object> = PropsWithChildren<{
  initialValues?: T;
  padding?: boolean;
  className?: string;
  onSubmit?: (v: T) => void;
  validationSchema?: unknown;
  form?: Ref<FormInstance<T>>;
}>;

interface FormComponent {
  <T extends object>(props: FormProps<T>): React.ReactElement | null;
  Checkbox: typeof FormCheckbox;
  DatePicker: typeof FormDatePicker;
  Input: typeof FormInput;
  Password: typeof FormPassword;
  RadioGroup: typeof FormRadioGroup;
  RadioGroupItem: typeof FormRadioGroupItem;
  CheckboxGroup: typeof FormCheckboxGroup;
  CheckboxGroupItem: typeof FormCheckboxGroupItem;
  Section: typeof FormSection;
  Select: typeof FormSelect;
  Submit: typeof FormSubmit;
  Textarea: typeof FormTextarea;
  Divider: typeof FormDivider;
  Actions: typeof FormActions;
  FileUpload: typeof FormFileUpload;
}

export const Form: FormComponent = ({
  form,
  padding,
  children,
  className,
  initialValues = {},
  onSubmit = noop,
  validationSchema,
}) => {
  const [
    formDataIndex,
    {
      set: setFormDataIndex,
      remove: removeFormDataIndex,
      get: getFormItemElement,
    },
  ] = useMap<FormDataIndex>();

  const [submitted, setSubmitted] = useState(false);

  const onSubmitted = useCallback(() => setSubmitted(true), []);

  const focusNextFormItem = (currentTabIndex: number) => {
    const tabIndexes = Object.keys(formDataIndex)
      .map((v) => toNumber(v))
      .sort();
    const currentIndex = tabIndexes.indexOf(currentTabIndex);
    const nextIndex = currentIndex + 1;
    const nextKey = tabIndexes[nextIndex];
    const control = getFormItemElement(nextKey);
    if (control) control.current?.setFocus();
  };

  return (
    <FormContext.Provider
      value={{
        onSubmitted,
        setFormDataIndex,
        focusNextFormItem,
        removeFormDataIndex,
      }}
    >
      <Formik<any>
        innerRef={form}
        validateOnBlur={submitted}
        validateOnChange={submitted}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(value) => onSubmit(value)}
      >
        <FormikForm
          className={classNames(className, {
            'ion-padding': padding,
          })}
        >
          {children}
        </FormikForm>
      </Formik>
    </FormContext.Provider>
  );
};

Form.Checkbox = FormCheckbox;
Form.DatePicker = FormDatePicker;
Form.Input = FormInput;
Form.Password = FormPassword;
Form.RadioGroup = FormRadioGroup;
Form.RadioGroupItem = FormRadioGroupItem;
Form.Section = FormSection;
Form.Select = FormSelect;
Form.Submit = FormSubmit;
Form.Textarea = FormTextarea;
Form.Divider = FormDivider;
Form.Actions = FormActions;
Form.CheckboxGroup = FormCheckboxGroup;
Form.CheckboxGroupItem = FormCheckboxGroupItem;
Form.FileUpload = FormFileUpload;
