import { FC, Fragment, useRef } from 'react';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  CallToActionRoutes,
  RoutePath,
} from '@common/constants';
import { useRoute } from '@core/navigation';

import {
  CtaList,
  type CtaListProps,
  GuidePaper,
  StackCarousel,
} from '@common/features';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation, Translate } from '@wearehelpful/ui-localization';
import { CDTO } from '@helpful/network';
import {
  Skeleton,
  Content,
  Page,
  Header,
  Typography,
  InfiniteScroll,
} from '@wearehelpful/ui-kit';
import { Empty, SearchbarInstance, Toolbar, useWindowSize } from '@core/ui';
import { SearchbarWithStateInURL } from '../../../../features/SearchbarWithStateInURL/SearchbarWithStateInURL';
import { useGuides } from '../../../../hooks/useGuides/useGuides.hook';
import { Link, Gutter } from './Index.style';
import { MORE_GUIDE_SLUG } from './Index.const';

export const Index: FC = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const searchbar = useRef<SearchbarInstance>();
  const pageRef = useRef<HTMLDivElement>(null);
  const { track, trackClick } = useAnalytics();
  const [
    { loading, guides, stacks, total, query, hasNextPage },
    { refetch, filter, fetchNextPage },
  ] = useGuides();

  const [{ width }] = useWindowSize();
  const thumbW = width - 32;
  const thumbH = parseInt(`${thumbW * 0.55}`);

  const handleOnItemClick = (slug: string, order: number) => {
    trackClick({
      source: AnalyticsPageCategory.GUIDES,
      element: AnalyticsPageCategory.GUIDE,
      properties: { order: order, guide: slug },
    });
    push({
      path: RoutePath.ROOT_GUIDES_DETAILS,
      params: { slug },
    });
  };

  const handleSearch = (query: string) => {
    if (query) {
      track({
        id: AnalyticsTrackEvents.GUIDES_SEARCH,
        properties: {
          query: query.toLowerCase(),
        },
      });
    }
    filter(query);
  };

  const handleOnGuideClick = (
    { slug, title }: DeepPartial<CDTO.Training>,
    order: number
  ) => {
    trackClick({
      source: `${AnalyticsPageCategory.STACK_CAROUSEL} - ${AnalyticsPageCategory.GUIDES}`,
      element: AnalyticsPageCategory.GUIDE,
      properties: { guide: slug, order: order, stack: title },
    });
    push({ path: RoutePath.ROOT_GUIDES_DETAILS, params: { slug } });
  };

  const handleOnBenefitClick = (
    { slug, title }: DeepPartial<CDTO.PlanBenefit>,
    order: number
  ) => {
    trackClick({
      source: `${AnalyticsPageCategory.STACK_CAROUSEL} - ${AnalyticsPageCategory.GUIDES}`,
      element: AnalyticsPageCategory.BENEFIT,
      properties: { title: slug, order: order, stack: title },
    });
    push({ path: RoutePath.ROOT_BENEFITS_DETAILS, params: { slug } });
  };

  const handleOnStackClick = ({ title, slug }: DeepPartial<CDTO.Stack>) => {
    trackClick({
      source: `${AnalyticsPageCategory.STACK_CAROUSEL} - ${AnalyticsPageCategory.GUIDES}`,
      element: AnalyticsPageCategory.STACK,
      properties: { title },
    });
    push({ path: RoutePath.ROOT_STACKS_DETAILS, params: { slug } });
  };

  const handleOnCtaClick: CtaListProps['onClick'] = ({
    title,
    ctaUrl: path,
  }) => {
    track({
      id: AnalyticsTrackEvents.GUIDES_CTA_CLICK,
      properties: { title },
    }).finally(() => {
      push({ path });
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.ROOT}
      name={AnalyticsPageCategory.GUIDES}
    >
      <Page ref={pageRef}>
        <Header>
          <Toolbar padding>
            <SearchbarWithStateInURL
              value={query}
              ref={searchbar}
              onSearch={handleSearch}
              placeholder={t('placeholders.searchForGuides')}
            />
          </Toolbar>
        </Header>
        <Content onScroll={() => searchbar.current?.blur()}>
          <InfiniteScroll
            onRefresh={refetch}
            onScroll={fetchNextPage}
            disabled={!hasNextPage}
          >
            <Content.Subheader divider>
              <Typography variant="h1">
                {t('subheadings.guides.title')}
              </Typography>
              <Typography variant="p2">
                <Translate
                  id="subheadings.guides.description"
                  components={{
                    a: (
                      <Link
                        path={`${RoutePath.ROOT_GUIDES}/${MORE_GUIDE_SLUG}`}
                      />
                    ),
                  }}
                />
              </Typography>
            </Content.Subheader>
            <Content.Fixed>
              <CtaList
                route={CallToActionRoutes.GUIDES}
                onClick={handleOnCtaClick}
              />
              <Skeleton variant="feed" loading={loading}>
                {guides.map((props, index) => {
                  const showStrack = index > 0 && (index + 1) % 3 === 0;
                  const stackIndex = (index + 1) / 3 - 1;
                  const hasStack = stackIndex % 1 === 0 && !!stacks[stackIndex];
                  return (
                    <Fragment key={props.sys.id}>
                      <GuidePaper
                        {...props}
                        thumb={{ width: thumbW, height: thumbH }}
                        onClick={() => handleOnItemClick(props.slug, index)}
                      />
                      {showStrack && hasStack && (
                        <StackCarousel
                          {...stacks[stackIndex]}
                          onGuideClick={handleOnGuideClick}
                          onBenefitClick={handleOnBenefitClick}
                          onStackClick={handleOnStackClick}
                        />
                      )}
                    </Fragment>
                  );
                })}
                {total === 0 && (
                  <Empty padding>
                    <Empty.Message>{t('empty.noSearchResults')}</Empty.Message>
                    <Empty.Asset />
                  </Empty>
                )}
              </Skeleton>
            </Content.Fixed>
            <Gutter />
          </InfiniteScroll>
        </Content>
      </Page>
    </TrackPageView>
  );
};
