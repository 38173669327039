import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '../firebaseMessaging/firebaseMessaging';
import { Device } from '@capacitor/device';

type Props = {
  /**
   * Show a prompt with allow/disallow for push notifications.
   */
  requestPermissions?: boolean;
};

/**
 * Checks if notifications are allowed on the device.
 * @returns `true` if allowed.
 */
export const getNotificationsAllowance = async (
  props?: Props,
): Promise<boolean> => {
  const osVersion = (await Device.getInfo()).osVersion;
  // Android devices with os version < 13 receive push notifications without a prompt.
  if (Capacitor.getPlatform() === 'android' && osVersion < '13') {
    return true;
  }

  let permissions = await FirebaseMessaging.checkPermissions();

  if (props?.requestPermissions && permissions.receive === 'prompt') {
    permissions = await FirebaseMessaging.requestPermissions();
  }

  return permissions.receive === 'granted';
};
