import {
  CONTENTFUL_BLOCKS,
  CONTENTFUL_INLINES,
  ContentfulRenderFn,
} from '@helpful/network';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Hr,
  ListItem,
  OlList,
  Paragraph,
  Quote,
  UlList,
  Link,
} from './Contentful.style';
import { ContentfulImage } from './ContentfulImage/ContentfulImage';

export const RENDER_NODE: ContentfulRenderFn = ({ assets }) => ({
  [CONTENTFUL_BLOCKS.EMBEDDED_ASSET]: (node) => {
    const img = assets.find(
      ({ sys: { id } }) => id === node.data.target.sys.id
    );
    return img ? <ContentfulImage url={img.url} title={img.title} /> : null;
  },
  [CONTENTFUL_BLOCKS.PARAGRAPH]: (_, children) => (
    <Paragraph>{children}</Paragraph>
  ),
  [CONTENTFUL_BLOCKS.UL_LIST]: (_, children) => <UlList>{children}</UlList>,
  [CONTENTFUL_BLOCKS.OL_LIST]: (_, children) => <OlList>{children}</OlList>,
  [CONTENTFUL_BLOCKS.HEADING_1]: (_, children) => (
    <Heading1>{children}</Heading1>
  ),
  [CONTENTFUL_BLOCKS.HEADING_2]: (_, children) => (
    <Heading2>{children}</Heading2>
  ),
  [CONTENTFUL_BLOCKS.HEADING_3]: (_, children) => (
    <Heading3>{children}</Heading3>
  ),
  [CONTENTFUL_BLOCKS.HEADING_4]: (_, children) => (
    <Heading4>{children}</Heading4>
  ),
  [CONTENTFUL_BLOCKS.HEADING_5]: (_, children) => (
    <Heading5>{children}</Heading5>
  ),
  [CONTENTFUL_BLOCKS.HEADING_6]: (_, children) => (
    <Heading6>{children}</Heading6>
  ),
  [CONTENTFUL_BLOCKS.HR]: () => <Hr />,
  [CONTENTFUL_BLOCKS.LIST_ITEM]: (_, children) => (
    <ListItem>{children}</ListItem>
  ),
  [CONTENTFUL_BLOCKS.QUOTE]: (_, children) => <Quote>{children}</Quote>,
  [CONTENTFUL_INLINES.HYPERLINK]: (node, children) => {
    return <Link href={node.data.uri}>{children}</Link>;
  },
});
