import { FC, PropsWithChildren, ReactNode } from 'react';
import { Root, Description } from './FormSection.style';

type FormSectionProps = PropsWithChildren<{
  description?: ReactNode;
}>;

export const FormSection: FC<FormSectionProps> = ({
  description,
  children,
}) => {
  return (
    <Root>
      {description && <Description>{description}</Description>}
      {children}
    </Root>
  );
};
