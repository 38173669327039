import { RoutePath, UserRole } from '@common/constants';
import { RouteProps } from '@core/navigation';
import { Index } from './Index/Index';
import { Callback } from './Callback/Callback';
import { SignUp } from './SignUp/SignUp';
import { SignIn } from './SignIn/SignIn';
import { SignOut } from './SignOut/SignOut';
import { VerifyEmail } from './VerifyEmail/VerifyEmail';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { ResetPassword } from './ResetPassword/ResetPassword';

export const AUTH_ROUTES: Array<RouteProps> = [
  {
    path: RoutePath.AUTH,
    children: <Index />,
    exact: true,
    roles: [UserRole.NONE],
  },
  {
    path: RoutePath.AUTH_CALLBACK,
    children: <Callback />,
    roles: [UserRole.NONE],
  },
  {
    path: RoutePath.AUTH_SIGN_UP,
    children: <SignUp />,
    roles: [UserRole.NONE, UserRole.INITIAL, UserRole.PARTIAL],
  },
  {
    path: RoutePath.AUTH_SIGN_IN,
    children: <SignIn />,
    roles: [UserRole.NONE],
  },
  {
    path: RoutePath.AUTH_SIGN_OUT,
    children: <SignOut />,
    roles: [UserRole.USER],
  },
  {
    path: RoutePath.AUTH_VERIFY_EMAIL,
    children: <VerifyEmail />,
    roles: [UserRole.NONE, UserRole.INITIAL, UserRole.USER],
  },
  {
    path: RoutePath.AUTH_FORGOT_PASSWORD,
    children: <ForgotPassword />,
    roles: [UserRole.NONE],
  },
  {
    path: RoutePath.AUTH_RESET_PASSWORD,
    children: <ResetPassword />,
    roles: [UserRole.NONE],
  },
];
