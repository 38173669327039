import { FC } from 'react';
import {
  Card,
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
  Empty,
  Button,
  Badge,
} from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useRoute } from '@core/navigation';
import { usePatient } from '@common/hooks';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useGetPatientMedicationsInfinite } from '@helpful/network';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';
import { useMedical } from '../../../../../hooks/useMedical/useMedical.hook';

const TAKE = 20;

export const Index: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [{ connections }] = useMedical();
  const { track } = useAnalytics();
  const [, { push }] = useRoute();

  const handleOnClick = (id: string) => {
    push({ path: RoutePath.ROOT_MEDICAL_MEDICATIONS_DETAILS, params: { id } });
  };

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_MEDICATIONS }).finally(
      () => {
        push({ path: RoutePath.CONNECT });
      }
    );
  };

  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetPatientMedicationsInfinite(
    patient.id,
    {
      skip: 0,
      take: TAKE,
      relations: 'connection.system',
      filter: {
        status: 'active',
        isHistorical: 'false',
        category: 'community',
      },
      order: 'authoredOn:DESC',
    },
    {
      query: {
        enabled: Boolean(patient.id),
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const medications = data.pages
    ?.reduce(
      (res, { data }) => [...res, ...data],
      [] as ADTO.MedicationEntity[]
    )
    .filter((medication) => medication.status !== 'entered-in-error');

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.MEDICATIONS}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading2>
              {t('subheadings.medications.title')}
            </Typography.Heading2>
            <Typography.Body1>
              {t('subheadings.medications.description', {
                name: patient.firstName,
              })}
            </Typography.Body1>
          </Subheader>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching}>
              {!!connections.length && !medications?.length && (
                <Empty padding>
                  <Empty.Message>
                    {t('empty.noMedicationsDetected')}
                  </Empty.Message>
                  <Empty.Asset />
                </Empty>
              )}
              {!connections.length && (
                <Empty padding size="md">
                  <Empty.Asset name="MEDICATIONS_SCREEN" width={298} />
                  <Empty.Message>
                    {t('prompts.connectMedical.medications')}
                  </Empty.Message>
                  <Button variant="tertiary" onClick={handleOnConnect}>
                    {t('actions.connectMedicalRecords')}
                  </Button>
                </Empty>
              )}
              {!!medications?.length && (
                <InfiniteScroll
                  onScroll={fetchNextPage}
                  disabled={!hasNextPage}
                >
                  {medications.map(({ id, name, instruction, connection }) => {
                    return (
                      <Card
                        divider
                        flat
                        key={id}
                        onClick={() => handleOnClick(id)}
                      >
                        <Card.Icon name="medication" />
                        <Card.Header>
                          <Card.Title>{name}</Card.Title>
                        </Card.Header>
                        <Card.Content truncate={6}>{instruction}</Card.Content>
                        <Card.Footer justifyContent="flex-start">
                          <Badge variant="primary">
                            {connection.system.name}
                          </Badge>
                        </Card.Footer>
                      </Card>
                    );
                  })}
                </InfiniteScroll>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
