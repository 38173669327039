import { useRef, forwardRef, useLayoutEffect } from 'react';
import { noop } from 'lodash';
import { Root } from './ToolbarSearch.style';

interface ToolbarSearchProps {
  autofocus?: boolean;
  onChange?: (v: string) => void;
}

export interface ToolbarSearchRef {
  focus: () => void;
}

export const ToolbarSearch = forwardRef<ToolbarSearchRef, ToolbarSearchProps>(
  ({ onChange = noop, autofocus = false }, _) => {
    const input = useRef<HTMLIonInputElement>();

    useLayoutEffect(() => {
      if (autofocus) {
        setTimeout(() => {
          input.current?.setFocus();
        }, 100);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Root
        ref={input}
        placeholder="Search"
        onIonChange={({ target: { value } }) => onChange(value)}
      />
    );
  }
);
