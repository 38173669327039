import type { CDTO } from '@helpful/network';
import { CallToActionRoutes } from '@common/constants';
import { useContextSelector } from 'use-context-selector';
import { useMemo } from 'react';
import { useUser } from '@core/user';
import { RootContext } from '../../../root/contexts/Root.context';
import { usePatient } from '../usePatient/usePatient.hook';

interface UseCtasParams {
  route: CallToActionRoutes;
}

interface UseCtasReturnType {
  ctas: DeepPartial<CDTO.CallToAction>[];
}

export const useCtas = ({ route }: UseCtasParams): UseCtasReturnType => {
  const [{ roles }] = useUser();
  const [{ plans }] = usePatient();

  const rootContext = useContextSelector(
    RootContext,
    ({ ctas: { items }, isRootLoading }) => ({
      allCtas: items,
      isLoading: isRootLoading,
    })
  );

  const ctas = useMemo(() => {
    if (rootContext.isLoading) {
      return [];
    }

    return rootContext.allCtas?.filter(
      ({
        userRole,
        route: routes,
        healthPlanCollection: { items: healthPlans },
      }) =>
        routes?.includes(route) &&
        roles.filter((role) => userRole?.includes(role)).length > 0 &&
        (healthPlans.length > 0
          ? healthPlans.filter(({ slug }) => plans.includes(slug)).length > 0
          : true)
    );
  }, [rootContext.isLoading, rootContext.allCtas, roles, plans, route]);

  return { ctas };
};
