import { FC, PropsWithChildren, useCallback } from 'react';
import {
  NavigationPromptProvider,
  NavigationPromptCallback,
} from '@core/navigation';
import { useUtils } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';

type NavigationPromptProps = PropsWithChildren<{}>;

export const NavigationPrompt: FC<NavigationPromptProps> = ({ children }) => {
  const { confirm } = useUtils();
  const { t } = useTranslation();

  const navigationPromptCallback: NavigationPromptCallback = useCallback(
    ({ proceed }) => {
      confirm({
        icon: 'lifebuoy',
        message: t('confirms.unsavedChangesLeaveConfirm'),
        onConfirm: async () => proceed(),
      });
    },
    [t, confirm]
  );

  return (
    <NavigationPromptProvider
      navigationPromptCallback={navigationPromptCallback}
    >
      {children}
    </NavigationPromptProvider>
  );
};
