import { createContext } from 'use-context-selector';
import { ADTO, useGetPatientTodosInfinite } from '@helpful/network';
import { noop } from 'lodash';

export interface HomeReorderParams {
  from: number;
  to: number;
  items: ADTO.TodoLocalizedEntity[];
}

export interface HomeContextProps {
  total: number;
  loading: boolean;
  hasNextPage: boolean;
  todos: ADTO.TodoLocalizedEntity[];
  reorder: (params: HomeReorderParams) => void;
  fetchNextPage: ReturnType<typeof useGetPatientTodosInfinite>['fetchNextPage'];
  refetch: ReturnType<typeof useGetPatientTodosInfinite>['refetch'];
}

export const HomeContext = createContext<HomeContextProps>({
  total: 0,
  todos: [],
  reorder: noop,
  loading: false,
  hasNextPage: false,
  refetch: () => Promise.resolve(null),
  fetchNextPage: () => Promise.resolve(null),
});
