import { FC } from 'react';
import { Redirect, useRoute } from '@core/navigation';
import {
  Page,
  ContentFixed,
  Header,
  Toolbar,
  Progress,
  Typography,
} from '@core/ui';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { type ADTO, useGetFhirReady } from '@helpful/network';
import { useUser } from '@core/user';
import { Content } from '@wearehelpful/ui-kit';

export const Callback: FC = () => {
  const { t } = useTranslation();
  const [{ search }] = useRoute<object, ADTO.FhirControllerReadyParams>();
  const { data, isError } = useGetFhirReady(search);
  const [, refetchUser] = useUser();

  if (data || isError) {
    refetchUser();
    return <Redirect path={RoutePath.ROOT_MEDICAL} />;
  }

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CONNECT_MEDICAL}
      name={AnalyticsPageCategory.CONNECT_MEDICAL_LOADING}
    >
      <Page>
        <Header>
          <Toolbar />
        </Header>
        <Content padding>
          <ContentFixed>
            <Progress
              title={
                <Typography.Heading2 align="center">
                  {t('subheadings.medicalConnect.title')}
                </Typography.Heading2>
              }
              description={
                <Typography.Body2>
                  {' '}
                  {t('subheadings.medicalConnect.description')}
                </Typography.Body2>
              }
              image={{ name: 'HAND_WALK', width: 81 }}
            />
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
