import { FC, PropsWithChildren } from 'react';
import { useGetBootstrapQuery } from '@helpful/network';
import { useLocale } from '@wearehelpful/ui-localization';
import { useConfig } from '../../../hooks';
import { DataContext } from '../../../contexts/Data.context';

type DataProps = PropsWithChildren<{}>;

export const Data: FC<DataProps> = ({ children }) => {
  const [{ lng }] = useLocale();
  const { supportedLanguages } = useConfig();

  const { data, isSuccess } = useGetBootstrapQuery({
    policy: ['privacy-policy', 'terms'],
    // TODO: Check why locale from Contentfull bootstrap does not work. Delete locale.
    locale: supportedLanguages.find(({ code }) => code === lng)?.contentful,
  });

  if (!isSuccess) return null;

  return (
    <DataContext.Provider
      value={{
        tours: data.tourCollection.items,
        stacks: data.stackCollection.items,
        policy: data.policyCollection.items,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
