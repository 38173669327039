import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import { IMAGES } from '../../constants';
import { BulletPointsItem } from './BulletPointsItem/BulletPointsItem';
import { BulletPointsTitle } from './BulletPointsTitle/BulletPointsTitle';
import { BulletPointsDescription } from './BulletPointsDescription/BulletPointsDescription';
import { Root, Asset, List } from './BulletPoints.style';

type BulletPointsProps = PropsWithChildren<{
  padding?: boolean;
  image?: {
    name: keyof typeof IMAGES;
    width?: number;
    height?: number;
  };
}>;

interface BulletPointsComponent {
  (props: BulletPointsProps): ReactElement | null;
  Item: typeof BulletPointsItem;
  Title: typeof BulletPointsTitle;
  Description: typeof BulletPointsDescription;
}

export const BulletPoints: BulletPointsComponent = ({
  image,
  children,
  padding = false,
}) => {
  return (
    <Root className={classNames({ 'ion-padding': padding })}>
      {!!image && <Asset src={IMAGES[image.name]} width={image.width} />}
      <List>{children}</List>
    </Root>
  );
};

BulletPoints.Item = BulletPointsItem;
BulletPoints.Title = BulletPointsTitle;
BulletPoints.Description = BulletPointsDescription;
