import styled from 'styled-components';

export const Root = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  background-color: ${({ theme: { palette } }) => palette.periwinkle200};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  justify-content: center;
  align-items: center;
`;

export const Image = styled('img')`
  height: 140px;
  width: auto;
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
`;
