import styled from 'styled-components';
import { IonBadge } from '@ionic/react';
import { UiKitVariant } from '../../types/uikit.types';
import { VARIANT_BACKGROUND_MAP, VARIANT_TEXT_MAP } from './Badge.const';

interface RootProps {
  variant: UiKitVariant;
}

export const Root = styled(IonBadge)<RootProps>`
  font-weight: 500;
  white-space: normal;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize(14)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  border-radius: 13px;
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  --color: ${({ theme, variant }) => theme.palette[VARIANT_TEXT_MAP[variant]]};
  --background: ${({ theme, variant }) =>
    theme.palette[VARIANT_BACKGROUND_MAP[variant]]};
  --padding-start: ${({ theme }) => theme.spacing(3)};
  --padding-end: ${({ theme }) => theme.spacing(3)};
`;
