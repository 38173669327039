import { FC, PropsWithChildren } from 'react';
import { Root } from './ToolbarTitle.style';

type ToolbarTitleProps = PropsWithChildren<{
  center?: boolean;
}>;

export const ToolbarTitle: FC<ToolbarTitleProps> = ({
  children,
  center = false,
}) => {
  return <Root center={center}>{children}</Root>;
};
