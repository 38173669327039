import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { Root } from './ToolbarButton.style';

type ToolbarButtonProps = PropsWithChildren<{
  onClick?: () => void;
}>;

export const ToolbarButton: FC<ToolbarButtonProps> = ({
  children,
  onClick = noop,
}) => {
  return <Root onClick={onClick}>{children}</Root>;
};
