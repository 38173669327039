import { FC } from 'react';
import { useRoute, Route } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { useGetPlans, useGetPayors } from '@helpful/network';
import { Page, Content, Header } from '@wearehelpful/ui-kit';
import { SignUpRouteSearch } from './SignUp.types';
import { Index } from './Index/Index';
import { Plan } from './Plan/Plan';
import { Payor } from './Payor/Payor';
import { SignUpContext } from './SignUp.context';

export const SignUp: FC = () => {
  const [{ pathname }] = useRoute<{}, SignUpRouteSearch>();

  const { data: plans } = useGetPlans(
    { take: 1000, relations: 'payor', order: 'name:ASC' },
    {
      query: {
        initialData: {
          data: [],
          meta: { total: 0 },
        },
      },
    }
  );

  const { data: payors } = useGetPayors(
    {},
    {
      query: {
        initialData: {
          data: [],
          meta: {
            total: 0,
          },
        },
      },
    }
  );

  const progress = [
    null,
    RoutePath.AUTH_SIGN_UP,
    RoutePath.AUTH_SIGN_UP_PAYOR,
    RoutePath.AUTH_SIGN_UP_PLAN,
  ];

  const active = progress.findIndex((v) => v === pathname) + 1;

  return (
    <Page headerBackground="periwinkle300">
      <SignUpContext.Provider
        value={{ plans: plans.data, payors: payors.data }}
      >
        <Header>
          <Header.Back defaultHref={RoutePath.AUTH} />
          <Header.Logo />
        </Header>
        <Content>
          <Content.Progress value={active / progress.length} />
          <Route path={RoutePath.AUTH_SIGN_UP} exact>
            <Index />
          </Route>
          <Route path={RoutePath.AUTH_SIGN_UP_PAYOR}>
            <Payor />
          </Route>
          <Route path={RoutePath.AUTH_SIGN_UP_PLAN}>
            <Plan />
          </Route>
        </Content>
      </SignUpContext.Provider>
    </Page>
  );
};
