import { FC, Key, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { stringify } from 'qs';
import { generatePath } from 'react-router';
import { Root } from './Link.style';

type LinkProps = PropsWithChildren<{
  path: string;
  className?: string;
  onClick?: () => void;
  search?: Record<string, unknown>;
  params?: Record<string, Key>;
}>;

export const Link: FC<LinkProps> = ({
  path,
  search,
  children,
  className,
  params = {},
  onClick = noop,
}) => {
  return (
    <Root
      to={{
        pathname: generatePath(path, params),
        search: stringify(search, { addQueryPrefix: true }),
      }}
      onClick={onClick}
      className={className}
    >
      {children}
    </Root>
  );
};
