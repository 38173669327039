import styled from 'styled-components';
import {
  Heading3 as CommonHeading3,
  Paragraph as CommonParagraph,
} from '@bootstrap/components';

export const Info = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(8)} 0;
`;

export const List = styled('ul')`
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  margin: 0 0 ${({ theme: { spacing } }) => spacing(6)} 0;
  padding: 0;
  list-style: none;
  position: relative;
`;

export const ListItem = styled('li')`
  margin: 0;
  padding: 0 0 0 ${({ theme: { spacing } }) => spacing(12)};
`;

export const Heading3 = styled(CommonHeading3)`
  margin: 0 0 ${({ theme: { spacing } }) => spacing(1)} 0;
`;

export const Paragraph = styled(CommonParagraph)`
  margin: 0;
`;

export const CheckMark = styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  background-color: ${({ theme }) => theme.palette.periwinkle300};
`;
