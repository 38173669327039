import { Dispatch, SetStateAction } from 'react';
import { Preference } from '@common/constants';
import { useLocalStorage } from 'react-use';

interface UsePayorPreferenceState {
  slug?: string;
}

type UsePayorPreferenceDispatch = Dispatch<
  SetStateAction<UsePayorPreferenceState>
>;

type UsePayorPreferenceReturnType = [
  UsePayorPreferenceState,
  UsePayorPreferenceDispatch
];

export const usePayorPreference = (): UsePayorPreferenceReturnType => {
  const [, dispatch] = useLocalStorage<UsePayorPreferenceState>(
    Preference.PAYOR,
    {}
  );

  const value = localStorage.getItem(Preference.PAYOR);

  const state = JSON.parse(value) as UsePayorPreferenceState;

  return [state, dispatch];
};
