import { RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useFlagedOptions } from '@helpful/flags';
import { MedicalListItem } from './Index.types';

export const useOptions = () => {
  const { t } = useTranslation();
  return useFlagedOptions<MedicalListItem>({
    key: 'disabled',
    callback: (v) => !v,
    options: [
      {
        icon: 'calendar',
        title: t('subheadings.careplans.title'),
        path: RoutePath.ROOT_MEDICAL_VISIT_SUMMARIES,
      },
      {
        icon: 'medication',
        title: t('subheadings.medications.title'),
        path: RoutePath.ROOT_MEDICAL_MEDICATIONS,
      },
      {
        icon: 'allergy',
        title: t('subheadings.allergies.title'),
        path: RoutePath.ROOT_MEDICAL_ALLERGIES,
      },
      {
        icon: 'provider',
        title: t('subheadings.careTeam.title'),
        path: RoutePath.ROOT_MEDICAL_CARE_TEAM,
      },
      {
        icon: 'condition',
        title: t('subheadings.conditions.title'),
        path: RoutePath.ROOT_MEDICAL_CONDITIONS,
      },
    ],
  });
};
