import { FC, PropsWithChildren } from 'react';
import { Root } from './AccordionContent.style';

type AccordionContentProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
}>;

export const AccordionContent: FC<AccordionContentProps> = ({
  padding,
  children,
  className,
}) => {
  return (
    <Root padding={padding} slot="content" className={className}>
      {children}
    </Root>
  );
};
