import { FC } from 'react';
import {
  Card,
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
  Empty,
  Button,
  Badge,
} from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useMedical, usePatient } from '@common/hooks';
import { ADTO, useGetPatientConditionsInfinite } from '@helpful/network';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';

const TAKE = 20;

export const Conditions: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [{ connections }] = useMedical();
  const { track } = useAnalytics();

  const [, { push }] = useRoute();

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_CONDITIONS });
    push({ path: RoutePath.CONNECT });
  };

  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetPatientConditionsInfinite(
    patient.id,
    {
      skip: 0,
      take: TAKE,
      relations: 'connection.system',
    },
    {
      query: {
        enabled: Boolean(patient.id),
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const conditions = data.pages
    ?.reduce((res, { data }) => [...res, ...data], [] as ADTO.ConditionEntity[])
    .filter(
      (condition) =>
        condition.status !== 'entered-in-error' &&
        condition.status !== 'inactive' &&
        condition.status !== 'refuted'
    );

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.CONDITION}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading2>
              {t('subheadings.conditions.title')}
            </Typography.Heading2>
          </Subheader>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching}>
              {!!connections.length && !conditions?.length && (
                <Empty padding>
                  <Empty.Message>
                    {t('empty.noConditionsDetected')}
                  </Empty.Message>
                  <Empty.Asset />
                </Empty>
              )}
              {!connections.length && (
                <Empty padding size="md">
                  <Empty.Asset name="CONDITION_SCREEN" width={298} />
                  <Empty.Message>
                    {t('prompts.connectMedical.conditions')}
                  </Empty.Message>
                  <Button variant="tertiary" onClick={handleOnConnect}>
                    {t('actions.connectMedicalRecords')}
                  </Button>
                </Empty>
              )}
              {!!conditions?.length && (
                <InfiniteScroll
                  onScroll={fetchNextPage}
                  disabled={!hasNextPage}
                >
                  {conditions.map(({ id, name, connection }) => {
                    return (
                      <Card divider flat key={id}>
                        <Card.Icon name="condition" />
                        <Card.Header>
                          <Card.Title>{name}</Card.Title>
                        </Card.Header>
                        <Card.Footer justifyContent="flex-start">
                          <Badge variant="primary">
                            {connection?.system?.name}
                          </Badge>
                        </Card.Footer>
                      </Card>
                    );
                  })}
                </InfiniteScroll>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
