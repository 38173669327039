import { FC } from 'react';
import { noop } from 'lodash';
import { IonButtons, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { Close } from './ToolbarClose.style';

interface ToolbarCloseProps {
  onClick?: () => void;
  className?: string;
  slot?: 'start' | 'end';
}

export const ToolbarClose: FC<ToolbarCloseProps> = ({
  onClick = noop,
  className,
  slot,
}) => {
  return (
    <IonButtons slot={slot} className={className}>
      <Close onClick={onClick}>
        <IonIcon icon={close} />
      </Close>
    </IonButtons>
  );
};
