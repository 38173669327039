import { useContext, useContextSelector } from 'use-context-selector';
import { useGetPatientTodosInfinite } from '@helpful/network';
import {
  HomeContext,
  HomeContextProps,
  HomeReorderParams,
} from '../../contexts/Home.context';
import { RootContext } from '../../contexts/Root.context';

interface UseHomeState
  extends Omit<HomeContextProps, 'refetch' | 'fetchNextPage' | 'reorder'> {}

interface UseHomeDispatch {
  reorder: (params: HomeReorderParams) => void;
  fetchNextPage: ReturnType<typeof useGetPatientTodosInfinite>['fetchNextPage'];
  refetch: ReturnType<typeof useGetPatientTodosInfinite>['refetch'];
}

type UseBenefitsReturnType = [UseHomeState, UseHomeDispatch];

export const useHome = (): UseBenefitsReturnType => {
  const {
    todos,
    total,
    refetch,
    reorder,
    hasNextPage,
    fetchNextPage,
    loading: homeLoading,
  } = useContext(HomeContext);

  const loading = useContextSelector(RootContext, ({ isRootLoading }) => {
    return homeLoading || isRootLoading;
  });

  return [
    { todos, loading, total, hasNextPage },
    { refetch, fetchNextPage, reorder },
  ];
};
