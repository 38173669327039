import { forwardRef } from 'react';
import { useEffectOnce } from 'react-use';
import {
  Searchbar,
  type SearchbarInstance,
  type SearchbarProps,
} from '@core/ui';
import { useRoute } from '@core/navigation';

export const SearchbarWithStateInURL = forwardRef<
  SearchbarInstance,
  SearchbarProps
>(({ value, onSearch, ...rest }, ref) => {
  const [{ search, pathname }, { push }] = useRoute<
    object,
    { query: string }
  >();

  const handleOnSearch = (query: string) => {
    onSearch(query);
    if (query) {
      push({
        path: pathname,
        search: { ...search, query },
      });
    } else {
      push({
        path: pathname,
        search: { ...search, query: undefined },
      });
    }
  };

  useEffectOnce(() => {
    handleOnSearch(search?.query?.toString());
  });

  return (
    <Searchbar
      ref={ref}
      value={search?.query || value}
      onSearch={handleOnSearch}
      {...rest}
    />
  );
});
