import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { submitNewPassword } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { IResetPasswordRequest } from '../../types/custom-dto.type';

type UseResetPasswordReturnType = [
  {
    data?: boolean;
    error?: Error;
    loading: boolean;
  },
  UseMutateFunction<boolean, Error, IResetPasswordRequest, unknown>
];

export const useResetPassword = (): UseResetPasswordReturnType => {
  const {
    data,
    error,
    isLoading: loading,
    mutate,
  } = useMutation<boolean, Error, IResetPasswordRequest>((req) => {
    return submitNewPassword({
      formFields: Object.entries(req).map(([id, value]) => ({
        id,
        value,
      })),
    }).then((data) => {
      if (data.status === 'OK') {
        return true;
      } else if (data.status === 'FIELD_ERROR' && data.formFields) {
        throw new Error(data.formFields[0].error);
      } else if (data.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        throw new Error('Password reset failed. Please try again');
      } else {
        throw new Error('Unknown error');
      }
    });
  });

  return [{ data, error, loading }, mutate];
};
