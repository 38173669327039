import { UseMutation } from '../../../network/types/hooks.types';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  ICaregiverPartialDto,
  IIdString,
} from '../../__generated__/data-contracts';

export const useUpdateUser = (): UseMutation<
  [string, ICaregiverPartialDto],
  IIdString,
  unknown
> => {
  return useMutation<[string, ICaregiverPartialDto], IIdString>(
    ({ Caregivers }) => Caregivers.caregiverControllerPatchCaregiver
  );
};
