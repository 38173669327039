import { PropsWithChildren, ReactElement } from 'react';
import { AccordionItem } from './AccordionItem/AccordionItem';
import { AccordionHeader } from './AccordionHeader/AccordionHeader';
import { AccordionContent } from './AccordionContent/AccordionContent';
import { Root } from './Accordion.style';

type AccordionProps = PropsWithChildren<{}>;

interface AccordionComponent {
  (props: AccordionProps): ReactElement | null;
  Item: typeof AccordionItem;
  Header: typeof AccordionHeader;
  Content: typeof AccordionContent;
}

export const Accordion: AccordionComponent = ({ children }) => {
  return (
    <Root>
      {children}
      <AccordionItem />
    </Root>
  );
};

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;
