import styled from 'styled-components';

export const Root = styled('div')<{ count: number }>`
  display: grid;
  overflow-x: scroll;
  gap: ${({ theme: { spacing } }) => spacing(3)};
  grid-template-columns: repeat(${({ count }) => count}, 144px);
  padding-top: ${({ theme: { spacing } }) => spacing(1)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  p {
    word-break: break-word;
  }
`;

export const Item = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme: { spacing } }) => spacing(0.5)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  background: ${({ theme }) => theme.palette.periwinkle200};
  padding: ${({ theme: { spacing } }) => spacing(2)}
    ${({ theme: { spacing } }) => spacing(1)};
`;
