import { FC } from 'react';
import { useUser } from '@core/user';
import { AnalyticsPageCategory, AnalyticsTrackEvents } from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useUpdatePatient } from '@helpful/network';
import { Typography, Content, Form } from '@wearehelpful/ui-kit';
import { usePatient } from '@common/hooks';
import { ContactUs } from '../_partitions/ContactUs/ContactUs';
import { useVerifyEmailPlan } from './Plan.hooks';
import { PlanForm } from './Plan.types';
import { MultiPlanNotSuplimental } from './_partitions/MultiPlanNotSuplimental/MultiPlanNotSuplimental';
import { SinglePlanNotSuplimental } from './_partitions/SinglePlanNotSuplimental/SinglePlanNotSuplimental';
import { MultiPlanSupplemental } from './_partitions/MultiPlanSupplemental/MultiPlanSupplemental';
import { SinglePlanSuplimental } from './_partitions/SinglePlanSuplimental/SinglePlanSuplimental';
import { SinglePlanDefault } from './_partitions/SindlePlanDefault/SindlePlanDefault';

export const Plan: FC = () => {
  const [, refetch] = useUser();
  const [{ patient }] = usePatient();
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const [{ payor, config, plans, defaultValues }] = useVerifyEmailPlan();

  const { isLoading: updating, mutate } = useUpdatePatient();

  const handleOnSubmit = ({
    plans = [],
    plan = null,
    supplemental = null,
    supplementals = [],
  }: PlanForm) => {
    mutate(
      {
        id: patient.id,
        data: {
          plans: [plan, supplemental, ...plans, ...supplementals]
            .filter((id) => !!id)
            .map((id) => ({ id })),
        },
      },
      {
        onSuccess: () => {
          track({
            id: AnalyticsTrackEvents.PLAN_SELECTED,
            properties: {
              payor: payor,
              plans: [...plans, plan].filter((v) => !!v).join(','),
            },
          }).finally(() => {
            refetch();
          });
        },
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.SELECT_PLAN}
    >
      <Content.Subheader>
        <Typography variant="h2">
          {t('subheadings.signUpPlan.title', { payor })}
        </Typography>
      </Content.Subheader>
      <Content.Fixed>
        <Form<PlanForm> onSubmit={handleOnSubmit} defaultValues={defaultValues}>
          <MultiPlanNotSuplimental config={config} plans={plans} />
          <SinglePlanNotSuplimental config={config} plans={plans} />
          <MultiPlanSupplemental config={config} plans={plans} />
          <SinglePlanSuplimental config={config} plans={plans} />
          <SinglePlanDefault config={config} plans={plans} />
          <Form.Actions inset="sm">
            <Form.Submit checkValidity loading={updating}>
              {t('actions.letsGetHelpful')}
            </Form.Submit>
            <ContactUs>{t('actions.contactHelpful')}</ContactUs>
          </Form.Actions>
        </Form>
      </Content.Fixed>
    </TrackPageView>
  );
};
