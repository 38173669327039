import { FC, PropsWithChildren, Key } from 'react';
import { Root, Radio, Label } from './FormRadioGroupItem.style';

type FormRadioGroupItemProps = PropsWithChildren<{
  value: Key;
  disabled?: boolean;
}>;

export const FormRadioGroupItem: FC<FormRadioGroupItemProps> = ({
  value,
  children,
  disabled = false,
}) => {
  return (
    <Root disabled={disabled}>
      <Radio slot="start" value={value} />
      <Label>{children}</Label>
    </Root>
  );
};
