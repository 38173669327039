import styled from 'styled-components';
import { Card } from '@core/ui';

export const Preview = styled(Card)`
  box-shadow: none;
`;

export const LongDescription = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)};

  img {
    display: block;
    margin: 0 auto;
  }
`;
