import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

const RootWebDesctopMixin = css`
  padding-bottom: ${({ theme: { spacing } }) => spacing(8)};
  .swiper-pagination {
    height: 32px;
  }
`;

export const Root = styled(Swiper).withConfig({
  shouldForwardProp: (prop) => !['isWebDesktop'].includes(prop),
})<{ isWebDesktop: boolean }>`
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-horizontal-gap: ${({ theme: { spacing } }) =>
    spacing(1.25)};
  --swiper-pagination-bullet-width: ${({ theme: { spacing } }) => spacing(2.5)};
  --swiper-pagination-bullet-height: ${({ theme: { spacing } }) =>
    spacing(2.5)};
  --swiper-pagination-bullet-inactive-color: ${({ theme }) =>
    theme.palette.periwinkle200};
  --swiper-pagination-color: ${({ theme }) => theme.palette.periwinkle500};
  --swiper-pagination-bullet-inactive-opacity: 1;
  padding-bottom: ${({ theme: { spacing } }) => spacing(5)};
  ${({ isWebDesktop }) => isWebDesktop && RootWebDesctopMixin}
`;
