import { UiKitVariant, UiKitColor } from '../../types/uikit.types';

export const VARIANT_BACKGROUND_MAP: Record<UiKitVariant, UiKitColor> = {
  error: 'red400',
  warning: 'orange200',
  primary: 'periwinkle100',
  secondary: 'yellow400',
};

export const VARIANT_TEXT_MAP: Record<UiKitVariant, UiKitColor> = {
  error: 'white100',
  warning: 'brown400',
  primary: 'brown400',
  secondary: 'brown400',
};
