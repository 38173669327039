import { FC } from 'react';
import { Page } from '@core/ui';
import { RoutePath } from '@common/constants';
import { Outlet, Route } from '@core/navigation';
import { Redirect } from './Redirect/Redirect';
import { Index } from './Index/Index';

export const Connect: FC = () => {
  return (
    <Page>
      <Outlet>
        <Route path={RoutePath.CONNECT} exact>
          <Index />
        </Route>
        <Route path={RoutePath.CONNECT_REDIRECT}>
          <Redirect />
        </Route>
      </Outlet>
    </Page>
  );
};
