import styled from 'styled-components';
import { Link as Nav } from '@core/navigation';

export const Gutter = styled('div')`
  height: ${({ theme: { spacing } }) => spacing(15)};
`;

export const Link = styled(Nav)`
  color: ${({ theme }) => theme.palette.blue400};
  font-weight: 700;
`;
