import styled from 'styled-components';
import { Typography } from '@core/ui';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Title = styled(Typography.Body1)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(8)};
`;

export const Description = styled(Typography.Body1)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(12)};
`;

export const Note = styled(Typography.Body1)``;

export const Image = styled('img')`
  width: 216px;
  margin-bottom: ${({ theme: { spacing } }) => spacing(8)};
`;

export const Hint = styled(Typography.Body1)`
  margin-top: ${({ theme: { spacing } }) => spacing(8)};
`;
