import styled from 'styled-components';

interface RootProps {
  width: number | string;
  height: number | string;
}

export const Root = styled('img')<RootProps>(({ width, height }) => ({
  width: '100%',
  height: '100%',
  maxWidth: width,
  maxHeight: height,
  alignSelf: 'center',
}));
