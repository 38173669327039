import { FC, useCallback, useEffect } from 'react';
import {
  useImportPatientToDos,
  useGetPatientTodosInfinite,
  useGetTodoListQuery,
} from '@helpful/network';
import { useRoute } from '@core/navigation';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
  UserRole,
} from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { usePatient } from '@common/hooks';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import {
  Card,
  Content,
  Form,
  Header,
  Page,
  Skeleton,
  Typography,
  useUtils,
} from '@wearehelpful/ui-kit';
import { Footer, Toolbar } from '@core/ui';
import { useUser } from '@core/user';
import { useRedirectAfterAuth } from '@common/features';
import { CareplanSelectForm } from './Select.types';
import {
  ButtonHint,
  Subheader,
  Heading,
  Image,
  ContentFixed,
  Description,
} from './Select.style';
import handWithHeart from './assets/hand-with-heart.svg?url';

export const Select: FC = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [{ roles }] = useUser();
  const [{ patient, plans }] = usePatient();
  const { toast, confirm } = useUtils();
  const { mutate: importToDos, isLoading: isImportLoading } =
    useImportPatientToDos();
  const [redirectAfterAuth, setRedirectAfterAuth, clearRedirectAfterAuth] =
    useRedirectAfterAuth();

  const {
    isFetching: isPatientTodosFetching,
    data: { pages: todos },
  } = useGetPatientTodosInfinite(
    patient?.id,
    { take: 100 },
    {
      query: {
        select: ({ pages, pageParams }) => ({
          pages: pages
            .flatMap(({ data }) => data)
            .map(({ template, title }) => ({ id: template, title })),
          pageParams,
        }),
        enabled: !!patient?.id,
        initialData: {
          pages: [],
          pageParams: [],
        },
      },
    }
  );

  const [
    {
      params: { id: experienceId },
    },
  ] = useRoute<{ id: string }, {}>();

  const { data, isFetching: isTodoListFetching } = useGetTodoListQuery(
    { experienceId, plans },
    {
      select: ({ todoListCollection }) => {
        const [todoList] = todoListCollection.items;

        return {
          ...todoList,
          todoCards: todoList?.todoCardsCollection?.items.filter(
            (item) => !todos.find((todo) => todo.id === item.sys.id)
          ),
        };
      },
      initialData: {
        todoListCollection: {
          items: [],
        },
      },
    }
  );

  const handleTodosSubmit = useCallback(
    (todosTitlePosition: Array<{ title: string; position: number }>) => {
      const todosTitlePositionId = todosTitlePosition
        ?.map((todoCard) => {
          const matchingTodo = data.todoCards.find(
            (item) => item.title === todoCard.title
          );

          if (!matchingTodo) {
            return undefined;
          }

          return { id: matchingTodo.sys.id, ...todoCard };
        })
        .filter(Boolean);

      if (todosTitlePositionId.length === 0) {
        return;
      }

      const todoIds = todosTitlePositionId.map((item) => item.id);

      importToDos(
        { id: patient.id, data: todoIds },
        {
          onSuccess: () => {
            Promise.allSettled(
              todosTitlePositionId.map((item) => {
                return track({
                  id: AnalyticsTrackEvents.CAREPLAN_TODO_SELECTED,
                  properties: {
                    id: item.id,
                    title: item.title,
                    position: item.position,
                  },
                });
              })
            ).finally(() => {
              toast({
                message: t('notifications.careplanTodosSuccessfullyAdded'),
                variant: 'success',
              });
              push({ path: RoutePath.ROOT_HOME });
            });
          },
        }
      );
    },
    [data?.todoCards, patient.id, t, importToDos, push, toast, track]
  );

  useEffect(() => {
    const queriesAreFinished =
      !isPatientTodosFetching && !isTodoListFetching && !isImportLoading;

    if (
      roles.includes(UserRole.USER) &&
      queriesAreFinished &&
      redirectAfterAuth &&
      'todosTitlePosition' in redirectAfterAuth.state &&
      Array.isArray(redirectAfterAuth.state.todosTitlePosition)
    ) {
      const todosTitlePositionWithoutUserTodos =
        redirectAfterAuth.state.todosTitlePosition.filter(
          (item) => !todos.find((todo) => item?.title === todo.title)
        );

      clearRedirectAfterAuth();

      if (todosTitlePositionWithoutUserTodos.length === 0) {
        push({ path: RoutePath.ROOT_HOME });
      } else {
        handleTodosSubmit(todosTitlePositionWithoutUserTodos);
      }
    }
  }, [
    roles,
    isPatientTodosFetching,
    isTodoListFetching,
    todos,
    redirectAfterAuth,
    isImportLoading,
    push,
    clearRedirectAfterAuth,
    handleTodosSubmit,
  ]);

  const handleOnSubmit = ({ todosTitlePosition }: CareplanSelectForm) => {
    const todosTitlePositionDeserialized = todosTitlePosition.map<{
      title: string;
      position: number;
    }>((item) => JSON.parse(item));

    if (roles.includes(UserRole.NONE)) {
      setRedirectAfterAuth({
        path: RoutePath.CAREPLAN_SELECT,
        params: { id: experienceId },
        state: { todosTitlePosition: todosTitlePositionDeserialized },
      });
      push({ path: RoutePath.AUTH });
      return;
    }

    handleTodosSubmit(todosTitlePositionDeserialized);
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CAREPLAN}
      name={AnalyticsPageCategory.TODOS_SELECTION}
    >
      <Page>
        <Form<CareplanSelectForm> onSubmit={handleOnSubmit}>
          <Header>
            <Toolbar.Close
              slot="end"
              onClick={() =>
                confirm({
                  message: t('notifications.unsavedChangesLeaveConfirm'),
                  onConfirm: async () => push({ path: RoutePath.ROOT_HOME }),
                })
              }
            />
          </Header>
          <Content>
            <Subheader divider gap="md">
              <Heading variant="h2">
                {t('subheadings.careplanSelection.title')}
                <Image src={handWithHeart} width="94" height="94" alt="" />
              </Heading>
              <Typography variant="p1">
                <strong>{data?.title}</strong>
              </Typography>
              <Description variant="p1">{data?.description}</Description>
            </Subheader>
            <ContentFixed>
              <Skeleton
                variant="list"
                loading={isPatientTodosFetching || isTodoListFetching}
              >
                <Form.CheckboxToggle
                  name="todosTitlePosition"
                  spacing="none"
                  validation={{ required: true }}
                >
                  {data?.todoCards?.map(
                    ({ sys: { id }, title, shortDescription }, index) => (
                      <Card key={id} variant="flat" divider>
                        <Card.Icon name="helpful" />
                        <Card.Header>
                          <Card.Title>
                            {t('forms.titleWithOrder', {
                              title,
                              order: index + 1,
                            })}
                          </Card.Title>
                        </Card.Header>
                        <Card.Content>{shortDescription}</Card.Content>
                        <Card.Footer>
                          <Form.CheckboxToggle.Item
                            value={JSON.stringify({
                              title,
                              position: index + 1,
                            })}
                            icon="plus"
                          />
                        </Card.Footer>
                      </Card>
                    )
                  )}
                </Form.CheckboxToggle>
              </Skeleton>
            </ContentFixed>
          </Content>
          <Footer>
            <Toolbar padding>
              <Form.Submit loading={isImportLoading} checkValidity>
                {t('actions.addToCareplan')}
              </Form.Submit>
              <ButtonHint variant="p2" align="center">
                {t('hints.careplanTodosRequired')}
              </ButtonHint>
            </Toolbar>
          </Footer>
        </Form>
      </Page>
    </TrackPageView>
  );
};
