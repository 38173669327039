import styled from 'styled-components';
import { IonNote, IonItem } from '@ionic/react';

export const Errors = styled(IonItem)`
  --min-height: 0;
  --highlight-color-focused: ${({ theme }) => theme.palette.periwinkle300};
  --highlight-color-invalid: ${({ theme }) => theme.palette.red400};
`;

export const Root = styled('div')`
  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
`;

export const Error = styled(IonNote)<{ withError: boolean }>`
  display: ${({ withError }) => (withError ? 'block' : 'none')};
`;
