import { useTranslation } from '@wearehelpful/ui-localization';
import { object, string } from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const validationSchema = object().shape({
    system: string()
      .label(t('forms.healthNetwork'))
      .required(t('validation.required', { label: t('forms.healthNetwork') })),
  });

  return { validationSchema };
};
