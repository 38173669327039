import { FC } from 'react';
import {
  Card,
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
  Empty,
  Button,
  Badge,
} from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useMedical, usePatient } from '@common/hooks';
import { ADTO, useGetPatientCareteamsInfinite } from '@helpful/network';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';

const TAKE = 20;

interface IProvider extends ADTO.ProviderEntity {
  connection?: ADTO.ConnectionEntity;
}

export const CareTeam: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [{ connections }] = useMedical();
  const { track } = useAnalytics();
  const [, { push }] = useRoute();

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_CARE_TEAM }).finally(
      () => {
        push({ path: RoutePath.CONNECT });
      }
    );
  };

  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetPatientCareteamsInfinite(
    patient.id,
    {
      skip: 0,
      take: TAKE,
      relations: 'connection.system, providers',
    },
    {
      query: {
        enabled: Boolean(patient.id),
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const careTeams = data.pages
    ?.reduce((res, { data }) => [...res, ...data], [] as ADTO.CareteamEntity[])
    .map((careTeam) => {
      return careTeam.providers.map(
        (provider): IProvider => ({
          ...provider,
          connection: careTeam.connection,
        })
      );
    })
    .flat();

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.CARETEAM}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading2>
              {t('subheadings.careTeam.title')}
            </Typography.Heading2>
          </Subheader>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching}>
              {!!connections.length && !careTeams?.length && (
                <Empty padding>
                  <Empty.Message>{t('empty.noCareTeamDetected')}</Empty.Message>
                  <Empty.Asset />
                </Empty>
              )}
              {!connections.length && (
                <Empty padding size="md">
                  <Empty.Asset name="CARETEAM_SCREEN" width={298} />
                  <Empty.Message>
                    {t('prompts.connectMedical.careTeam')}
                  </Empty.Message>
                  <Button variant="tertiary" onClick={handleOnConnect}>
                    {t('actions.connectMedicalRecords')}
                  </Button>
                </Empty>
              )}
              {!!careTeams?.length && (
                <InfiniteScroll
                  onScroll={fetchNextPage}
                  disabled={!hasNextPage}
                >
                  {careTeams.map(
                    ({
                      id,
                      firstName,
                      lastName,
                      suffix,
                      prefix,
                      specialty,
                      connection,
                    }) => {
                      const name = [prefix, firstName, lastName, suffix].join(
                        ' '
                      );
                      return (
                        <Card divider flat key={id}>
                          <Card.Icon name="provider" />
                          <Card.Header>
                            <Card.Title>{name}</Card.Title>
                          </Card.Header>
                          <Card.Content truncate={6}>{specialty}</Card.Content>
                          <Card.Footer justifyContent="flex-start">
                            <Badge variant="primary">
                              {connection?.system?.name}
                            </Badge>
                          </Card.Footer>
                        </Card>
                      );
                    }
                  )}
                </InfiniteScroll>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
