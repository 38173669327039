import styled, { css } from 'styled-components';
import { IonButton } from '@ionic/react';
import { Typography } from '../Typography/Typography';

interface RootProps {
  margin: boolean;
  hasValue: boolean;
}

const RootMarginMixin = css`
  margin: ${({ theme: { spacing } }) => spacing(4)};
`;

const RootWithValueMixin = css`
  padding-left: ${({ theme: { spacing } }) => spacing(5)};
  background-color: ${({ theme }) => theme.palette.neutral100};
`;

export const Root = styled('div')<RootProps>`
  display: flex;
  align-items: center;
  height: ${({ theme: { spacing } }) => spacing(14)};
  border: 1px solid ${({ theme }) => theme.palette.neutral300};
  border-radius: ${({ theme: { spacing } }) => spacing(7)};
  padding: 0 ${({ theme: { spacing } }) => spacing(3)};
  gap: ${({ theme: { spacing } }) => spacing(3)};
  ${({ margin }) => margin && RootMarginMixin}
  ${({ hasValue }) => hasValue && RootWithValueMixin}
`;

export const Default = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Description = styled(Typography.Body3)`
  color: #6b7280;
`;

export const Button = styled(IonButton)`
  --border-width: 1px;
  --color: ${({ theme }) => theme.palette.brown400};
  --border-radius: ${({ theme: { spacing } }) => spacing(4)};
  --border-color: ${({ theme }) => theme.palette.neutral300};
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
