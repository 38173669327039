import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { sendPasswordResetEmail } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { IForgotPasswordRequest } from '../../types/custom-dto.type';

type UseForgotPasswordReturnType = [
  {
    data?: boolean;
    loading: boolean;
  },
  UseMutateFunction<boolean, unknown, IForgotPasswordRequest, unknown>
];

export const useForgotPassword = (): UseForgotPasswordReturnType => {
  const {
    data,
    isLoading: loading,
    mutate,
  } = useMutation<boolean, {}, IForgotPasswordRequest>((req) => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail({
        formFields: Object.entries(req).map(([id, value]) => ({
          id,
          value,
        })),
      }).then((data) => {
        if (data.status === 'OK') {
          resolve(true);
        } else if (data.status === 'FIELD_ERROR' && data.formFields) {
          reject(data.formFields[0].error);
        }
      });
    });
  });

  return [{ data, loading }, mutate];
};
