import styled from 'styled-components';
import { IonInput, IonIcon } from '@ionic/react';

export const Root = styled(IonInput)``;

export const Icon = styled(IonIcon)`
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  padding: ${({ theme: { spacing } }) => spacing(3)};
  padding-top: ${({ theme: { spacing } }) => spacing(9)};
  margin: 0px -12px -12px 0px;
`;
