import { get } from 'lodash';
import { useContext } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { IChangePasswordDto } from '../../types/custom-dto.type';
import { HttpContext } from '../../contexts/Http.context';
import { AuthContext } from '../../contexts/Auth.context';

type UseChangePasswordReturnType = [
  { data?: boolean; loading: boolean; error?: Error },
  UseMutateFunction<boolean, Error, IChangePasswordDto>
];

export const useChangePassword = (): UseChangePasswordReturnType => {
  const { Auth } = useContext(HttpContext);

  const responseErrorInterceptor = useContextSelector(
    AuthContext,
    ({ responseErrorInterceptor }) => responseErrorInterceptor
  );

  const {
    data,
    error,
    isLoading: loading,
    mutate,
  } = useMutation<boolean, Error, IChangePasswordDto>((params) => {
    return new Promise<boolean>((resolve, reject) => {
      Auth.changePassword(params)
        .then(({ data }) => {
          if (data.status === 'OK') {
            resolve(true);
          } else if (data.status === 'FIELD_ERROR' && data.formFields) {
            reject(new Error(data.formFields[0].error));
          }
        })
        .catch((err) => {
          const message = get(err, ['response', 'data', 'message']);
          reject(new Error(message));
        });
    }).catch((err) => {
      return Promise.reject(responseErrorInterceptor(err));
    });
  });

  return [{ data, loading, error }, mutate];
};
