import styled from 'styled-components';
import { Subheader } from '@core/ui';

export const Root = styled('div')`
  padding-top: ${({ theme: { spacing } }) => spacing(10)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(10)};
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
  background: ${({ theme }) => theme.palette.periwinkle200};
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(6)};
`;

export const Header = styled(Subheader)`
  padding: 0;
  > div {
    gap: ${({ theme: { spacing } }) => spacing(3)};
  }
`;

export const Image = styled('div')`
  display: flex;
  justify-content: center;
`;

export const Content = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.black};
  border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
`;
