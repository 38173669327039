import { get } from 'lodash';

interface GetInitialsParams {
  firstName: string;
  lastName: string;
}

export const getInitials = ({
  firstName = '',
  lastName: initialLastName = '',
}: GetInitialsParams): string => {
  const [, lastName] = firstName.split(' ');
  const first = get(firstName, '0', '');
  const last = get(initialLastName || lastName, '0', '');

  return `${first}${last}`.toUpperCase();
};
