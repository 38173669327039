import styled from 'styled-components';

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Placeholder = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled('img')`
  width: ${({ theme: { spacing } }) => spacing(70)};
`;
