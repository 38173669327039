import { FC, PropsWithChildren } from 'react';
import { UiKitVariant } from '../../../types/uikit.types';
import { Badge } from '../../Badge/Badge';

type ListBadgeProps = PropsWithChildren<{
  variant: UiKitVariant;
  className?: string;
}>;

export const ListBadge: FC<ListBadgeProps> = ({
  children,
  variant,
  className,
}) => {
  return (
    <Badge className={className} variant={variant}>
      {children}
    </Badge>
  );
};
