import { FC } from 'react';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useUser } from '@core/user';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useStaticOptions } from '@common/hooks';
import { useTranslation, useLocale } from '@wearehelpful/ui-localization';
import {
  Page,
  Header,
  Content,
  Typography,
  Form,
  Button,
  useUtils,
} from '@wearehelpful/ui-kit';
import { Link } from '@core/navigation';
import { useUpdateCaregiver, ADTO } from '@helpful/network';

export const Index: FC = () => {
  const [{ user }, refetch] = useUser();
  const { toast } = useUtils();
  const [{ careGivingExperience }] = useStaticOptions();
  const { t } = useTranslation();
  const [{ lng }] = useLocale();

  const { isLoading, mutate } = useUpdateCaregiver();

  const handleOnSubmit = ({
    firstName,
    lastName,
    experience,
  }: ADTO.CaregiverPartialDto) => {
    mutate(
      {
        id: user.id,
        data: { firstName, lastName, experience, locale: user?.locale || lng },
      },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            message: t('notifications.profileSuccessfullyUpdated'),
          });
          refetch();
        },
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MORE}
      name={AnalyticsPageCategory.PROFILE}
    >
      <Page>
        <Header>
          <Header.Back defaultHref={RoutePath.ROOT_MORE} />
        </Header>
        <Content>
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.myAccount.subtitle')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form defaultValues={user} onSubmit={handleOnSubmit}>
              <Form.Text
                name="firstName"
                autocapitalize="on"
                label={t('forms.yourFirstName')}
                validation={{ required: true }}
              />
              <Form.Text
                name="lastName"
                autocapitalize="on"
                label={t('forms.yourLastName')}
                validation={{ required: true }}
              />
              <Form.Select
                name="experience"
                options={careGivingExperience}
                label={t('forms.yourExperienceInCaregiving')}
              />
              <Form.Text name="email" label={t('forms.yourEmail')} disabled />
              <Form.Password
                disabled
                name="password"
                label={t('forms.yourPassword')}
                placeholder="******"
              />
              <Form.Actions>
                <Form.Submit loading={isLoading}>
                  {t('actions.save')}
                </Form.Submit>
                <Button variant="text">
                  <Link path={RoutePath.ROOT_MORE_PROFILE_CHANGE_PASSWORD}>
                    {t('actions.changePassword')}
                  </Link>
                </Button>
              </Form.Actions>
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
