import { FC, PropsWithChildren, CSSProperties } from 'react';
import { Root } from './CardFooter.style';

type CardFooterProps = PropsWithChildren<{
  className?: string;
  justifyContent?: CSSProperties['justifyContent'];
}>;

export const CardFooter: FC<CardFooterProps> = ({
  children,
  justifyContent = 'flex-end',
}) => {
  return <Root justifyContent={justifyContent}>{children}</Root>;
};
