import { FC, PropsWithChildren } from 'react';
import { UiKitColor, UiKitStatus } from '../../../types/uikit.types';
import { Root } from './ChipsItem.style';

type ChipsItemProps = PropsWithChildren<{
  status?: UiKitStatus;
  background?: UiKitColor;
}>;

export const ChipsItem: FC<ChipsItemProps> = ({
  status,
  children,
  background = 'periwinkle100',
}) => {
  return (
    <Root status={status} background={background}>
      {children}
    </Root>
  );
};
