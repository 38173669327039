import styled from 'styled-components';
import { List, UiKitColor } from '@core/ui';
import { SECONDARY_BACKROUND_COLOR_MAP } from './CtaList.const';

interface CommonProps {
  backgroundColor: UiKitColor;
}

export const Root = styled(List)`
  margin-bottom: 0;
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
  padding: 0;
  & + & {
    margin-top: 0;
  }
`;

export const Cta = styled(List.Item)<CommonProps>`
  --background: ${({ theme, backgroundColor }) =>
    theme.palette[backgroundColor]};
`;

export const Container = styled('div')<CommonProps>`
  background: ${({ theme, backgroundColor }) => theme.palette[backgroundColor]};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  padding-top: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Content = styled('div')<CommonProps>`
  padding: ${({ theme: { spacing } }) => spacing(4)};
  background: ${({ theme, backgroundColor }) =>
    theme.palette[SECONDARY_BACKROUND_COLOR_MAP[backgroundColor]]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(1)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
`;
