import { FC } from 'react';
import { Route, Redirect } from '@core/navigation';
import { RoutePath, UserRole } from '@common/constants';
import { Switch } from 'react-router';
import { Welcome } from './welcome/routes/Welcome/Welcome';
import { Insurance } from './insurance/routes/Insurance/Insurance';
import { Feedback } from './feedback/routes/Feedback/Feedback';
import { Root } from './root/routes/Root/Root';
import { Connect } from './connect/routes/Connect/Connect';
import { ContactUs } from './contact-us/routes/ContactUs/ContactUs';
import { Legal } from './legal/routes/Legal/Legal';
import { Careplan } from './careplan/routes/Careplan/Careplan';
import { ToDos } from './todos/routes/ToDos/ToDos';
import { APP_ROUTES } from './App.const';

export const App: FC = () => {
  return (
    <Switch>
      <Route path={RoutePath.ROOT}>
        <Root />
      </Route>
      {APP_ROUTES.map((props, index) => (
        <Route key={index} {...props} />
      ))}
      <Route path={RoutePath.LANDING} roles={[UserRole.NONE]} exact>
        <Welcome />
      </Route>
      <Route path={RoutePath.CONNECT}>
        {(roles) => {
          if (!roles.includes(UserRole.USER)) {
            return <Redirect path={RoutePath.AUTH} />;
          }
          if (!roles.includes(UserRole.VERIFIED)) {
            return <Redirect path={RoutePath.ROOT_MEDICAL_UNVERIFIED} />;
          }
          return <Connect />;
        }}
      </Route>
      <Route path={RoutePath.CAREPLAN}>
        <Careplan />
      </Route>
      <Route
        path={RoutePath.TODOS}
        roles={[UserRole.USER]}
        redirect={RoutePath.AUTH}
      >
        <ToDos />
      </Route>
      <Route path={RoutePath.FEEDBACK}>
        <Feedback />
      </Route>
      <Route path={RoutePath.INSURANCE} roles={[UserRole.USER]}>
        <Insurance />
      </Route>
      <Route path={RoutePath.CONTACT_US}>
        <ContactUs />
      </Route>
      <Route path={RoutePath.LEGAL}>
        <Legal />
      </Route>
    </Switch>
  );
};
