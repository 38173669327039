import { FC } from 'react';
import { Form, useForm } from '@core/ui';
import { DTO, useChangePassword } from '@core/auth';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import {
  Page,
  Content,
  Header,
  Typography,
  useUtils,
} from '@wearehelpful/ui-kit';
import { useValidationSchema } from './ChangePassword.hooks';

export const ChangePassword: FC = () => {
  const form = useForm<DTO.IChangePasswordDto>();
  const { toast } = useUtils();
  const [{ loading }, changePassword] = useChangePassword();
  const [, { replace }] = useRoute();
  const { t } = useTranslation();
  const { validationSchema } = useValidationSchema();

  const handleOnSubmit = ({
    oldPassword,
    newPassword,
  }: DTO.IChangePasswordDto) => {
    changePassword(
      { oldPassword, newPassword },
      {
        onSuccess: () => {
          replace({ path: RoutePath.ROOT_MORE_PROFILE });
          toast({
            variant: 'success',
            message: t('notifications.passwordSuccessfullyUpdated'),
          });
        },
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.PROFILE}
      name={AnalyticsPageCategory.CHANGE_PASSWORD}
    >
      <Page>
        <Header>
          <Header.Back defaultHref={RoutePath.ROOT_MORE_PROFILE} />
        </Header>
        <Content>
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.changePassword.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form
              padding
              form={form}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              <Form.Password
                name="oldPassword"
                label={t('forms.oldPassword')}
              />
              <Form.Password
                tabIndex={4}
                name="newPassword"
                label={t('forms.newPassword')}
                enterKeyHint="next"
                help={[
                  t('hints.passwordHasMinCharacters'),
                  t('hints.passwordContainsNumber'),
                  t('hints.passwordContainsUppercaseLetter'),
                  t('hints.passwordContainsSpecialCharacter'),
                ]}
              />
              <Form.Password
                tabIndex={5}
                name="confirmNewPassword"
                label={t('forms.reEnterPassword')}
                enterKeyHint="go"
              />
              <Form.Submit loading={loading}>{t('actions.save')}</Form.Submit>
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
