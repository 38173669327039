import { ReactNode } from 'react';
import { usePatient } from '@common/hooks';
import { List, UiKitColor } from '@core/ui';
import { useRoute } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useUser } from '@core/user';
import { useSession } from '@core/auth';
import { useNotifications } from '@helpful/messaging';
import { FeatureName, useFlag } from '@helpful/flags';
import { Icon, IconName, useUtils } from '@wearehelpful/ui-kit';
import { useBootstrapData } from '@bootstrap/hooks';
import { getInitials } from './Index.utils';

interface NavItemOption {
  icon: ReactNode;
  title: ReactNode;
  enabled: boolean;
  onClick: () => void;
  subtitle?: ReactNode;
  color?: UiKitColor;
}

interface NavItem {
  title: string;
  enabled: boolean;
  options: NavItemOption[];
}

const ICONS: Record<string, IconName> = {
  terms: 'document',
  'privacy-policy': 'privacy',
};

export const useNavigation = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const [{ user }] = useUser();
  const [{ isSessionExist, isUserVerified }] = useSession();
  const { deleteDevice } = useNotifications();
  const [{ patient }] = usePatient();
  const localeSelection = useFlag(FeatureName.LOCALESELECTION);
  const { policy } = useBootstrapData();
  const { confirm } = useUtils();

  const handleOnLogout = () => {
    confirm({
      icon: 'laptop-with-arrow',
      message: t('confirms.logout'),
      onConfirm: async () => {
        return deleteDevice({ id: user?.email }).then(() =>
          push({ path: RoutePath.AUTH_SIGN_OUT })
        );
      },
    });
  };

  const myAccountOptions: NavItemOption[] = [
    {
      enabled: !isSessionExist,
      color: 'yellow400' as UiKitColor,
      icon: <Icon name="user-add" />,
      title: <List.Title>{t('actions.createAnAccount')}</List.Title>,
      onClick: () => push({ path: RoutePath.AUTH }),
    },
    {
      enabled: isSessionExist,
      icon: (
        <strong>
          {getInitials({
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
        </strong>
      ),
      title: t('subheadings.myAccount.subtitle'),
      onClick: () => push({ path: RoutePath.ROOT_MORE_PROFILE }),
    },
    // {
    //   enabled: isSessionExist,
    //   icon: <Icon name="preferences" />,
    //   title: t('navigation.personalizationPreferences'),
    //   onClick: () => push({ path: RoutePath.ROOT_MORE_PROFILE_PREFERENCES }),
    // },
    {
      enabled: isSessionExist && localeSelection,
      icon: <Icon name="language" />,
      title: t('subheadings.language.title'),
      onClick: () => push({ path: RoutePath.ROOT_MORE_LANGUAGE }),
    },
    {
      color: 'yellow400' as UiKitColor,
      enabled: isSessionExist && !isUserVerified,
      icon: <Icon name="mail" />,
      title: <List.Title>{t('subheadings.verifyAccount.title')}</List.Title>,
      subtitle: (
        <List.Subtitle>
          {t('subheadings.verifyAccount.description')}
        </List.Subtitle>
      ),
      onClick: () => push({ path: RoutePath.ROOT_MEDICAL_UNVERIFIED }),
    },
  ].filter(({ enabled }) => enabled);

  const actionSupportActions: NavItemOption[] = [
    {
      enabled: true,
      icon: <Icon name="faq" />,
      title: t('subheadings.faq.title'),
      onClick: () => push({ path: RoutePath.ROOT_MORE_FAQ }),
    },
    {
      enabled: true,
      icon: <Icon name="mail" />,
      title: t('subheadings.contactUs.title'),
      onClick: () => push({ path: RoutePath.CONTACT_US }),
    },
    {
      enabled: true,
      icon: <Icon name="chat" />,
      title: t('subheadings.feedback.subtitle'),
      onClick: () => push({ path: RoutePath.FEEDBACK }),
    },
    {
      enabled: isSessionExist,
      icon: <Icon name="logout" />,
      title: t('subheadings.logout.title'),
      onClick: handleOnLogout,
    },
  ].filter(({ enabled }) => enabled);

  const patientOptions: NavItemOption[] = [
    {
      enabled: isSessionExist,
      icon: (
        <strong>
          {getInitials({
            firstName: patient.firstName,
            lastName: patient.lastName,
          })}
        </strong>
      ),
      title: t('subheadings.profile.title'),
      onClick: () =>
        push({
          path: RoutePath.ROOT_MORE_PATIENT,
          params: { id: patient?.id },
        }),
    },
    {
      enabled: isSessionExist,
      icon: <Icon name="medical-outline" />,
      title: t('subheadings.medicalMore.title', {
        name: patient.firstName,
      }),
      onClick: () => push({ path: RoutePath.ROOT_MEDICAL }),
    },
    {
      enabled: isSessionExist,
      icon: <Icon name="insurance" />,
      title: t('options.patientInsurance', {
        name: patient.firstName,
      }),
      onClick: () => push({ path: RoutePath.ROOT_MORE_INSURANCE }),
    },
  ];

  const legalOptions: NavItemOption[] = policy.map(
    ({ title, type }): NavItemOption => ({
      title,
      enabled: true,
      icon: <Icon name={ICONS[type]} />,
      onClick: () => push({ path: RoutePath.LEGAL_DETAILS, params: { type } }),
    })
  );

  const nav: NavItem[] = [
    {
      enabled: true,
      options: myAccountOptions,
      title: t('subheadings.myAccount.title'),
    },
    {
      enabled: isSessionExist,
      title: `${patient.firstName} ${patient.lastName}`,
      options: patientOptions,
    },
    {
      enabled: true,
      options: actionSupportActions,
      title: t('subheadings.actionSupport.title'),
    },
    {
      enabled: true,
      options: legalOptions,
      title: t('subheadings.legal.title'),
    },
  ].filter(({ enabled, options }) => enabled && options.length);

  return { nav };
};
