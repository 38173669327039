import styled, { css } from 'styled-components';
import { ButtonType } from './SwiperNavButton.const';

interface RootProps {
  navDirection: ButtonType;
}

const RootPrevMixin = css`
  left: 50%;
  margin-left: -${({ theme: { spacing } }) => spacing(47)};
`;

const RootNextMixin = css`
  right: 50%;
  margin-right: -${({ theme: { spacing } }) => spacing(47)};
`;

export const Root = styled('button')<RootProps>`
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  background-color: ${({ theme }) => theme.palette.periwinkle200};
  cursor: pointer;
  position: absolute;
  bottom: ${({ theme: { spacing } }) => spacing(2)};
  ${({ navDirection }) => navDirection === 'prev' && RootPrevMixin}
  ${({ navDirection }) => navDirection === 'next' && RootNextMixin}
`;
