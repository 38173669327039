import { useContext } from 'use-context-selector';
import type { ADTO } from '@helpful/network';
import { UserContext } from '../../contexts/User.context';
import { NavigationContext } from '../../../navigation/contexts/Navigation.context';

type UseUserReturnType = [
  {
    user: ADTO.CaregiverEntity;
    loading: boolean;
    roles: string[];
    connections: ADTO.ConnectionEntity[];
  },
  () => void
];

export const useUser = (): UseUserReturnType => {
  const { user, loading, connections, refetch } = useContext(UserContext);

  const { roles } = useContext(NavigationContext);

  return [{ user, loading, roles, connections }, refetch];
};
