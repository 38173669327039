import styled, { createGlobalStyle } from 'styled-components';
import { IonInput, IonIcon } from '@ionic/react';

export const Global = createGlobalStyle`
  .picker-md {
    --border-radius: 22px 22px 0px 0px;
    --ion-color-primary: ${({ theme }) => theme.palette.blue400}
  }
  .picker-wrapper {
    height: ${({ theme: { spacing } }) => spacing(88)} !important;
    min-height: ${({ theme: { spacing } }) => spacing(88)} !important;
  }
  .picker-toolbar {
    height: ${({ theme: { spacing } }) => spacing(24)} !important;
    padding: ${({ theme: { spacing } }) => spacing(6)};
  }
`;

export const Input = styled(IonInput)``;

export const Icon = styled(IonIcon)`
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  padding-top: ${({ theme: { spacing } }) => spacing(9)};
`;
