import styled from 'styled-components';
import { Root as Button } from '../../Button/Button.style';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
  ${Button} {
    margin: 0;
  }
`;
