import { useContextSelector } from 'use-context-selector';
import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import {
  thirdPartySignInAndUp,
  ThirdPartyUserType,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { AuthContext } from '../../contexts/Auth.context';

interface UseThirdPartySignInAndUpData {
  user: ThirdPartyUserType;
  isNew: boolean;
}

type UseThirdPartySignInAndUpReturnType = [
  {
    data?: UseThirdPartySignInAndUpData;
    error?: Error;
    loading: boolean;
  },
  UseMutateFunction<UseThirdPartySignInAndUpData, Error, void, unknown>
];

export const useThirdPartySignInAndUp =
  (): UseThirdPartySignInAndUpReturnType => {
    const responseErrorInterceptor = useContextSelector(
      AuthContext,
      ({ responseErrorInterceptor }) => responseErrorInterceptor
    );

    const {
      data,
      error,
      isLoading: loading,
      mutate,
    } = useMutation<UseThirdPartySignInAndUpData, Error, void>(() => {
      return thirdPartySignInAndUp()
        .then((data) => {
          if (data.status === 'OK') {
            return { user: data.user, isNew: data.createdNewUser };
          }
          if (data.status === 'NO_EMAIL_GIVEN_BY_PROVIDER') {
            throw new Error(
              'No email provided by social login. Please use another form of login'
            );
          }
        })
        .catch((err) => {
          return Promise.reject(responseErrorInterceptor(err));
        });
    });

    return [{ data, error, loading }, mutate];
  };
