import { FC } from 'react';
import {
  Page,
  Header,
  Content,
  Typography,
  Skeleton,
} from '@wearehelpful/ui-kit';
import { useRoute } from '@core/navigation';
import { useGetLegalQuery, ContentfulDocument } from '@helpful/network';

export const Details: FC = () => {
  const [
    {
      params: { type },
    },
  ] = useRoute<{ type: string }, {}>();

  const {
    isFetching,
    data: {
      policyCollection: { items },
    },
  } = useGetLegalQuery(
    { policy: type },
    {
      initialData: {
        policyCollection: { items: [] },
      },
    }
  );

  return (
    <Page>
      <Header>
        <Header.Back />
      </Header>
      <Content>
        <Content.Subheader>
          <Typography variant="h2">{items[0]?.title}</Typography>
        </Content.Subheader>
        <Content.Fixed inset>
          <Skeleton loading={isFetching} variant="document">
            <ContentfulDocument>{items[0]?.body.json}</ContentfulDocument>
          </Skeleton>
        </Content.Fixed>
      </Content>
    </Page>
  );
};
