import moment from 'moment';
import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useStaticOptions, usePatient } from '@common/hooks';
import {
  Page,
  Header,
  Content,
  Form,
  Typography,
  useUtils,
  Loading,
} from '@wearehelpful/ui-kit';
import { ADTO, useUpdatePatient, useGetPatient } from '@helpful/network';
import { merge } from 'lodash';
import { useUser } from '@core/user';
import { FC } from 'react';

export const Patient: FC = () => {
  const { t } = useTranslation();
  const [{ user }, refetch] = useUser();
  const [{ relationships, sexAtBirth }] = useStaticOptions();
  const [{ patient }] = usePatient();
  const { toast } = useUtils();

  const [
    {
      params: { id },
    },
  ] = useRoute<{ id: string }, {}>();

  const { isFetching, data } = useGetPatient(id, {
    relations: 'caregivers',
  });

  const { isLoading, mutate } = useUpdatePatient();

  const handleOnSubmit = ({ id: _, ...data }: ADTO.PatientEntity) => {
    mutate(
      {
        id,
        data: merge({}, data, {
          caregivers: [
            {
              caregiver: {
                id: user.id,
              },
            },
          ],
        }),
      },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            message: t('notifications.lovedOneSuccessfullyUpdated'),
          });
          refetch();
        },
      }
    );
  };

  const defaultDate = moment().subtract(70, 'years').utc(true).toISOString();

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MORE}
      name={AnalyticsPageCategory.PATIENT}
    >
      <Loading loading={isFetching} />
      <Page>
        <Header>
          <Header.Back defaultHref={RoutePath.ROOT_MORE} />
        </Header>
        <Content>
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.patientAccount.subtitle', {
                name: patient.firstName,
              })}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form<ADTO.PatientEntity>
              onSubmit={handleOnSubmit}
              defaultValues={data?.data}
              key={String(isFetching)}
            >
              <Form.Text
                autofocus
                tabIndex={1}
                name="firstName"
                autocapitalize="on"
                label={t('forms.lovedOnesFirstName')}
              />
              <Form.Text
                tabIndex={2}
                name="lastName"
                autocapitalize="on"
                label={t('forms.lovedOnesLastName')}
              />
              <Form.Datepicker
                tabIndex={3}
                name="dateOfBirth"
                defaultValue={defaultDate}
                label={t('forms.lovedOnesDateOfBirth')}
              />
              <Form.Select
                tabIndex={4}
                name="sexAtBirth"
                label={t('forms.lovedOnesSexAtBirth')}
                options={sexAtBirth}
              />
              <Form.Select
                tabIndex={5}
                name="caregivers[0].relationship"
                options={relationships}
                label={t('forms.lovedOneRelationship')}
              />
              <Form.Actions>
                <Form.Submit loading={isLoading}>
                  {t('actions.save')}
                </Form.Submit>
              </Form.Actions>
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
