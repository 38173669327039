import { FC } from 'react';
import { List } from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  CallToActionRoutes,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useSession } from '@core/auth';
import { useTranslation } from '@wearehelpful/ui-localization';
import { CtaList, type CtaListProps, HeaderWithAuth } from '@common/features';
import { useRoute } from '@core/navigation';
import { useConfig } from '@bootstrap/hooks';
import { Page, Content, Typography, Skeleton } from '@wearehelpful/ui-kit';
import { useMore } from '../../../../hooks/useMore/useMore.hook';
import { Version } from './Index.style';
import { useNavigation } from './Index.hooks';

export const Index: FC = () => {
  const { t } = useTranslation();
  const [{ loading }] = useMore();
  const { nav } = useNavigation();
  const [{ isSessionExist }] = useSession();
  const [, { push }] = useRoute();
  const { track } = useAnalytics();
  const { version } = useConfig();

  const handleOnCtaClick: CtaListProps['onClick'] = ({
    title,
    ctaUrl: path,
  }) => {
    track({
      id: AnalyticsTrackEvents.MORE_CTA_CLICK,
      properties: { title },
    }).finally(() => {
      push({ path });
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.ROOT}
      name={AnalyticsPageCategory.MORE}
    >
      <Page>
        <HeaderWithAuth logo={!isSessionExist} />
        <Content>
          <Content.Subheader>
            <Typography variant="h2">{t('subheadings.more.title')}</Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Skeleton variant="feed" loading={loading}>
              <CtaList
                route={CallToActionRoutes.MORE}
                onClick={handleOnCtaClick}
              />
              {nav.map(({ title, options }, index) => {
                return (
                  <List key={index} title={title}>
                    {options.map(
                      ({ title, subtitle, onClick, icon, color }, index) => {
                        return (
                          <List.Item key={index} onClick={onClick}>
                            <List.Thumb color={color}>{icon}</List.Thumb>
                            <List.Label>
                              {title}
                              {subtitle}
                            </List.Label>
                          </List.Item>
                        );
                      }
                    )}
                  </List>
                );
              })}
              <Version>
                <Typography variant="p1">
                  {t('version', { version })}
                </Typography>
              </Version>
            </Skeleton>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
