import { noop } from 'lodash';
import { createContext } from 'use-context-selector';

interface RadioButtonGroupContextProps {
  value: any;
  onChange: (v: any) => void;
}

export const RadioButtonGroupContext =
  createContext<RadioButtonGroupContextProps>({
    value: null,
    onChange: noop,
  });
