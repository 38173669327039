import { registerPlugin } from '@capacitor/core';

import type { FirebaseMessagingPlugin } from '../../definitions';
import { FirebaseMessagingWeb } from '../../web';

const FirebaseMessaging = registerPlugin<FirebaseMessagingPlugin>(
  'FirebaseMessaging',
  {
    // web: () => import('./web').then(m => new m.FirebaseMessagingWeb()),
    web: () => new FirebaseMessagingWeb(),
  },
);

export { FirebaseMessaging };
