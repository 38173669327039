import styled, { css } from 'styled-components';
import { Content, Section } from '@wearehelpful/ui-kit';

const HeaderIsNull = css`
  position: sticky;
  top: 0;
  background-color: ${({ theme: { palette } }) => palette.white100};
  z-index: 2;
  padding-top: 56px;
  border-top-right-radius: ${({ theme: { spacing } }) => spacing(4)};
  border-top-left-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Add = styled('button')`
  padding: 7px 16px 7px 21px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.orange400};
  border-radius: ${({ theme: { spacing } }) => spacing(21)};
  width: 100%;
  max-width: 460px;
  gap: ${({ theme: { spacing } }) => spacing(2.5)};
  img {
    flex: 0;
    width: 55px;
    height: 55px;
  }
  p {
    flex: 1;
  }
  span {
    flex: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.orange300};
  }
`;

export const Centered = styled('div')`
  display: flex;
  justify-content: center;
`;

export const Subheader = styled(Content.Subheader)`
  padding-bottom: 130px;
`;

export const Container = styled('div')`
  margin-top: -${({ theme: { spacing } }) => spacing(20.5)};
  z-index: 2;
  position: relative;
  background: ${({ theme }) => theme.palette.neutral100};
  padding-bottom: ${({ theme: { spacing } }) => spacing(10)};
  border-top-right-radius: ${({ theme: { spacing } }) => spacing(4)};
  border-top-left-radius: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Asset = styled('img')`
  width: 56px;
  height: 56px;
  flex: 0;
`;

export const Title = styled(Section.Title)<{ active: boolean }>`
  color: ${({ active, theme: { palette } }) => active && palette.periwinkle500};
`;

export const Header = styled(Section.Header)<{ isNull: boolean }>`
  ${({ isNull }) => isNull && HeaderIsNull}
`;
