import { FC } from 'react';
import {
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  Accordion,
  Button,
  ContentFixed,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { AnalyticsPageCategory } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ContentfulDocument } from '@helpful/network';
import { Content } from '@wearehelpful/ui-kit';
import { useMore } from '../../../../hooks/useMore/useMore.hook';

export const Faq: FC = () => {
  const [, { push }] = useRoute();
  const [{ faqs }] = useMore();
  const { t } = useTranslation();

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MORE}
      name={AnalyticsPageCategory.FAQ}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <ContentFixed>
            <Subheader divider>
              <Typography.Heading2>
                {t('subheadings.faq.title')}
              </Typography.Heading2>
            </Subheader>
            <Accordion>
              {faqs.map(({ title, description: { json }, ctaUrl, cta }) => {
                return (
                  <Accordion.Item key={title}>
                    <Accordion.Header textWrap>{title}</Accordion.Header>
                    <Accordion.Content padding>
                      <ContentfulDocument>{json}</ContentfulDocument>
                      {!!ctaUrl && (
                        <Button
                          variant="tertiary"
                          onClick={() => push({ path: ctaUrl })}
                        >
                          {cta}
                        </Button>
                      )}
                    </Accordion.Content>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
