import styled from 'styled-components';
import { Typography } from '@wearehelpful/ui-kit';

export const Root = styled('div')`
  padding: 0 ${({ theme: { spacing } }) => spacing(4)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(20)};
`;

export const Title = styled(Typography)`
  text-align: center;
  margin-top: ${({ theme: { spacing } }) => spacing(12)};
`;

export const Social = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { spacing } }) => spacing(10)};
  margin-top: ${({ theme: { spacing } }) => spacing(5)};
`;

export const Legal = styled(Typography)`
  text-align: center;
  margin-top: ${({ theme: { spacing } }) => spacing(11)};
  a {
    font-weight: bold;
    text-decoration: underline;
  }
`;
