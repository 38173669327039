import { FC } from 'react';
import { Page } from '@core/ui';
import { RoutePath } from '@common/constants';
import { Outlet, Route } from '@core/navigation';
import { Select } from './Select/Select';
import { Index } from './Index/Index';

export const Careplan: FC = () => {
  return (
    <Page>
      <Outlet>
        <Route path={RoutePath.CAREPLAN} exact>
          <Index />
        </Route>
        <Route path={RoutePath.CAREPLAN_SELECT}>
          <Select />
        </Route>
      </Outlet>
    </Page>
  );
};
