import { FC } from 'react';
import { useMount } from 'react-use';
import { useContextSelector } from 'use-context-selector';
import { Icon, IconName } from '@wearehelpful/ui-kit';
import { UiKitSize } from '../../../types/uikit.types';
import { CardContext } from '../Card.context';
import { Root } from './CardIcon.style';

interface CardIconProps {
  name: IconName;
  size?: UiKitSize;
}

export const CardIcon: FC<CardIconProps> = ({ name, size = 'md' }) => {
  const setCardState = useContextSelector(
    CardContext,
    ({ setCardState }) => setCardState
  );

  useMount(() => {
    setCardState({ icon: size });
  });

  return (
    <Root size={size}>
      <Icon name={name} size={size} />
    </Root>
  );
};
