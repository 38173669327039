import { FC } from 'react';
import { IMAGES } from '../../../constants/assets.const';
import { Root } from './EmptyAsset.style';

interface EmptyAssetProps {
  name?: keyof typeof IMAGES;
  width?: number | string;
  height?: number | string;
}

export const EmptyAsset: FC<EmptyAssetProps> = ({
  width = 104,
  height = 'auto',
  name = 'PEACE_PUZZLE',
}) => {
  return <Root src={IMAGES[name]} width={width} height={height} />;
};
