import { FC } from 'react';
import {
  CDTO,
  ContentfulDocument,
  CONTENTFUL_BLOCKS,
  ContentfulImage,
} from '@helpful/network';
import { Typography } from '@wearehelpful/ui-kit';
import { Root } from './NavTourCard.style';

export const NavTourCard: FC<
  CDTO.GetBootstrapQuery['tourCollection']['items']['0']
> = ({ title, description, image }) => {
  return (
    <Root>
      <Typography align="center" variant="h3">
        {title}
      </Typography>
      <ContentfulDocument
        render={() => ({
          [CONTENTFUL_BLOCKS.PARAGRAPH]: (_, children) => (
            // @ts-ignore
            <Typography align="center">{children}</Typography>
          ),
        })}
      >
        {description.json}
      </ContentfulDocument>
      <ContentfulImage url={image?.url} height={125} />
    </Root>
  );
};
