import { AnalyticsPageCategory } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import {
  Badge,
  ContentFixed,
  Header,
  List,
  Page,
  Subheader,
  Toolbar,
  Typography,
} from '@core/ui';
import { FC } from 'react';
import { useRoute } from '@core/navigation';
import moment from 'moment-timezone';
import { useUser } from '@core/user';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useGetPatientEncounter } from '@helpful/network';
import { Icon, Content } from '@wearehelpful/ui-kit';
import { Notes } from './Details.style';

const EMAIL_REGEX = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
const PHONE_REGEX = /(?:\b|\()(?:\d{3})\)?[-. ]?(?:\d{3})[-. ]?(?:\d{4})\b/g;
const URL_REGEX =
  /(?:[hH][tT][tT][pP][sS]?):\/\/(?:[wW]{3}\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?:[wW]{3}\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/;

const COMBINED_REGEX = new RegExp(
  `(${PHONE_REGEX.source})|(${URL_REGEX.source})|(${EMAIL_REGEX.source})`,
  'g'
);

export const Details: FC = () => {
  const { t } = useTranslation();
  const [
    {
      params: { id },
    },
  ] = useRoute<{ id: string }, {}>();

  const [{ user }] = useUser();
  const patientId = user.patients[0].patient.id;

  const { data, isSuccess } = useGetPatientEncounter(patientId, id, {
    relations: 'provider, connection.system, careplan',
  });

  const replaceWithLinks = (text: string) => {
    const parts = text.split(COMBINED_REGEX);
    return parts.map((part: any, index: any) => {
      if (PHONE_REGEX.test(part)) {
        return (
          <a href={`tel:${part}`} key={index}>
            {part}
          </a>
        );
      } else if (URL_REGEX.test(part)) {
        if (
          !(
            part.toLowerCase().startsWith('http://') ||
            part.toLowerCase().startsWith('https://')
          )
        ) {
          part = `http://${part}`;
        }
        return (
          <a href={part} target="_blank" rel="noopener noreferrer" key={index}>
            {part}
          </a>
        );
      } else if (EMAIL_REGEX.test(part)) {
        return (
          <a href={`mailto:${part}`} key={index}>
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  const tz = moment.tz.guess();
  let date, time;
  if (data?.data.date) {
    date = moment(data?.data.date).format('dddd, MMMM Do, YYYY');
    time = moment.tz(data?.data.date, tz).format('h:mmA z');
  }
  const provider = data?.data.provider;
  const providerName = [
    provider?.prefix,
    provider?.firstName,
    provider?.lastName,
    provider?.suffix,
  ].join(' ');

  const content = (
    <TrackPageView
      category={AnalyticsPageCategory.CAREPLAN}
      name={data?.data.id}
    >
      <Subheader>
        <Typography.Heading3>{data?.data.title}</Typography.Heading3>
      </Subheader>

      <ContentFixed padding>
        <Badge variant="primary">{data?.data.connection.system.name}</Badge>
        {date && (
          <List.Item lines="none">
            <List.Thumb size="sm">
              <Icon name="calendar" size="sm" />
            </List.Thumb>
            <List.Label>{date}</List.Label>
          </List.Item>
        )}

        {time && (
          <List.Item lines="none">
            <List.Thumb size="sm">
              <Icon name="time" size="sm" />
            </List.Thumb>
            <List.Label>{time}</List.Label>
          </List.Item>
        )}

        {data?.data.location && (
          <List.Item lines="none">
            <List.Thumb size="sm">
              <Icon name="location" size="sm" />
            </List.Thumb>
            <List.Label wrap>{data?.data.location}</List.Label>
          </List.Item>
        )}

        {data?.data.provider && providerName && (
          <List.Item lines="none">
            <List.Thumb size="sm">
              <Icon name="provider" size="sm" />
            </List.Thumb>
            <List.Label>{providerName}</List.Label>
          </List.Item>
        )}

        {data?.data?.careplan?.description && (
          <Notes>
            <Typography.Subheading1 bold>
              {t('subheadings.visitSummaryDetails.title')}
            </Typography.Subheading1>
            {data?.data?.careplan?.description
              .split('\r\n')
              .map((desc: any, i: any) => {
                return (
                  <Typography.Body1 key={i}>
                    {replaceWithLinks(desc)}
                    <br />
                  </Typography.Body1>
                );
              })}
          </Notes>
        )}
      </ContentFixed>
    </TrackPageView>
  );

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Content padding>{isSuccess ? content : null}</Content>
    </Page>
  );
};
