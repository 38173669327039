import { PropsWithChildren, ReactElement } from 'react';
import { ChipsItem } from './ChipsItem/ChipsItem';
import { Root } from './Chips.style';

type ChipsProps = PropsWithChildren<{
  className?: string;
}>;

interface ChipsComponent {
  (props: ChipsProps): ReactElement | null;
  Item: typeof ChipsItem;
}

export const Chips: ChipsComponent = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};

Chips.Item = ChipsItem;
