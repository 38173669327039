import { FC, PropsWithChildren } from 'react';
import { UiKitVariant } from '../../types/uikit.types';
import { Root } from './Badge.style';

type BadgeProps = PropsWithChildren<{
  className?: string;
  variant: UiKitVariant;
}>;

export const Badge: FC<BadgeProps> = ({ children, variant, className }) => {
  return (
    <Root variant={variant} className={className}>
      {children}
    </Root>
  );
};
