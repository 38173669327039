import styled, { css } from 'styled-components';
import { IonChip } from '@ionic/react';
import { UiKitColor, UiKitStatus } from '../../../types/uikit.types';
import { STATUS_COLOR_MAP } from './ChipsItem.const';

interface RootProps {
  status?: UiKitStatus;
  background: UiKitColor;
}

const RootWithStatusMixin = css<RootProps>`
  &:before {
    content: '';
    width: ${({ theme: { spacing } }) => spacing(1.5)};
    min-width: ${({ theme: { spacing } }) => spacing(1.5)};
    height: ${({ theme: { spacing } }) => spacing(1.5)};
    border-radius: 50%;
    background-color: ${({ status, theme: { palette } }) =>
      STATUS_COLOR_MAP(status, palette)};
    margin-right: ${({ theme: { spacing } }) => spacing(2)};
  }
`;

export const Root = styled(IonChip)<RootProps>`
  margin-inline: 0;
  font-size: ${({ theme }) => theme.fontSize(14)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  margin-top: 0;
  margin-bottom: 0;
  min-height: ${({ theme: { spacing } }) => spacing(6)};
  height: auto;
  padding-top: ${({ theme: { spacing } }) => spacing(1)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(1)};
  --color: ${({ theme }) => theme.palette.brown400};
  --background: ${({ theme, background }) => theme.palette[background]};
  ${({ status }) => status && RootWithStatusMixin}
`;
