import { FC, PropsWithChildren } from 'react';
import { useGetConfig } from '@helpful/network';
import { ConfigContext } from '../../contexts/Config.context';

export type ConfigProps = PropsWithChildren<{
  build: string;
  host: string;
  environment: string;
}>;

export const Config: FC<ConfigProps> = ({
  host,
  build,
  children,
  environment,
}) => {
  const {
    isSuccess,
    data = { fallbackLanguage: null, supportedLanguages: [] },
  } = useGetConfig({
    request: {
      headers: {
        Accept: '*/*',
      },
    },
  });

  if (!isSuccess) return null;

  return (
    <ConfigContext.Provider value={{ host, build, environment, ...data }}>
      {children}
    </ConfigContext.Provider>
  );
};
