import { FC, PropsWithChildren, useEffect } from 'react';
import { merge } from 'lodash';
import {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';

type CommonHttpProviderProps = PropsWithChildren<{
  bearer?: boolean;
  instance: AxiosInstance;
  requestInterceptor?: (
    config: AxiosRequestConfig
  ) => Promise<AxiosRequestConfig> | AxiosRequestConfig;
  responseInterceptor?: (
    response: AxiosResponse
  ) => AxiosResponse | Promise<AxiosResponse>;
  responseErrorInterceptor?: (
    error: AxiosError
  ) => AxiosError | Promise<AxiosError>;
}>;

export const CommonHttpProvider: FC<CommonHttpProviderProps> = ({
  children,
  instance,
  requestInterceptor = null,
  responseInterceptor = null,
  responseErrorInterceptor = null,
}) => {
  useEffect(() => {
    const interceptor1 = instance.interceptors.response.use((response) => {
      if (!response.data && response.status === 200) {
        return merge(response, {
          data: {
            response: {
              status: response.status,
            },
          },
        });
      }
      return response;
    });
    const interceptor2 = instance.interceptors.response.use(
      responseInterceptor,
      responseErrorInterceptor
    );
    const interceptor3 = instance.interceptors.request.use(requestInterceptor);
    return () => {
      instance.interceptors.response.eject(interceptor1);
      instance.interceptors.response.eject(interceptor2);
      instance.interceptors.request.eject(interceptor3);
    };
  }, [
    responseErrorInterceptor,
    responseInterceptor,
    requestInterceptor,
    instance,
  ]);

  return <>{children}</>;
};
