import styled from 'styled-components';
import { Button, Footer as DefaultFooter } from '@core/ui';

export const Back = styled(Button)`
  margin-top: ${({ theme: { spacing } }) => spacing(13)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(9)};
`;

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Placeholder = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled('img')`
  width: ${({ theme: { spacing } }) => spacing(70)};
`;

export const Footer = styled(DefaultFooter)`
  background-color: white;
`;
