import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Root, Content } from './FormDivider.style';

type FormDividerProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
}>;

export const FormDivider: FC<FormDividerProps> = ({
  children,
  className,
  padding,
}) => {
  return (
    <Root
      className={classNames(className, { 'ion-padding-horizontal': padding })}
    >
      {children && <Content>{children}</Content>}
    </Root>
  );
};
