import { FC, PropsWithChildren } from 'react';
import { IonItem } from '@ionic/react';
import classnames from 'classnames';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { FormItemName } from '../Form.types';
import { Root, Label, Error, Help, HelpList } from './FormItem.style';

type FormItemProps = PropsWithChildren<{
  label?: string;
  counter?: boolean;
  name: FormItemName;
  className?: string;
  help?: string | string[];
  lines?: Parameters<typeof IonItem>['0']['lines'];
}>;

export const FormItem: FC<FormItemProps> = ({
  name,
  label,
  lines,
  children,
  className,
  counter = false,
  help: initialHelp,
}) => {
  const [, { error }] = useField(castArray(name).join('.'));

  const classNames = classnames(className, {
    'ion-invalid': !!error,
    'ion-touched': !!error,
  });

  const help = (() => {
    if (Array.isArray(initialHelp)) {
      return (
        <HelpList>
          {initialHelp.map((v) => {
            return <li key={v}>{v}</li>;
          })}
        </HelpList>
      );
    }
    return initialHelp;
  })();

  const counterFormatter = (len: number, max: number) => {
    return `${max - len} characters remaining`;
  };

  return (
    <Root
      lines={lines}
      counter={counter}
      className={classNames}
      counterFormatter={counterFormatter}
    >
      {label && (
        <Label position="floating" mode="ios">
          {label}
        </Label>
      )}
      {children}
      {!!error && <Error slot="helper">{error}</Error>}
      {!!help && !error && <Help slot="helper">{help}</Help>}
    </Root>
  );
};
