import { FC } from 'react';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useUser } from '@core/user';
import { useResendEmail } from '@core/auth';
import { useTranslation } from '@wearehelpful/ui-localization';
import { TrackPageView } from '@wearehelpful/ui-stats';
import {
  useUtils,
  Content,
  Page,
  Header,
  Typography,
  Button,
} from '@wearehelpful/ui-kit';
import { Toolbar } from '@core/ui';
import {
  Container,
  Title,
  Image,
  Description,
  Note,
  Hint,
} from './Unverified.style';
import IMAGE from './Unverified.png';

export const Unverified: FC = () => {
  const { toast } = useUtils();
  const [
    {
      user: { email },
    },
  ] = useUser();
  const { t } = useTranslation();
  const [{ loading }, resend] = useResendEmail();

  const handleOnResend = () => {
    resend(null, {
      onSuccess: () => {
        toast({
          variant: 'success',
          message: t('notifications.emailSuccessfullyResent', { email }),
        });
      },
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.UNVERIFIED}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back defaultHref={RoutePath.ROOT_MORE} />
          </Toolbar>
        </Header>
        <Content>
          <Content.Subheader divider>
            <Typography variant="h1">
              {t('subheadings.insurance.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Container>
              <Title align="center">{t('prompts.verifyEmail.incurance')}</Title>
              <Description align="center">
                {t('prompts.verifyEmail.message', { email })}
              </Description>
              <Image src={IMAGE} />
              <Note align="center">{t('prompts.verifyEmail.note')}</Note>
            </Container>
            <Button loading={loading} onClick={handleOnResend}>
              {t('actions.resendEmail')}
            </Button>
            <Hint align="center">{t('prompts.verifyEmail.hint')}</Hint>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
