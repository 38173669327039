import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { decoder } from './useRoute.utils';

interface UseRouteState<
  P extends object,
  Search extends object,
  State extends object
> {
  params: P;
  search: Search;
  pathname: string;
  state: State;
}

export interface UseRouteDispatchPushParams {
  path: string;
  params?: object;
  search?: object;
  state?: object;
}

interface UseRouteDispatch {
  push: (params: UseRouteDispatchPushParams) => void;
  replace: (params: UseRouteDispatchPushParams) => void;
  back: () => void;
}

type UseRouteReturnType<
  P extends object,
  Search extends object,
  State extends object
> = [UseRouteState<P, Search, State>, UseRouteDispatch];

export const useRoute = <
  P extends object,
  Search extends object,
  State extends object = object
>(): UseRouteReturnType<P, Search, State> => {
  const { push: historyPush, goBack, replace: historyReplace } = useHistory();
  const params = useParams<P>();
  const { search: initialSearch, pathname, state } = useLocation();

  const search = useMemo(() => {
    return parse(initialSearch, {
      ignoreQueryPrefix: true,
      decoder: decoder,
    }) as Search;
  }, [initialSearch]);

  const push = useCallback(
    ({
      path,
      params = {},
      search = {},
      state = {},
    }: UseRouteDispatchPushParams) => {
      const pathname = generatePath(path, params);
      historyPush({
        pathname,
        search: stringify(search, {
          encode: false,
          addQueryPrefix: true,
        }),
        state,
      });
    },
    [historyPush]
  );

  const replace = useCallback(
    ({ path, params = {}, search = {} }: UseRouteDispatchPushParams) => {
      const pathname = generatePath(path, params);
      historyReplace({
        pathname,
        search: stringify(search, {
          encode: false,
          addQueryPrefix: true,
        }),
      });
    },
    [historyReplace]
  );

  return [
    { pathname, params, search, state: state as State },
    { push, back: goBack, replace },
  ];
};
