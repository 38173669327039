import {
  FC,
  PropsWithChildren,
  useState,
  MouseEventHandler,
  useRef,
  useLayoutEffect,
} from 'react';
import { Root, Content, Action, Link } from './Overflow.style';

type OverflowProps = PropsWithChildren<{
  height: number;
  scrollTo?: HTMLElement;
  action: (v: boolean) => string;
}>;

export const Overflow: FC<OverflowProps> = ({
  action,
  height,
  children,
  scrollTo,
}) => {
  const [open, toggle] = useState(false);
  const content = useRef<HTMLDivElement>();
  const [, render] = useState(0);

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    toggle((v) => {
      if (scrollTo && v) {
        scrollTo.scrollIntoView({ behavior: 'smooth' });
      }

      return !v;
    });
  };

  const hasAction = content.current?.offsetHeight > height;

  useLayoutEffect(() => render((v) => v + 1), []);

  return (
    <Root>
      <Content
        open={open}
        hasAction={hasAction}
        height={open ? content.current?.offsetHeight : height}
      >
        <div ref={content}>{children}</div>
      </Content>
      {hasAction && (
        <Action>
          <Link href="/" onClick={handleOnClick}>
            {action(open)}
          </Link>
        </Action>
      )}
    </Root>
  );
};
