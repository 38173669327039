import { FC, Fragment } from 'react';
import { CDTO } from '@helpful/network';
import { noop } from 'lodash';
import { StackHeroCard } from '../StackHeroCard/StackHeroCard';
import { StackCarouselCta } from './StackCarouselCta/StackCarouselCta';
import { Root, Guide, Benefit } from './StackCarousel.style';

interface StackCarouselProps extends DeepPartial<CDTO.Stack> {
  onGuideClick: (item: DeepPartial<CDTO.Training>, index: number) => void;
  onBenefitClick: (item: DeepPartial<CDTO.PlanBenefit>, index: number) => void;
  onStackClick: (item: DeepPartial<CDTO.Stack>) => void;
}

export const StackCarousel: FC<StackCarouselProps> = ({
  onGuideClick = noop,
  onBenefitClick = noop,
  onStackClick = noop,
  ...item
}) => {
  const {
    title,
    heroTitle,
    heroColor,
    heroImage,
    benefitsCollection: { items: benefits },
    guidesCollection: { items: guides },
  } = item;

  const max = Math.max(benefits.length, guides.length);

  const template = Array.from({ length: max })
    .reduce((res: number[], _, index) => {
      return [...res, benefits[index] ? 264 : 0, guides[index] ? 264 : 0];
    }, [])
    .filter((v) => !!v)
    .map((v) => `${v}px`)
    .join(' ');

  return (
    <Root template={template}>
      <StackHeroCard
        image={heroImage?.url}
        category={title}
        title={heroTitle}
        background={heroColor}
        onClick={() => onStackClick(item)}
      />
      {Array.from({ length: max }).map((_, index) => {
        return (
          <Fragment key={index}>
            {benefits[index] && (
              <Benefit
                withIcon
                truncate={5}
                {...benefits[index]}
                onClick={() => onBenefitClick(benefits[index], index)}
              />
            )}
            {guides[index] && (
              <Guide
                {...guides[index]}
                truncate={4}
                thumb={{ width: 264, height: 149 }}
                onClick={() => onGuideClick(guides[index], index)}
              />
            )}
          </Fragment>
        );
      })}
      <StackCarouselCta
        title={title}
        background={heroColor}
        onClick={() => onStackClick(item)}
      ></StackCarouselCta>
    </Root>
  );
};
