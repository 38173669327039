import { CDTO } from '@helpful/network';
import { usePatient } from '@common/hooks';

export const useDefaultParams = () => {
  const [{ plans }] = usePatient();

  const defaultParams: CDTO.GetGuidesQueryVariables = {
    plans,
    skipTranings: 0,
    limitTranings: 25,
    skipStacks: 0,
    limitStaks: 8,
  };

  return { defaultParams };
};
