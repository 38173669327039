import { FC } from 'react';
import { isUndefined } from 'lodash';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation, Translate } from '@wearehelpful/ui-localization';
import {
  Page,
  Content,
  Form,
  Typography,
  Button,
  useUtils,
  Header,
} from '@wearehelpful/ui-kit';
import { useRoute } from '@core/navigation';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
  VALIDATION_PATTERNS,
} from '@common/constants';
import { FederatedSignIn } from '@common/features';
import { useCheckEmail, useSignUp } from '@core/auth';
import { SignIn } from './Index.style';
import { AuthForm } from './Index.types';

export const Index: FC = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { toast } = useUtils();

  const [{ data: checkData, loading: checking }, check] = useCheckEmail();

  const [{ loading: signingUp }, signUp] = useSignUp();

  const checkIfUserExists = (form: AuthForm) => {
    check(form, {
      onSuccess: ({ exists }, request) => {
        if (exists) {
          track({
            id: AnalyticsTrackEvents.EMAIL_INPUT_EMAIL_EXISTS,
            properties: { email: request.email.toLowerCase() },
          }).finally(() => {
            push({ path: RoutePath.AUTH_SIGN_IN, search: request });
            toast({ variant: 'error', message: t('errors.emailExists') });
          });
        } else {
          track({
            id: AnalyticsTrackEvents.EMAIL_INPUT,
            properties: { email: request.email.toLowerCase() },
          });
        }
      },
    });
  };

  const signUpUser = (form: AuthForm) => {
    signUp({
      firstName: '',
      lastName: '',
      email: form.email,
      password: form.password,
    });
  };

  const handleOnSubmit = (form: AuthForm) => {
    if (isUndefined(checkData) || checkData.exists) {
      checkIfUserExists(form);
    } else {
      signUpUser(form);
    }
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.ENTER_EMAIL}
    >
      <Page headerBackground="periwinkle300">
        <Header>
          <Header.Back />
          <Header.Logo />
        </Header>
        <Content>
          <Content.Progress value={1 / 4} />
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.signUp.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form<AuthForm> onSubmit={handleOnSubmit}>
              <Form.Text
                autofocus
                type="email"
                name="email"
                label={t('forms.email')}
                validation={{
                  email: true,
                  required: true,
                }}
              />
              {checkData?.exists === false && (
                <Form.Password
                  name="password"
                  label={t('forms.password')}
                  validation={{
                    required: true,
                    minLength: 8,
                    pattern: [
                      VALIDATION_PATTERNS.CONTAINS_NUMBER,
                      VALIDATION_PATTERNS.CONTAINS_UPPERCASE_LETTER,
                      VALIDATION_PATTERNS.CONTAINS_SPECIAL_CHARACTER,
                    ],
                  }}
                  errors={{
                    pattern: [
                      t('validation.passwordContainsNumber'),
                      t('validation.passwordContainsUppercaseLetter'),
                      t('validation.passwordContainsSpecialCharacter'),
                    ],
                  }}
                  help={[
                    t('hints.passwordHasMinCharacters'),
                    t('hints.passwordContainsNumber'),
                    t('hints.passwordContainsUppercaseLetter'),
                    t('hints.passwordContainsSpecialCharacter'),
                  ]}
                />
              )}
              <Form.Actions inset="sm">
                <Form.Submit loading={checking || signingUp} variant="primary">
                  {t('actions.next')}
                </Form.Submit>
                <Button variant="text">
                  <Translate
                    id="actions.haveAnAccountLogIn"
                    components={{
                      a: <SignIn path={RoutePath.AUTH_SIGN_IN} />,
                    }}
                  />
                </Button>
              </Form.Actions>
              <FederatedSignIn />
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
