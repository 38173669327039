import { useEffect, useState } from 'react';
import { MAX_CONTENT_WIDTH } from '../../constants/app.const';

interface UseWindowSizeState {
  width: number;
}

type UseWindowSizeReturnType = [UseWindowSizeState];

const getWidth = (width: number) => {
  if (width > MAX_CONTENT_WIDTH) return MAX_CONTENT_WIDTH;
  return width;
};

export const useWindowSize = (): UseWindowSizeReturnType => {
  const [state, setState] = useState<UseWindowSizeState>({
    width: getWidth(document.body.offsetWidth),
  });

  useEffect(() => {
    const handler = () => {
      setState(() => ({ width: getWidth(document.body.offsetWidth) }));
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return [state];
};
