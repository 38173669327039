import { useEffect, useState } from 'react';
import { noop } from 'lodash';
import { MAX_CONTENT_WIDTH } from '../../constants/app.const';

interface UseThumbParams {
  width: (w: number) => number;
  height?: (w: number) => number | void;
}

interface UseThumbReturnType {
  width: number;
  height?: number;
}

const getWidth = (width: number) => {
  if (width > MAX_CONTENT_WIDTH) return MAX_CONTENT_WIDTH;
  return width;
};

export const useThumb = ({
  width: initialWidth = (w) => w,
  height: initialHeight = noop,
}: UseThumbParams): UseThumbReturnType => {
  const [value, setValue] = useState<number>(
    getWidth(document.body.offsetWidth)
  );

  const width = (() => {
    return Math.trunc(initialWidth(value) * window.devicePixelRatio);
  })();

  const height = (() => {
    return Math.trunc(initialHeight(value) || 0 * window.devicePixelRatio);
  })();

  useEffect(() => {
    const handler = () => {
      setValue(() => getWidth(document.body.offsetWidth));
    };
    document.addEventListener('resize', handler);
    return () => document.removeEventListener('resize', handler);
  }, []);

  return { width, height };
};
