import { PropsWithChildren, ReactElement, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';
import { RadioButtonGroupValue } from '../RadioButtonGroup.type';
import { RadioButtonGroupContext } from '../RadioButtonGroup.context';
import { Root } from './RadioButtonGroupButton.style';

type RadioButtonGroupButtonProps<T extends RadioButtonGroupValue> =
  PropsWithChildren<{
    value: T;
  }>;

interface RadioButtonGroupButtonComponent {
  <T extends RadioButtonGroupValue = string>(
    props: RadioButtonGroupButtonProps<T>
  ): ReactElement;
}

export const RadioButtonGroupButton: RadioButtonGroupButtonComponent = ({
  value,
  children,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const rootValue = useContextSelector(
    RadioButtonGroupContext,
    ({ value }) => value
  );

  const onChange = useContextSelector(
    RadioButtonGroupContext,
    ({ onChange }) => onChange
  );

  return (
    <Root
      ref={buttonRef}
      active={value === rootValue}
      onClick={() => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
        onChange(value);
      }}
    >
      {children}
    </Root>
  );
};
