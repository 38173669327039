import styled from 'styled-components';

export const Pictographic = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme: { spacing } }) => spacing(6)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(6)};
`;

export const Image = styled('img')`
  height: ${({ theme: { spacing } }) => spacing(47)};
`;
