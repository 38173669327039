import { FC } from 'react';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Icon } from '@wearehelpful/ui-kit';
import { Root } from './ReadTime.style';

interface ReadTimeProps {
  value: string;
}

export const ReadTime: FC<ReadTimeProps> = ({ value }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <span>
        {value.replace(/\s/g, '')} {t('forms.read')}
      </span>
      <Icon name="file-text" />
    </Root>
  );
};
