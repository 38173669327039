import { FC } from 'react';
import { ADTO } from '@helpful/network';
import { Form } from '@wearehelpful/ui-kit';

interface SinglePlanNotSuplimentalProps {
  plans: ADTO.PlanEntity[];
  config: ADTO.PayorEntity;
}

export const SinglePlanNotSuplimental: FC<SinglePlanNotSuplimentalProps> = ({
  config,
  plans,
}) => {
  const notSuplementalPlans = plans.filter(
    ({ isSupplemental, isHidden }) => !isSupplemental && !isHidden
  );

  if (!config?.isMultiPlan && !!notSuplementalPlans.length) {
    return (
      <Form.RadioGroup name="plan" validation={{ required: true }}>
        {notSuplementalPlans.map(({ id, name }) => {
          return (
            <Form.RadioGroup.Item key={id} value={id}>
              {name}
            </Form.RadioGroup.Item>
          );
        })}
      </Form.RadioGroup>
    );
  }

  return null;
};
