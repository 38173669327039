import { ADTO } from '@helpful/network';

export const SECTION_TITLE_MAPPING = {
  null: 'forms.myCarePlan',
  completed: 'forms.completed',
};

export const VISIBLE_SECTIONS = Object.keys(SECTION_TITLE_MAPPING);

export const EMPTY_SECTION: ADTO.TodoLocalizedEntity[] = [];
