import { FC } from 'react';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { Redirect } from '@core/navigation';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Content, Typography, Form, useUtils } from '@wearehelpful/ui-kit';
import { ContactUs } from '../_partitions/ContactUs/ContactUs';
import { DetailsForm } from './Index.types';
import { PatientForm } from './_partitions/PatientForm/PatientForm';
import { useDetails } from './Index.hooks';

export const Index: FC = () => {
  const { t } = useTranslation();
  const { toast } = useUtils();

  const [{ defaultValues, loading, value }, update] = useDetails();

  const handleOnSubmit = (form: DetailsForm) => {
    update(form).catch((error) => {
      toast({ variant: 'error', message: error.message });
    });
  };

  if (!!value) {
    return <Redirect path={RoutePath.AUTH_SIGN_UP_PAYOR} />;
  }

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.CREATE_ACCOUNT}
    >
      <Content.Subheader>
        <Typography variant="h2">
          {t('subheadings.signUpAccount.title')}
        </Typography>
      </Content.Subheader>
      <Content.Fixed>
        <Form<DetailsForm>
          onSubmit={handleOnSubmit}
          defaultValues={defaultValues}
        >
          <Form.Section description={t('forms.createAccountInformation')}>
            <Form.Text
              autofocus
              tabIndex={1}
              autocapitalize="on"
              name="caregiver.firstName"
              validation={{ required: true }}
              label={t('forms.yourFirstName')}
            />
            <Form.Text
              tabIndex={2}
              autocapitalize="on"
              name="caregiver.lastName"
              label={t('forms.yourLastName')}
              validation={{ required: true }}
            />
          </Form.Section>
          <PatientForm />
          <Form.Actions>
            <Form.Submit checkValidity loading={loading}>
              {t('actions.next')}
            </Form.Submit>
            <ContactUs>{t('actions.contactHelpful')}</ContactUs>
          </Form.Actions>
        </Form>
      </Content.Fixed>
    </TrackPageView>
  );
};
