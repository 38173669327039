import styled, { css } from 'styled-components';
import { IonItem, IonLabel } from '@ionic/react';

export const Root = styled(IonItem)`
  --border-color: ${({ theme }) => theme.palette.neutral300};
`;

type LabelProps = {
  textWrap?: boolean;
};

const textWrap = css`
  white-space: pre-line !important;
`;

export const Label = styled(IonLabel)<LabelProps>`
  font-weight: 700;
  transition: margin-bottom 0.3s ease;
  margin-top: ${({ theme: { spacing } }) => spacing(9)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(9)};
  font-size: ${({ theme }) => theme.fontSize(18)};
  line-height: ${({ theme }) => theme.fontSize(22)};
  --color: ${({ theme }) => theme.palette.brown400};
  ${({ textWrap: shouldWrapText }) => shouldWrapText && textWrap}
`;
