import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { FormItemName } from '../Form.types';
import { Root, Errors, Error } from './FormGroupItem.style';

type FormGroupItemProps = PropsWithChildren<{
  name: FormItemName;
}>;

export const FormGroupItem: FC<FormGroupItemProps> = ({ name, children }) => {
  const [, { error }] = useField(castArray(name).join('.'));

  const cn = classNames({
    'ion-invalid': !!error,
    'ion-touched': !!error,
  });

  return (
    <Root>
      {children}
      <Errors className={cn} lines="none">
        <Error slot="error" withError={!!error}>
          {error}
        </Error>
      </Errors>
    </Root>
  );
};
