import { FC, useRef } from 'react';
import {
  Header,
  Page,
  Toolbar,
  Subheader,
  Typography,
  ContentFixed,
  Form,
  Card,
} from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { useCommon, usePatient, useStaticOptions } from '@common/hooks';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import moment from 'moment';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import {
  ADTO,
  ContentfulDocument,
  useGetEligiblePlans,
  useUpdatePatient,
} from '@helpful/network';
import { useUser } from '@core/user';
import { useUtils, Content } from '@wearehelpful/ui-kit';
import { useUpload } from './Index.hooks';

interface FormValues {
  dme: FileList;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sexAtBirth: ADTO.SexAtBirth;
  consent: boolean;
}

export type UploadType = 'dme';

export const Index: FC = () => {
  const { validationSchema } = useUpload({ fileSizeLimit: 20 });
  const [{ upload }] = useCommon();
  const { t } = useTranslation();
  const [{ user }, refetch] = useUser();
  const [{ patient }] = usePatient();
  const payor = patient.plans[0].payor.name;
  const plan = patient.plans[0].name;
  const [
    {
      params: { type },
    },
    { push },
  ] = useRoute<{ type: UploadType }, {}>();
  const [{ sexAtBirth }] = useStaticOptions();
  const pageRef = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();
  const { toast } = useUtils();

  const { isLoading: isUpdatePatientLoading, mutate: updatePatient } =
    useUpdatePatient();
  const { isLoading: isGetPlansLoading, mutate: getPlans } =
    useGetEligiblePlans();

  const uploadEntity = upload.filter(
    ({ type: uploadType }) => uploadType === type
  )[0];

  const initialValues: FormValues = {
    dme: null,
    firstName: user?.patients?.[0]?.patient?.firstName ?? '',
    lastName: user?.patients?.[0]?.patient?.lastName ?? '',
    dateOfBirth: user?.patients?.[0]?.patient?.dateOfBirth
      ? moment(user?.patients?.[0]?.patient?.dateOfBirth).format('MM/DD/YYYY')
      : '',
    sexAtBirth: user?.patients?.[0]?.patient?.sexAtBirth ?? null,
    consent: false,
  };

  const handleFormSubmit = (values: FormValues) => {
    getPlans(
      {
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
          sexAtBirth: values.sexAtBirth,
        },
      },
      {
        onSuccess: ({ data: eligiblePlans }) => {
          if (eligiblePlans.length === 0) {
            toast({
              variant: 'error',
              message: t('errors.eligibilityPlanNotFound', { payor }),
            });
            track({
              id: AnalyticsTrackEvents.UPLOAD_DME_ERROR,
            });
          } else {
            updatePatient(
              {
                id: patient.id,
                data: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
                  sexAtBirth: values.sexAtBirth,
                },
              },
              {
                onSuccess: () => {
                  refetch();
                  push({
                    path: RoutePath.ROOT_UPLOAD_CALLBACK,
                    params: { type },
                    search: { payor },
                    state: {
                      caregiver: {
                        email: user.email,
                      },
                      patient: {
                        id: patient.id,
                        payor,
                        plan,
                      },
                      upload: {
                        type,
                        files: values.dme,
                      },
                    },
                  });
                },
              }
            );
          }
        },
        onError: (error) => {
          toast({
            variant: 'error',
            message: t('errors.dmeNotUploaded'),
          });
          track({
            id: AnalyticsTrackEvents.UPLOAD_DME_ERROR,
            properties: {
              error: error as any,
            },
          });
        },
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.UPLOAD}
      name={`${AnalyticsPageCategory.UPLOAD}: ${type}`}
      properties={{ plan }}
    >
      <Page ref={pageRef}>
        <Header>
          <Toolbar>
            <Toolbar.Back defaultHref={RoutePath.ROOT_BENEFITS} />
          </Toolbar>
        </Header>
        <Content>
          <Subheader>
            <Typography.Heading3>
              {t('subheadings.benefitsUpload.title')}
            </Typography.Heading3>
            <Typography.Body2>
              {t('subheadings.benefitsUpload.description', { payor })}
            </Typography.Body2>
          </Subheader>
          <ContentFixed padding>
            <Form
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              <Form.FileUpload
                name="dme"
                label={t('forms.dmeUploadTitle')}
                prompt={t('forms.dmeUploadPrompt')}
                accept={[
                  'application/pdf',
                  'image/jpeg',
                  'image/heic',
                  'image/heif',
                  'image/tiff',
                  'image/png',
                  'image/bmp',
                  'image/gif',
                ].join(',')}
              />
              <Form.Input
                autocapitalize="on"
                name="firstName"
                placeholder="First name"
                label={t('forms.lovedOnesFirstName')}
                tabIndex={1}
                enterKeyHint="next"
              />
              <Form.Input
                autocapitalize="on"
                name="lastName"
                placeholder="Last name"
                label={t('forms.lovedOnesLastName')}
                tabIndex={2}
                enterKeyHint="next"
              />
              <Form.DatePicker
                name="dateOfBirth"
                placeholder="MM/DD/YYYY"
                label={t('forms.lovedOnesDateOfBirth')}
                defaultValue={moment()
                  .subtract(70, 'years')
                  .format('MM/DD/YYYY')}
                tabIndex={3}
                enterKeyHint="next"
              />
              <Form.Select
                name="sexAtBirth"
                label={t('forms.lovedOnesSexAtBirth')}
                options={sexAtBirth}
                tabIndex={4}
              />
              <Card>
                <Card.Content>
                  {uploadEntity?.body && (
                    <ContentfulDocument>
                      {uploadEntity.body.json}
                    </ContentfulDocument>
                  )}
                  <Form.Checkbox
                    name="consent"
                    label={t('forms.dmeUploadConsent')}
                  />
                </Card.Content>
              </Card>
              <Form.Submit
                disabled={isUpdatePatientLoading || isGetPlansLoading}
              >
                {t('actions.submit')}
              </Form.Submit>
            </Form>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
