import { useRef, useLayoutEffect } from 'react';
import { noop } from 'lodash';
import { useContext } from 'use-context-selector';
import { FormikProps, useFormikContext } from 'formik';
import { EnterKeyHint } from './Form.types';
import { FormContext } from './Form.context';

export const useForm = <T extends object>() => {
  return useRef<FormikProps<T>>();
};

interface UseInputParams {
  autofocus?: boolean;
  tabIndex?: number;
  enterKeyHint?: EnterKeyHint;
}

export const useInput = ({
  tabIndex,
  enterKeyHint,
  autofocus = false,
}: UseInputParams) => {
  const input = useRef<HTMLIonInputElement>();
  const form = useFormikContext();

  const { setFormDataIndex, removeFormDataIndex, focusNextFormItem } =
    useContext(FormContext);

  useLayoutEffect(() => {
    if (tabIndex) {
      const control = input.current;
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (enterKeyHint === 'next') {
            focusNextFormItem(tabIndex);
          }
          if (enterKeyHint === 'go') {
            form.submitForm();
          }
        }
      };
      control?.addEventListener('keydown', listener);
      return () => control?.removeEventListener('keydown', listener);
    }
    return noop;
  }, [focusNextFormItem, enterKeyHint, tabIndex, form]);

  useLayoutEffect(() => {
    if (tabIndex) setFormDataIndex(tabIndex, input);
    return () => {
      if (tabIndex) removeFormDataIndex(tabIndex);
    };
  }, [tabIndex, setFormDataIndex, removeFormDataIndex]);

  useLayoutEffect(() => {
    if (autofocus) {
      setTimeout(() => {
        input.current?.setFocus();
      }, 0);
    }
  }, [autofocus]);

  return input;
};
