enum Locale {
  en = 'en',
  es = 'es',
  ru = 'ru',
  uk = 'uk',
}

export const SUPPORTED_LNGS: Locale[] = [
  Locale.en,
  Locale.es,
  Locale.ru,
  Locale.uk,
];
