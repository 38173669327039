import styled from 'styled-components';
import { IonButton } from '@ionic/react';

export const Close = styled(IonButton)`
  display: block !important;
  --border-radius: 100% !important;
  --padding-start: 12px !important;
  --padding-end: 12px !important;
  height: 48px !important;
  font-size: 18px !important;
`;
