import { FC } from 'react';
import { noop } from 'lodash';
import { CDTO } from '@helpful/network';
import { Root, Benefit } from './BenefitsCarousel.style';

interface BenefitsCarouselProps {
  items: DeepPartial<CDTO.PlanBenefit>[];
  onItemClick?: (item: DeepPartial<CDTO.PlanBenefit>, order: number) => void;
}

export const BenefitsCarousel: FC<BenefitsCarouselProps> = ({
  items = [],
  onItemClick = noop,
}) => {
  return (
    <Root itemsCount={items.length}>
      {items.map((item, index) => (
        <Benefit
          withIcon
          {...item}
          truncate={3}
          key={item.sys.id}
          onClick={() => onItemClick(item, index)}
        />
      ))}
    </Root>
  );
};
