import { useCallback, useMemo } from 'react';
import { RoutePath, TourId } from '@common/constants';
import { TabsNavigation, useRoute } from '@core/navigation';
import { Icon } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { useMount, useSet } from 'react-use';

export const useRoot = () => {
  const { t } = useTranslation();
  const { page } = useAnalytics();
  const [{ pathname }] = useRoute();

  const tabs: TabsNavigation = useMemo(
    () => [
      {
        path: RoutePath.ROOT_HOME,
        label: t('navigation.home'),
        icon: <Icon name="helpful" />,
        className: TourId.AutomaticallyCreateAPersonalizedCarePlan,
      },
      {
        path: RoutePath.ROOT_SEARCH,
        label: t('navigation.search'),
        icon: <Icon name="search" />,
        className: TourId.AskHelpfulsAssistantWhatToDoNext,
      },
      {
        path: RoutePath.ROOT_MEDICAL,
        label: t('navigation.medical'),
        icon: <Icon name="medical" />,
        className: TourId.OrganizeMedicalRecordsAllInOnePlace,
      },
      {
        path: RoutePath.ROOT_BENEFITS,
        label: t('navigation.benefits'),
        icon: <Icon name="nav_benefits" />,
        className: TourId.UnderstandYourLovedOnesInsuranceBenefits,
      },
      {
        path: RoutePath.ROOT_MORE,
        label: t('navigation.more'),
        icon: <Icon name="nav_more" />,
      },
    ],
    [t]
  );

  const [, { add, has }] = useSet();

  useMount(() => {
    const currentTab = tabs.find((item) => item.path === pathname);

    if (currentTab) {
      add(currentTab.label);
    }
  });

  const onTabsDidChange = useCallback(
    (tab: string) => {
      if (has(tab)) {
        page({ name: tab, category: RoutePath.ROOT });
      } else {
        add(tab);
      }
    },
    [add, has, page]
  );

  return useMemo(() => ({ tabs, onTabsDidChange }), [tabs, onTabsDidChange]);
};
