import { PropsWithChildren, ReactElement } from 'react';
import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { SwiperNavButton } from './SwiperNavButton/SwiperNavButton';
import { Root } from './Swiper.style';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

type SwiperProps = PropsWithChildren<{
  className?: string;
}>;

interface SwiperComponent {
  (props: SwiperProps): ReactElement | null;
  Slide: typeof SwiperSlide;
}

const MODULES = [
  Pagination,
  !Capacitor.isNativePlatform() && Navigation,
].filter((v) => !!v);

export const Swiper: SwiperComponent = ({ children, className }) => {
  const isWebDesktop = isPlatform('desktop') && !Capacitor.isNativePlatform();

  return (
    <Root
      modules={MODULES}
      className={className}
      isWebDesktop={isWebDesktop}
      pagination={{ enabled: true, clickable: false }}
    >
      {isWebDesktop && (
        <>
          <SwiperNavButton type="prev">123</SwiperNavButton>
          <SwiperNavButton type="next">123</SwiperNavButton>
        </>
      )}

      {children}
    </Root>
  );
};

Swiper.Slide = SwiperSlide;
