import SECURE from '../assets/images/secure.png';
import HAND_WALK from '../assets/images/hand-walk.png';
import PEACE_PUZZLE from '../assets/images/peace-puzzle.png';
import AFTER_VISIT_SUMMARY_SCREEN from '../assets/images/after-visit-summary-screen.png';
import ALLERGIES_SCREEN from '../assets/images/allergies-screen.png';
import CARETEAM_SCREEN from '../assets/images/careteam-screen.png';
import CONDITION_SCREEN from '../assets/images/condition-screen.png';
import MEDICATIONS_SCREEN from '../assets/images/medications-screen.png';

export const IMAGES = {
  ALLERGIES_SCREEN,
  AFTER_VISIT_SUMMARY_SCREEN,
  CARETEAM_SCREEN,
  CONDITION_SCREEN,
  HAND_WALK,
  MEDICATIONS_SCREEN,
  PEACE_PUZZLE,
  SECURE,
};
