import { FC } from 'react';
import { stringify } from 'qs';
import { generatePath } from 'react-router';
import { Redirect as RouterDomRedirect } from 'react-router-dom';

interface RedirectProps {
  path: string;
  search?: object;
  params?: object;
  state?: object;
  push?: boolean;
}

export const Redirect: FC<RedirectProps> = ({
  path,
  push,
  search: initialSearch = {},
  params = {},
  state,
}) => {
  const pathname = generatePath(path, params);

  const search = stringify(initialSearch, {
    addQueryPrefix: true,
    encode: false,
  });

  return <RouterDomRedirect push={push} to={{ pathname, search, state }} />;
};
