import {
  Page,
  Header,
  Toolbar,
  Typography,
  Footer,
  Button,
  Swiper,
} from '@core/ui';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useCommon } from '@common/hooks';
import { useRoute } from '@core/navigation';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation, Translate } from '@wearehelpful/ui-localization';
import { Logo, Content } from '@wearehelpful/ui-kit';
import {
  Heading,
  Container,
  Slides,
  Slider,
  Actions,
  SignIn,
} from './Welcome.style';
import { WelcomeSlide } from './partitions/WelcomeSlide/WelcomeSlide';
import { useGetStarted } from './Welcome.hooks';

const { Heading5, Body2 } = Typography;

export const Welcome = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const [{ loading }, setStarted] = useGetStarted();

  const [{ welcome }] = useCommon();

  const handleOnGetStarted = () => {
    setStarted();
    push({ path: RoutePath.ROOT_HOME });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.WELCOME}
      name={AnalyticsPageCategory.WELCOME}
    >
      <Page>
        <Header>
          <Toolbar>
            <Heading>
              <Heading5>{t('subheadings.welcome.title')}</Heading5>
              <Logo />
            </Heading>
          </Toolbar>
        </Header>
        <Content padding>
          <Container>
            <Slides>
              <Slider>
                {welcome.map((item) => {
                  return (
                    <Swiper.Slide key={item.title}>
                      <WelcomeSlide {...item} />
                    </Swiper.Slide>
                  );
                })}
              </Slider>
            </Slides>
          </Container>
        </Content>
        <Footer>
          <Toolbar padding>
            <Actions>
              <Button onClick={handleOnGetStarted} loading={loading}>
                {t('actions.getStartedForFree')}
              </Button>
              <Body2 align="center">
                <Translate
                  id="actions.haveAnAccountLogIn"
                  components={{
                    a: (
                      <SignIn
                        onClick={setStarted}
                        path={RoutePath.AUTH_SIGN_IN}
                      />
                    ),
                  }}
                />
              </Body2>
            </Actions>
          </Toolbar>
        </Footer>
      </Page>
    </TrackPageView>
  );
};
