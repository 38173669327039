import { FC } from 'react';
import { DTO, useCheckEmail, useForgotPassword } from '@core/auth';
import { useRoute } from '@core/navigation';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { capitalize } from 'lodash';
import {
  useUtils,
  Page,
  Header,
  Content,
  Typography,
  Form,
} from '@wearehelpful/ui-kit';

export const ForgotPassword: FC = () => {
  const { toast } = useUtils();
  const { t } = useTranslation();

  const [
    {
      search: { email },
    },
    { push },
  ] = useRoute<{}, { email: string }>();

  const [{ loading }, resetPassword] = useForgotPassword();
  const [, checkEmail] = useCheckEmail();
  const { track } = useAnalytics();

  const handleOnSubmit = ({ email }: DTO.IForgotPasswordRequest) => {
    track({
      id: AnalyticsTrackEvents.FORGOT_PASSWORD,
      properties: { email },
    }).finally(() => {
      checkEmail(
        { email },
        {
          onSuccess: ({ provider }) => {
            if (provider) {
              push({ path: RoutePath.AUTH_SIGN_IN });
              toast({
                variant: 'error',
                message: t('errors.federatedEmailExists', {
                  provider: capitalize(provider),
                }),
              });
            } else {
              resetPassword(
                { email },
                {
                  onSuccess: () =>
                    toast({
                      variant: 'success',
                      message: t('notifications.forgotPasswordSuccess', {
                        email,
                      }),
                    }),
                }
              );
            }
          },
        }
      );
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.FORGOT_PASSWORD}
    >
      <Page headerBackground="periwinkle300">
        <Header>
          <Header.Back defaultHref={RoutePath.AUTH_SIGN_IN} />
          <Header.Logo />
        </Header>
        <Content>
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.forgotPassword.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form<DTO.IForgotPasswordRequest>
              onSubmit={handleOnSubmit}
              defaultValues={{ email }}
            >
              <Form.Text
                autofocus
                name="email"
                label={t('forms.email')}
                validation={{ email: true, required: true }}
              />
              <Form.Actions>
                <Form.Submit loading={loading}>
                  {t('actions.sendResetInstructions')}
                </Form.Submit>
              </Form.Actions>
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
