import { useContext } from 'use-context-selector';
import {
  ConfigContext,
  ConfigContextProps,
} from '../../contexts/Config.context';

interface UseConfigReturnType extends ConfigContextProps {
  version: string;
}

export const useConfig = (): UseConfigReturnType => {
  const config = useContext(ConfigContext);

  const version = (({ build, environment }) => {
    return `${environment?.toUpperCase()[0]}${build}`;
  })(config);

  return { ...config, version };
};
