import { Theme } from '@wearehelpful/ui-kit';
import { UiKitStatus } from '../../../types/uikit.types';

export const STATUS_COLOR_MAP = (
  status: UiKitStatus,
  palette: Theme['palette']
) =>
  ({
    error: palette.red500,
    success: palette.green400,
  }[status]);
