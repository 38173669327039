import styled from 'styled-components';
import { CSSProperties } from 'react';
import { IonCardContent } from '@ionic/react';

interface RootProps {
  justifyContent: CSSProperties['justifyContent'];
}

export const Root = styled(IonCardContent)<RootProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
