import { FC } from 'react';
import { useRoute } from '@core/navigation';
import { useSession } from '@core/auth';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Button, Header, Logo } from '@wearehelpful/ui-kit';
import { RoutePath } from '../../constants';
import { Container, Buttons, SignInButton } from './HeaderWithAuth.style';
import { HeaderWithAuthLogo } from './HeaderWithAuthLogo/HeaderWithAuthLogo';

interface HeaderWithAuthProps {
  logo?: boolean;
  logoVariant?: typeof Logo['defaultProps']['variant'];
  signInButtonColor?: string;
}

export const HeaderWithAuth: FC<HeaderWithAuthProps> = ({
  logo = true,
  logoVariant,
  signInButtonColor,
}) => {
  const [, { push }] = useRoute<{ slug: string }, {}>();

  const [{ isSessionExist }] = useSession();

  const { t } = useTranslation();

  return (
    <Header>
      <Container>
        {logo && <HeaderWithAuthLogo logoVariant={logoVariant} />}
        {!isSessionExist && (
          <Buttons>
            <SignInButton
              color={signInButtonColor}
              size="s"
              variant="secondary"
              onClick={() => push({ path: RoutePath.AUTH_SIGN_IN })}
            >
              {t('actions.logIn')}
            </SignInButton>
            <Button
              size="s"
              variant="tertiary"
              onClick={() => push({ path: RoutePath.AUTH })}
            >
              {t('actions.signUp')}
            </Button>
          </Buttons>
        )}
      </Container>
    </Header>
  );
};
