import { FC } from 'react';
import { ADTO } from '@helpful/network';
import { Form } from '@wearehelpful/ui-kit';

interface SinglePlanSuplimentalProps {
  plans: ADTO.PlanEntity[];
  config: ADTO.PayorEntity;
}

export const SinglePlanSuplimental: FC<SinglePlanSuplimentalProps> = ({
  config,
  plans,
}) => {
  const supplementalPlans = plans.filter(
    ({ isSupplemental, isHidden }) => isSupplemental && !isHidden
  );

  if (!config?.isMultiSupplemental && !!supplementalPlans.length) {
    return (
      <Form.RadioGroup
        name="supplemental"
        validation={{ required: config?.isSupplementalRequired }}
      >
        {supplementalPlans.map(({ id, name }) => {
          return (
            <Form.RadioGroup.Item key={id} value={id}>
              {name}
            </Form.RadioGroup.Item>
          );
        })}
      </Form.RadioGroup>
    );
  }

  return null;
};
