import { FC } from 'react';
import {
  ContentFixed,
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  List,
  Chips,
  Button,
} from '@core/ui';
import { PromptPaper } from '@common/features';
import { useRoute } from '@core/navigation';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useMedical, usePatient } from '@common/hooks';
import { useTranslation } from '@wearehelpful/ui-localization';
import { CDTO } from '@helpful/network';
import { Icon, Skeleton, Content } from '@wearehelpful/ui-kit';
import { useOptions } from './Index.hooks';
import { ListLabel } from './Index.style';

export const Index: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const { options } = useOptions();
  const [, { push }] = useRoute();
  const [{ loading, connections, prompt }] = useMedical();
  const { track } = useAnalytics();

  const handleOnConnectionClick = (
    id: string,
    name: string,
    isActive: boolean
  ) => {
    if (!isActive) {
      track({
        id: AnalyticsTrackEvents.MEDICAL_UPDATE_CONNECTION,
        properties: {
          systemId: id,
          systemName: name,
        },
      }).finally(() => {
        push({ path: RoutePath.CONNECT_REDIRECT, params: { id } });
      });
    }
  };

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_STARTED }).finally(() => {
      push({ path: RoutePath.CONNECT });
    });
  };

  const handleOnContactUs = () => {
    push({
      path: RoutePath.CONTACT_US,
    });
  };

  const handleOnCtaClick = ({ ctaUrl: path }: DeepPartial<CDTO.Prompt>) => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_STARTED }).finally(() => {
      push({ path });
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.ROOT}
      name={AnalyticsPageCategory.MEDICAL}
    >
      <Page>
        <Header>
          <Toolbar />
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading1>
              {t('subheadings.medical.title')}
            </Typography.Heading1>
            <Typography.Body1>
              {t('subheadings.medical.description', {
                name: patient.firstName,
              })}
            </Typography.Body1>
            {!connections?.length && (
              <Chips>
                <Chips.Item status="error">
                  {t('empty.noMedicalRecordsConnected')}
                </Chips.Item>
              </Chips>
            )}
          </Subheader>
          <ContentFixed>
            <List>
              {options.map(({ title, icon, path, disabled }) => {
                return (
                  <List.Item
                    disabled={disabled}
                    key={title}
                    onClick={() => push({ path })}
                  >
                    <List.Thumb disabled={disabled}>
                      <Icon name={icon} />
                    </List.Thumb>
                    <List.Label>
                      <List.Label>{title}</List.Label>
                      {disabled && (
                        <List.Subtitle>
                          {t('subheadings.comingSoon')}
                        </List.Subtitle>
                      )}
                    </List.Label>
                  </List.Item>
                );
              })}
            </List>
            <Skeleton variant="list" loading={loading}>
              <List title={t('subheadings.healthNetworks.title')}>
                <List.Item onClick={handleOnConnect}>
                  <List.Thumb color="orange400">
                    <Icon name="plus" />
                  </List.Thumb>
                  <List.Label>
                    <List.Label>
                      {t('actions.connectMedicalRecords')}
                    </List.Label>
                  </List.Label>
                </List.Item>
                {connections.map(({ isActive, system: { id, name } }) => {
                  return (
                    <List.Item
                      key={id}
                      onClick={() =>
                        handleOnConnectionClick(id, name, isActive)
                      }
                    >
                      <List.Thumb align="flex-start">
                        <Icon name="medical-building" />
                      </List.Thumb>
                      <List.Label>
                        <ListLabel>
                          <span>{name}</span>
                          {!isActive && (
                            <List.Badge variant="error">
                              {t('actions.updateConnection')}&nbsp;
                              <Icon name="refresh" size="sm" />
                            </List.Badge>
                          )}
                        </ListLabel>
                      </List.Label>
                    </List.Item>
                  );
                })}
              </List>
              {!loading && prompt && (
                <PromptPaper prompt={prompt} onCtaClick={handleOnCtaClick}>
                  <Button variant="secondary" onClick={handleOnContactUs}>
                    {t('actions.contactHelpful')}
                  </Button>
                </PromptPaper>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
