import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import {
  useGetPatientTodosInfinite,
  useRankPatientTodo,
} from '@helpful/network';
import { usePatient } from '@common/hooks';
import { useSession } from '@core/auth';
import { useLocale } from '@wearehelpful/ui-localization';
import { HomeContext, HomeReorderParams } from '../../contexts/Home.context';
import { TAKE, INITIAL_DATA } from './HomeProvider.const';

type HomeProviderProps = PropsWithChildren<{}>;

export const HomeProvider: FC<HomeProviderProps> = ({ children }) => {
  const [{ lng: locale }] = useLocale();
  const [{ patient }] = usePatient();
  const [{ isSessionExist }] = useSession();
  const [order, setOrder] = useState<Record<string, number>>({});

  const {
    refetch,
    hasNextPage,
    fetchNextPage,
    isInitialLoading,
    data: { pages } = INITIAL_DATA,
  } = useGetPatientTodosInfinite(
    patient?.id,
    {
      locale,
      skip: 0,
      take: TAKE,
      order: 'status:DESC,rank:ASC',
    },
    {
      query: {
        enabled: isSessionExist && !!patient?.id,
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const { mutate: rank } = useRankPatientTodo();

  const todos = useMemo(() => {
    return pages
      .flatMap(({ data }) => data)
      .sort(({ id: a }, { id: b }) => {
        return get(order, a, 0) - get(order, b, 0);
      });
  }, [pages, order]);

  const total = get(pages, ['0', 'meta', 'total'], 0);

  const reorder = useCallback(
    ({ items, to }: HomeReorderParams) => {
      const todoId = get(items, [to, 'id']);
      const after = get(items, [to - 1, 'rank']);
      const before = get(items, [to + 1, 'rank']);
      setOrder(() =>
        items.reduce((res, { id }, index) => ({ ...res, [id]: index }), {})
      );
      rank(
        {
          todoId,
          data: {
            before,
            after,
          },
          id: patient.id,
        },
        {
          onSuccess: async () => {
            await refetch();
            setOrder({});
          },
        }
      );
    },
    [patient.id, rank, refetch]
  );

  return (
    <HomeContext.Provider
      value={{
        todos,
        total,
        reorder,
        refetch,
        hasNextPage,
        fetchNextPage,
        loading: isInitialLoading,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
