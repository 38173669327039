import { FC, PropsWithChildren } from 'react';
import { useGetStacksQuery } from '@helpful/network';
import { StacksContext } from '../../contexts/Stacks.context';

type StacksProviderProps = PropsWithChildren<{}>;

export const StacksProvider: FC<StacksProviderProps> = ({ children }) => {
  const { isLoading, data } = useGetStacksQuery();

  return (
    <StacksContext.Provider value={{ isLoading, data }}>
      {children}
    </StacksContext.Provider>
  );
};
