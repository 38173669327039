import { PropsWithChildren, forwardRef, useImperativeHandle } from 'react';
import { Card, ContentFixed, Subheader, Typography, useThumb } from '@core/ui';
import { CtaList, type CtaListProps, ReadTime } from '@common/features';
import {
  useGetGuideQuery,
  ContentfulDocument,
  ContentfulImage,
} from '@helpful/network';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useShare } from '@helpful/native';
import { generatePath, useRoute } from '@core/navigation';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  CallToActionRoutes,
  RoutePath,
} from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useUtils, Skeleton, Content } from '@wearehelpful/ui-kit';
import { Preview, LongDescription } from './GuidesDetails.style';

type GuidesDetailsProps = PropsWithChildren<{
  slug: string;
  origin: string;
}>;

export interface GuidesDetailsInstanse {
  onShare: () => void;
}

export const GuidesDetails = forwardRef<
  GuidesDetailsInstanse,
  GuidesDetailsProps
>(({ children, slug, origin }, ref) => {
  const [, share] = useShare();
  const { toast } = useUtils();
  const { t } = useTranslation();
  const { track, trackClick } = useAnalytics();
  const { width: thumbW, height: thumbH } = useThumb({
    width: (v) => v - 32,
    height: (v) => (v - 32) * 0.55,
  });
  const [{ search }, { push }] = useRoute();

  const { data: response, isFetching } = useGetGuideQuery(
    { slug },
    {
      initialData: {
        trainingCollection: {
          items: [],
        },
      },
    }
  );

  const [guide] = response.trainingCollection.items;

  const handleOnCtaClick: CtaListProps['onClick'] = ({
    title,
    ctaUrl: path,
  }) => {
    track({
      id: AnalyticsTrackEvents.GUIDE_CTA_CLICK,
      properties: { title, guideTitle: guide.title },
    }).finally(() => {
      push({ path });
    });
  };

  useImperativeHandle(ref, () => ({
    onShare: () => {
      share(
        {
          title: guide?.title,
          search: { utm_source: 'share' },
          description: guide?.shortDescription,
          pathname: generatePath(RoutePath.ROOT_GUIDES_DETAILS, { slug }),
        },
        {
          onSuccess: ({ canShare }) => {
            trackClick({
              source: AnalyticsPageCategory.GUIDE,
              element: AnalyticsTrackEvents.SHARE,
              properties: { guide: slug },
            });
            if (!canShare) {
              toast({
                variant: 'success',
                message: t('notifications.linkCopied'),
              });
            }
          },
        }
      );
    },
  }));

  return (
    <Content>
      {guide?.title && (
        <Subheader>
          <Typography.Heading2>{guide?.title}</Typography.Heading2>
          <Typography.Body2>{guide?.shortDescription}</Typography.Body2>
        </Subheader>
      )}
      <ContentFixed>
        <Skeleton variant="article" loading={isFetching}>
          <TrackPageView
            category={origin}
            name={guide?.title}
            properties={{ ...search }}
          >
            <Preview flat>
              <Card.Thumb>
                {guide?.cardImage && (
                  <ContentfulImage
                    width={thumbW}
                    height={thumbH}
                    alt={guide?.title}
                    url={guide?.cardImage.url}
                  />
                )}
              </Card.Thumb>
              <Card.Footer>
                {guide?.readTime && <ReadTime value={guide?.readTime} />}
              </Card.Footer>
            </Preview>
            <CtaList
              route={CallToActionRoutes.GUIDES_DETAILS}
              onClick={handleOnCtaClick}
            />
            <LongDescription>
              <ContentfulDocument
                assets={guide?.longDescription?.links.assets.block}
              >
                {guide?.longDescription?.json}
              </ContentfulDocument>
            </LongDescription>
            {children}
          </TrackPageView>
        </Skeleton>
      </ContentFixed>
    </Content>
  );
});
