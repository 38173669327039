import { PropsWithChildren, ReactElement } from 'react';
import { IonRouterOutlet, IonTabBar, IonTabButton } from '@ionic/react';
import { noop } from 'lodash';
import { TabsNavigation } from './Tabs.types';
import { Root, Label } from './Tabs.style';

type TabsProps = PropsWithChildren<{
  navigation: TabsNavigation;
  onTabsDidChange?: (tab: string) => void;
}>;

interface TabsComponent {
  (props: TabsProps): ReactElement<any, any> | null;
}

export const Tabs: TabsComponent = ({
  children,
  navigation,
  onTabsDidChange = noop,
}) => {
  return (
    <Root>
      <IonRouterOutlet>{children}</IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        onIonTabsDidChange={({ detail: { tab } }) => onTabsDidChange(tab)}
      >
        {navigation.map(({ path, label, icon, className }, index) => {
          return (
            <IonTabButton
              href={path}
              key={`tab${index}`}
              tab={label}
              className={className}
            >
              {icon}
              <Label>{label}</Label>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </Root>
  );
};
