import { FC } from 'react';
import {
  Page,
  Header,
  ContentFixed,
  Subheader,
  Typography,
  Toolbar,
} from '@core/ui';
import { useTranslation } from '@wearehelpful/ui-localization';
import { StackHeroCard } from '@common/features';
import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { Skeleton, Content } from '@wearehelpful/ui-kit';
import { useStacks } from '../../../../hooks/useStacks/useStacks.hook';
import { List } from './Index.style';

const { Heading1 } = Typography;

export const Index: FC = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const { trackClick } = useAnalytics();

  const { isLoading, data } = useStacks();

  const handleOnStackClick = (slug: string, title: string) => {
    trackClick({
      source: AnalyticsPageCategory.STACKS,
      element: AnalyticsPageCategory.STACK,
      properties: { title: title },
    });
    push({ path: RoutePath.ROOT_STACKS_DETAILS, params: { slug } });
  };
  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Content>
        <Subheader divider>
          <Heading1>{t('subheadings.stacks.title')}</Heading1>
        </Subheader>
        <ContentFixed>
          <Skeleton variant="feed" loading={isLoading}>
            <TrackPageView
              category={AnalyticsPageCategory.ROOT}
              name={AnalyticsPageCategory.STACKS}
            >
              <List>
                {data?.stackCollection.items.map(
                  ({ title, heroTitle, heroColor, slug }) => {
                    return (
                      <StackHeroCard
                        key={slug}
                        category={title}
                        title={heroTitle}
                        background={heroColor}
                        onClick={() => handleOnStackClick(slug, title)}
                      />
                    );
                  }
                )}
              </List>
            </TrackPageView>
          </Skeleton>
        </ContentFixed>
      </Content>
    </Page>
  );
};
