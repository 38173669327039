import { FC, useState } from 'react';
import { useMount } from 'react-use';
import {
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  Footer,
  Button,
  SplashPage,
} from '@core/ui';
import { useVerifyEmail, useSession } from '@core/auth';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Redirect } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { useUtils, Content } from '@wearehelpful/ui-kit';
import { Container, Placeholder, Image } from './VerifyEmail.style';
import PLACEHOLDER from './assets/pictographic.png';

const { Heading2, Body1 } = Typography;

export const VerifyEmail: FC = () => {
  const [redirect, setRedirect] = useState<RoutePath>();
  const { t } = useTranslation();
  const [{ isSessionExist }] = useSession();
  const [{ loading }, verify] = useVerifyEmail();
  const { toast } = useUtils();

  useMount(() =>
    verify(null, {
      onError: ({ message }) => {
        toast({
          variant: 'error',
          message,
        });
        setRedirect(RoutePath.LANDING);
      },
    })
  );

  if (loading) {
    return <SplashPage />;
  }

  if (redirect) {
    return <Redirect path={RoutePath.LANDING} />;
  }

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Content padding>
        <Container>
          <Subheader>
            <Heading2 align="center">
              {t('subheadings.verifyEmail.title')}
            </Heading2>
            <Body1 align="center">
              {t('subheadings.verifyEmail.description')}
            </Body1>
          </Subheader>
          <Placeholder>
            <Image src={PLACEHOLDER} alt="" />
          </Placeholder>
        </Container>
      </Content>
      <Footer>
        <Toolbar padding>
          <Button
            onClick={() =>
              setRedirect(
                isSessionExist ? RoutePath.ROOT_MEDICAL : RoutePath.AUTH_SIGN_IN
              )
            }
          >
            {isSessionExist ? t('actions.goToTheApp') : t('actions.signIn')}
          </Button>
        </Toolbar>
      </Footer>
    </Page>
  );
};
