import styled from 'styled-components';
import { Link } from '@core/navigation';
import { Typography } from '@wearehelpful/ui-kit';

export const LinkIcon = styled('span')`
  flex-shrink: 0;
  display: flex;
  width: ${({ theme: { spacing } }) => spacing(11)};
  height: ${({ theme: { spacing } }) => spacing(11)};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  background: ${({ theme }) => theme.palette.periwinkle300};
`;

export const EntityLink = styled(Link)`
  display: block;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  border: 1px solid ${({ theme }) => theme.palette.neutral300};
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  margin: 0;
`;

export const Anchor = styled('a').attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  color: ${({ theme }) => theme.palette.blue400};
  &:hover {
    text-decoration: none;
  }
`;

export const AnchorButton = styled('a').attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  display: block;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  border: 1px solid ${({ theme }) => theme.palette.neutral300};
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  margin-left: 0;
  margin-right: 0;
  text-decoration: none;
  color: ${({ theme: { palette } }) => palette.brown400};
`;

export const More = styled('div')`
  border-top: 1px solid ${({ theme: { palette } }) => palette.neutral300};
  margin-top: ${({ theme: { spacing } }) => spacing(10)};
  padding-top: ${({ theme: { spacing } }) => spacing(10)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const ShortDescription = styled(Typography)`
  white-space: break-spaces;
`;
