import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { CDTO, useInfiniteGetBenefitsQuery } from '@helpful/network';

export type FetchNextPageFunction = ReturnType<
  typeof useInfiniteGetBenefitsQuery
>['fetchNextPage'];

export type RefetchFunction = ReturnType<
  typeof useInfiniteGetBenefitsQuery
>['refetch'];

export interface GetBenefitsFilterParams {
  query?: string;
  stack?: string;
}

export interface BenefitsContextProps {
  stack?: string;
  total?: number;
  query?: string;
  loading: boolean;
  hasNextPage: boolean;
  refetch: RefetchFunction;
  filter: (update: GetBenefitsFilterParams) => void;
  fetchNextPage: FetchNextPageFunction;
  benefits: DeepPartial<CDTO.PlanBenefit>[];
  stacks: CDTO.GetBootstrapQuery['stackCollection']['items'];
}

export const BenefitsContext = createContext<BenefitsContextProps>({
  stacks: [],
  benefits: [],
  filter: noop,
  loading: false,
  hasNextPage: false,
  refetch: () => Promise.resolve(null),
  fetchNextPage: () => Promise.resolve(null),
});
