import { FC } from 'react';
import { RoutePath, UserRole } from '@common/constants';
import { Page } from '@core/ui';
import { Outlet, Route } from '@core/navigation';
import { Index } from './Index/Index';
import { Unverified } from './Unverified/Unverified';

export const Insurance: FC = () => {
  return (
    <Page>
      <Outlet>
        <Route
          path={RoutePath.ROOT_MORE_INSURANCE}
          roles={[UserRole.VERIFIED]}
          redirect={RoutePath.ROOT_MORE_INSURANCE_UNVERIFIED}
          exact
        >
          <Index />
        </Route>
        <Route
          path={RoutePath.ROOT_MORE_INSURANCE_UNVERIFIED}
          roles={[UserRole.UNVERIFIED]}
          redirect={RoutePath.ROOT_MORE_INSURANCE}
        >
          <Unverified />
        </Route>
      </Outlet>
    </Page>
  );
};
