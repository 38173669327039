import styled, { css } from 'styled-components';

const List = css`
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  margin: ${({ theme: { spacing } }) => spacing(4)} 0;
  padding-inline-start: ${({ theme: { spacing } }) => spacing(4)};
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Root = styled('div')`
  color: ${({ theme }) => theme.palette.brown400};
  a {
    color: ${({ theme }) => theme.palette.blue400};
    font-weight: bold;
  }
`;

export const Paragraph = styled('p')`
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  margin: ${({ theme: { spacing } }) => spacing(4)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const UlList = styled('ul')`
  ${List}
`;

export const OlList = styled('ol')`
  ${List}
`;

export const Heading1 = styled('h1')`
  margin: ${({ theme: { spacing } }) => spacing(8)} 0
    ${({ theme: { spacing } }) => spacing(6)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Heading2 = styled('h2')`
  margin: ${({ theme: { spacing } }) => spacing(8)} 0
    ${({ theme: { spacing } }) => spacing(6)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Heading3 = styled('h3')`
  margin: ${({ theme: { spacing } }) => spacing(8)} 0
    ${({ theme: { spacing } }) => spacing(6)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Heading4 = styled('h4')`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize(22)};
  line-height: ${({ theme }) => theme.fontSize(26)};
  margin: ${({ theme: { spacing } }) => spacing(10)} 0
    ${({ theme: { spacing } }) => spacing(4)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Heading5 = styled('h5')`
  margin: ${({ theme: { spacing } }) => spacing(8)} 0
    ${({ theme: { spacing } }) => spacing(6)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Heading6 = styled('h6')`
  margin: ${({ theme: { spacing } }) => spacing(8)} 0
    ${({ theme: { spacing } }) => spacing(6)} 0;
  color: ${({ theme }) => theme.palette.brown400};
`;

export const Hr = styled('hr')``;

export const ListItem = styled('li')`
  margin: ${({ theme: { spacing } }) => spacing(0.5)} 0;
  color: ${({ theme }) => theme.palette.brown400};
  ${Paragraph} {
    margin: 0;
  }
`;

export const Quote = styled('q')``;

export const Link = styled('a').attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  color: ${({ theme }) => theme.palette.blue400};
  &:hover {
    text-decoration: none;
  }
`;
