import { FC, PropsWithChildren } from 'react';
import { History } from '@core/navigation';
// import { NetworkProvider } from '@core/network';
import { FlagsProvider, FlagsProviderProps } from '@helpful/flags';
import { SPLASH } from '@common/constants';
import { NotificationsProvider } from '@helpful/messaging';
import { ITags, render } from 'micromustache';
import { NativeProvider } from '@helpful/native';
import { NavigationBootstrap } from './NavigationBootstrap/NavigationBootstrap';
import { UserBootstrap } from './UserBootstrap/UserBootstrap';
import { AuthBootstrap } from './AuthBootstrap/AuthBootstrap';
import { CommonBootstrap } from './CommonBootstrap/CommonBootstrap';

const tags: ITags = ['{', '}'];

export const templateContent = (string?: string, data?: object) =>
  render(string, data, { tags });

export interface BootstrapProps {
  build: string;
  app: {
    host: string;
  };
  notifications: {
    host: string;
  };
  flags: FlagsProviderProps;
  auth: {
    host: string;
    base?: string;
  };
  history: History;
}

export const Bootstrap: FC<PropsWithChildren<BootstrapProps>> = ({
  app,
  auth,
  flags,
  build,
  children,
  notifications,
  history,
}) => {
  return (
    <NativeProvider domain={app.host}>
      <FlagsProvider {...flags} fallback={SPLASH}>
        <NotificationsProvider {...notifications}>
          <AuthBootstrap domain={app.host} {...auth}>
            <UserBootstrap history={history}>
              <NavigationBootstrap history={history}>
                <CommonBootstrap build={build}>{children}</CommonBootstrap>
              </NavigationBootstrap>
            </UserBootstrap>
          </AuthBootstrap>
        </NotificationsProvider>
      </FlagsProvider>
    </NativeProvider>
  );
};
