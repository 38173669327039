import { FC, PropsWithChildren } from 'react';
import { Root } from './BulletPointsDescription.style';

type BulletPointsDescriptionProps = PropsWithChildren<{}>;

export const BulletPointsDescription: FC<BulletPointsDescriptionProps> = ({
  children,
}) => {
  return <Root>{children}</Root>;
};
