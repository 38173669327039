import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Image = styled('img')`
  height: ${({ theme: { spacing } }) => spacing(9)};
`;
