import { createContext } from 'use-context-selector';
import { ADTO } from '@helpful/network';

export interface SignUpContextProps {
  plans: ADTO.PlanEntity[];
  payors: ADTO.PayorEntity[];
}

export const SignUpContext = createContext<SignUpContextProps>({
  plans: [],
  payors: [],
});
