import styled from 'styled-components';
import { ProgressBar } from '../ProgressBar/ProgressBar';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(21)};
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(6)};
`;

export const Title = styled('div')``;

export const Description = styled('div')``;

export const Asset = styled('img')`
  margin: 0;
  padding: 0;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

export const Bar = styled(ProgressBar)`
  align-self: stretch;
`;
