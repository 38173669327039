import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { IonIcon } from '@ionic/react';
import { Icon, IconName } from '@wearehelpful/ui-kit';
import { Root } from './ToolbarIconButton.style';

type ToolbarIconButtonProps = PropsWithChildren<{
  name: IconName;
  onClick?: () => void;
}>;

export const ToolbarIconButton: FC<ToolbarIconButtonProps> = ({
  name,
  onClick = noop,
}) => {
  return (
    <Root onClick={onClick}>
      <Icon name={name} />
      <IonIcon slot="icon-only" />
    </Root>
  );
};
