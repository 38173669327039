import { FC } from 'react';
import {
  ContentFixed,
  Toolbar,
  Footer,
  Subheader,
  Typography,
  Form,
  Button,
  useForm,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { useUser } from '@core/user';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useCreateFeedback } from '@helpful/network';
import { Content } from '@wearehelpful/ui-kit';
import { Pictographic, Image } from './Index.style';
import IMAGE from './assets/pictographic.png';
import { useValidationSchema } from './Index.hooks';

const { Heading2, Body1 } = Typography;

export const Index: FC = () => {
  const { t } = useTranslation();
  const [{ user }] = useUser();
  const form = useForm<Partial<ADTO.FeedbackDto>>();
  const { validationSchema } = useValidationSchema();

  const [, { replace }] = useRoute();

  const { isLoading, mutate } = useCreateFeedback();

  const handleOnSubmit = ({
    email,
    description,
  }: Partial<ADTO.FeedbackDto>) => {
    mutate(
      {
        data: {
          email,
          description,
          subject: 'Feedback',
          type: ADTO.FeedbackDtoType.Question,
        },
      },
      {
        onSuccess: () => replace({ path: RoutePath.FEEDBACK_SUCCESS }),
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.FEEDBACK}
      name={AnalyticsPageCategory.FEEDBACK}
    >
      <Content>
        <Subheader gap="md">
          <Heading2 align="center">{t('subheadings.feedback.title')}</Heading2>
          <Body1 align="center">{t('subheadings.feedback.description')}</Body1>
        </Subheader>
        <ContentFixed>
          <Pictographic>
            <Image src={IMAGE} />
          </Pictographic>
          <Form<Partial<ADTO.FeedbackDto>>
            padding
            form={form}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            initialValues={{ email: user?.email }}
          >
            <Form.Input name="email" label={t('forms.yourEmail')} />
            <Form.Textarea
              autocapitalize="on"
              maxlength={400}
              name="description"
              label={t('forms.yourFeedback')}
            />
          </Form>
        </ContentFixed>
      </Content>
      <Footer>
        <Toolbar padding>
          <Button
            loading={isLoading}
            onClick={() => form.current?.submitForm()}
          >
            {t('actions.send')}
          </Button>
        </Toolbar>
      </Footer>
    </TrackPageView>
  );
};
