import {
  Page,
  Header,
  Toolbar,
  Typography,
  ContentFixed,
  Progress,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { AnalyticsTrackEvents, RoutePath } from '@common/constants';
import { useEffectOnce } from 'react-use';
import { useUploadFile } from '@helpful/network';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { useState } from 'react';
import { useUtils, Content } from '@wearehelpful/ui-kit';
import type { UploadType } from '../Index/Index';

interface UploadCallbackState {
  caregiver: {
    email: string;
  };
  patient: {
    id: string;
    payor: string;
    plan: string;
  };
  upload: {
    type: UploadType;
    files: FileList;
  };
}

const UPLOAD_TYPE_TO_TITLE_MAP: Record<UploadType, string> = {
  dme: 'DME',
};

export const UploadCallback = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { toast } = useUtils();

  const [progress, setProgress] = useState(0);
  const { mutate: uploadFile } = useUploadFile({
    request: {
      onUploadProgress: (event) => setProgress(event.loaded / event.total),
    },
  });

  const [
    {
      params: { type },
      state,
    },
    { push },
  ] = useRoute<{ type: UploadType }, object, UploadCallbackState>();

  useEffectOnce(() => {
    if (!state) {
      push({ path: RoutePath.ROOT_UPLOAD, params: { type } });
      return;
    }

    const { caregiver, patient, upload } = state;

    uploadFile(
      {
        type: upload.type,
        id: patient.id,
        data: { file: upload.files[0] },
      },
      {
        onSuccess: () => {
          track({
            id: AnalyticsTrackEvents.UPLOAD_DME_SUCCESS,
            properties: { plan: patient.plan },
          }).finally(() =>
            push({
              path: RoutePath.ROOT_UPLOAD_SUCCESS,
              params: { type },
              search: { payor: patient.payor, email: caregiver.email },
            })
          );
        },
        onError: (error) => {
          toast({ variant: 'error', message: t('errors.dmeNotUploaded') });
          track({
            id: AnalyticsTrackEvents.UPLOAD_DME_ERROR,
            properties: {
              error: error as any,
            },
          }).finally(() => {
            push({ path: RoutePath.ROOT_UPLOAD, params: { type } });
          });
        },
      }
    );
  });

  return (
    <Page>
      <Header>
        <Toolbar />
      </Header>
      <Content padding>
        <ContentFixed>
          <Progress
            value={progress}
            title={
              <Typography.Heading2 align="center">
                {t('subheadings.upload.title', {
                  type: UPLOAD_TYPE_TO_TITLE_MAP[type],
                })}
              </Typography.Heading2>
            }
            description={
              <Typography.Body2>
                {' '}
                {t('subheadings.upload.description')}
              </Typography.Body2>
            }
            image={{ name: 'HAND_WALK', width: 81 }}
          />
        </ContentFixed>
      </Content>
    </Page>
  );
};
