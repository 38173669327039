import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import {
  emailPasswordSignUp,
  UserType,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { sendVerificationEmail } from 'supertokens-auth-react/recipe/emailverification';
import { ISignUpRequest } from '../../types/custom-dto.type';

type UseSignUpReturnType = [
  {
    data?: UserType;
    error?: Error;
    loading: boolean;
  },
  UseMutateFunction<UserType, Error, ISignUpRequest, unknown>
];

export const useSignUp = (): UseSignUpReturnType => {
  const {
    data,
    isLoading: loading,
    error,
    mutate,
  } = useMutation<UserType, Error, ISignUpRequest>((req) => {
    return emailPasswordSignUp({
      formFields: Object.entries(req).map(([id, value]) => ({
        id,
        value,
      })),
    })
      .then((data) => {
        if (data.status === 'OK') {
          return data.user;
        } else if (data.status === 'FIELD_ERROR' && data.formFields) {
          throw new Error(data.formFields[0].error);
        }
      })
      .then((user) =>
        Promise.all([
          Promise.resolve(user),
          sendVerificationEmail({
            userContext: user,
          }),
        ])
      )
      .then(([user, data]) => {
        if (data.status === 'OK') {
          return user;
        } else if (data.status === 'EMAIL_ALREADY_VERIFIED_ERROR') {
          throw new Error('Email already verified');
        }
      });
  });

  return [{ data, error, loading }, mutate];
};
