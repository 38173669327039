import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { spacing } }) => spacing(1.5)};
`;

export const Item = styled('div')`
  width: ${({ theme: { spacing } }) => spacing(7)};
  height: ${({ theme: { spacing } }) => spacing(1)};
  background-color: ${({ theme }) => theme.palette.brown400};
  border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  &.active ~ & {
    background-color: ${({ theme }) => theme.palette.white100};
  }
`;
