import styled from 'styled-components';
import { ContentFixed } from '@core/ui';

export const Root = styled(ContentFixed)`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 32px;
  padding: 0 16px;
`;

export const Text = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Img = styled('img')`
  width: 138px;
`;
