import styled from 'styled-components';
import { GuidePaper } from '@common/features';

export const Root = styled('div')<{ itemsCount: number }>`
  display: grid;
  overflow-x: auto;
  border-bottom: 1px solid rgb(209, 213, 219);
  grid-template-columns: repeat(${({ itemsCount }) => itemsCount}, min-content);
`;

export const Guide = styled(GuidePaper)`
  margin: 0;
  width: 264px;
  box-sizing: content-box;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  ion-card-content {
    padding-inline: 0;
    padding-top: 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
  ion-card-header {
    padding-inline: 0;
  }
`;
