import { kebabCase } from 'lodash';
import { useCommon } from '@common/hooks';
import { CDTO } from '@helpful/network';
import { useTranslation } from '@wearehelpful/ui-localization';
import { AnchorItem } from './Search.types';
import { SearchSectionId } from './Search.const';

interface UseHelpfulResponseParams {
  shortcut?: string;
}

interface UseAnchorsParams {
  benefits: DeepPartial<CDTO.PlanBenefit>[];
  guides: DeepPartial<CDTO.Training>[];
  stacks: DeepPartial<CDTO.Stack>[];
}

export const useHelpfulResponse = ({ shortcut }: UseHelpfulResponseParams) => {
  const [{ searchShortcuts }] = useCommon();

  const generate = searchShortcuts.find(({ system, slug }) => {
    return !!shortcut ? slug === shortcut : system;
  })?.prompt;

  return { generate };
};

export const useAnchors = ({ benefits, guides, stacks }: UseAnchorsParams) => {
  const { t } = useTranslation();

  const responeAnchors: AnchorItem[] = [
    {
      label: t('options.helpfulResponse'),
      value: SearchSectionId.HelpfulResponse,
    },
  ];

  const benefitsAnchors: AnchorItem[] = !!benefits.length
    ? [
        {
          label: t('options.relatedBenefits'),
          value: SearchSectionId.Benefits,
        },
      ]
    : [];

  const guidesAnchors: AnchorItem[] = !!guides.length
    ? [
        {
          label: t('options.helpfulGuides'),
          value: SearchSectionId.Guides,
        },
      ]
    : [];

  const stacksAnchors: AnchorItem[] = stacks?.map(({ title }) => ({
    label: title,
    value: kebabCase(title),
  }));

  const anchors = [
    ...responeAnchors,
    ...benefitsAnchors,
    ...guidesAnchors,
    ...stacksAnchors,
  ];

  return { anchors };
};
