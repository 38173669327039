import type { MethodsMap } from '../../types/utils.types';

type Props<MethodName extends keyof MethodsMap> = Parameters<
  MethodsMap[MethodName]
>;

export const fetchCio = async <MethodName extends keyof MethodsMap>(
  url: string,
  methodName: MethodName,
  props: Props<MethodName>
): Promise<void> => {
  const data = JSON.stringify({
    method: methodName,
    arguments: props,
  });

  const options: RequestInit = {
    body: data,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  await fetch(url, options);
};
