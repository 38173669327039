import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { Link } from './LinkVoid.style';

export type LinkVoidProps = PropsWithChildren<{
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}>;

export const LinkVoid: FC<LinkVoidProps> = ({
  children,
  className,
  onClick = noop,
  disabled = false,
}) => {
  const handleOnClick: MouseEventHandler = (e) => {
    e.preventDefault();

    if (!disabled) onClick();
  };

  return (
    <Link href="#" className={className} onClick={handleOnClick}>
      {children}
    </Link>
  );
};
