import { ReactElement, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { UiKitSize } from '../../types/uikit.types';
import { EmptyAsset } from './EmptyAsset/EmptyAsset';
import { EmptyMessage } from './EmptyMessage/EmptyMessage';
import { Root } from './Empty.style';

type EmptyProps = PropsWithChildren<{
  size?: UiKitSize;
  padding?: boolean;
  className?: string;
}>;

interface EmptyComponent {
  (props: EmptyProps): ReactElement | null;
  Asset: typeof EmptyAsset;
  Message: typeof EmptyMessage;
}

export const Empty: EmptyComponent = ({
  padding,
  children,
  className,
  size = 'sm',
}) => {
  return (
    <Root
      size={size}
      className={classNames(className, { 'ion-padding': padding })}
    >
      {children}
    </Root>
  );
};

Empty.Asset = EmptyAsset;
Empty.Message = EmptyMessage;
