import { useContext } from 'use-context-selector';
import {
  CommonContext,
  CommonContextProps,
} from '../../contexts/Common.context';

type UseCommonReturnType = [CommonContextProps];

export const useCommon = (): UseCommonReturnType => {
  const state = useContext(CommonContext);

  return [state];
};
