import { FC } from 'react';
import { useMount } from 'react-use';
import { useSignOut } from '@core/auth';
import { SplashPage } from '@core/ui';
import { useAnalytics } from '@wearehelpful/ui-stats';

export const SignOut: FC = () => {
  const [, signOut] = useSignOut();
  const { reset } = useAnalytics();

  useMount(() => {
    reset();
    signOut();
  });

  return <SplashPage />;
};
