import { FC, useRef, PropsWithChildren, ReactNode, useState } from 'react';
import { History } from 'history';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdPartyEmailPassword, {
  Google,
  Apple,
  Facebook,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { noop } from 'lodash';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { CommonHttpProvider } from '../../../network/components/CommonHttpProvider/CommonHttpProvider';
import { ResponseErrorInterceptor } from '../../types/http.types';
import { HttpClient } from '../../__generated__/http-client';
import { HttpContext, HttpContextProps } from '../../contexts/Http.context';
import { Caregivers } from '../../__generated__/Caregivers';
import { Patients } from '../../__generated__/Patients';
import { Auth } from '../../services/Auth.service';
import { SessionManager } from './SessionManager/SessionManager';
import { getCookieHandler } from './AuthProvider.utils';
import {
  EmailSignInUpCallback,
  SocialSignInUpCallback,
  ContextFetchResponse,
} from './AuthProvider.types';
import './AuthProvider.css';

const client = new QueryClient();

export type AuthProviderProps = PropsWithChildren<{
  name: string;
  apiHost: string;
  apiBase: string;
  webDomain: string;
  history?: History;
  fallback?: ReactNode;
  onEmailSignIn?: EmailSignInUpCallback;
  onEmailSignUp?: EmailSignInUpCallback;
  onSocialSignUp?: SocialSignInUpCallback;
  onSocialSignIn?: SocialSignInUpCallback;
  responseErrorInterceptor?: ResponseErrorInterceptor;
}>;

export const AuthProvider: FC<AuthProviderProps> = ({
  name,
  apiHost,
  apiBase,
  children,
  webDomain,
  fallback = null,
  onEmailSignIn = noop,
  onEmailSignUp = noop,
  onSocialSignUp = noop,
  onSocialSignIn = noop,
  responseErrorInterceptor = null,
}) => {
  const { current: httpClient } = useRef<HttpClient>(
    new HttpClient({
      baseURL: `${apiHost}`,
    })
  );

  const { current: services } = useRef<HttpContextProps>({
    Auth: new Auth(httpClient),
    Caregivers: new Caregivers(httpClient),
    Patients: new Patients(httpClient),
  });

  useState(() =>
    SuperTokens.init({
      appInfo: {
        appName: name,
        apiDomain: apiHost,
        apiBasePath: `${apiBase}/auth`,
        websiteDomain: webDomain,
      },
      cookieHandler: getCookieHandler,
      recipeList: [
        EmailVerification.init({
          mode: 'REQUIRED',
        }),
        ThirdPartyEmailPassword.init({
          postAPIHook: async (context) => {
            const response: ContextFetchResponse =
              await context.fetchResponse.json();

            if (response.status !== 'OK') return;

            switch (context.action) {
              case 'EMAIL_PASSWORD_SIGN_IN':
                if (response.user) {
                  await onEmailSignIn({
                    email: response.user.email,
                  });
                }
                break;
              case 'EMAIL_PASSWORD_SIGN_UP':
                if (response.user) {
                  await onEmailSignUp({
                    email: response.user.email,
                  });
                }
                break;
              case 'THIRD_PARTY_SIGN_IN_UP':
                if (response.user.thirdParty) {
                  if (response.createdNewUser) {
                    await onSocialSignUp({
                      email: response.user.email,
                      provider: response.user.thirdParty.id,
                    });
                  } else {
                    await onSocialSignIn({
                      email: response.user.email,
                      provider: response.user.thirdParty.id,
                    });
                  }
                }
                break;
              default:
                break;
            }
          },
          signInAndUpFeature: {
            providers: [Google.init(), Apple.init(), Facebook.init()],
          },
        }),
        Session.init({
          tokenTransferMethod: 'header',
        }),
      ],
    })
  );

  return (
    <QueryClientProvider client={client}>
      <SuperTokensWrapper>
        <CommonHttpProvider instance={httpClient.instance}>
          <HttpContext.Provider value={services}>
            <SessionManager
              fallback={fallback}
              webDomain={webDomain}
              responseErrorInterceptor={responseErrorInterceptor}
            >
              {children}
            </SessionManager>
          </HttpContext.Provider>
        </CommonHttpProvider>
      </SuperTokensWrapper>
    </QueryClientProvider>
  );
};
