import { RoutePath } from '@common/constants';
import { Outlet, Route } from '@core/navigation';
import { withErrorBoundary } from '@wearehelpful/ui-stats';
import { Page } from '@core/ui';
import { Index } from './Index/Index';
import { Details } from './Details/Details';

export const Guides = withErrorBoundary(() => {
  return (
    <Page>
      <Outlet>
        <Route path={RoutePath.ROOT_GUIDES} exact>
          <Index />
        </Route>
        <Route path={RoutePath.ROOT_GUIDES_DETAILS}>
          <Details />
        </Route>
      </Outlet>
    </Page>
  );
});
