import { FC, PropsWithChildren } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { FormGroupItem } from '../FormGroupItem/FormGroupItem';
import { BaseFormItemProps } from '../Form.types';
import { List, Group, Title } from './FormRadioGroup.style';

interface FormRadioGroupProps extends PropsWithChildren<BaseFormItemProps> {
  title?: string;
}

export const FormRadioGroup: FC<FormRadioGroupProps> = ({
  title,
  children,
  name: initialName,
}) => {
  const [{ name, value, onChange }] = useField<string>({
    type: 'text',
    name: castArray(initialName).join('.'),
  });

  return (
    <FormGroupItem name={name}>
      {!!title && <Title>{title}</Title>}
      <List lines="full">
        <Group
          value={value}
          name={name}
          onIonChange={(e) => setTimeout(() => onChange(e), 0)}
        >
          {children}
        </Group>
      </List>
    </FormGroupItem>
  );
};
