import { FC } from 'react';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { useRoute } from '@core/navigation';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useCommon } from '@common/hooks';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ContentfulImage } from '@helpful/network';
import { Content, Typography, Form, Skeleton } from '@wearehelpful/ui-kit';
import { PayorForm } from './Payor.types';

export const Payor: FC = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const [{ payors, loading }] = useCommon();
  const { track } = useAnalytics();

  const handleOnSubmit = ({ payor }: PayorForm) => {
    const { name, slug } = payors.find(({ slug }) => slug === payor);
    track({
      id: AnalyticsTrackEvents.SELECT_PAYOR,
      properties: {
        payor: name,
      },
    }).finally(() => {
      push({ path: RoutePath.AUTH_SIGN_UP_PLAN, search: { payor: slug } });
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.PAYOR}
    >
      <Content.Subheader>
        <Typography variant="h2">{t('forms.selectInsurance')}</Typography>
      </Content.Subheader>
      <Content.Fixed>
        <Skeleton variant="list" loading={loading}>
          <Form<PayorForm> onSubmit={handleOnSubmit}>
            <Form.RadioGroup name="payor" validation={{ required: true }}>
              {payors.map(({ slug, logo, name }) => {
                return (
                  <Form.RadioGroup.Item key={slug} value={slug}>
                    {logo && (
                      <ContentfulImage url={logo?.url} height={32} alt={name} />
                    )}
                  </Form.RadioGroup.Item>
                );
              })}
            </Form.RadioGroup>
            <Form.Actions inset="sm">
              <Form.Submit checkValidity disabled={loading}>
                {t('actions.next')}
              </Form.Submit>
            </Form.Actions>
          </Form>
        </Skeleton>
      </Content.Fixed>
    </TrackPageView>
  );
};
