import styled from 'styled-components';
import { Typography } from '@core/ui';

const { Body3, Body2 } = Typography;

export const Root = styled('div')<{ background?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme: { spacing } }) => spacing(1)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  background: ${({ background, theme }) =>
    background || theme.palette.periwinkle200};
  cursor: pointer;
`;

export const Title = styled(Body3)<{ background?: string }>`
  color: ${({ theme: { invert, palette }, background }) =>
    invert(background || palette.periwinkle200)};
`;

export const Cta = styled(Body2)<{ background?: string }>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  color: ${({ theme: { invert, palette }, background }) =>
    invert(background || palette.periwinkle200)};
`;
