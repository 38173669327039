import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { UserProvider } from '@core/user';
import { useSession } from '@core/auth';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { useNotifications } from '@helpful/messaging';
import { History } from '@core/navigation';
import { useLocale } from '@wearehelpful/ui-localization';
import {
  type ADTO,
  useGetCaregiver,
  useGetPatientConnections,
} from '@helpful/network';
import { get } from 'lodash';
import { SPLASH } from '../../../constants';

type UserBootstrapProps = PropsWithChildren<{
  history: History;
}>;

export const UserBootstrap: FC<UserBootstrapProps> = ({
  children,
  history,
}) => {
  const [{ isSessionExist, userId }] = useSession();
  const { identify, group } = useAnalytics();
  const { registerDevice } = useNotifications();
  const [{ lng: locale }] = useLocale();

  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useGetCaregiver(
    userId,
    {
      relations:
        'patients.patient, patients.patient.plans, patients.patient.plans.payor',
    },
    {
      query: {
        refetchOnMount: isSessionExist && Boolean(userId),
        enabled: isSessionExist && Boolean(userId),
      },
    }
  );

  const user = data?.data;

  const patient = get(user, 'patients[0].patient', {} as ADTO.PatientEntity);

  const {
    data: connections,
    isFetching: areConnectionsLoading,
    refetch: refetchConnections,
  } = useGetPatientConnections(
    patient.id,
    { skip: 0, take: 100, relations: 'system' },
    {
      query: {
        enabled: isSessionExist && Boolean(patient.id),
        initialData: {
          data: [],
          meta: { total: 0 },
        },
      },
    }
  );

  useUpdateEffect(() => {
    if (user) {
      const id = user.email;

      const payor = user.patients?.flatMap((patient) =>
        patient?.patient?.plans?.map((plan) => plan?.payor?.slug)
      );

      const plans = user.patients?.flatMap(
        (item) => item?.patient?.plans?.map(({ slug }) => slug),
        []
      );

      identify({
        id,
        traits: {
          email: id,
          payor,
          plans,
          lastName: user.lastName,
          firstName: user.firstName,
          locale,
        },
      }).then(() => {
        registerDevice({ id, history }).then((result) => {
          if (typeof result.isAllowed !== 'undefined') {
            identify({
              id,
              traits: {
                pnAllowed: result.isAllowed,
              },
            });
          }
        });

        if (user.patients?.[0]?.patient) {
          const { id, firstName, lastName } = user.patients[0].patient;

          // Name to display in Customer.io's Caregiver - Patient relationship.
          let cioName;
          if (firstName && lastName) {
            cioName = `${firstName} ${lastName}`;
          } else if (firstName) {
            cioName = firstName;
          } else {
            cioName = id;
          }

          group({
            groupId: id,
            traits: {
              name: cioName,
            },
            options: {
              integrations: {
                Mixpanel: false,
              },
            },
          });
        }
      });
    }
  }, [user]);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isLoading && !areConnectionsLoading) {
      setReady(true);
    }
  }, [isLoading, areConnectionsLoading]);

  return ready ? (
    <UserProvider
      user={user}
      connections={connections.data}
      loading={isLoading || areConnectionsLoading}
      refetch={() => {
        refetch();
        if (isSessionExist && Boolean(patient.id)) {
          refetchConnections();
        }
      }}
    >
      {children}
    </UserProvider>
  ) : (
    SPLASH
  );
};
