import type { PropsWithChildren, FC } from 'react';

import { NotificationsContext } from '../../contexts/Notifications.context';

type NotificationsProviderProps = PropsWithChildren<{
  host: string;
}>;

export const NotificationsProvider: FC<NotificationsProviderProps> = ({
  children,
  host,
}) => {
  return (
    <NotificationsContext.Provider value={{ host }}>
      {children}
    </NotificationsContext.Provider>
  );
};
