import { MutableRefObject } from 'react';
import { createContext } from 'use-context-selector';
import { noop } from 'lodash';

export interface FormContextProps {
  onSubmitted: () => void;
  removeFormDataIndex: (key: number) => void;
  setFormDataIndex: (
    key: number,
    v: MutableRefObject<HTMLIonInputElement>
  ) => void;
  focusNextFormItem: (v: number) => void;
}

export const FormContext = createContext<FormContextProps>({
  onSubmitted: noop,
  setFormDataIndex: noop,
  focusNextFormItem: noop,
  removeFormDataIndex: noop,
});
