import styled from 'styled-components';

export const Root = styled('div')`
  position: relative;
  min-height: 100px;
`;

export const Container = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
