import { forwardRef } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { useIonPicker } from '@ionic/react';
import { calendar } from 'ionicons/icons';
import { useIMask } from 'react-imask';
import { BaseFormItemProps } from '../Form.types';
import { FormItem } from '../FormItem/FormItem';
import { Input, Global, Icon } from './FormDatePicker.style';
import { useDatePicker } from './FormDatePicker.hooks';

interface FormDatePickerProps extends BaseFormItemProps {
  defaultValue?: string;
}

const MASK = '00/00/0000';

export interface FormInputInstance {
  focus: () => void;
}

export const FormDatePicker = forwardRef<
  FormInputInstance,
  FormDatePickerProps
>(function FormInput(
  { label, placeholder, name: initialName, defaultValue },
  _
) {
  const [picker] = useIonPicker();

  const [{ name, value, onBlur }, , { setValue }] = useField(
    castArray(initialName).join('.')
  );

  const { ref: inputRef } = useIMask<HTMLIonInputElement, object>(
    {
      mask: MASK,
    },
    {
      onAccept: (value) => setValue(value),
      onComplete: (value) => setValue(value),
    }
  );

  const { options } = useDatePicker({ name, defaultValue });

  return (
    <FormItem name={name} label={label}>
      <Global />
      <Input
        name={name}
        value={value}
        onIonBlur={onBlur}
        placeholder={placeholder}
        maxlength={MASK.length}
        ref={inputRef}
      />
      <Icon slot="end" icon={calendar} onClick={() => picker(options)} />
    </FormItem>
  );
});
