import { FC } from 'react';
import { CDTO } from '@helpful/network';
import { Root, Title, Message, Picture } from './WelcomeSlide.style';

export const WelcomeSlide: FC<DeepPartial<CDTO.WelcomeCarousel>> = ({
  title,
  message,
  image: { url },
}) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <Picture>
        <img src={url} alt="" />
      </Picture>
    </Root>
  );
};
