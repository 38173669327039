import styled from 'styled-components';
import {
  Subheader as DefaultSubheader,
  ContentFixed as DefaultContentFixed,
  Typography,
  Badge,
  Chips,
  Button,
} from '@core/ui';

export const Subheader = styled(DefaultSubheader)`
  padding-bottom: 0;
`;

export const ContentFixed = styled(DefaultContentFixed)`
  padding-top: 0;
`;

export const Info = styled('div')`
  margin-top: ${({ theme: { spacing } }) => spacing(6)};
  ${Typography.Subheading1} {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
`;

export const ButtonDME = styled(Button)`
  margin-top: ${({ theme: { spacing } }) => spacing(6)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
`;

export const HowToRedeem = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Thumb = styled('img')`
  width: ${({ theme: { spacing } }) => spacing(32)};
`;

export const PlanName = styled(Badge)`
  max-width: min-content;
`;

export const Tags = styled(Chips)`
  margin-top: ${({ theme: { spacing } }) => spacing(5)};
`;
