import { RoutePath, UserRole } from '@common/constants';
import { RouteProps } from '@core/navigation';
import { ChangePassword } from './Profile/ChangePassword/ChangePassword';
import { Faq } from './Faq/Faq';
import { Insurance } from './Insurance/Insurance';
import { Patient } from './Patient/Patient';
import { Preferences as ProfilePreferences } from './Profile/Preferences/Preferences';
import { Index as ProfileIndex } from './Profile/Index/Index';
import { Index } from './Index/Index';

export const ROOT_MORE_ROUTES: Array<RouteProps> = [
  {
    path: RoutePath.ROOT_MORE,
    children: <Index />,
    exact: true,
  },
  {
    path: RoutePath.ROOT_MORE_INSURANCE,
    children: <Insurance />,
    roles: [UserRole.USER],
  },
  {
    path: RoutePath.ROOT_MORE_FAQ,
    children: <Faq />,
  },
  {
    path: RoutePath.ROOT_MORE_PROFILE,
    children: <ProfileIndex />,
    roles: [UserRole.USER],
    exact: true,
  },
  {
    path: RoutePath.ROOT_MORE_PROFILE_CHANGE_PASSWORD,
    children: <ChangePassword />,
    roles: [UserRole.USER],
    exact: true,
  },
  {
    path: RoutePath.ROOT_MORE_PROFILE_PREFERENCES,
    children: <ProfilePreferences />,
    roles: [UserRole.USER],
    exact: true,
  },
  {
    path: RoutePath.ROOT_MORE_PATIENT,
    children: <Patient />,
    roles: [UserRole.USER],
  },
];
