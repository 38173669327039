import { createContext } from 'use-context-selector';
import { type CDTO } from '@helpful/network';

export interface CommonContextProps {
  build: string;
  loading: boolean;
  defaultPlans: CDTO.GetCommonQuery['healthPlanCollection']['items'];
  welcome: DeepPartial<CDTO.WelcomeCarousel>[];
  medicalConect: DeepPartial<CDTO.MedicalRedirect>;
  payors: DeepPartial<CDTO.Payor>[];
  upload: CDTO.GetCommonQuery['uploadCollection']['items'];
  success: CDTO.GetCommonQuery['successCollection']['items'];
  searchShortcuts: CDTO.GetCommonQuery['globalSearchShortcutCollection']['items'];
}

export const CommonContext = createContext<CommonContextProps>({
  payors: [],
  welcome: [],
  loading: false,
  build: 'local',
  defaultPlans: [],
  medicalConect: {},
  upload: [],
  success: [],
  searchShortcuts: [],
});
