import { RoutePath, UserRole } from '@common/constants';
import { RouteProps } from '@core/navigation';
import { Index } from './Index/Index';
import { Index as VisitSummaries } from './VisitSummaries/Index/Index';
import { Details as VisitSummariesDetails } from './VisitSummaries/Details/Details';
import { Index as Medications } from './Medications/Index/Index';
import { Details as MedicationsDetails } from './Medications/Details/Details';
import { Allergies } from './Allergies/Allergies';
import { Conditions } from './Conditions/Conditions';
import { CareTeam } from './CareTeam/CareTeam';
import { Callback } from './Callback/Callback';

export const ROOT_MEDICAL_ROUTES: Array<RouteProps> = [
  {
    path: RoutePath.ROOT_MEDICAL,
    children: <Index />,
    exact: true,
  },
  {
    path: RoutePath.ROOT_MEDICAL_ALLERGIES,
    children: <Allergies />,
  },
  {
    path: RoutePath.ROOT_MEDICAL_CARE_TEAM,
    children: <CareTeam />,
  },
  {
    path: RoutePath.ROOT_MEDICAL_VISIT_SUMMARIES,
    children: <VisitSummaries />,
    exact: true,
  },
  {
    path: RoutePath.ROOT_MEDICAL_VISIT_SUMMARIES_DETAILS,
    children: <VisitSummariesDetails />,
  },
  {
    path: RoutePath.ROOT_MEDICAL_CONDITIONS,
    children: <Conditions />,
  },
  {
    path: RoutePath.ROOT_MEDICAL_MEDICATIONS,
    children: <Medications />,
    exact: true,
  },
  {
    path: RoutePath.ROOT_MEDICAL_MEDICATIONS_DETAILS,
    children: <MedicationsDetails />,
  },
  {
    path: RoutePath.ROOT_MEDICAL_CONNECT_CALLBACK,
    children: <Callback />,
    roles: [UserRole.VERIFIED],
    redirect: RoutePath.ROOT_MEDICAL_UNVERIFIED,
  },
];
