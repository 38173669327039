import styled, { css } from 'styled-components';
import { Capacitor } from '@capacitor/core';

const RootNowrapMixin = css`
  white-space: nowrap;
  overflow-y: scroll;
  padding-bottom: ${Capacitor.getPlatform() === 'web' ? 15 : 0}px;
`;

export const Root = styled('div')<{ nowrap: boolean }>`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  ${({ nowrap }) => nowrap && RootNowrapMixin}
`;
