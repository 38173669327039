import styled from 'styled-components';
import { IonRadioGroup, IonList } from '@ionic/react';
import { Typography } from '../../Typography/Typography';

export const Group = styled(IonRadioGroup)``;

export const List = styled(IonList)`
  padding: 0;
`;

export const Title = styled(Typography.Body1)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral300};
  padding: ${({ theme: { spacing } }) => spacing(4)};
`;
