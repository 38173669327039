import styled, { css } from 'styled-components';
import { UiKitColor, UiKitSize } from '../../types/uikit.types';
import { Root as Chips } from '../Chips/Chips.style';
import { Root as Searchbar } from '../Searchbar/Searchbar.style';
import { ContentFixed } from '../ContentFixed/ContentFixed';
import { CONTENT_GAP_MAP } from './Subheader.const';

interface RootProps {
  background?: UiKitColor;
  divider?: boolean;
}

interface ContentProps {
  gap: UiKitSize;
}

export const Divider = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral600};
`;

export const Root = styled('div')<RootProps>`
  background: ${({ theme, background }) =>
    background && theme.palette[background]};
  padding-top: ${({ theme: { spacing } }) => spacing(6)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  ${({ divider }) => divider && Divider}
`;

export const Content = styled(ContentFixed)<ContentProps>`
  display: flex;
  flex-direction: column;
  padding-inline: ${({ theme: { spacing } }) => spacing(4)};
  gap: ${({ gap, theme: { spacing } }) => spacing(CONTENT_GAP_MAP[gap])};
  ${Chips} {
    margin-top: ${({ theme: { spacing } }) => spacing(4)};
  }
  ${Searchbar} {
    margin-top: ${({ theme: { spacing } }) => spacing(4)};
  }
`;
