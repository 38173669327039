import { FC } from 'react';
import { Typography } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Root, Content, Image } from './ToDosCompleted.style';
import SRC from './ToDosCompleted.png';

export const ToDosCompleted: FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Content>
        <Typography align="center">
          {t('prompts.todosCompleted.title')}
        </Typography>
        <Typography bold align="center">
          {t('prompts.todosCompleted.message')}
        </Typography>
        <Image src={SRC} />
      </Content>
    </Root>
  );
};
