import styled from 'styled-components';
import { IonLabel, IonTabs } from '@ionic/react';

export const Root = styled(IonTabs)`
  ion-tab-bar {
    padding-top: ${({ theme: { spacing } }) => spacing(1)};
    background: ${({ theme }) => theme.palette.white100};
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.07);
  }
  ion-tab-button {
    --color: #9ca3af;
    --color-selected: ${({ theme }) => theme.palette.brown400};
    span[class^='icon'] {
      color: #9ca3af;
    }
    &.tab-selected,
    &:hover {
      span[class^='icon'] {
        color: ${({ theme }) => theme.palette.brown400};
      }
    }
  }
`;

export const Label = styled(IonLabel)`
  margin-top: 4px;
  font-size: ${({ theme }) => theme.fontSize(12)};
  line-height: ${({ theme }) => theme.fontSize(15)};
`;
