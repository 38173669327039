import { FC } from 'react';
import { ADTO } from '@helpful/network';
import { Form } from '@wearehelpful/ui-kit';

interface MultiPlanSupplementalProps {
  plans: ADTO.PlanEntity[];
  config: ADTO.PayorEntity;
}

export const MultiPlanSupplemental: FC<MultiPlanSupplementalProps> = ({
  config,
  plans,
}) => {
  const supplementalPlans = plans.filter(
    ({ isSupplemental }) => isSupplemental
  );

  if (config?.isMultiSupplemental && !!supplementalPlans.length) {
    return (
      <Form.CheckboxGroup name="supplementals" validation={{ required: true }}>
        {supplementalPlans.map(({ id, name }) => {
          return (
            <Form.CheckboxGroup.Item key={id} value={id}>
              {name}
            </Form.CheckboxGroup.Item>
          );
        })}
      </Form.CheckboxGroup>
    );
  }

  return null;
};
