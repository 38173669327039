import { IonButton } from '@ionic/react';
import { ButtonVariant, ButtonSize } from './Button.types';

export const BUTTON_TYPE_FILL: Record<ButtonVariant, 'outline' | 'solid'> = {
  primary: 'solid',
  secondary: 'outline',
  tertiary: 'solid',
};

export const BUTTON_SIZE_MAP: Record<
  ButtonSize,
  Parameters<typeof IonButton>['0']['size']
> = {
  s: 'small',
  m: 'large',
};
