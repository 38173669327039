import { FC, PropsWithChildren } from 'react';
import { LocalizationProvider } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';

type LocalizationProps = PropsWithChildren<{}>;

export const Localization: FC<LocalizationProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider
      form={{
        text: {
          errors: {
            email: ({ label }) => t('validation.email', { label }),
            required: ({ label }) => t('validation.required', { label }),
          },
        },
        password: {
          errors: {
            minLength: ({ label, value }) =>
              t('validation.minLength', { label, value }),
            required: ({ label }) => t('validation.required', { label }),
            pattern: ({ label }) => t('validation.required', { label }),
          },
        },
        checkbox: {
          errors: {
            required: ({ label }) => t('validation.required', { label }),
          },
        },
        checkboxGroup: {
          errors: {
            required: ({ label }) => t('validation.required', { label }),
          },
        },
        datepicker: {
          buttons: {
            confirm: t('actions.confirm'),
            cancel: t('actions.cancel'),
          },
          errors: {
            required: ({ label }) => t('validation.required', { label }),
          },
        },
        radioGroup: {
          errors: {
            required: ({ label }) => t('validation.required', { label }),
          },
        },
      }}
      confirm={{
        cancelText: t('actions.cancel'),
        confirmText: t('actions.yes'),
      }}
      tour={{
        next: t('actions.next'),
        last: t('actions.letsGetHelpful'),
      }}
    >
      {children}
    </LocalizationProvider>
  );
};
