import { FC, useRef } from 'react';
import { kebabCase, noop } from 'lodash';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { CDTO, useGetSearchHistory } from '@helpful/network';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { StackCarousel } from '@common/features';
import { Skeleton, Page, Content } from '@wearehelpful/ui-kit';
import { List, RadioButtonGroup, SearchbarInstance, Toolbar } from '@core/ui';
import { useCommon } from '@common/hooks';
import { useSearch } from '../../../hooks/useSearch/useSearch.hook';
import { SearchbarWithStateInURL } from '../../../features/SearchbarWithStateInURL/SearchbarWithStateInURL';
import { BenefitsCarousel } from './partitions/BenefitsCarousel/BenefitsCarousel';
import { GuidesCarousel } from './partitions/GuidesCarousel/GuidesCarousel';
import { Response } from './partitions/Response/Response';
import { SearchRouteSearch } from './Search.types';
import { useHelpfulResponse, useAnchors } from './Search.hooks';
import { SearchSectionId } from './Search.const';
import {
  GradientHeader,
  ContentSubheader,
  HistoryList,
  HistoryItem,
  ContentFixed,
  Hint,
  AnchorsToolbar,
} from './Search.style';
import { GlobalSearchShortcuts } from './partitions/GlobalSearchShortcuts/GlobalSearchShortcuts';

export const Search: FC = () => {
  const { t } = useTranslation();
  const { trackClick, track } = useAnalytics();
  const [
    {
      search: { shortcut, query },
    },
    { push },
  ] = useRoute<{}, SearchRouteSearch>();
  const { generate } = useHelpfulResponse({ shortcut });
  const {
    data: { data: searchHistory },
    isFetching,
    refetch,
  } = useGetSearchHistory([], {
    query: {
      initialData: {
        data: [],
      },
    },
  });

  const searchbar = useRef<SearchbarInstance>(null);

  const [{ loading, benefits, guides, stacks }] = useSearch({ query });

  const { anchors } = useAnchors({ benefits, guides, stacks });

  const handleOnSearch = (query: string) => {
    if (query) {
      track({
        id: AnalyticsPageCategory.GLOBAL_SEARCH,
        properties: {
          query,
        },
      });
    }
    push({ path: RoutePath.ROOT_SEARCH, search: { query } });
    refetch();
  };

  const handleOnBenefitClick = (
    { slug }: DeepPartial<CDTO.PlanBenefit>,
    order: number
  ) => {
    trackClick({
      source: AnalyticsPageCategory.GLOBAL_SEARCH,
      element: AnalyticsPageCategory.BENEFIT,
      properties: { slug, order, query },
    });
    push({ path: RoutePath.ROOT_BENEFITS_DETAILS, params: { slug } });
  };

  const [{ searchShortcuts: shortcuts }] = useCommon();

  const handleOnGuideClick = (
    { slug }: DeepPartial<CDTO.Training>,
    order: number
  ) => {
    trackClick({
      source: AnalyticsPageCategory.GLOBAL_SEARCH,
      element: AnalyticsPageCategory.GUIDE,
      properties: { slug, order, query },
    });
    push({ path: RoutePath.ROOT_GUIDES_DETAILS, params: { slug } });
  };

  const handleOnStackClick = ({ title, slug }: DeepPartial<CDTO.Stack>) => {
    trackClick({
      source: AnalyticsPageCategory.GLOBAL_SEARCH,
      element: AnalyticsPageCategory.STACK,
      properties: { title },
    });
    push({ path: RoutePath.ROOT_STACKS_DETAILS, params: { slug } });
  };

  const handleOnAnchorClick = (id: string) => {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleOnBackClick = () => {
    handleOnSearch(undefined);
  };

  const handleOnShortcutClick = (
    { slug, title, query }: DeepPartial<CDTO.GlobalSearchShortcut>,
    index: number
  ) => {
    trackClick({
      source: `${AnalyticsPageCategory.SHORTCURS} - ${AnalyticsPageCategory.HOME}`,
      element: AnalyticsPageCategory.SHORTCURS,
      properties: { title, index },
    });
    push({ path: RoutePath.ROOT_SEARCH, search: { shortcut: slug, query } });
  };

  const handleOnHistoryItemClick = (query: string) => {
    handleOnSearch(query);
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.HOME}
      name={AnalyticsPageCategory.SEARCH}
    >
      <Page background="neutral100">
        <GradientHeader>
          <Toolbar padding background="transparent">
            <SearchbarWithStateInURL
              value={query}
              onBack={query ? handleOnBackClick : noop}
              ref={searchbar}
              onSearch={handleOnSearch}
              placeholder={t('placeholders.askForAssistance')}
            />
            <Hint variant="p2" align="center" color="white100">
              {t('hints.globalSearch')}
            </Hint>
          </Toolbar>
          {!query && (
            <ContentSubheader>
              <GlobalSearchShortcuts
                data={shortcuts}
                onClick={handleOnShortcutClick}
              />
            </ContentSubheader>
          )}
        </GradientHeader>
        <Content onScroll={() => searchbar.current?.blur()}>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching || loading}>
              {!loading && !!query && (
                <AnchorsToolbar padding background="transparent">
                  <RadioButtonGroup
                    nowrap
                    value={null}
                    onChange={handleOnAnchorClick}
                  >
                    {anchors.map(({ value, label }) => (
                      <RadioButtonGroup.Button value={value} key={value}>
                        {label}
                      </RadioButtonGroup.Button>
                    ))}
                  </RadioButtonGroup>
                </AnchorsToolbar>
              )}
              {!query && (
                <HistoryList>
                  {searchHistory.map((item, index) => (
                    <li key={index}>
                      <HistoryItem
                        onClick={() => handleOnHistoryItemClick(item.query)}
                        icon="search"
                        variant="text"
                      >
                        {item.query}
                      </HistoryItem>
                    </li>
                  ))}
                </HistoryList>
              )}
              {!!query && (
                <Response
                  id={SearchSectionId.HelpfulResponse}
                  query={query}
                  generate={generate}
                />
              )}
              {!!benefits.length && (
                <List
                  id={SearchSectionId.Benefits}
                  title={t('forms.relatedBenefits')}
                >
                  <BenefitsCarousel
                    items={benefits}
                    onItemClick={handleOnBenefitClick}
                  />
                </List>
              )}
              {!!guides.length && (
                <List
                  id={SearchSectionId.Guides}
                  title={t('forms.helpfulGuides')}
                >
                  <GuidesCarousel
                    items={guides}
                    onItemClick={handleOnGuideClick}
                  />
                </List>
              )}
              {stacks.map((item) => (
                <List
                  key={item.sys.id}
                  id={kebabCase(item.title)}
                  title={t('forms.stackSeries', {
                    title: item.title,
                  })}
                >
                  <StackCarousel
                    {...item}
                    onStackClick={handleOnStackClick}
                    onGuideClick={handleOnGuideClick}
                    onBenefitClick={handleOnBenefitClick}
                  />
                </List>
              ))}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
