import { FC } from 'react';
import { useRoute } from '@core/navigation';
import { useUser } from '@core/user';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useCreateFeedback } from '@helpful/network';
import { Content, Footer, Form, Typography } from '@wearehelpful/ui-kit';
import { Toolbar } from '@core/ui';
import { Pictographic, Image } from './Index.style';
import IMAGE from './assets/pictographic.svg?url';
import { DESCRIPTION_MAX_LENGTH } from './Index.const';

export const Index: FC = () => {
  const [{ user }] = useUser();
  const { t } = useTranslation();

  const [, { replace }] = useRoute();

  const { isLoading, mutate } = useCreateFeedback();

  const handleOnSubmit = ({
    email,
    description,
  }: Partial<ADTO.FeedbackDto>) => {
    mutate(
      {
        data: {
          email,
          description,
          subject: 'Question',
          type: ADTO.FeedbackDtoType.Question,
        },
      },
      {
        onSuccess: () => replace({ path: RoutePath.CONTACT_US_SUCCESS }),
      }
    );
  };

  const descriptionHelp = (value = '') => {
    return t('hints.countOfCharactersRemaining', {
      value: DESCRIPTION_MAX_LENGTH - value.length,
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CONTACT_US}
      name={AnalyticsPageCategory.CONTACT_US}
    >
      <Form<Partial<ADTO.FeedbackDto>>
        onSubmit={handleOnSubmit}
        defaultValues={{ email: user?.email }}
      >
        <Content>
          <Content.Subheader>
            <Typography variant="h2" align="center">
              {t('subheadings.contactUs.title')}
            </Typography>
            <Typography align="center">
              {t('subheadings.contactUs.description')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Pictographic>
              <Image src={IMAGE} alt="" />
            </Pictographic>
            <Form.Text
              name="email"
              label={t('forms.yourEmail')}
              validation={{ required: true }}
            />
            <Form.Textrea
              autocapitalize="on"
              name="description"
              label={t('forms.yourMessage')}
              validation={{ required: true, maxLength: DESCRIPTION_MAX_LENGTH }}
              help={({ value }) => descriptionHelp(value)}
            />
          </Content.Fixed>
        </Content>
        <Footer>
          <Toolbar padding>
            <Form.Submit checkValidity loading={isLoading}>
              {t('actions.send')}
            </Form.Submit>
          </Toolbar>
        </Footer>
      </Form>
    </TrackPageView>
  );
};
