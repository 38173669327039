import { Key } from 'react';
import { createContext } from 'use-context-selector';
import { noop } from 'lodash';

interface FormCheckboxGroupContextProps {
  name: string;
  value: Key[];
  setValue: (value: Key[], shouldValidate?: boolean) => void;
}

export const FormCheckboxGroupContext =
  createContext<FormCheckboxGroupContextProps>({
    name: '',
    value: [],
    setValue: noop,
  });
