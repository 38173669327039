import styled from 'styled-components';
import { IonItem, IonLabel, IonNote } from '@ionic/react';
import { Root as Button } from '../../Button/Button.style';

export const Root = styled(IonItem)`
  --highlight-color-focused: ${({ theme }) => theme.palette.periwinkle300};
  --highlight-color-invalid: ${({ theme }) => theme.palette.red400};
  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
  + ${Button} {
    margin-top: ${({ theme: { spacing } }) => spacing(10)};
  }
`;

export const Label = styled(IonLabel)`
  --color: ${({ theme }) => theme.palette.brown400};
`;

export const Error = styled(IonNote)`
  display: block;
  --color: var(--highlight-color-invalid);
`;

export const Help = styled(IonNote)`
  display: block;
`;

export const HelpList = styled('ul')`
  padding-inline-start: ${({ theme: { spacing } }) => spacing(4)};
`;
