import { FC } from 'react';
import { noop } from 'lodash';
import { Card } from '@core/ui';
import { CDTO, ContentfulImage } from '@helpful/network';
import { ReadTime } from './ReadTime/ReadTime';

interface GuidePaperProps extends DeepPartial<CDTO.Training> {
  truncate?: number;
  className?: string;
  onClick?: () => void;
  thumb: { width: number; height: number };
}

export const GuidePaper: FC<GuidePaperProps> = ({
  title,
  truncate = 3,
  readTime,
  className,
  onClick = noop,
  shortDescription,
  cardImage: { url },
  thumb: { width, height },
}) => {
  return (
    <Card divider flat onClick={onClick} className={className}>
      <Card.Thumb>
        <ContentfulImage
          url={url}
          alt={title}
          width={width}
          height={height}
          fit={CDTO.ImageResizeStrategy.Pad}
        />
      </Card.Thumb>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Content truncate={truncate}>{shortDescription}</Card.Content>
      {readTime && (
        <Card.Footer>
          <ReadTime value={readTime.replace(/\s/g, '')} />
        </Card.Footer>
      )}
    </Card>
  );
};
