import { get, startsWith } from 'lodash';
import { ContentfulRenderFn, CONTENTFUL_INLINES } from '@helpful/network';
import { RoutePath } from '@common/constants';
import { Icon } from '@wearehelpful/ui-kit';
import { EntityLink, LinkIcon, Anchor, AnchorButton } from './Details.style';

export const useContentfulDocumentRender = () => {
  const render: ContentfulRenderFn = () => ({
    [CONTENTFUL_INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const target = get(node, ['data', 'target']);
      const type = get(target, ['sys', 'contentType', 'sys', 'id']);

      if (type === 'training') {
        const slug = get(target, ['fields', 'slug', 'en-US']);
        return (
          <EntityLink path={RoutePath.ROOT_GUIDES_DETAILS} params={{ slug }}>
            <LinkIcon>
              <Icon name="book-open" />
            </LinkIcon>
            <strong>{children}</strong>
          </EntityLink>
        );
      }

      if (type === 'screen') {
        const url = get(target, ['fields', 'url', 'en-US']);

        return (
          <EntityLink path={url}>
            <LinkIcon>
              <Icon name="helpful" />
            </LinkIcon>
            <strong>{children}</strong>
          </EntityLink>
        );
      }

      return null;
    },
    [CONTENTFUL_INLINES.HYPERLINK]: (node, children) => {
      if (startsWith(node.data.uri, 'tel:')) {
        return (
          <AnchorButton href={node.data.uri}>
            <LinkIcon>
              <Icon name="phone" />
            </LinkIcon>
            <strong>{children}</strong>
          </AnchorButton>
        );
      }

      return <Anchor href={node.data.uri}>{children}</Anchor>;
    },
  });

  return { render };
};
