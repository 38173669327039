import { FC } from 'react';
import {
  Card,
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
  Empty,
  Button,
  Badge,
} from '@core/ui';
import { ADTO, useGetPatientAllergiesInfinite } from '@helpful/network';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { usePatient, useMedical } from '@common/hooks';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';

const TAKE = 20;

export const Allergies: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [{ connections }] = useMedical();
  const { track } = useAnalytics();
  const [, { push }] = useRoute();

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_ALLERGIES }).finally(
      () => {
        push({ path: RoutePath.CONNECT });
      }
    );
  };

  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetPatientAllergiesInfinite(
    patient.id,
    {
      skip: 0,
      take: TAKE,
      relations: 'connection.system',
    },
    {
      query: {
        enabled: Boolean(patient.id),
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const allergies = data.pages
    ?.reduce((res, { data }) => [...res, ...data], [] as ADTO.AllergyEntity[])
    .filter(
      (allergy) =>
        allergy.status !== 'entered-in-error' &&
        allergy.status !== 'inactive' &&
        allergy.status !== 'refuted'
    );

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.ALLERGIES}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading2>
              {t('subheadings.allergies.title')}
            </Typography.Heading2>
          </Subheader>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching}>
              {!!connections.length && !allergies?.length && (
                <Empty padding>
                  <Empty.Message>
                    {t('empty.noAllergiesDetected')}
                  </Empty.Message>
                  <Empty.Asset />
                </Empty>
              )}
              {!connections.length && (
                <Empty padding size="md">
                  <Empty.Asset name="ALLERGIES_SCREEN" width={298} />
                  <Empty.Message>
                    {t('prompts.connectMedical.allergies')}
                  </Empty.Message>
                  <Button variant="tertiary" onClick={handleOnConnect}>
                    {t('actions.connectMedicalRecords')}
                  </Button>
                </Empty>
              )}
              {!!allergies?.length && (
                <InfiniteScroll
                  onScroll={fetchNextPage}
                  disabled={!hasNextPage}
                >
                  {allergies.map(({ id, name, reactions, connection }) => {
                    return (
                      <Card divider flat key={id}>
                        <Card.Icon name="allergy" />
                        <Card.Header>
                          <Card.Title>{name}</Card.Title>
                        </Card.Header>
                        <Card.Content truncate={6}>
                          {reactions.join(', ')}
                        </Card.Content>
                        <Card.Footer justifyContent="flex-start">
                          <Badge variant="primary">
                            {connection.system.name}
                          </Badge>
                        </Card.Footer>
                      </Card>
                    );
                  })}
                </InfiniteScroll>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
