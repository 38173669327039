import { groupBy, entries } from 'lodash';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { TrackPageView, withErrorBoundary } from '@wearehelpful/ui-stats';
import { useUser } from '@core/user';
import { HeaderWithAuth } from '@common/features';
import {
  Page,
  Content,
  Typography,
  Skeleton,
  Section,
  InfiniteScroll,
  Icon,
  ActionSheet,
  Reorder,
  Toggle,
  useUtils,
} from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useRoute, useNavigationPrompt } from '@core/navigation';
import { ADTO } from '@helpful/network';
import { useState } from 'react';
import { useHome } from '../../../hooks/useHome/useHome.hook';
import { ToDoCard } from './partitions/ToDoCard/ToDoCard';
import { ToDosCompleted } from './partitions/ToDosCompleted/ToDosCompleted';
import { ToDosEmpty } from './partitions/ToDosEmpty/ToDosEmpty';
import { VISIBLE_SECTIONS, EMPTY_SECTION } from './Home.const';
import {
  Subheader,
  Container,
  Centered,
  Add,
  Asset,
  Title,
  Header,
} from './Home.style';
import { useHomeCopy } from './Home.hooks';
import { HomeMode } from './Home.types';
import ADD_SRC from './assets/add.png';
import SUGGEST_SRC from './assets/suggest.png';
import CREATE_SRC from './assets/create.png';

export const Home = withErrorBoundary(() => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const [{ user }] = useUser();
  const { confirm } = useUtils();
  const [mode, setMode] = useState<HomeMode>(HomeMode.View);

  const [{ todos, loading, total, hasNextPage }, { fetchNextPage, reorder }] =
    useHome();

  const { getToggleLabel, getSectionHeader } = useHomeCopy();

  const sections = (() => {
    if (total === 0) {
      return entries({ null: EMPTY_SECTION });
    }
    if (todos.some(({ status }) => status === null)) {
      return entries(groupBy(todos, 'status')).filter(([key]) =>
        VISIBLE_SECTIONS.includes(key)
      );
    }
    return entries({ null: EMPTY_SECTION, completed: todos }).filter(([key]) =>
      VISIBLE_SECTIONS.includes(key)
    );
  })();

  const handleOnClick = ({ id }: ADTO.TodoLocalizedEntity) => {
    if (mode === HomeMode.View) {
      push({ path: RoutePath.ROOT_TODOS_DETAILS, params: { id } });
    }
  };

  const handleOnSuggest = () => {
    push({ path: RoutePath.CAREPLAN });
  };

  const handleOnCreate = () => {
    push({ path: RoutePath.TODOS_CREATE });
  };

  const handleOnModeChange = (v: boolean) => {
    setMode(v ? HomeMode.Reorder : HomeMode.View);
  };

  useNavigationPrompt(
    () => mode === HomeMode.Reorder,
    ({ proceed }) =>
      confirm({
        message: t('confirms.unsavedChangesLeaveConfirm'),
        onConfirm: async () => {
          setMode(HomeMode.View);
          proceed();
        },
      })
  );

  return (
    <TrackPageView
      category={AnalyticsPageCategory.ROOT}
      name={AnalyticsPageCategory.HOME}
    >
      <Page headerBackground="gradientPeriwinkle" background="neutral50">
        <HeaderWithAuth logoVariant="white" signInButtonColor="white" />
        <Content>
          <Subheader gap="md" sticky>
            <Typography variant="p1" align="center" color="white100">
              {!!user
                ? t('subheadings.home.titlePersonalized', {
                    firstName: user?.firstName,
                  })
                : t('subheadings.home.titleDefault')}
            </Typography>
            <Centered>
              <ActionSheet>
                <ActionSheet.Toggle>
                  <Add>
                    <Asset src={ADD_SRC} />
                    <Typography bold align="left">
                      {t('actions.addToMyCarePlan')}
                    </Typography>
                    <Icon name="arrow-forward" />
                  </Add>
                </ActionSheet.Toggle>
                <ActionSheet.Menu width={460} placement={['bottom', 'center']}>
                  <ActionSheet.Menu.Button
                    onClick={handleOnSuggest}
                    icon={<Asset src={SUGGEST_SRC} />}
                  >
                    {t('actions.suggestCarePlan')}
                  </ActionSheet.Menu.Button>
                  <ActionSheet.Menu.Button
                    icon={<Asset src={CREATE_SRC} />}
                    onClick={handleOnCreate}
                  >
                    {t('actions.createMyOwnToDo')}
                  </ActionSheet.Menu.Button>
                </ActionSheet.Menu>
              </ActionSheet>
            </Centered>
          </Subheader>
          <Content.Fixed>
            <Container>
              <Skeleton variant="feed" loading={loading}>
                <InfiniteScroll
                  disabled={!hasNextPage}
                  onScroll={fetchNextPage}
                >
                  {sections.map(([key, todos]) => {
                    const content = todos.map((item) => {
                      return (
                        <ToDoCard
                          {...item}
                          mode={mode}
                          key={item.id}
                          transparent={key !== 'null'}
                          onClick={() => handleOnClick(item)}
                        />
                      );
                    });
                    return (
                      <Section key={key} background="transparent">
                        <Header isNull={key === 'null'}>
                          <Title
                            active={key === 'null' && mode === HomeMode.Reorder}
                          >
                            {getSectionHeader({ mode, key })}
                          </Title>
                          {key === 'null' && (
                            <Toggle
                              onChange={handleOnModeChange}
                              value={mode === HomeMode.Reorder}
                            >
                              <Title
                                active={
                                  key === 'null' && mode === HomeMode.Reorder
                                }
                              >
                                {getToggleLabel(mode)}
                              </Title>
                            </Toggle>
                          )}
                        </Header>
                        {key === 'null' ? (
                          <Section.Content>
                            <Reorder<ADTO.TodoLocalizedEntity>
                              items={todos}
                              onComplete={reorder}
                              disabled={mode === HomeMode.View}
                            >
                              {!!total && !todos.length && <ToDosCompleted />}
                              {!total && !todos.length && <ToDosEmpty />}
                              {content}
                            </Reorder>
                          </Section.Content>
                        ) : (
                          <Section.Collapse>{content}</Section.Collapse>
                        )}
                      </Section>
                    );
                  })}
                </InfiniteScroll>
              </Skeleton>
            </Container>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
});
