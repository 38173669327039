import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(8)};
`;

export const Asset = styled('img')`
  margin: 0;
  padding: 0;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const List = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;
