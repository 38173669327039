import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Root } from './Header.style';

type HeaderProps = PropsWithChildren<{
  className?: string;
}>;

export const Header: FC<HeaderProps> = ({ children, className }) => {
  return (
    <Root collapse="fade" className={classNames(className, 'ion-no-border')}>
      {children}
    </Root>
  );
};
