import styled from 'styled-components';
import { GuidePaper } from '../GuidePaper/GuidePaper';
import { BenefitPaper } from '../BenefitPaper/BenefitPaper';

interface RootProps {
  template: string;
}

export const Root = styled('div')<RootProps>`
  display: grid;
  overflow-x: auto;
  margin-top: ${({ theme: { spacing } }) => spacing(3)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(3)};
  border-bottom: 1px solid rgb(209, 213, 219);
  grid-template-rows: max-content;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  grid-template-columns:
    0 ${({ theme: { spacing } }) => spacing(66)}
    ${({ template }) => template}
    ${({ theme: { spacing } }) => spacing(52)} 0;
  &::before {
    content: '';
  }
  &::after {
    content: '';
  }
`;

export const Guide = styled(GuidePaper)`
  margin: 0;
  box-shadow: none;
  ion-card-content {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
  ion-card-header {
    padding-inline: 0;
  }
`;

export const Benefit = styled(BenefitPaper)`
  margin: 0;
  padding-left: 0;
  box-shadow: none;
  > div:first-child {
    position: static;
  }
  ion-card-header {
    padding-inline: 0;
  }
  ion-card-content {
    padding-inline: 0;
  }
`;
