import { createContext } from 'use-context-selector';
import { ADTO } from '@helpful/network';

interface DetailsContextProps {
  plans: ADTO.PlanEntity[];
}

export const DetailsContext = createContext<DetailsContextProps>({
  plans: [],
});
