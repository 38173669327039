import styled from 'styled-components';
import { BenefitPaper } from '@common/features';

export const Root = styled('div')<{ itemsCount: number }>`
  display: grid;
  overflow-x: auto;
  border-bottom: 1px solid rgb(209, 213, 219);
  grid-template-columns: repeat(${({ itemsCount }) => itemsCount}, min-content);
`;

export const Benefit = styled(BenefitPaper)`
  margin: 0;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  width: 240px;
  box-sizing: content-box;
  box-shadow: none;
  & > * {
    position: static;
  }
  ion-card-header {
    padding-inline: 0;
  }
  ion-card-content {
    padding-inline: 0;
  }
`;
