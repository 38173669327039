import { useTranslation } from '@wearehelpful/ui-localization';
import { object, string } from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = object().shape({
    email: string()
      .label(t('forms.yourEmail'))
      .required(t('validation.required', { label: t('forms.email') }))
      .email(t('validation.email', { label: t('forms.email') })),
    description: string()
      .label(t('forms.yourMessage'))
      .required(t('validation.required', { label: t('forms.yourMessage') }))
      .max(2000),
  });

  return { validationSchema };
};
