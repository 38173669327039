import styled from 'styled-components';
import { IonSearchbar } from '@ionic/react';

export const Root = styled(IonSearchbar)`
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-end: 0 !important;
  padding-inline-start: 0 !important;
  --placeholder-opacity: 1;
  --icon-color: ${({ theme }) => theme.palette.black};
  --placeholder-color: ${({ theme }) => theme.palette.neutral500};
  --box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.neutral300};
  --border-radius: ${({ theme: { spacing } }) => spacing(13.5)};
  .searchbar-input-container {
    input {
      font-weight: 400;
      font-size: ${({ theme: { fontSize } }) => fontSize(16)};
      padding-top: ${({ theme: { spacing } }) => spacing(3.25)};
      padding-bottom: ${({ theme: { spacing } }) => spacing(3.25)};
      padding-inline-start: ${({ theme: { spacing } }) => spacing(12)};
      padding-inline-end: ${({ theme: { spacing } }) => spacing(12)};
    }
    .searchbar-search-icon {
      left: ${({ theme: { spacing } }) => spacing(3)};
      top: ${({ theme: { spacing } }) => spacing(4.5)};
      pointer-events: auto;
      cursor: pointer;
      padding: ${({ theme: { spacing } }) => spacing(2)};
      margin: -${({ theme: { spacing } }) => spacing(2)};
    }
  }
`;
