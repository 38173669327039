import styled from 'styled-components';
import { IonItem, IonRadio, IonLabel } from '@ionic/react';

export const Label = styled(IonLabel)`
  margin: 0;
  padding: ${({ theme: { spacing } }) => spacing(4)} 0;
  min-height: ${({ theme: { spacing } }) => spacing(17)};
`;

export const Root = styled(IonItem)`
  --border-color: ${({ theme }) => theme.palette.neutral300};
  ${Label} {
    display: flex;
    align-items: center;
  }
`;

export const Radio = styled(IonRadio)`
  --color: ${({ theme }) => theme.palette.neutral300};
  --color-checked: ${({ theme }) => theme.palette.blue400};
  margin-inline-start: 0;
  margin-inline-end: ${({ theme: { spacing } }) => spacing(4)};
`;
