import styled from 'styled-components';

export const Root = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)} 0;
`;

export const Img = styled('img')``;

export const Caption = styled('p')`
  font-size: ${({ theme }) => theme.fontSize(12)} !important;
  line-height: 130%;
  color: ${({ theme }) => theme.palette.neutral500};
  margin-top: ${({ theme: { spacing } }) => spacing(1)} !important;
`;
