import { FC } from 'react';
import { IonButtons } from '@ionic/react';
import { Back } from './ToolbarBack.style';

interface ToolbarBackProps {
  defaultHref?: string;
  className?: string;
}

export const ToolbarBack: FC<ToolbarBackProps> = ({
  defaultHref,
  className,
}) => {
  return (
    <IonButtons slot="start" className={className}>
      <Back defaultHref={defaultHref} />
    </IonButtons>
  );
};
