import { FC, useState } from 'react';
import { useTranslation, Translate } from '@wearehelpful/ui-localization';
import { RoutePath } from '@common/constants';
import { ContentfulImage } from '@helpful/network';
import { useCommon } from '@common/hooks';
import { useRoute } from '@core/navigation';
import { Content, Form, Header, Page, Typography } from '@wearehelpful/ui-kit';
import { Footer, Searchbar, Toolbar } from '@core/ui';
import { Link } from './Index.style';
import { ConnectForm } from './Index.types';

export const Index: FC = () => {
  const { t } = useTranslation();
  const [query, search] = useState('');
  const [{ payors: allPayours }] = useCommon();
  const [, { push }] = useRoute();

  const payors = allPayours.filter(({ name }) => {
    return !!query ? name.toLowerCase().includes(query.toLowerCase()) : true;
  });

  const handleOnSubmit = ({ payor }: ConnectForm) => {
    push({ path: RoutePath.INSURANCE_CONNECT_PLAN, search: { payor } });
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back defaultHref={RoutePath.ROOT_MORE_INSURANCE} />
        </Toolbar>
      </Header>
      <Form<ConnectForm> onSubmit={handleOnSubmit}>
        <Content>
          <Content.Subheader gap="md" divider>
            <Typography variant="h2">
              {t('subheadings.insuranceConnect.title')}
            </Typography>
            <Typography>
              <Translate
                id="subheadings.insuranceConnect.description"
                components={{
                  a: <Link path={RoutePath.CONTACT_US} />,
                }}
              />
            </Typography>
            <Searchbar
              value={query}
              onChange={search}
              placeholder={t('placeholders.searchInsuranceProvider')}
            />
          </Content.Subheader>
          <Content.Fixed>
            <Form.RadioGroup name="payor" validation={{ required: true }}>
              {payors.map(({ slug, name, logo, sys: { id } }) => {
                return (
                  <Form.RadioGroup.Item key={id} value={slug}>
                    {logo && (
                      <ContentfulImage url={logo?.url} height={32} alt={name} />
                    )}
                  </Form.RadioGroup.Item>
                );
              })}
            </Form.RadioGroup>
          </Content.Fixed>
        </Content>
        <Footer shadow>
          <Toolbar>
            <Form.Submit checkValidity>{t('actions.next')}</Form.Submit>
          </Toolbar>
        </Footer>
      </Form>
    </Page>
  );
};
