import { createContext } from 'use-context-selector';
import { DTO } from '@helpful/network';

export interface ConfigContextProps extends DTO.ConfigDto {
  host: string;
  build: string;
  environment: string;
}

export const ConfigContext = createContext<ConfigContextProps>({
  host: null,
  build: null,
  environment: null,
  fallbackLanguage: null,
  supportedLanguages: [],
});
