import styled from 'styled-components';
import { IonLabel } from '@ionic/react';

export const Root = styled(IonLabel)<{ wrap: boolean }>`
  font-weight: 400;
  white-space: ${({ wrap }) => (wrap ? 'normal' : 'nowrap')} !important;
  line-height: ${({ theme }) => theme.fontSize(21)};
  font-size: ${({ theme }) => theme.fontSize(16)} !important;
  --color: ${({ theme }) => theme.palette.brown400};
`;
