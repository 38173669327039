import { Capacitor } from '@capacitor/core';
import type { History } from 'history';
import { useContextSelector } from 'use-context-selector';
import { NotificationsContext } from '../../contexts/Notifications.context';
import { deleteToken } from '../../utils/deleteToken/deleteToken';
import { fetchCio } from '../../utils/fetchCio/fetchCio';
import { getDeviceToken } from '../../utils/getDeviceToken/getDeviceToken';
import { getNotificationsAllowance } from '../../utils/getNotificationsAllowance/getNotificationsAllowance';
import { registerNotificationsListeners } from '../../utils/registerNotificationsListeners/registerNotificationsListeners';
import { unregisterNotificationsListeners } from '../../utils/unregisterNotificationsListeners/unregisterNotificationsListeners';

interface UseNotificationsRegisterDeviceParams {
  id: string;
  history: History;
}

interface UseNotificationsDeleteDeviceParams {
  id: string;
}

type UseNotificationsRegisterDeviceCallback = (
  props: UseNotificationsRegisterDeviceParams,
) => Promise<{
  /**
   * @undefined is returned if the platform is not native.
   */
  isAllowed?: boolean;
}>;

type UseNotificationsDeleteDeviceCallback = (
  props: UseNotificationsDeleteDeviceParams,
) => Promise<void>;

type UseNotficationsReturn = {
  registerDevice: UseNotificationsRegisterDeviceCallback;
  deleteDevice: UseNotificationsDeleteDeviceCallback;
};

export const useNotifications = (): UseNotficationsReturn => {
  const config = useContextSelector(NotificationsContext, v => v);

  const registerDevice: UseNotificationsRegisterDeviceCallback = async ({
    id,
    history,
  }) => {
    if (!Capacitor.isNativePlatform()) {
      return {};
    }

    const isAllowed = await getNotificationsAllowance({
      requestPermissions: true,
    });
    if (!isAllowed) {
      return { isAllowed: false };
    }

    await registerNotificationsListeners({
      onReceived: event => {
        if (!config.host) {
          return { isAllowed: false };
        }

        const delivery_id = event.notification.data['CIO-Delivery-ID'];
        const device_id = event.notification.data['CIO-Delivery-Token'];

        fetchCio(config.host, 'trackPush', [
          {
            delivery_id,
            device_id,
            event: 'opened',
          },
        ]);
      },
      onActionPerformed: event => {
        if (!config.host) {
          return { isAllowed: false };
        }

        const delivery_id = event.notification.data['CIO-Delivery-ID'];
        const device_id = event.notification.data['CIO-Delivery-Token'];

        fetchCio(config.host, 'trackPush', [
          {
            delivery_id,
            device_id,
            event: 'opened',
          },
        ]);

        const redirectURL = event.notification.data.CIO?.push.link;
        if (redirectURL) {
          const { pathname, search } = new URL(redirectURL);
          history.push({ pathname, search });
        }
      },
    });

    if (!config.host) {
      return { isAllowed: false };
    }

    const deviceToken = await getDeviceToken();
    await fetchCio(config.host, 'addDevice', [
      id,
      deviceToken,
      Capacitor.getPlatform(),
    ]);

    return { isAllowed: true };
  };

  const deleteDevice: UseNotificationsDeleteDeviceCallback = async ({ id }) => {
    if (!Capacitor.isNativePlatform() || !config.host) {
      return;
    }

    const isAllowed = await getNotificationsAllowance();
    if (!isAllowed) {
      return;
    }

    await unregisterNotificationsListeners();

    const deviceToken = await getDeviceToken();
    await fetchCio(config.host, 'deleteDevice', [id, deviceToken]);
    await deleteToken();
  };

  return { registerDevice, deleteDevice };
};
