import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1 1 auto;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.neutral600};
  }
  margin: ${({ theme: { spacing } }) => spacing(8)} 0;
`;

export const Content = styled('span')`
  margin: 0 ${({ theme: { spacing } }) => spacing(4)};
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(24)};
  color: ${({ theme }) => theme.palette.neutral500};
`;
