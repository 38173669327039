import { FC } from 'react';
import classNames from 'classnames';
import { Root, Item } from './ToolbarProgress.style';

interface ToolbarProgressProps {
  count: number;
  value: number;
}

export const ToolbarProgress: FC<ToolbarProgressProps> = ({ count, value }) => {
  return (
    <Root>
      {Array.from({ length: count }).map((_, index) => {
        return (
          <Item
            key={index}
            className={classNames({ active: index === value })}
          />
        );
      })}
    </Root>
  );
};
