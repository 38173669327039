import { PropsWithChildren, forwardRef } from 'react';
import { IonPage } from '@ionic/react';

type PageProps = PropsWithChildren<unknown>;

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children }, ref) => {
    return <IonPage ref={ref}>{children}</IonPage>;
  }
);
