import { FC } from 'react';
import { Page } from '@wearehelpful/ui-kit';
import { Outlet, Route } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { Index } from './Index/Index';
import { Details } from './Details/Details';

export const Legal: FC = () => {
  return (
    <Page>
      <Outlet>
        <Route path={RoutePath.LEGAL} exact>
          <Index />
        </Route>
        <Route path={RoutePath.LEGAL_DETAILS}>
          <Details />
        </Route>
      </Outlet>
    </Page>
  );
};
