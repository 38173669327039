import { FC } from 'react';
import { CDTO } from '@helpful/network';
import { List, Typography, UiKitColor } from '@core/ui';
import { noop } from 'lodash';
import { Icon } from '@wearehelpful/ui-kit';
import { useCtas } from '../../hooks';
import { CallToActionRoutes } from '../../constants';
import { Root, Cta, Container, Content } from './CtaList.style';

const { Body2 } = Typography;

export type CtaListProps = {
  route: CallToActionRoutes;
  onClick?: (cta: DeepPartial<CDTO.CallToAction>) => void;
};

export const CtaList: FC<CtaListProps> = ({ route, onClick = noop }) => {
  const { ctas } = useCtas({ route });

  return (
    <>
      {ctas.map((cta) => (
        <Root key={cta.title}>
          <Cta
            lines="none"
            backgroundColor={cta.backgroundColor as UiKitColor}
            onClick={() => onClick(cta)}
          >
            <List.Thumb color="orange400">
              <Icon name="plus" />
            </List.Thumb>
            <List.Title>{cta.ctaAnchor}</List.Title>
          </Cta>
          <Container backgroundColor={cta.backgroundColor as UiKitColor}>
            <Content backgroundColor={cta.backgroundColor as UiKitColor}>
              <Body2 bold>{cta.title}</Body2>
              <Body2>{cta.description}</Body2>
            </Content>
          </Container>
        </Root>
      ))}
    </>
  );
};
