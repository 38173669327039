import { useEffect, useRef } from 'react';
import { invoke } from 'lodash';
import { useHistory } from 'react-router-dom';

interface UseBlockerBlockerParams {
  proceed: () => void;
}

type UseBlockerBlocker = (params: UseBlockerBlockerParams) => void;

export const useBlocker = (blocker: UseBlockerBlocker, when: () => boolean) => {
  const history = useHistory();
  const proceed = useRef(false);

  useEffect(() => {
    // @ts-ignore
    const unblock = history.block((location, action) => {
      const shouldBlock = when();
      if (shouldBlock && !proceed.current) {
        blocker({
          proceed: () => {
            proceed.current = true;
            unblock();
            if (action === 'POP') {
              history.goBack();
              proceed.current = false;
            } else {
              invoke(history, action.toLowerCase(), location);
              proceed.current = false;
            }
          },
        });
      }
      return !shouldBlock || proceed.current;
    });

    return unblock;
  }, [history, blocker, when]);
};
