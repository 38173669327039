import {
  Page,
  Header,
  Toolbar,
  Button,
  Subheader,
  Typography,
  templateContent,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { compiler } from 'markdown-to-jsx';
import { useCommon } from '@common/hooks';
import { RoutePath } from '@common/constants';
import { Content } from '@wearehelpful/ui-kit';
import { Container, Footer, Image, Placeholder } from './UploadSuccess.style';

const { Heading2, Body1 } = Typography;
export const UploadSuccess = () => {
  const [
    {
      search: { payor, email },
    },
    { push },
  ] = useRoute<object, { payor: string; email: string }>();
  const { t } = useTranslation();
  const [{ success }] = useCommon();
  const successEntity = success.filter(
    ({ type: successType }) => successType === 'dme'
  )[0];

  const handleCTAClick = () => {
    push({ path: RoutePath.ROOT_BENEFITS });
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Close onClick={handleCTAClick} />
        </Toolbar>
      </Header>
      <Content padding>
        <Container>
          <Subheader>
            <Heading2 align="center">{successEntity?.title}</Heading2>
            <Body1 as="div" align="center">
              {successEntity?.body &&
                compiler(
                  templateContent(successEntity?.body, { payor, email }),
                  { wrapper: null }
                )}
            </Body1>
          </Subheader>
          <Placeholder>
            <Image src={successEntity?.image?.url} alt="" />
          </Placeholder>
        </Container>
      </Content>
      <Footer>
        <Toolbar padding>
          <Button onClick={handleCTAClick}>{t('actions.done')}</Button>
        </Toolbar>
      </Footer>
    </Page>
  );
};
