import styled from 'styled-components';
import { IonButton } from '@ionic/react';

export const Root = styled(IonButton)`
  text-transform: none;
  letter-spacing: normal;
  font-size: ${({ theme }) => theme.fontSize(16)} !important;
  font-weight: 700 !important;
  --color: ${({ theme }) => theme.palette.blue400} !important;
`;
