import { forwardRef, useLayoutEffect, useState } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { eye, eyeOff } from 'ionicons/icons';
import { IonInput } from '@ionic/react';
import { BaseFormItemProps } from '../Form.types';
import { FormItem } from '../FormItem/FormItem';
import { useInput } from '../Form.hooks';
import { Root, Icon } from './FormPassword.style';

interface FormPasswordProps extends BaseFormItemProps {
  autocomplete?: Parameters<typeof IonInput>['0']['autocomplete'];
}

export const FormPassword = forwardRef<{}, FormPasswordProps>(
  function FormPassword(
    {
      help,
      label,
      tabIndex,
      placeholder,
      disabled = false,
      name: initialName,
      autofocus = false,
      enterKeyHint = 'next',
    },
    _
  ) {
    const [visible, setVisible] = useState(false);

    const input = useInput({
      tabIndex,
      autofocus,
      enterKeyHint,
    });

    const [{ name, value, onChange, onBlur }] = useField(
      castArray(initialName).join('.')
    );

    useLayoutEffect(() => {
      const el = input.current;
      const handler: EventListenerOrEventListenerObject = (e) => {
        setTimeout(() => onChange(e), 0);
      };
      el?.addEventListener('change', handler);
      return () => el?.removeEventListener('change', handler);
    }, [input, onChange]);

    return (
      <FormItem name={name} label={label} help={help}>
        <Root
          ref={input}
          name={name}
          value={value}
          onIonBlur={onBlur}
          disabled={disabled}
          onIonInput={onChange}
          autofocus={autofocus}
          placeholder={placeholder}
          enterkeyhint={enterKeyHint}
          type={visible ? 'text' : 'password'}
        />
        {!disabled && (
          <Icon
            slot="end"
            icon={visible ? eyeOff : eye}
            onClick={() => setVisible((v) => !v)}
          />
        )}
      </FormItem>
    );
  }
);
