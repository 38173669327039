import styled from 'styled-components';
import { Typography } from '@core/ui';
import { ContentfulImage } from '@helpful/network';

interface CommonProps {
  background?: string;
}

export const Root = styled('div')<CommonProps>`
  position: relative;
  border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  background-color: ${({ background, theme: { palette } }) =>
    background || palette.periwinkle200};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  &:hover {
    cursor: pointer;
  }
`;

export const SeeAll = styled(Typography.Body3)<CommonProps>`
  color: ${({ theme: { invert, palette }, background }) =>
    invert(background || palette.periwinkle200, {
      black: palette.periwinkle500,
      white: palette.periwinkle200,
    })};
`;

export const Title = styled(Typography.Heading4)<CommonProps>`
  position: relative;
  z-index: 1;
  font-size: ${({ theme }) => theme.fontSize(22)};
  font-weight: 400;
  line-height: ${({ theme }) => theme.fontSize(23)};
  margin-top: ${({ theme: { spacing } }) => spacing(1)};
  color: ${({ theme: { invert, palette }, background }) =>
    invert(background || palette.periwinkle200)};
`;

export const Description = styled(Typography.Body2)<CommonProps>`
  margin-top: ${({ theme: { spacing } }) => spacing(2)};
  color: ${({ theme: { invert, palette }, background }) =>
    invert(background || palette.periwinkle200)};
`;

export const Image = styled(ContentfulImage)`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
`;
