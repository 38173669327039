import { FC, useRef } from 'react';
import { Page, Toolbar, Header } from '@core/ui';
import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Back } from './Details.style';
import {
  GuidesDetails,
  GuidesDetailsInstanse,
} from './partitions/GuidesDetails/GuidesDetails';

export const Details: FC = () => {
  const { t } = useTranslation();
  const guide = useRef<GuidesDetailsInstanse>();

  const [
    {
      params: { slug },
    },
    { push },
  ] = useRoute<{ slug: string }, {}>();

  const handleClick = () => {
    push({ path: RoutePath.ROOT_GUIDES });
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back defaultHref={RoutePath.ROOT_GUIDES} />
          <Toolbar.Buttons slot="end">
            <Toolbar.IconButton
              name="share"
              onClick={() => guide.current?.onShare()}
            />
          </Toolbar.Buttons>
        </Toolbar>
      </Header>
      <GuidesDetails
        ref={guide}
        slug={slug}
        origin={AnalyticsPageCategory.GUIDE}
      >
        <Back variant="secondary" onClick={handleClick}>
          {t('actions.goToGuides')}
        </Back>
      </GuidesDetails>
    </Page>
  );
};
