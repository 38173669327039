import { FC, PropsWithChildren } from 'react';
import { useRoute } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { Button } from '@wearehelpful/ui-kit';

type ContactUsProps = PropsWithChildren<unknown>;

export const ContactUs: FC<ContactUsProps> = ({ children }) => {
  const [, { push }] = useRoute();

  const handleOnClick = () => {
    push({
      path: RoutePath.CONTACT_US,
    });
  };

  return (
    <Button variant="secondary" onClick={handleOnClick}>
      {children}
    </Button>
  );
};
