import styled, { css } from 'styled-components';

interface ContentProps {
  open: boolean;
  height: number;
  hasAction: boolean;
}

export const Root = styled('div')``;

const ContentCollepsedMixin = css`
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 65px;
    background: linear-gradient(
      355deg,
      #fff 5.09%,
      rgba(255, 255, 255, 0) 96.96%
    );
  }
`;

export const Content = styled('div')<ContentProps>`
  overflow: hidden;
  position: relative;
  height: ${({ height, hasAction }) => (hasAction ? height + 'px' : 'auto')};
  transition: height 500ms;
  ${({ open, hasAction }) => !open && hasAction && ContentCollepsedMixin}
`;

export const Action = styled('div')`
  display: flex;
  justify-content: center;
`;

export const Link = styled('a')`
  font-size: ${({ theme }) => theme.fontSize(16)};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.fontSize(20)};
  padding: ${({ theme: { spacing } }) => spacing(3)};
  color: ${({ theme }) => theme.palette.blue400};
  text-decoration: none;
`;
