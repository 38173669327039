import { FC, ReactNode } from 'react';
import { IMAGES } from '../../constants/assets.const';
import {
  Root,
  Content,
  Title,
  Description,
  Asset,
  Bar,
} from './Progress.style';

interface ProgressProps {
  value?: number;
  title?: ReactNode;
  description?: ReactNode;
  image?: {
    name: keyof typeof IMAGES;
    width?: number;
    height?: number;
  };
}

export const Progress: FC<ProgressProps> = ({
  value,
  image,
  title,
  description,
}) => {
  return (
    <Root>
      {!!title && <Title>{title}</Title>}
      <Content>
        {!!image && (
          <Asset
            src={IMAGES[image.name]}
            width={image.width}
            height={image.height}
          />
        )}
        <Bar value={value} />
        {!!description && <Description>{description}</Description>}
      </Content>
    </Root>
  );
};
