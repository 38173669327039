import { useMemo } from 'react';
import { get, has, merge, uniqBy } from 'lodash';
import { ADTO } from '@helpful/network';
import { useUser } from '@core/user';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useCommon } from '../useCommon/useCommon.hook';

interface UsePatientState {
  payors: ADTO.PayorEntity[];
  patient: ADTO.PatientEntity;
  plans: string[];
}

type UsePatientReturnType = [UsePatientState];

export const usePatient = (): UsePatientReturnType => {
  const { t } = useTranslation();
  const [{ user }] = useUser();
  const [{ defaultPlans }] = useCommon();

  const patient = useMemo((): ADTO.PatientEntity => {
    const item = get(user, 'patients[0].patient', {} as ADTO.PatientEntity);
    return merge({}, item, {
      firstName: !item.firstName ? t('forms.yourLovedOne') : item.firstName,
      lastName: !item.lastName ? '' : item.lastName,
      plans: [],
    } as ADTO.PatientEntity);
  }, [user, t]);

  const plans = useMemo(() => {
    if (has(user, 'patients[0].patient')) {
      return user.patients[0].patient.plans.map(({ slug }) => slug);
    }

    return defaultPlans.map(({ slug }) => slug);
  }, [defaultPlans, user]);

  const payors = useMemo((): ADTO.PayorEntity[] => {
    return patient?.plans?.reduce((res, { payor }) => {
      return uniqBy([...res, payor], ({ id }) => id);
    }, []);
  }, [patient]);

  return [{ patient, plans, payors }];
};
