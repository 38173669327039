import { FC, PropsWithChildren, useCallback } from 'react';
import { NetworkConnectionHandler } from '@helpful/network';
import { useUtils } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Localization } from './Localization/Localization';
import { Data } from './Data/Data';
import { NavigationPrompt } from './NavigationPrompt/NavigationPrompt';

type ReadyProps = PropsWithChildren<{}>;

export const Ready: FC<ReadyProps> = ({ children }) => {
  const { toast } = useUtils();
  const { t } = useTranslation();

  const connectionCallback = useCallback(() => {
    toast({
      duration: 0,
      variant: 'error',
      message: t('errors.networkConnection'),
    });
  }, [t, toast]);

  return (
    <NetworkConnectionHandler connectionCallback={connectionCallback}>
      <Localization>
        <NavigationPrompt>
          <Data>{children}</Data>
        </NavigationPrompt>
      </Localization>
    </NetworkConnectionHandler>
  );
};
