import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { NavigationPromptCallback } from '../hooks/useNavigationPrompt/useNavigationPrompt.hook';

interface NavigationPromptContextProps {
  navigationPromptCallback: NavigationPromptCallback;
}

export const NavigationPromptContext =
  createContext<NavigationPromptContextProps>({
    navigationPromptCallback: noop,
  });
