import { ReactElement } from 'react';
import { useField } from 'formik';
import { castArray, merge, get } from 'lodash';
import { isPlatform } from '@ionic/react';
import { FormItem } from '../FormItem/FormItem';
import { BaseFormItemProps } from '../Form.types';
import { Select, Option } from './FormSelect.style';
import { FormSelectOption, FormSelectDataProps } from './FormSelect.types';
import { DEFAULT_DATA_PROPS } from './FormSelect.const';

interface FormSelectProps<T extends object = FormSelectOption>
  extends BaseFormItemProps {
  options: T[];
  multiple?: boolean;
  dataProps?: FormSelectDataProps<T>;
}

interface FormSelectComponent {
  <T extends object>(props: FormSelectProps<T>): ReactElement<any, any> | null;
}

export const FormSelect: FormSelectComponent = ({
  label,
  options = [],
  multiple = false,
  name: initialName,
  dataProps: initialDataProps = {},
}) => {
  const [{ name, value, onChange, onBlur }] = useField(
    castArray(initialName).join('.')
  );

  const dataProps = merge({}, DEFAULT_DATA_PROPS, initialDataProps);
  return (
    <FormItem label={label} name={name}>
      <Select
        name={name}
        value={value}
        onIonBlur={onBlur}
        multiple={multiple}
        onIonChange={(e) => setTimeout(() => onChange(e), 0)}
        interface={isPlatform('desktop') ? 'popover' : 'action-sheet'}
      >
        {options.map((item, index) => {
          return (
            <Option key={index} value={get(item, dataProps.value, index)}>
              {/* @ts-ignore */}
              {get(item, dataProps.label)}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );
};
