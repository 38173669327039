import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { sendVerificationEmail } from 'supertokens-auth-react/recipe/emailverification';

type UseCheckEmail = [
  {
    data: boolean;
    loading: boolean;
    error: Error;
  },
  UseMutateFunction<boolean, unknown, void, unknown>
];

export const useResendEmail = (): UseCheckEmail => {
  // @ts-ignore
  const { userId } = useSessionContext();

  const {
    data,
    error,
    isLoading: loading,
    mutate,
  } = useMutation<boolean, Error, void>(() => {
    return sendVerificationEmail({ userContext: { userId } }).then((data) => {
      if (data.status === 'OK') {
        return true;
      } else if (data.status === 'EMAIL_ALREADY_VERIFIED_ERROR') {
        throw new Error('Email already verified');
      }
    });
  });

  return [{ data, loading, error }, mutate];
};
