import { get } from 'lodash';
import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { useContext } from 'react';
import type { ICheckEmailRequest } from '../../types/custom-dto.type';
import { AuthContext } from '../../contexts/Auth.context';
import { HttpContext } from '../../contexts/Http.context';
import type { CheckEmailResponse } from '../../services/Auth.service';

type UseCheckEmail = [
  {
    data: CheckEmailResponse;
    loading: boolean;
  },
  UseMutateFunction<CheckEmailResponse, unknown, ICheckEmailRequest, unknown>
];

export const useCheckEmail = (): UseCheckEmail => {
  const { Auth } = useContext(HttpContext);

  const responseErrorInterceptor = useContextSelector(
    AuthContext,
    ({ responseErrorInterceptor }) => responseErrorInterceptor
  );

  const {
    data,
    isLoading: loading,
    mutate,
  } = useMutation<CheckEmailResponse, unknown, ICheckEmailRequest>((req) => {
    return Auth.checkEmail(req)
      .then(({ data }) => data)
      .catch((err) => {
        return err.json().then((data: any) => {
          throw new Error(get(data, 'message'));
        });
      })
      .catch(responseErrorInterceptor);
  });

  return [{ data, loading }, mutate];
};
