import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { IonThumbnail } from '@ionic/react';
import { UiKitColor, UiKitSize } from '../../../types/uikit.types';
import { SIZE_MAP } from './ListThumb.const';

interface RootProps {
  size: UiKitSize;
  color: UiKitColor;
  align: CSSProperties['alignSelf'];
  disabled: boolean;
}

const RootDisabledMixin = css`
  background-color: ${({ theme }) => theme.palette.neutral300};
`;

export const Root = styled(IonThumbnail)<RootProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize(14)};
  line-height: 130%;
  --border-radius: 4px;
  align-self: ${({ align }) => align};
  margin-right: ${({ theme: { spacing } }) => spacing(4)};
  background-color: ${({ theme, color }) => theme.palette[color]};
  width: ${({ size, theme: { spacing } }) => spacing(SIZE_MAP[size])};
  height: ${({ size, theme: { spacing } }) => spacing(SIZE_MAP[size])};
  ${({ disabled }) => disabled && RootDisabledMixin}
`;
