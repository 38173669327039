import { object, ref, string } from 'yup';
import { useTranslation } from '@wearehelpful/ui-localization';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = object().shape({
    password: string()
      .label(t('forms.newPassword'))
      .required(t('validation.required', { label: t('forms.newPassword') }))
      .min(8)
      .matches(/^(?=.*[0-9])/, t('validation.passwordContainsNumber'))
      .matches(/^(?=.*[A-Z])/, t('validation.passwordContainsUppercaseLetter'))
      .matches(
        /^(?=.*[!@#$%^&*()_=+`~,./<>?-])/,
        t('validation.passwordContainsSpecialCharacter')
      ),
    confirmPassword: string()
      .label(t('forms.password'))
      .required(t('validation.required', { label: t('forms.password') }))
      .oneOf([ref('password')], t('validation.passwordsEqual')),
  });

  return { validationSchema };
};
