export enum RELATIONSHIPS {
  MOTHER = 'Mother',
  FATHER = 'Father',
  MOTHER_IN_LAW = 'Mother-in-law',
  FATHER_IN_LAW = 'Father-in-law',
  SISTER = 'Sister',
  BROTHER = 'Brother',
  HUSBAND = 'Husband',
  WIFE = 'Wife',
  GRANDMOTHER = 'Grandmother',
  GRANDFATHER = 'Grandfather',
  SON = 'Son',
  DAUGHTER = 'Daughter',
  OTHER = 'Other',
}
