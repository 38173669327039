import { createContext } from 'use-context-selector';
import { ResponseErrorInterceptor } from '../types/http.types';

export interface AuthContextProps {
  webDomain: string;
  isSessionExist: boolean;
  isUserVerified: boolean;
  responseErrorInterceptor: ResponseErrorInterceptor;
}

export const AuthContext = createContext<AuthContextProps>({
  webDomain: null,
  isUserVerified: false,
  isSessionExist: false,
  responseErrorInterceptor: (v) => v,
});
