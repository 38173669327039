import { PropsWithChildren, ReactElement } from 'react';
import { noop } from 'lodash';
import { RadioButtonGroupValue } from './RadioButtonGroup.type';
import { RadioButtonGroupContext } from './RadioButtonGroup.context';
import { Root } from './RadioButtonGroup.style';
import { RadioButtonGroupButton } from './RadioButtonGroupButton/RadioButtonGroupButton';

type RadioButtonGroupProps<T extends RadioButtonGroupValue> =
  PropsWithChildren<{
    value: T;
    nowrap?: boolean;
    onChange?: (v: T) => void;
  }>;

interface RadioButtonGroupComponent {
  <T extends RadioButtonGroupValue = string>(
    props: RadioButtonGroupProps<T>
  ): ReactElement;
  Button: typeof RadioButtonGroupButton;
}

export const RadioButtonGroup: RadioButtonGroupComponent = ({
  value,
  children,
  nowrap = false,
  onChange = noop,
}) => {
  return (
    <RadioButtonGroupContext.Provider value={{ value, onChange }}>
      <Root nowrap={nowrap}>{children}</Root>
    </RadioButtonGroupContext.Provider>
  );
};

RadioButtonGroup.Button = RadioButtonGroupButton;
