import styled from 'styled-components';

export const Root = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral300};
  margin-bottom: ${({ theme: { spacing } }) => spacing(8)};
`;

export const Loader = styled('div')`
  padding-top: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  padding-top: ${({ theme: { spacing } }) => spacing(4)};
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
`;
