export enum RoutePath {
  AUTH = '/auth',
  AUTH_CALLBACK = '/auth/callback',
  AUTH_CHANGE_PASSWORD_SUCCESS = '/auth/change-password-success',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_RESET_PASSWORD = '/auth/reset-password',
  AUTH_SIGN_IN = '/auth/sign-in',
  AUTH_SIGN_OUT = '/auth/sign-out',
  AUTH_SIGN_UP = '/auth/sign-up',
  AUTH_SIGN_UP_PAYOR = '/auth/sign-up/payor',
  AUTH_SIGN_UP_PLAN = '/auth/sign-up/plan',
  AUTH_VERIFY_EMAIL = '/auth/verify-email',
  CONNECT = '/connect',
  CONNECT_REDIRECT = '/connect/:id',
  CAREPLAN = '/careplan',
  CAREPLAN_SELECT = '/careplan/:id',
  CONTACT_US = '/contact-us',
  CONTACT_US_SUCCESS = '/contact-us/success',
  FEEDBACK = '/feedback',
  FEEDBACK_SUCCESS = '/feedback/success',
  INSURANCE = '/insurance',
  INSURANCE_CONNECT = '/insurance/connect',
  INSURANCE_CONNECT_PLAN = '/insurance/connect/plan',
  INSURANCE_DETAILS = '/insurance/:id',
  INSURANCE_DETAILS_UPDATE = '/insurance/:id/update',
  LANDING = '/',
  LEGAL = '/legal',
  LEGAL_DETAILS = '/legal/:type',
  ROOT = '/r',
  ROOT_BENEFITS = '/r/benefits',
  ROOT_BENEFITS_DETAILS = '/r/benefits/:slug',
  ROOT_GUIDES = '/r/guides',
  ROOT_GUIDES_DETAILS = '/r/guides/:slug',
  ROOT_HOME = '/r/home',
  ROOT_MEDICAL = '/r/medical',
  ROOT_MEDICAL_ALLERGIES = '/r/medical/allergies',
  ROOT_MEDICAL_CARE_TEAM = '/r/medical/care-team',
  ROOT_MEDICAL_CONDITIONS = '/r/medical/conditions',
  ROOT_MEDICAL_CONNECT = '/r/medical/connect',
  ROOT_MEDICAL_CONNECT_CALLBACK = '/r/medical/connect/callback',
  ROOT_MEDICAL_MEDICATIONS = '/r/medical/medications',
  ROOT_MEDICAL_MEDICATIONS_DETAILS = '/r/medical/medications/:id',
  ROOT_MEDICAL_UNVERIFIED = '/r/medical/unverified',
  ROOT_MEDICAL_VISIT_SUMMARIES = '/r/medical/visit-summaries',
  ROOT_MEDICAL_VISIT_SUMMARIES_DETAILS = '/r/medical/visit-summaries/:id',
  ROOT_MORE = '/r/more',
  ROOT_MORE_FAQ = '/r/more/faq',
  ROOT_MORE_INSURANCE = '/r/more/insurance',
  ROOT_MORE_INSURANCE_UNVERIFIED = '/r/more/insurance/unverified',
  ROOT_MORE_LANGUAGE = '/r/more/language',
  ROOT_MORE_PATIENT = '/r/more/patient/:id',
  ROOT_MORE_PROFILE = '/r/more/profile',
  ROOT_MORE_PROFILE_CHANGE_PASSWORD = '/r/more/profile/change-password',
  ROOT_MORE_PROFILE_PREFERENCES = '/r/more/profile/preferences',
  ROOT_SEARCH = '/r/search',
  ROOT_STACKS = '/r/stacks',
  ROOT_STACKS_DETAILS = '/r/stacks/:slug',
  ROOT_TODOS = '/r/todos',
  ROOT_TODOS_DETAILS = '/r/todos/:id',
  ROOT_UPLOAD = '/r/upload/:type',
  ROOT_UPLOAD_CALLBACK = '/r/upload/:type/callback',
  ROOT_UPLOAD_SUCCESS = '/r/upload/:type/success',
  TODOS = '/todos',
  TODOS_CREATE = '/todos/create',
  TODOS_DETAILS = '/todos/:id',
}
