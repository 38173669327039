import { createContext } from 'use-context-selector';
import { CDTO } from '@helpful/network';

interface StacksContextProps {
  isLoading: boolean;
  data: CDTO.GetStacksQuery;
}

export const StacksContext = createContext<StacksContextProps>({
  data: null,
  isLoading: false,
});
