import { FC } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Button, ButtonProps } from '../../Button/Button';
import { FormContext, FormContextProps } from '../Form.context';

export const FormSubmit: FC<ButtonProps> = ({
  children,
  type: _type,
  onClick: _onClick,
  ...props
}) => {
  const onSubmitted = useContextSelector<FormContextProps, () => void>(
    FormContext,
    ({ onSubmitted }) => onSubmitted
  );

  return (
    <Button type="submit" onClick={onSubmitted} {...props}>
      {children}
    </Button>
  );
};
