import { FC } from 'react';
import { Typography } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Root, Content, Image } from './ToDosEmpty.style';
import SRC from './ToDosEmpty.png';

export const ToDosEmpty: FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Content>
        <Typography bold align="center">
          {t('prompts.todosEmpty.title')}
        </Typography>
        <Typography align="center">
          {t('prompts.todosEmpty.message')}
        </Typography>
        <Image src={SRC} />
      </Content>
    </Root>
  );
};
