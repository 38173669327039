import { FC, Fragment } from 'react';
import {
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
  Empty,
  Button,
} from '@core/ui';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useRoute } from '@core/navigation';
import { VisitSummaryCard } from '@common/features';
import { usePatient } from '@common/hooks';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useGetPatientEncountersInfinite } from '@helpful/network';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';
import { useMedical } from '../../../../../hooks/useMedical/useMedical.hook';

const TAKE = 20;

export const Index: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [{ connections }] = useMedical();
  const { track } = useAnalytics();

  const [, { push }] = useRoute();

  const handleOnClick = (id: string) => {
    push({
      path: RoutePath.ROOT_MEDICAL_VISIT_SUMMARIES_DETAILS,
      params: { id },
    });
  };

  const handleOnConnect = () => {
    track({ id: AnalyticsTrackEvents.MEDICAL_CONNECT_CARE_PLANS }).finally(
      () => {
        push({ path: RoutePath.CONNECT });
      }
    );
  };

  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetPatientEncountersInfinite(
    patient.id,
    {
      skip: 0,
      take: TAKE,
      relations: 'provider, connection.system, careplan',
      order: 'date:DESC',
    },
    {
      query: {
        enabled: Boolean(patient.id),
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * TAKE;
        },
      },
    }
  );

  const visitSummaries = data.pages
    ?.reduce((res, { data }) => [...res, ...data], [] as ADTO.EncounterEntity[])
    .filter((encounter) => encounter.status !== 'entered-in-error');

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.CAREPLANS}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading2>
              {t('subheadings.careplans.title')}
            </Typography.Heading2>
          </Subheader>
          <ContentFixed>
            <Skeleton variant="feed" loading={isFetching}>
              {!!connections.length && !visitSummaries?.length && (
                <Empty padding>
                  <Empty.Message>
                    {t('empty.noCarePlansDetected')}
                  </Empty.Message>
                  <Empty.Asset />
                </Empty>
              )}
              {!connections.length && (
                <Empty padding>
                  <Empty.Asset name="AFTER_VISIT_SUMMARY_SCREEN" width={298} />
                  <Empty.Message>
                    {t('prompts.connectMedical.careplans')}
                  </Empty.Message>
                  <Button variant="tertiary" onClick={handleOnConnect}>
                    {t('actions.connectMedicalRecords')}
                  </Button>
                </Empty>
              )}

              {!!visitSummaries?.length && (
                <InfiniteScroll
                  onScroll={fetchNextPage}
                  disabled={!hasNextPage}
                >
                  <Fragment>
                    {visitSummaries.map((item) => {
                      return (
                        <VisitSummaryCard
                          item={item}
                          key={item.id}
                          onClick={() => handleOnClick(item.id)}
                        />
                      );
                    })}
                  </Fragment>
                </InfiniteScroll>
              )}
            </Skeleton>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
