import { FC } from 'react';
import { unionBy } from 'lodash';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { usePatient } from '@common/hooks';
import { useRoute } from '@core/navigation';
import { ADTO } from '@helpful/network';
import { Icon, Content, Page, Header, Typography } from '@wearehelpful/ui-kit';
import { List, Toolbar } from '@core/ui';

export const Index: FC = () => {
  const [{ patient }] = usePatient();
  const [, { push }] = useRoute();

  const { t } = useTranslation();

  const payors: ADTO.PayorEntity[] = patient?.plans.reduce((res, { payor }) => {
    return unionBy([...res, payor], ({ id }) => id);
  }, []);

  const handleOnClick = (id: string) => {
    push({
      path: RoutePath.INSURANCE_DETAILS,
      params: { id },
    });
  };

  const handleOnConnect = () => {
    push({
      path: RoutePath.INSURANCE_CONNECT,
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MORE}
      name={AnalyticsPageCategory.INSURANCE}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back defaultHref={RoutePath.ROOT_MORE} />
          </Toolbar>
        </Header>
        <Content>
          <Content.Fixed>
            <Content.Subheader divider>
              <Typography variant="h2">
                {t('subheadings.insurance.title', {
                  name: patient.firstName,
                })}
              </Typography>
              <Typography>
                {t('subheadings.insurance.description', {
                  name: patient.firstName,
                })}
              </Typography>
            </Content.Subheader>
            <List title={t('forms.insuranceAccounts')}>
              <List.Item onClick={handleOnConnect}>
                <List.Thumb color="orange400">
                  <Icon name="plus" />
                </List.Thumb>
                <List.Label>{t('actions.addAnInsuranceProvider')}</List.Label>
              </List.Item>
              {payors.map(({ id, name }) => {
                return (
                  <List.Item key={id} onClick={() => handleOnClick(id)}>
                    <List.Thumb>
                      <Icon name="insurance" />
                    </List.Thumb>
                    <List.Label>{name}</List.Label>
                  </List.Item>
                );
              })}
            </List>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
