import { RoutePath } from '@common/constants';
import { RouteProps } from '@core/navigation';
import { Index } from './Index/Index';
import { Details } from './Details/Details';

export const ROOT_TODOS_ROUTES: Array<RouteProps> = [
  {
    path: RoutePath.ROOT_TODOS,
    children: <Index />,
    exact: true,
  },
  {
    path: RoutePath.ROOT_TODOS_DETAILS,
    children: <Details />,
  },
];
