import { FC, useCallback, useState } from 'react';
import { useIonModal } from '@ionic/react';
import { ModalParams } from '../../types/modal.types';

interface UseModalState {}

type UseModalDispatch<T extends object> = (v: boolean, p?: T) => void;

type UseModalReturnType<T extends object> = [
  UseModalState,
  UseModalDispatch<T>
];

export const useModal = <T extends object>(
  component: FC<T & ModalParams> | JSX.Element
): UseModalReturnType<T> => {
  const [params, setParams] = useState<T>({} as T);

  const [show, dismiss] = useIonModal(component, {
    ...params,
    dismiss: () => dismiss(),
  });

  const toggle = useCallback(
    (v: boolean, params: T) => {
      if (v) {
        setParams(params);
        show();
      } else {
        dismiss();
      }
    },
    [show, dismiss]
  );

  return [{}, toggle];
};
