import { FC, PropsWithChildren } from 'react';
import { Root, Label } from './AccordionHeader.style';

type AccordionHeaderProps = PropsWithChildren<{
  textWrap?: boolean;
}>;

export const AccordionHeader: FC<AccordionHeaderProps> = ({
  textWrap,
  children,
}) => {
  return (
    <Root slot="header">
      <Label textWrap={textWrap}>{children}</Label>
    </Root>
  );
};
