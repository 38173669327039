import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(2.5)};
  background: ${({ theme }) => theme.palette.periwinkle200};
  padding-top: ${({ theme: { spacing } }) => spacing(7)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(7)};
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
`;
