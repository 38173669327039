import { FC } from 'react';
import { Button } from '@wearehelpful/ui-kit';
import { Form } from '@core/ui';
import { useGoogleSignIn, useAppleSignIn, useFacebookSignIn } from '@core/auth';
import { Link } from '@core/navigation';
import { useTranslation, Translate } from '@wearehelpful/ui-localization';
import { RoutePath } from '../../constants';
import { Root, Title, Legal, Social } from './FederatedSignIn.style';

export const FederatedSignIn: FC = () => {
  const { t } = useTranslation();

  const [{ loading: googleLoading }, signInWithGoogle] = useGoogleSignIn();
  const [{ loading: appleLoading }, signInWithApple] = useAppleSignIn();
  const [{ loading: facebookLoading }, signInWithFacebook] =
    useFacebookSignIn();

  return (
    <Root>
      <Form.Divider>{t('forms.or')}</Form.Divider>
      <Title variant="p1">{t('forms.continueWith')}</Title>
      <Social>
        <Button.Social
          variant="google"
          loading={googleLoading}
          onClick={signInWithGoogle}
        />
        <Button.Social
          variant="apple"
          loading={appleLoading}
          onClick={signInWithApple}
        />
        <Button.Social
          variant="facebook"
          loading={facebookLoading}
          onClick={signInWithFacebook}
        />
      </Social>
      <Legal variant="p2">
        <Translate
          id="forms.agreeLegalTermsAndPolicy"
          components={{
            terms: (
              <Link path={RoutePath.LEGAL_DETAILS} params={{ type: 'terms' }} />
            ),
            privacy: (
              <Link
                path={RoutePath.LEGAL_DETAILS}
                params={{ type: 'privacy-policy' }}
              />
            ),
          }}
        />
      </Legal>
    </Root>
  );
};
