import { Toolbar, Footer, Button, Subheader, Typography } from '@core/ui';
import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Content } from '@wearehelpful/ui-kit';
import { Container, Placeholder, Image } from './Success.style';
import PLACEHOLDER from './assets/pictographic.png';

const { Heading2, Body1 } = Typography;

export const Success = () => {
  const [, { back }] = useRoute();
  const { t } = useTranslation();
  return (
    <TrackPageView
      category={AnalyticsPageCategory.FEEDBACK}
      name={AnalyticsPageCategory.FEEDBACK_SUCCESS}
    >
      <Content padding>
        <Container>
          <Subheader gap="md">
            <Heading2 align="center">
              {t('subheadings.feedbackSuccess.title')}
            </Heading2>
            <Body1 align="center">
              {t('notifications.feedbackSuccessfullySent')}
            </Body1>
          </Subheader>
          <Placeholder>
            <Image src={PLACEHOLDER} alt="" />
          </Placeholder>
        </Container>
      </Content>
      <Footer>
        <Toolbar padding>
          <Button onClick={back}>{t('actions.back')}</Button>
        </Toolbar>
      </Footer>
    </TrackPageView>
  );
};
