import { memo } from 'react';
// import moment from 'moment';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Form, useFormControl } from '@wearehelpful/ui-kit';

export const PatientForm = memo(() => {
  const { t } = useTranslation();

  const [caregiverFirstName] = useFormControl({
    name: 'caregiver.firstName',
    watch: true,
  });

  const [caregiverLastName] = useFormControl({
    name: 'caregiver.lastName',
    watch: true,
  });

  const [, { setValue: setPatientFirstName }] = useFormControl({
    name: 'patient.firstName',
    watch: true,
  });

  const [, { setValue: setPatientLastName }] = useFormControl({
    name: 'patient.lastName',
    watch: true,
  });

  const handleOnCaringForMyselfChange = (value: boolean) => {
    if (value) {
      setPatientFirstName(caregiverFirstName);
      setPatientLastName(caregiverLastName);
    }
  };

  // const defaultDate = moment().subtract(70, 'years').utc(true).toISOString();

  return (
    <Form.Section description={t('forms.createLovedOnesInformation')}>
      <Form.Checkbox
        name="caringForMyself"
        label={t('forms.caringForMyself')}
        onChange={handleOnCaringForMyselfChange}
      />
      <Form.Text
        tabIndex={3}
        autocapitalize="on"
        name="patient.firstName"
        validation={{ required: true }}
        label={t('forms.lovedOnesFirstName')}
        help={t('hints.lovedOnesFirstNameAsInInsurance')}
      />
      <Form.Text
        tabIndex={4}
        autocapitalize="on"
        name="patient.lastName"
        validation={{ required: true }}
        label={t('forms.lovedOnesLastName')}
        help={t('hints.lovedOnesLastNameAsInInsurance')}
      />
      {/* <Form.Datepicker
        tabIndex={5}
        enterKeyHint="go"
        name="patient.dateOfBirth"
        defaultValue={defaultDate}
        validation={{ required: true }}
        label={t('forms.lovedOnesDateOfBirth')}
      /> */}
    </Form.Section>
  );
});
