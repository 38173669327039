import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Root } from './FormActions.style';

type FormActionsProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
}>;

export const FormActions: FC<FormActionsProps> = ({
  padding,
  children,
  className,
}) => {
  return (
    <Root
      className={classNames(className, { 'ion-padding-horizontal': padding })}
    >
      {children}
    </Root>
  );
};
