import styled from 'styled-components';
import { IonCardContent } from '@ionic/react';
import { Root as Header } from '../CardHeader/CardHeader.style';
import { UiKitColor } from '../../../types/uikit.types';

export const Root = styled(IonCardContent)<{ background?: UiKitColor }>`
  background: ${({ background, theme }) =>
    background && theme.palette.periwinkle300};
  + ${Header} {
    padding-top: 0;
  }

  img {
    display: block;
    width: auto;
    height: auto;
  }
`;
