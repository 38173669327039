import { PropsWithChildren, ReactElement } from 'react';
import { isUndefined } from 'lodash';
import { Root, Header, Title } from './List.style';
import { ListItem } from './ListItem/ListItem';
import { ListThumb } from './ListThumb/ListThumb';
import { ListLabel } from './ListLabel/ListLabel';
import { ListTitle } from './ListTitle/ListTitle';
import { ListSubtitle } from './ListSubtitle/ListSubtitle';
import { ListBody } from './ListBody/ListBody';
import { ListBadge } from './ListBadge/ListBadge';

type ListProps = PropsWithChildren<{
  id?: string;
  title?: string;
  className?: string;
}>;

interface ListComponent {
  (props: ListProps): ReactElement<any, any> | null;
  Item: typeof ListItem;
  Thumb: typeof ListThumb;
  Label: typeof ListLabel;
  Title: typeof ListTitle;
  Subtitle: typeof ListSubtitle;
  Body: typeof ListBody;
  Badge: typeof ListBadge;
}

export const List: ListComponent = ({ id, title, className, children }) => {
  return (
    <Root className={className} id={id}>
      {!isUndefined(title) && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      {children}
    </Root>
  );
};

List.Item = ListItem;
List.Thumb = ListThumb;
List.Label = ListLabel;
List.Title = ListTitle;
List.Subtitle = ListSubtitle;
List.Body = ListBody;
List.Badge = ListBadge;
