import styled, { css } from 'styled-components';
import { IonCardContent } from '@ionic/react';
import { Root as Footer } from '../CardFooter/CardFooter.style';

interface ContentProps {
  truncate?: number;
}

export const Root = styled(IonCardContent)`
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(20)};
  p {
    font-size: ${({ theme }) => theme.fontSize(16)};
    line-height: ${({ theme }) => theme.fontSize(20)};
  }
  + ${Footer} {
    padding-top: 0;
  }
`;

const ContentTruncateMixin = css<ContentProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ truncate }) => truncate};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Content = styled('div')<ContentProps>`
  ${({ truncate }) => truncate && ContentTruncateMixin}
`;
