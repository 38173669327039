import { FC, PropsWithChildren } from 'react';
import {
  AuthProvider,
  type ResponseErrorInterceptor,
  type EmailSignInUpCallback,
  type SocialSignInUpCallback,
} from '@core/auth';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { useUtils } from '@wearehelpful/ui-kit';
import { AnalyticsTrackEvents, SPLASH } from '../../../constants';
import { usePayorPreference } from '../../../hooks';

type AuthBootstrapProps = PropsWithChildren<{
  host: string;
  domain: string;
  base?: string;
}>;

export const AuthBootstrap: FC<AuthBootstrapProps> = ({
  host,
  base,
  domain,
  children,
}) => {
  const { toast } = useUtils();
  const { trackCompleteRegistration, track } = useAnalytics();
  const [{ slug: payor }] = usePayorPreference();

  const authResponseErrorInterceptor: ResponseErrorInterceptor = (
    err: Error
  ) => {
    toast({ variant: 'error', message: err.message });
    return err;
  };

  const emailSignInHandler: EmailSignInUpCallback = ({ email }) =>
    Promise.all([
      track({
        id: AnalyticsTrackEvents.SIGN_IN,
        properties: { email: email.toLowerCase() },
      }),
    ]);

  const emailSignUpHandler: EmailSignInUpCallback = ({ email }) =>
    Promise.all([
      track({
        id: AnalyticsTrackEvents.SIGN_UP_ACCOUNT_CREATED,
        properties: { email: email.toLowerCase(), payor },
      }),
      trackCompleteRegistration({
        email: email.toLowerCase(),
        registrationMethod: 'email',
      }),
    ]);

  const socialSignUpHandler: SocialSignInUpCallback = ({ email, provider }) =>
    Promise.all([
      track({
        id: AnalyticsTrackEvents.FEDERATED_SIGNUP,
        properties: { email: email.toLowerCase(), provider },
      }),
      trackCompleteRegistration({
        email: email.toLowerCase(),
        registrationMethod: provider,
      }),
    ]);

  const socialSignInHandler: SocialSignInUpCallback = ({ email, provider }) =>
    track({
      id: AnalyticsTrackEvents.FEDERATED_LOGIN,
      properties: { email: email.toLowerCase(), provider },
    });

  return (
    <AuthProvider
      name="Helpful"
      fallback={SPLASH}
      webDomain={domain}
      apiHost={host}
      apiBase={base}
      onEmailSignIn={emailSignInHandler}
      onEmailSignUp={emailSignUpHandler}
      onSocialSignUp={socialSignUpHandler}
      onSocialSignIn={socialSignInHandler}
      responseErrorInterceptor={authResponseErrorInterceptor}
    >
      {children}
    </AuthProvider>
  );
};
