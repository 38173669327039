import { FC, PropsWithChildren } from 'react';
import { render } from 'micromustache';
import { LocalizationProvider } from '@wearehelpful/ui-localization';
import { useConfig } from '../../hooks';
import { LOCALIZATION_FORMATTERS } from './Localization.const';

export type LocalizationProps = PropsWithChildren<{
  url: string;
  version: string;
}>;

export const Localization: FC<LocalizationProps> = ({
  url,
  version,
  children,
}) => {
  const localizationUrl = render(url, { version }, { tags: ['<%=', '%>'] });

  const { supportedLanguages, fallbackLanguage } = useConfig();

  return (
    <LocalizationProvider
      url={localizationUrl}
      fallbackLng={fallbackLanguage}
      formatters={LOCALIZATION_FORMATTERS}
      supportedLngs={supportedLanguages.map(({ code }) => code)}
    >
      {children}
    </LocalizationProvider>
  );
};
