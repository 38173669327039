import { useMemo } from 'react';
import { NavigationPromptCallback } from '../../hooks/useNavigationPrompt/useNavigationPrompt.hook';

interface UseNavigationPromptCallbackParams {
  navigationPromptCallback: NavigationPromptCallback;
}

export const useNavigationPromptCallback = ({
  navigationPromptCallback: initialNavigationPromptCallback,
}: UseNavigationPromptCallbackParams) => {
  const callback: NavigationPromptCallback = useMemo(() => {
    return initialNavigationPromptCallback;
  }, [initialNavigationPromptCallback]);

  return { callback };
};
