import styled from 'styled-components';

export const Root = styled('span')`
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  background-color: ${({ theme }) => theme.palette.periwinkle300};
  display: flex;
  align-items: center;
  justify-content: center;
`;
