import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { verifyEmail } from 'supertokens-auth-react/recipe/emailverification';

type UseVerifyEmailReturnType = [
  {
    data?: boolean;
    error?: Error;
    loading: boolean;
  },
  UseMutateFunction<boolean, Error, void, unknown>
];

export const useVerifyEmail = (): UseVerifyEmailReturnType => {
  const {
    data,
    error,
    isLoading: loading,
    mutate,
  } = useMutation<boolean, Error, void>(() => {
    return verifyEmail().then((data) => {
      if (data.status === 'OK') {
        return true;
      } else if (data.status === 'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR') {
        throw new Error('Email verification invalid token');
      } else {
        throw new Error('Unknown error');
      }
    });
  });

  return [{ data, error, loading }, mutate];
};
