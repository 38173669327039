import { FC, useRef } from 'react';
import { toString } from 'lodash';
import { List, Typography, Progress, Overflow } from '@core/ui';
import { useGetSearch } from '@helpful/network';
import { useTranslation, useLocale } from '@wearehelpful/ui-localization';
import { Root, Content, Loader } from './Response.style';

interface ResponseProps {
  id?: string;
  query: string;
  generate: string;
}

const { Body2 } = Typography;

export const Response: FC<ResponseProps> = ({ id, query, generate }) => {
  const { t } = useTranslation();
  const root = useRef<HTMLDivElement>();
  const [{ lng }] = useLocale();

  const { isFetching, data } = useGetSearch(
    ['content'],
    {
      query,
      limit: 3,
      generate,
      locale: lng,
      grouped: true,
    },
    {
      query: {
        enabled: !!generate,
        initialData: {
          data: {
            content: [],
          },
        },
      },
    }
  );

  const groupedResult =
    data?.data?.content[0]?._additional?.generate?.groupedResult
      ?.split('\n')
      .filter((v) => !!v.trim());

  const loader = (
    <Loader>
      <Progress
        image={{ width: 36, name: 'HAND_WALK' }}
        description={t('system.loadingHelpfulResponse')}
      />
    </Loader>
  );

  const content = (
    <Overflow
      height={300}
      key={toString(isFetching)}
      scrollTo={root.current}
      action={(v) => (v ? t('actions.showLess') : t('actions.showMore'))}
    >
      <Content>
        {groupedResult?.map((str) => (
          <Body2 key={str}>{str}</Body2>
        ))}
      </Content>
    </Overflow>
  );

  return (
    <Root ref={root} id={id}>
      <List title={t('forms.helpfulResponse')}>
        {isFetching ? loader : content}
      </List>
    </Root>
  );
};
