import { FC } from 'react';
import { noop } from 'lodash';
import { useTranslation } from '@wearehelpful/ui-localization';
import { CDTO } from '@helpful/network';
import { Root, SeeAll, Title, Image } from './StackHeroCard.style';

interface StackHeroCardProps {
  title: string;
  image?: string;
  category: string;
  background?: string;
  onClick?: () => void;
}

export const StackHeroCard: FC<StackHeroCardProps> = ({
  title,
  image,
  category,
  background,
  onClick = noop,
}) => {
  const { t } = useTranslation();

  return (
    <Root onClick={onClick} background={background}>
      <SeeAll bold background={background}>
        {t('actions.catagorySeeAll', { category })}
      </SeeAll>
      <Title background={background}>{title}</Title>
      {image && (
        <Image
          url={image}
          width={264}
          height={141}
          fit={CDTO.ImageResizeStrategy.Thumb}
        />
      )}
    </Root>
  );
};
