import { useContextSelector } from 'use-context-selector';
import { CDTO } from '@helpful/network';
import { UserRole } from '@common/constants';
import { useUser } from '@core/user';
import type { UserContextProps } from '@core/user/contexts/User.context';
import { RootContext } from '../../contexts/Root.context';

interface UseMedicalState
  extends Pick<UserContextProps, 'connections' | 'loading'> {
  prompt: DeepPartial<CDTO.Prompt>;
}

type UseMedicalReturnType = [UseMedicalState];

export const useMedical = (): UseMedicalReturnType => {
  const [{ roles, loading, connections }] = useUser();

  const prompt = useContextSelector(RootContext, ({ prompts }) =>
    roles.includes(UserRole.MEDICAL_DISCONNECTED) ||
    roles.includes(UserRole.NONE)
      ? prompts.items.find(({ type }) => type === 'medical')
      : null
  );

  return [{ connections, loading, prompt }];
};
