import { InfiniteData, ADTO } from '@helpful/network';

export const TAKE = 25;

export const INITIAL_DATA: InfiniteData<
  ADTO.List & ADTO.PatientControllerGetTodos200AllOf
> = {
  pages: [],
  pageParams: [],
};
