import { forwardRef, PropsWithChildren } from 'react';
import { useSwiper } from 'swiper/react';
import { Icon } from '@wearehelpful/ui-kit';
import { Root } from './SwiperNavButton.style';
import { ButtonType, TYPE_ICON_MAP } from './SwiperNavButton.const';

type SwiperNavButtonProps = PropsWithChildren<{
  type: ButtonType;
}>;

export const SwiperNavButton = forwardRef<
  HTMLButtonElement,
  SwiperNavButtonProps
>(({ type }, ref) => {
  const swiper = useSwiper();

  const handleOnClick = () => {
    if (type === 'prev') {
      swiper.slidePrev();
    }
    if (type === 'next') {
      swiper.slideNext();
    }
  };

  return (
    <Root ref={ref} navDirection={type} onClick={handleOnClick}>
      <Icon size="sm" name={TYPE_ICON_MAP[type]} />
    </Root>
  );
});
