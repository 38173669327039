import type { RouteProps } from '@core/navigation';
import { ROOT_MORE_ROUTES } from './More/More';
import { ROOT_TODOS_ROUTES } from './ToDos/ToDos';
import { ROOT_MEDICAL_ROUTES } from './Medical/Medical';

export const ROOT_ROUTES: Array<RouteProps> = [
  ...ROOT_MORE_ROUTES,
  ...ROOT_TODOS_ROUTES,
  ...ROOT_MEDICAL_ROUTES,
];
