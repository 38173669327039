import { FC } from 'react';
import { RoutePath } from '@common/constants';
import { Route } from '@core/navigation';
import { Page, Header, Toolbar } from '@core/ui';
import { Index } from './Index/Index';
import { Success } from './Success/Success';

export const Feedback: FC = () => {
  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Route path={RoutePath.FEEDBACK} exact>
        <Index />
      </Route>
      <Route path={RoutePath.FEEDBACK_SUCCESS}>
        <Success />
      </Route>
    </Page>
  );
};
