import { FC, PropsWithChildren } from 'react';
import { AnalyticsProvider, ErrorBoundary } from '@wearehelpful/ui-stats';
import { NetworkProvider } from '@helpful/network';
import { ThemeProvider } from '@wearehelpful/ui-kit';
import { History } from '@core/navigation';
import { Config, ConfigProps } from '../Config/Config';
import { Network, NetworkProps } from '../Network/Network';
import { Localization, LocalizationProps } from '../Localization/Localization';
import { Contentful, ContentfulProps } from '../Contentful/Contentful';
import { Ready } from '../Ready/Ready';
import { Api, ApiProps } from '../Api/Api';
import { NetworkFallback } from './NetworkFallback/NetworkFallback';

export type BootstrapProps = PropsWithChildren<{
  config: ConfigProps;
  analytics: {
    writeKey: string;
    dataUrl: string;
    version: string;
    environment: string;
    facebook: {
      appId: string;
      clientToken: string;
    };
  };
  api: ApiProps;
  network: NetworkProps;
  contentful: ContentfulProps;
  localization: LocalizationProps;
  history: History;
}>;

export const Bootstrap: FC<BootstrapProps> = ({
  api,
  config,
  children,
  analytics,
  network,
  contentful,
  localization,
  history,
}) => {
  return (
    <ErrorBoundary>
      <NetworkProvider connectionFallback={<NetworkFallback />}>
        <AnalyticsProvider {...analytics}>
          <Network {...network}>
            <Config {...config}>
              <Localization {...localization}>
                <ThemeProvider history={history} splash>
                  <Api {...api}>
                    <Contentful {...contentful}>
                      <Ready>{children}</Ready>
                    </Contentful>
                  </Api>
                </ThemeProvider>
              </Localization>
            </Config>
          </Network>
        </AnalyticsProvider>
      </NetworkProvider>
    </ErrorBoundary>
  );
};
