import { FC } from 'react';
import { LinkVoid, useRoute } from '@core/navigation';
import { Button, useFormControl } from '@wearehelpful/ui-kit';
import { Translate, useTranslation } from '@wearehelpful/ui-localization';
import { RoutePath } from '@common/constants';

export const AuthActions: FC = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();

  const [email] = useFormControl({
    name: 'email',
    watch: true,
  });

  const handleOnForgot = () => {
    push({ path: RoutePath.AUTH_FORGOT_PASSWORD, search: { email } });
  };

  const handleOnSignUp = () => {
    push({ path: RoutePath.AUTH });
  };

  return (
    <>
      <Button variant="text">
        <LinkVoid onClick={handleOnForgot}>
          {t('actions.forgotPassword')}
        </LinkVoid>
      </Button>
      <Button variant="text">
        <Translate
          id="forms.noAccountSignUp"
          components={{
            'sign-up': (
              <LinkVoid onClick={handleOnSignUp}>
                {t('actions.signUp')}
              </LinkVoid>
            ),
          }}
        />
      </Button>
    </>
  );
};
