import { FC } from 'react';
import { useMount } from 'react-use';
import { RoutePath } from '@common/constants';
import { Redirect } from '@core/navigation';
import { useThirdPartySignInAndUp } from '@core/auth';
import { SplashPage } from '@core/ui';

export const Callback: FC = () => {
  const [{ error }, signInAndUp] = useThirdPartySignInAndUp();

  useMount(signInAndUp);

  if (error) {
    return <Redirect path={RoutePath.AUTH_SIGN_IN} />;
  }

  return <SplashPage />;
};
