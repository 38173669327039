import { FC, PropsWithChildren, useState, useCallback, useMemo } from 'react';
import { intersection } from 'lodash';
import {
  useInfiniteGetBenefitsQuery,
  useGetSearch,
  useGetStackBenefitsIdsQuery,
  CDTO,
} from '@helpful/network';
import { useLocale } from '@wearehelpful/ui-localization';
import { usePatient } from '@common/hooks';
import { useBootstrapData } from '@bootstrap/hooks';
import {
  BenefitsContext,
  GetBenefitsFilterParams,
} from '../../contexts/Benefits.context';
import { GetBenefitsQuery } from './BenefitsProvider.types';

type BenefitsProviderProps = PropsWithChildren<unknown>;

export const BenefitsProvider: FC<BenefitsProviderProps> = ({ children }) => {
  const [{ lng }] = useLocale();
  const [{ plans }] = usePatient();
  const { stacks: initialStaks } = useBootstrapData();

  const [{ query, stack, ...variables }, setVariables] =
    useState<GetBenefitsQuery>({
      skip: 0,
      limit: 25,
      plans: [],
    });

  const {
    isFetching: isBenefitsByStackFetching,
    data: {
      stack: {
        benefitsCollection: { items: benefitsByStack },
      },
    },
  } = useGetStackBenefitsIdsQuery(
    {
      plans,
      id: stack,
    },
    {
      enabled: !!stack,
      initialData: {
        stack: {
          benefitsCollection: {
            items: [],
          },
        },
      },
    }
  );

  const {
    data: {
      data: { benefits: benefitsBySearch },
    },
    isFetching,
  } = useGetSearch(
    ['benefits'],
    {
      plans,
      query,
      autocut: 1,
      limit: 25,
      locale: lng,
    },
    {
      query: {
        enabled: !!query,
        initialData: {
          data: {
            benefits: [],
          },
        },
      },
    }
  );

  const ids = useMemo(() => {
    const benefitsIdsBySearch = benefitsBySearch.map(
      ({ contentId }) => contentId
    );
    const benefitsIdsByStack = benefitsByStack.map(({ sys: { id } }) => id);
    if (!!benefitsIdsBySearch.length && !!benefitsIdsByStack.length) {
      return intersection(benefitsIdsBySearch, benefitsIdsByStack);
    }
    if (!!benefitsIdsBySearch.length) return benefitsIdsBySearch;
    if (!!benefitsIdsByStack.length) return benefitsIdsByStack;
  }, [benefitsBySearch, benefitsByStack]);

  const {
    refetch,
    isLoading,
    hasNextPage,
    fetchNextPage,
    data: { pages = [] } = {},
  } = useInfiniteGetBenefitsQuery(
    'skip',
    {
      ...variables,
      plans,
      ids,
    },
    {
      getNextPageParam: ({ planBenefitCollection: { total } }, all) => {
        const skip = all.reduce(
          (res, { planBenefitCollection: { items } }) => res + items.length,
          0
        );
        if (total > skip) return { skip };
      },
    }
  );

  const benefits = useMemo(() => {
    const items =
      pages.reduce(
        (res, { planBenefitCollection: { items } }) => [...res, ...items],
        [] as DeepPartial<CDTO.PlanBenefit>[]
      ) || [];
    if (!!query && ids) {
      return items.sort(({ sys: { id: idA } }, { sys: { id: idB } }) => {
        return ids.indexOf(idA) - ids.indexOf(idB);
      });
    }
    return items;
  }, [pages, query, ids]);

  const filter = useCallback((update: GetBenefitsFilterParams) => {
    setVariables((prev) => ({ ...prev, ...update }));
  }, []);

  const stacks = useMemo(() => {
    return initialStaks.filter(
      ({ benefitsCollection: { total } }) => total > 0
    );
  }, [initialStaks]);

  const loading = isBenefitsByStackFetching || isLoading || isFetching;

  return (
    <BenefitsContext.Provider
      value={{
        stack,
        query,
        stacks,
        filter,
        loading,
        refetch,
        benefits,
        hasNextPage,
        fetchNextPage,
        total: pages[0]?.planBenefitCollection.total,
      }}
    >
      {children}
    </BenefitsContext.Provider>
  );
};
