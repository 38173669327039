import { CookieHandlerInterface } from 'supertokens-website/utils/cookieHandler/types';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from '@core/ui/constants';
import { Capacitor } from '@capacitor/core';

async function getCookiesFromStorage(): Promise<string> {
  const cookiesFromStorage = await Preferences.get({
    key: STORAGE_KEY,
  });

  if (cookiesFromStorage.value === null) {
    Preferences.set({
      key: STORAGE_KEY,
      value: '[]',
    });
    return '';
  }

  const cookieArrayInStorage: string[] = JSON.parse(cookiesFromStorage.value);
  let cookieArrayToReturn: string[] = [];

  for (
    let cookieIndex = 0;
    cookieIndex < cookieArrayInStorage.length;
    cookieIndex++
  ) {
    const currentCookieString = cookieArrayInStorage[cookieIndex];
    const parts = currentCookieString.split(';');
    let expirationString: string = '';

    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      const currentPart = parts[partIndex];

      if (currentPart.toLocaleLowerCase().includes('expires=')) {
        expirationString = currentPart;
        break;
      }
    }

    if (expirationString !== '') {
      const expirationValueString = expirationString.split('=')[1];
      const expirationDate = new Date(expirationValueString);
      const currentTimeInMillis = Date.now();

      // if the cookie has expired, we skip it
      if (expirationDate.getTime() < currentTimeInMillis) {
        continue;
      }
    }

    cookieArrayToReturn.push(currentCookieString);
  }

  await Preferences.set({
    key: STORAGE_KEY,
    value: JSON.stringify(cookieArrayToReturn),
  });

  return cookieArrayToReturn.join('; ');
}

async function setCookieToStorage(cookieString: string) {
  const cookieName = cookieString.split(';')[0].split('=')[0];
  const cookiesFromStorage = await Preferences.get({
    key: STORAGE_KEY,
  });
  let cookiesArray: string[] = [];

  if (cookiesFromStorage.value !== null) {
    const cookiesArrayFromStorage: string[] = JSON.parse(
      cookiesFromStorage.value
    );
    cookiesArray = cookiesArrayFromStorage;
  }

  let cookieIndex = -1;

  for (let i = 0; i < cookiesArray.length; i++) {
    const currentCookie = cookiesArray[i];

    if (currentCookie.indexOf(`${cookieName}=`) !== -1) {
      cookieIndex = i;
      break;
    }
  }

  if (cookieIndex !== -1) {
    cookiesArray[cookieIndex] = cookieString;
  } else {
    cookiesArray.push(cookieString);
  }

  await Preferences.set({
    key: STORAGE_KEY,
    value: JSON.stringify(cookiesArray),
  });
}

export const getCookieHandler = (
  original: CookieHandlerInterface
): CookieHandlerInterface => {
  return {
    ...original,
    ...(Capacitor.isNativePlatform()
      ? {
          getCookie: async () => {
            return await getCookiesFromStorage();
          },
          setCookie: async function (cookieString: string) {
            await setCookieToStorage(cookieString);
          },
        }
      : {}),
  };
};
