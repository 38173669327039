import styled, { css } from 'styled-components';
import { IonToolbar } from '@ionic/react';
import { UiKitColor, UiKitSize } from '../../types/uikit.types';

interface RootProps {
  padding: boolean;
  background?: UiKitColor;
  size?: UiKitSize;
}

const RootSmMixin = css`
  --min-height: ${({ theme: { spacing } }) => spacing(9)};
`;

export const Root = styled(IonToolbar)<RootProps>`
  & + & {
    --padding-top: 0;
  }
  --padding-bottom: ${({ padding, theme: { spacing } }) =>
    padding && spacing(4)};
  --padding-top: ${({ padding, theme: { spacing } }) => padding && spacing(4)};
  --padding-end: ${({ padding, theme: { spacing } }) => padding && spacing(4)};
  --padding-start: ${({ padding, theme: { spacing } }) =>
    padding && spacing(4)};
  --background: ${({ theme, background }) => theme.palette[background]};
  ${({ size }) => size === 'sm' && RootSmMixin}
`;
