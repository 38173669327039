import styled, { css } from 'styled-components';
import { IonTitle } from '@ionic/react';

interface RootProps {
  center: boolean;
}

const RootCenterMixin = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: -1;
`;

export const Root = styled(IonTitle)<RootProps>`
  padding-inline: ${({ theme: { spacing } }) => spacing(4)};
  ${({ center }) => center && RootCenterMixin}
  img {
    height: ${({ theme: { spacing } }) => spacing(5.5)} !important;
  }
`;
