import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import Session from 'supertokens-auth-react/recipe/session';

type UseSignOutReturnType = [
  {
    loading: boolean;
  },
  UseMutateFunction<void, unknown, void, unknown>
];

export const useSignOut = (): UseSignOutReturnType => {
  const { isLoading: loading, mutate } = useMutation<void, unknown, void>(
    () => {
      return Session.signOut();
    }
  );

  return [{ loading }, mutate];
};
