import { FC, PropsWithChildren } from 'react';
import { Root } from './ToolbarButtons.style';

type ToolbarButtonsProps = PropsWithChildren<{
  slot: 'start' | 'end';
}>;

export const ToolbarButtons: FC<ToolbarButtonsProps> = ({ slot, children }) => {
  return <Root slot={slot}>{children}</Root>;
};
