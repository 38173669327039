import moment from 'moment';
import { FC } from 'react';
import { Card, Button, useUtils, Reorder } from '@wearehelpful/ui-kit';
import { ADTO, useUpdatePatientToDo } from '@helpful/network';
import { useTranslation } from '@wearehelpful/ui-localization';
import { usePatient } from '@common/hooks';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { AnalyticsTrackEvents } from '@common/constants';
import { ToDoActionSheet } from '@common/features';
import { useHome } from '../../../../../hooks/useHome/useHome.hook';
import { HomeMode } from '../../Home.types';
import { getTodoIcon, getTodoIconColor } from './ToDoCard.utils';

interface ToDoCardProps extends ADTO.TodoLocalizedEntity {
  mode: HomeMode;
  transparent: boolean;
  onClick?: () => void;
}

export const ToDoCard: FC<ToDoCardProps> = ({
  mode,
  onClick,
  transparent,
  ...item
}) => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const [, { refetch }] = useHome();
  const { toast } = useUtils();
  const { track } = useAnalytics();
  const { id, title, status, planBenefit, template, shortDescription } = item;

  const { isLoading: isCompleting, mutate: complete } = useUpdatePatientToDo();

  const handleOnComplete = (todoId: string) => {
    complete(
      {
        id: patient.id,
        todoId,
        data: {
          completedAt: moment().toISOString(),
          status: 'completed',
        },
      },
      {
        onSuccess: () => {
          track({
            id: AnalyticsTrackEvents.HELPFUL_TODO_COMPLETED,
            properties: { title },
          }).finally(() => {
            toast({
              variant: 'success',
              message: t('notifications.todoSuccessfullyCompleted'),
            });
            refetch();
          });
        },
      }
    );
  };

  return (
    <Card
      divider
      variant="flat"
      onClick={onClick}
      background={transparent ? 'transparent' : 'white100'}
    >
      <Reorder.Handler>
        <Card.Icon
          color={getTodoIconColor({ mode })}
          name={getTodoIcon({ mode, planBenefit, template })}
        />
      </Reorder.Handler>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Content truncate={3}>{shortDescription}</Card.Content>
      {status !== 'completed' && (
        <Card.Footer>
          <Button
            size="xs"
            icon="check"
            block={false}
            variant="secondary"
            loading={isCompleting}
            disabled={mode === HomeMode.Reorder}
            onClick={() => handleOnComplete(id)}
          >
            {t('actions.complete')}
          </Button>
          <ToDoActionSheet
            {...item}
            onDeleted={refetch}
            disabled={mode === HomeMode.Reorder}
          />
        </Card.Footer>
      )}
    </Card>
  );
};
