/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ICaregiverControllerGetCaregiverParams,
  ICaregiverControllerGetCaregiversParams,
  ICaregiverDto,
  ICaregiverEntity,
  ICaregiverPartialDto,
  IIdString,
  IList,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Caregivers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description List caregivers
   *
   * @tags Caregivers
   * @name CaregiverControllerGetCaregivers
   * @request GET:/api/v1/caregivers
   * @secure
   */
  caregiverControllerGetCaregivers = (query: ICaregiverControllerGetCaregiversParams, params: RequestParams = {}) =>
    this.http.request<
      IList & {
        data?: ICaregiverEntity[];
      },
      any
    >({
      path: `/api/v1/caregivers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Read caregiver
   *
   * @tags Caregivers
   * @name CaregiverControllerGetCaregiver
   * @request GET:/api/v1/caregivers/{id}
   * @secure
   */
  caregiverControllerGetCaregiver = (
    { id, ...query }: ICaregiverControllerGetCaregiverParams,
    params: RequestParams = {}
  ) =>
    this.http.request<ICaregiverEntity, any>({
      path: `/api/v1/caregivers/${id}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Update caregiver fully
   *
   * @tags Caregivers
   * @name CaregiverControllerPutCaregiver
   * @request PUT:/api/v1/caregivers/{id}
   * @secure
   */
  caregiverControllerPutCaregiver = (id: string, data: ICaregiverDto, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/caregivers/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Update caregiver partially
   *
   * @tags Caregivers
   * @name CaregiverControllerPatchCaregiver
   * @request PATCH:/api/v1/caregivers/{id}
   * @secure
   */
  caregiverControllerPatchCaregiver = (id: string, data: ICaregiverPartialDto, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/caregivers/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
