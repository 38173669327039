import { FC, PropsWithChildren, useRef } from 'react';
import { Tour, TourInstance } from '@wearehelpful/ui-kit';
import { useUpdateEffect } from 'react-use';
import { useTour } from './NavTour.hooks';

type NavTourProps = PropsWithChildren<{}>;

export const NavTour: FC<NavTourProps> = ({ children }) => {
  const tour = useRef<TourInstance>();
  const [{ steps, loading }, { onNext, onClose }] = useTour();

  useUpdateEffect(() => {
    if (!loading) setTimeout(tour.current.start, 300);
  }, [loading]);

  return (
    <Tour ref={tour} steps={steps} onNext={onNext} onClose={onClose}>
      {children}
    </Tour>
  );
};
