import styled from 'styled-components';
import { Typography } from '@core/ui';

const { Heading3, Body2 } = Typography;

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Title = styled(Heading3)`
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Message = styled(Body2)`
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing(10)};
`;

export const Picture = styled('div')`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: ${({ theme: { spacing } }) => spacing(6)};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
