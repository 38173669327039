import { FC, PropsWithChildren } from 'react';
import { UiKitColor } from '../../../types/uikit.types';
import { Root } from './CardThumb.style';

type CardThumbProps = PropsWithChildren<{
  background?: UiKitColor;
}>;

export const CardThumb: FC<CardThumbProps> = ({ children, background }) => {
  return <Root background={background}>{children}</Root>;
};
