import styled, { css } from 'styled-components';
import { IonItem } from '@ionic/react';
import { UiKitColor } from '@core/ui/types/uikit.types';
import { Root as Thumb } from '../ListThumb/ListThumb.style';

const RootDisabledMixin = css`
  opacity: 1;
  --color: ${({ theme }) => theme.palette.neutral500};
  --background: ${({ theme }) => theme.palette.neutral100};
  ${Thumb} {
    color: ${({ theme }) => theme.palette.brown400};
  }
`;

const RootDisableHoverMixin = css`
  pointer-events: none;
  --background-hover: var(--background);
`;

interface RootProps {
  disableHover?: boolean;
  background?: UiKitColor;
}

export const Root = styled(IonItem)<RootProps>`
  ${({ disabled }) => disabled && RootDisabledMixin}
  ${({ disableHover }) => disableHover && RootDisableHoverMixin}
  --background: ${({ theme, background }) => theme.palette[background]};
`;
