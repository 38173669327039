import { FC, PropsWithChildren } from 'react';
import { useGetRootQuery } from '@helpful/network';
import { usePatient } from '@common/hooks';
import { RootContext } from '../../contexts/Root.context';

type RootProviderProps = PropsWithChildren<{}>;

export const RootProvider: FC<RootProviderProps> = ({ children }) => {
  const [{ plans }] = usePatient();

  const { data: root, isFetching: isRootLoading } = useGetRootQuery(
    {
      prompts: ['home_page', 'more', 'medical'],
    },
    {
      enabled: !!plans.length,
      initialData: {
        promptCollection: {
          items: [],
        },
        frequentlyAskedQuestionCollection: {
          items: [],
        },
        callToActionCollection: {
          items: [],
        },
      },
    }
  );

  return (
    <RootContext.Provider
      value={{
        isRootLoading,
        prompts: root.promptCollection,
        faq: root.frequentlyAskedQuestionCollection,
        ctas: root.callToActionCollection,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};
