import {
  ReactElement,
  PropsWithChildren,
  useRef,
  useLayoutEffect,
} from 'react';
import { UiKitColor, UiKitSize } from '../../types/uikit.types';
import { widthConstrained } from '../ContentFixed/ContentFixed.style';
import { ToolbarTitle } from './ToolbarTitle/ToolbarTitle';
import { ToolbarBack } from './ToolbarBack/ToolbarBack';
import { ToolbarButtons } from './ToolbarButtons/ToolbarButtons';
import { ToolbarButton } from './ToolbarButton/ToolbarButton';
import { ToolbarClose } from './ToolbarClose/ToolbarClose';
import { ToolbarSearch } from './ToolbarSearch/ToolbarSearch';
import { ToolbarProgress } from './ToolbarProgress/ToolbarProgress';
import { ToolbarIconButton } from './ToolbarIconButton/ToolbarIconButton';
import { Root } from './Toolbar.style';

type ToolbarProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
  background?: UiKitColor;
  size?: UiKitSize;
}>;

interface ToolbarComponent {
  (props: ToolbarProps): ReactElement<any, any> | null;
  Back: typeof ToolbarBack;
  Title: typeof ToolbarTitle;
  Buttons: typeof ToolbarButtons;
  Button: typeof ToolbarButton;
  Close: typeof ToolbarClose;
  Search: typeof ToolbarSearch;
  Progress: typeof ToolbarProgress;
  IconButton: typeof ToolbarIconButton;
}

export const Toolbar: ToolbarComponent = ({
  size,
  padding,
  children,
  className,
  background,
}) => {
  const ref = useRef<HTMLIonToolbarElement>();

  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
    .toolbar-container {${widthConstrained.join('')}}
    .toolbar-background {height: 101%;}`;
    ref.current?.shadowRoot.appendChild(style);
  }, []);

  return (
    <Root
      ref={ref}
      size={size}
      padding={padding}
      className={className}
      background={background}
    >
      {children}
    </Root>
  );
};

Toolbar.Back = ToolbarBack;
Toolbar.Title = ToolbarTitle;
Toolbar.Buttons = ToolbarButtons;
Toolbar.Button = ToolbarButton;
Toolbar.Close = ToolbarClose;
Toolbar.Search = ToolbarSearch;
Toolbar.Progress = ToolbarProgress;
Toolbar.IconButton = ToolbarIconButton;
