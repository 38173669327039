import { useContextSelector } from 'use-context-selector';
import { CDTO, ADTO } from '@helpful/network';
import { useUser } from '@core/user';
import { RootContext } from '../../contexts/Root.context';

type UseMoreReturnType = [
  {
    loading: boolean;
    connections: ADTO.ConnectionEntity[];
    faqs: DeepPartial<CDTO.FrequentlyAskedQuestion>[];
  }
];

export const useMore = (): UseMoreReturnType => {
  const [{ connections }] = useUser();

  const isRootLoading = useContextSelector(RootContext, ({ isRootLoading }) => {
    return isRootLoading;
  });

  const faqs = useContextSelector(RootContext, ({ faq: { items } }) => {
    return items;
  });

  return [{ faqs, connections, loading: isRootLoading }];
};
