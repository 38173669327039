import styled, { css } from 'styled-components';
import { IonButton, IonSpinner } from '@ionic/react';
import { ButtonVariant } from './Button.types';

interface RootProps {
  variant: ButtonVariant;
}

const RootPrimaryMixin = css`
  --background: ${({ theme }) => theme.palette.periwinkle300};
  --background-hover: ${({ theme }) => theme.palette.periwinkle200};
  --background-activated: ${({ theme }) => theme.palette.periwinkle400};
  --background-activated-opacity: 1;
`;

const RootSecondaryMixin = css`
  --border-width: 1px;
  --border-color: ${({ theme }) => theme.palette.brown400};
  --background-activated: ${({ theme }) => theme.palette.neutral100};
  --background-activated-opacity: 1;
`;

const RootTertiaryMixin = css`
  --background: ${({ theme }) => theme.palette.orange400};
  --background-hover: ${({ theme }) => theme.palette.orange200};
  --background-activated: ${({ theme }) => theme.palette.orange300};
  --background-activated-opacity: 1;
`;

const RootSmallMixin = css`
  line-height: ${({ theme }) => theme.fontSize(20)};
  box-sizing: border-box;
  min-height: ${({ theme: { spacing } }) => spacing(8.5)};
  --padding-end: ${({ theme: { spacing } }) => spacing(3)};
  --padding-start: ${({ theme: { spacing } }) => spacing(3)};
  height: auto;
  font-size: ${({ theme }) => theme.fontSize(14)};
`;

export const Root = styled(IonButton)<RootProps>`
  margin: 0;
  --opacity: 1;
  position: relative;
  --box-shadow: none;
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(24)};
  font-weight: 700;
  min-height: ${({ theme: { spacing } }) => spacing(13)};
  height: auto;
  white-space: normal;
  --padding-top: ${({ theme: { spacing } }) => spacing(4)};
  --padding-end: ${({ theme: { spacing } }) => spacing(4)};
  --padding-start: ${({ theme: { spacing } }) => spacing(4)};
  --padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  --border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  --color: ${({ theme }) => theme.palette.brown400};
  ${({ variant }) => variant === 'primary' && RootPrimaryMixin}
  ${({ variant }) => variant === 'secondary' && RootSecondaryMixin}
  ${({ variant }) => variant === 'tertiary' && RootTertiaryMixin}
  ${({ size }) => size === 'small' && RootSmallMixin}
  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(2)};
  }
`;

export const Label = styled('span').withConfig({
  shouldForwardProp: (prop) => !['loading'].includes(prop),
})<{ loading: boolean }>`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

export const Spinner = styled(IonSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -14px;
  margin-left: -14px;
`;
