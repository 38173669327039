import { has } from 'lodash';

export function decoder(value: string) {
  const keywords: Record<string, boolean | undefined | null> = {
    false: false,
    null: null,
    true: true,
    undefined: undefined,
  };

  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value);
  }

  if (has(keywords, value)) {
    return keywords[value];
  }

  return decodeURI(value);
}
