import { forwardRef, useLayoutEffect } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { IonInput } from '@ionic/react';
import { BaseFormItemProps } from '../Form.types';
import { FormItem } from '../FormItem/FormItem';
import { useInput } from '../Form.hooks';
import { Root } from './FormInput.style';

interface FormInputProps extends BaseFormItemProps {
  type?: 'text' | 'email';
  autocomplete?: Parameters<typeof IonInput>['0']['autocomplete'];
  autocapitalize?: Parameters<typeof IonInput>['0']['autoCapitalize'];
}

export const FormInput = forwardRef<{}, FormInputProps>(function FormInput(
  {
    label,
    tabIndex,
    placeholder,
    autocomplete,
    type = 'text',
    autocapitalize,
    disabled = false,
    autofocus = false,
    name: initialName,
    enterKeyHint = 'next',
  },
  _
) {
  const input = useInput({
    tabIndex,
    autofocus,
    enterKeyHint,
  });

  const [{ name, value, onChange, onBlur }] = useField(
    castArray(initialName).join('.')
  );

  useLayoutEffect(() => {
    const el = input.current;
    const handler: EventListenerOrEventListenerObject = (e) => {
      setTimeout(() => onChange(e), 0);
    };
    el?.addEventListener('change', handler);
    return () => el?.removeEventListener('change', handler);
  }, [input, onChange]);

  return (
    <FormItem name={name} label={label}>
      <Root
        ref={input}
        type={type}
        name={name}
        value={value}
        onIonBlur={onBlur}
        disabled={disabled}
        onIonInput={onChange}
        placeholder={placeholder}
        enterkeyhint={enterKeyHint}
        autocomplete={autocomplete}
        autoCapitalize={autocapitalize}
      />
    </FormItem>
  );
});
