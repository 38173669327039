import { createRoot } from 'react-dom/client';
import { ErrorBoundaryProvider } from '@wearehelpful/ui-stats';
import { ErrorBoundary } from '@core/ui';
import { Bootstrap, BootstrapProps } from '@common/features';
import {
  Bootstrap as Bs,
  BootstrapProps as BsProps,
} from '@bootstrap/components';
import { createBrowserHistory } from '@core/navigation';
import { App } from './App';
import '@wearehelpful/ui-kit/dist/style.css';

const container = document.getElementById('root');

const root = createRoot(container!);

// Deploy

interface AppProps extends BootstrapProps, BsProps {
  build: string;
}

const HISTORY = createBrowserHistory();

const APP_CONFIG: AppProps = {
  build: process.env.REACT_APP_BUILD,
  config: {
    host: process.env.REACT_APP_APP_HOST,
    build: process.env.REACT_APP_BUILD,
    environment: process.env.REACT_APP_ENVIRONMENT,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_HOST,
  },
  network: {
    baseUrl: process.env.REACT_APP_APP_HOST,
  },
  auth: {
    host: process.env.REACT_APP_API_HOST,
    base: process.env.REACT_APP_API_BASE,
  },
  contentful: {
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    baseUrl: process.env.REACT_APP_CONTENTFUL_URI,
    token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  },
  app: {
    host: process.env.REACT_APP_APP_HOST,
  },
  analytics: {
    version: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    dataUrl: process.env.REACT_APP_RUDDERSTACK_DATA_URL,
    writeKey: process.env.REACT_APP_RUDDERSTACK_WRITE_KEY,
    facebook: {
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      clientToken: process.env.REACT_APP_FACEBOOK_CLIENT_TOKEN,
    },
  },
  notifications: {
    host: process.env.REACT_APP_CUSTOMERIO_HOST_LAMBDA,
  },
  localization: {
    version: process.env.REACT_APP_VERSION,
    url: process.env.REACT_APP_TRANSLATIONS_URL,
  },
  flags: {
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
    environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT,
    url: process.env.REACT_APP_UNLEASH_URL,
  },
  history: HISTORY,
};

root.render(
  <ErrorBoundaryProvider
    dsn={process.env.REACT_APP_SENTRY_DNS}
    release={process.env.REACT_APP_VERSION}
    environment={process.env.REACT_APP_ENVIRONMENT}
    fallback={<ErrorBoundary />}
  >
    <Bs {...APP_CONFIG}>
      <Bootstrap {...APP_CONFIG}>
        <App />
      </Bootstrap>
    </Bs>
  </ErrorBoundaryProvider>
);
