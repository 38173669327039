import { FC } from 'react';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Icon } from '@wearehelpful/ui-kit';
import { Root, Title, Cta } from './StackCarouselCta.style';

interface StackCarouselCtaProps {
  title: string;
  background?: string;
  onClick: () => void;
}

export const StackCarouselCta: FC<StackCarouselCtaProps> = ({
  title,
  onClick,
  background,
}) => {
  const { t } = useTranslation();

  return (
    <Root background={background} onClick={onClick}>
      <Title bold background={background}>
        {title}
      </Title>
      <Cta bold background={background}>
        {t('actions.seeFullSeries')}
        <Icon name="arrow-right" />
      </Cta>
    </Root>
  );
};
