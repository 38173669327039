import { FC, PropsWithChildren } from 'react';
import { BulletPointsItemCheckbox } from './BulletPointsItemCheckbox/BulletPointsItemCheckbox';
import { Root } from './BulletPointsItem.style';

type BulletPointsItemProps = PropsWithChildren<{}>;

export const BulletPointsItem: FC<BulletPointsItemProps> = ({ children }) => {
  return (
    <Root>
      <BulletPointsItemCheckbox />
      {children}
    </Root>
  );
};
