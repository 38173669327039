import { useContext } from 'use-context-selector';
import { CDTO } from '@helpful/network';
import {
  GuidesContext,
  RefetchFunction,
  FetchNextPageFunction,
} from '../../contexts/Guides.context';

interface UseGuidesState {
  total?: number;
  query?: string;
  loading: boolean;
  hasNextPage: boolean;
  stacks: DeepPartial<CDTO.Stack>[];
  guides: DeepPartial<CDTO.Training>[];
}

interface UseGuidesDispatch {
  filter: (v: string) => void;
  refetch: RefetchFunction;
  fetchNextPage: FetchNextPageFunction;
}

type UseGuidesReturnType = [UseGuidesState, UseGuidesDispatch];

export const useGuides = (): UseGuidesReturnType => {
  const {
    stacks,
    guides,
    total,
    refetch,
    loading,
    filter,
    query,
    fetchNextPage,
    hasNextPage,
  } = useContext(GuidesContext);

  return [
    { total, guides, loading, query, hasNextPage, stacks },
    { refetch, filter, fetchNextPage },
  ];
};
