import styled from 'styled-components';
import { IonItem } from '@ionic/react';
import { FormItem as DefaultFormItem } from '../FormItem/FormItem';
import { Typography } from '../../Typography/Typography';

export const Item = styled(IonItem)`
  --border-color: transparent;
`;

export const FormItem = styled(DefaultFormItem)`
  --border-color: transparent;
`;

export const ItemBody2 = styled(Typography.Body2)`
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

export const Thumbnail = styled('img')`
  object-fit: contain;
  width: ${({ theme }) => theme.spacing(30.5)};
`;

export const Root = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(6.5)}
    ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  width: 100%;
  &::after {
    border-radius: 2px;
    position: absolute;
    display: block;
    content: '';
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='rgb(209, 213, 219)' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    background-clip: content-box;
  }
`;

export const Prompt = styled(Typography.Body2)<{
  areFilesSelected: boolean;
}>`
  color: ${({ areFilesSelected, theme }) =>
    areFilesSelected ? theme.palette.brown400 : theme.palette.neutral500};
`;
