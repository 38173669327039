import { createContext } from 'use-context-selector';
import { type ADTO } from '@helpful/network';
import { noop } from 'lodash';

export interface UserContextProps {
  user: ADTO.CaregiverEntity;
  loading: boolean;
  refetch: () => void;
  connections: ADTO.ConnectionEntity[];
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  loading: false,
  refetch: noop,
  connections: [],
});
