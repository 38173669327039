import styled from 'styled-components';
import { Typography, Content, Button, Header } from '@wearehelpful/ui-kit';
import { Toolbar } from '@core/ui';

export const Hint = styled(Typography)`
  margin-top: ${({ theme: { spacing } }) => spacing(1)};
`;

export const GradientHeader = styled(Header)`
  & > ion-toolbar {
    --background: linear-gradient(
      180deg,
      #5a6dd3,
      #6578df,
      #7385e9,
      #8193f1,
      #92a2f8
    ) !important;
  }
`;

export const ContentSubheader = styled(Content.Subheader)`
  padding-top: ${({ theme: { spacing } }) => spacing(1)};
`;

export const HistoryList = styled('ul')`
  list-style: none;
  margin: 0px;
  padding: ${({ theme: { spacing } }) => spacing(4)};
  & > li {
    display: flex;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing(2.5)};
    padding: ${({ theme: { spacing } }) => spacing(2.5)} 0;
    cursor: pointer;
  }
`;

export const HistoryItem = styled(Button)`
  width: 100%;
  & > span {
    display: inline-flex !important;
    gap: ${({ theme: { spacing } }) => spacing(2.5)};
    margin-right: auto;
    text-align: left;
  }
`;

export const ContentFixed = styled(Content.Fixed)`
  background: ${({ theme: { palette } }) => palette.white100};
  min-height: 100%;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.07);
`;

export const AnchorsToolbar = styled(Toolbar)`
  position: sticky;
  top: 0;
  background-color: ${({ theme: { palette } }) => palette.white100};
  & > div {
    padding-bottom: 0px;
  }
`;
