import { useContextSelector } from 'use-context-selector';
import { useCommon } from '@common/hooks';
import { ADTO } from '@helpful/network';
import { useRoute } from '@core/navigation';
import { SignUpContext } from '../SignUp.context';
import { PlanForm } from './Plan.types';

type UseVerifyEmailReturnType = [
  {
    payor: string;
    config: ADTO.PayorEntity;
    plans: ADTO.PlanEntity[];
    supplementalPlans: ADTO.PlanEntity[];
    notSuplementalPlans: ADTO.PlanEntity[];
    defaultValues: PlanForm;
  }
];

export const useVerifyEmailPlan = (): UseVerifyEmailReturnType => {
  const [{ payors }] = useCommon();

  const [{ search }] = useRoute<{}, { payor: string }>();

  const config = useContextSelector(SignUpContext, ({ payors }) => {
    return payors.find(({ slug }) => slug === search.payor);
  });

  const plans = useContextSelector(SignUpContext, ({ plans }) =>
    plans.filter(({ payor: { slug } }) => slug === search.payor)
  );

  const notSuplementalPlans = plans.filter(
    ({ isSupplemental }) => !isSupplemental
  );

  const supplementalPlans = plans.filter(
    ({ isSupplemental }) => isSupplemental
  );

  const payor = payors.find(({ slug }) => slug === search.payor)?.name;

  const defaultValues: PlanForm = {
    plan: (() => {
      if (!config?.isMultiSupplemental) {
        return plans?.find(({ isCompulsory }) => isCompulsory)?.id;
      }
    })(),
    plans: [],
    supplementals: (() => {
      if (config?.isMultiSupplemental) {
        return plans
          ?.filter(({ isCompulsory }) => isCompulsory)
          .map(({ id }) => id);
      }
      return [];
    })(),
  };

  return [
    {
      plans,
      payor,
      config,
      defaultValues,
      notSuplementalPlans,
      supplementalPlans,
    },
  ];
};
