import styled from 'styled-components';

export const Root = styled('button')<{ active: boolean }>`
  white-space: nowrap;
  color: ${({ theme: { palette } }) => palette.brown400};
  text-align: center;
  font-family: Inter;
  font-size: ${({ theme }) => theme.fontSize(14)};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: ${({ theme: { spacing } }) => spacing(3)};
  padding-left: ${({ theme: { spacing } }) => spacing(3)};
  padding-top: ${({ theme: { spacing } }) => spacing(1.5)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(1.5)};
  border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  background-color: ${({ active, theme }) =>
    active ? theme.palette.periwinkle300 : theme.palette.periwinkle100};
`;
