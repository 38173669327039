import { FC, PropsWithChildren, Key } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { FormGroupItem } from '../FormGroupItem/FormGroupItem';
import { BaseFormItemProps } from '../Form.types';
import { List } from './FormCheckboxGroup.style';
import { FormCheckboxGroupContext } from './FormCheckboxGroup.context';

type FormCheckboxGroupProps = PropsWithChildren<BaseFormItemProps>;

export const FormCheckboxGroup: FC<FormCheckboxGroupProps> = ({
  children,
  name: initialName,
}) => {
  const [{ name, value }, , { setValue }] = useField<Key[]>({
    multiple: true,
    type: 'chackbox',
    name: castArray(initialName).join('.'),
  });

  return (
    <FormCheckboxGroupContext.Provider value={{ name, value, setValue }}>
      <FormGroupItem name={name}>
        <List lines="full">{children}</List>
      </FormGroupItem>
    </FormCheckboxGroupContext.Provider>
  );
};
