import { useAsyncFn, useLocalStorage } from 'react-use';
import { Capacitor } from '@core/ui';

interface UseGetStartedState {
  loading: boolean;
  data: boolean;
}

type UseGetStartedDispatch = () => Promise<boolean>;

type UseGetStartedReturnType = [UseGetStartedState, UseGetStartedDispatch];

export const useGetStarted = (): UseGetStartedReturnType => {
  const [{ visited }, setVisited] = useLocalStorage<{ visited: boolean }>(
    '@@helpfull:welcome',
    {
      visited: false,
    }
  );

  const [{ value: data, loading }, dispatch] = useAsyncFn<
    () => Promise<boolean>
  >(
    async () => {
      const visited = Capacitor.isNativePlatform() ? true : false;
      setVisited({ visited });
      return Promise.resolve(true);
    },
    [],
    {
      loading: false,
      value: visited,
    }
  );

  return [{ loading, data }, dispatch];
};
