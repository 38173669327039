import { FC, PropsWithChildren } from 'react';
import type { ADTO } from '@helpful/network';
import { UserContext } from '../../contexts/User.context';

type UserProviderProps = PropsWithChildren<{
  user: ADTO.CaregiverEntity;
  connections: ADTO.ConnectionEntity[];
  loading: boolean;
  refetch: () => void;
}>;

export const UserProvider: FC<UserProviderProps> = ({
  user,
  connections,
  refetch,
  children,
  loading,
}) => {
  return (
    <UserContext.Provider value={{ user, connections, loading, refetch }}>
      {children}
    </UserContext.Provider>
  );
};
