import { FC, useRef } from 'react';
import {
  Page,
  Form,
  Header,
  Content,
  useUtils,
  FormInstance,
  Footer,
} from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useCreatePatientTodo } from '@helpful/network';
import { usePatient } from '@common/hooks';
import { Redirect, useNavigationPrompt } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { SHORT_DESCRIPTION_MAX_LENGTH } from '../ToDos.const';

export const Create: FC = () => {
  const { t } = useTranslation();
  const { toast } = useUtils();
  const [{ patient }] = usePatient();
  const form = useRef<FormInstance>();

  const { isSuccess, isLoading, mutate } = useCreatePatientTodo();

  const handleOnSubmit = (data: ADTO.TodoPatientDto) => {
    mutate(
      {
        data: data,
        id: patient.id,
      },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            message: t('notifications.todoSuccessfullyCreated'),
          });
        },
      }
    );
  };

  const shortDescriptionHelp = (value: string = '') => {
    return t('hints.countOfCharactersRemaining', {
      value: SHORT_DESCRIPTION_MAX_LENGTH - value.length,
    });
  };

  useNavigationPrompt(() => form?.current?.hasUnsavedChanges());

  if (isSuccess) {
    return <Redirect path={RoutePath.ROOT_HOME} />;
  }

  return (
    <Page>
      <Form ref={form} onSubmit={handleOnSubmit}>
        <Header>
          <Header.Back defaultHref={RoutePath.ROOT_HOME} />
          <Header.Actions>
            <Form.Submit checkValidity loading={isLoading}>
              {t('actions.save')}
            </Form.Submit>
          </Header.Actions>
        </Header>
        <Content>
          <Content.Fixed>
            <Form.Text
              autofocus
              name="title"
              label={t('forms.title')}
              validation={{ required: true }}
            />
            <Form.Textrea
              rows={18}
              enterKeyHint="go"
              name="shortDescription"
              validation={{
                required: true,
                maxLength: SHORT_DESCRIPTION_MAX_LENGTH,
              }}
              label={t('forms.enterYourText')}
              help={({ value }) => shortDescriptionHelp(value)}
            />
          </Content.Fixed>
        </Content>
        <Footer />
      </Form>
    </Page>
  );
};
