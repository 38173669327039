import { FC } from 'react';
import { ADTO } from '@helpful/network';
import { Form } from '@wearehelpful/ui-kit';

interface MultiPlanNotSuplimentalProps {
  plans: ADTO.PlanEntity[];
  config: ADTO.PayorEntity;
}

export const MultiPlanNotSuplimental: FC<MultiPlanNotSuplimentalProps> = ({
  config,
  plans,
}) => {
  const notSuplementalPlans = plans.filter(
    ({ isSupplemental }) => !isSupplemental
  );

  if (config?.isMultiPlan && !!notSuplementalPlans.length) {
    return (
      <Form.CheckboxGroup name="plans" validation={{ required: true }}>
        {notSuplementalPlans.map(({ id, name }) => {
          return (
            <Form.CheckboxGroup.Item key={id} value={id}>
              {name}
            </Form.CheckboxGroup.Item>
          );
        })}
      </Form.CheckboxGroup>
    );
  }

  return null;
};
