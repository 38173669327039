import { get } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { AuthContext } from '../../contexts/Auth.context';

type UseSessionReturnType = [
  {
    userId: string;
    isSessionExist: boolean;
    isUserVerified: boolean;
  }
];

export const useSession = (): UseSessionReturnType => {
  const context = useSessionContext();

  const isSessionExist = useContextSelector(
    AuthContext,
    ({ isSessionExist }) => isSessionExist
  );

  const isUserVerified = useContextSelector(
    AuthContext,
    ({ isUserVerified }) => isUserVerified
  );

  const userId = get(context, 'userId');

  return [{ userId, isSessionExist, isUserVerified }];
};
