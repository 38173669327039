import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Root } from './ContentFixed.style';

type ContentFixedProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
}>;

export const ContentFixed: FC<ContentFixedProps> = ({
  padding,
  children,
  className,
}) => {
  return (
    <Root className={classNames(className, { 'ion-padding': padding })}>
      {children}
    </Root>
  );
};
