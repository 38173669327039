import styled, { css } from 'styled-components';
import { IonCard } from '@ionic/react';
import { UiKitSize } from '../../types/uikit.types';
import { WITH_ICON_PADDING_MAP } from './Card.const';

interface RootProps {
  flat: boolean;
  icon?: UiKitSize;
  divider: boolean;
  clickable: boolean;
}

const RootFlatMixin = css`
  margin: 0;
  border-radius: 0;
  box-shadow: none;
`;

const WithIconMixin = css<RootProps>`
  padding-left: ${({ theme: { spacing }, icon }) =>
    spacing(WITH_ICON_PADDING_MAP[icon])};
`;

const WithDividerMixin = css<RootProps>`
  margin-bottom: 1px;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.palette.neutral300};
`;

const ClickableMixin = css<RootProps>`
  cursor: pointer;
`;

export const Root = styled(IonCard)<RootProps>`
  margin-inline: 0;
  margin: ${({ theme: { spacing } }) => spacing(4)} 0;
  border-radius: ${({ theme: { spacing } }) => spacing(0.5)};
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.neutral300};
  --color: ${({ theme }) => theme.palette.brown400};
  ${({ flat }) => flat && RootFlatMixin}
  ${({ icon }) => icon && WithIconMixin}
  ${({ divider }) => divider && WithDividerMixin}
  ${({ clickable }) => clickable && ClickableMixin}
`;
