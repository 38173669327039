import { FC } from 'react';
import {
  CDTO,
  ContentfulImage,
  useInfiniteGetExperiencesQuery,
} from '@helpful/network';
import { useRoute } from '@core/navigation';
import {
  RoutePath,
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import {
  Page,
  Content,
  Header,
  Typography,
  Form,
  Skeleton,
  InfiniteScroll,
} from '@wearehelpful/ui-kit';
import { Footer, Toolbar } from '@core/ui';
import { CareplanIndexForm } from './Index.types';
import { ItemTitle } from './Index.style';

export const Index: FC = () => {
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const {
    isLoading,
    hasNextPage,
    fetchNextPage,
    data: { pages },
  } = useInfiniteGetExperiencesQuery(
    'skip',
    { limit: 25 },
    {
      select: ({ pages, pageParams }) => ({
        pages: pages.flatMap((item) => item.experienceCollection.items),
        pageParams,
      }),
      initialData: {
        pages: [],
        pageParams: [],
      },
      getNextPageParam: (lastPage, allPages) => {
        const skipExperiences = allPages.reduce(
          (res, { experienceCollection: { items } }) => res + items.length,
          0
        );

        if (lastPage?.experienceCollection?.total > skipExperiences)
          return { skip: allPages.length * 25 };
      },
    }
  );

  const handleOnSubmit = ({ experienceId }: CareplanIndexForm) => {
    const listIndex = pages.findIndex(({ sys: { id } }) => id === experienceId);
    const position = listIndex + 1;

    track({
      id: AnalyticsTrackEvents.CAREPLAN_EXPERIENCE_SELECTED,
      properties: { id: experienceId, title: pages[listIndex].title, position },
    }).finally(() => {
      push({
        path: RoutePath.CAREPLAN_SELECT,
        params: { id: experienceId },
      });
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CAREPLAN}
      name={AnalyticsPageCategory.EXPERIENCE_SELECTION}
    >
      <Page>
        <Form<CareplanIndexForm> onSubmit={handleOnSubmit}>
          <Header>
            <Header.Close defaultHref={RoutePath.ROOT_HOME} />
          </Header>
          <Content>
            <InfiniteScroll onScroll={fetchNextPage} disabled={!hasNextPage}>
              <Content.Subheader divider gap="md">
                <Typography variant="h2">
                  {t('subheadings.careplan.title')}
                </Typography>
                <Typography variant="p1">
                  {t('subheadings.careplan.description')}
                </Typography>
              </Content.Subheader>
              <Content.Fixed>
                <Skeleton variant="list" loading={isLoading}>
                  <Form.RadioGroup
                    name="experienceId"
                    validation={{ required: true }}
                  >
                    {pages?.map(({ sys: { id }, title, experienceImage }) => {
                      return (
                        <Form.RadioGroup.Item key={id} value={id}>
                          {experienceImage && (
                            <ContentfulImage
                              url={experienceImage.url}
                              width={68}
                              height={40}
                              alt={title}
                              fit={CDTO.ImageResizeStrategy.Pad}
                            />
                          )}
                          <ItemTitle variant="p2">{title}</ItemTitle>
                        </Form.RadioGroup.Item>
                      );
                    })}
                  </Form.RadioGroup>
                </Skeleton>
              </Content.Fixed>
            </InfiniteScroll>
          </Content>
          <Footer>
            <Toolbar padding>
              <Form.Submit checkValidity>{t('actions.next')}</Form.Submit>
            </Toolbar>
          </Footer>
        </Form>
      </Page>
    </TrackPageView>
  );
};
