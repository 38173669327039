import { useTranslation } from '@wearehelpful/ui-localization';
import moment from 'moment';
import { object, string, ObjectSchema, mixed, boolean } from 'yup';

type UseUploadParams = {
  fileSizeLimit: number;
};

type UseUploadReturnType = {
  validationSchema: ObjectSchema<any>;
};

export const useUpload = ({
  fileSizeLimit,
}: UseUploadParams): UseUploadReturnType => {
  const { t } = useTranslation();
  const validationSchema = object().shape({
    firstName: string()
      .label(t('forms.lovedOnesFirstName'))
      .required(
        t('validation.required', { label: t('forms.lovedOnesFirstName') })
      ),
    lastName: string()
      .label(t('forms.lovedOnesLastName'))
      .required(
        t('validation.required', { label: t('forms.lovedOnesLastName') })
      ),
    dateOfBirth: string()
      .label(t('forms.lovedOnesDateOfBirth'))
      .test({
        test: (value) => {
          return !!value ? moment(value, 'MM/DD/YYYY', true).isValid() : true;
        },
      })
      .required(
        t('validation.required', { label: t('forms.lovedOnesDateOfBirth') })
      )
      .nullable(),
    sexAtBirth: string()
      .label(t('forms.lovedOnesSexAtBirth'))
      .required(
        t('validation.required', { label: t('forms.lovedOnesSexAtBirth') })
      )
      .nullable(),
    dme: mixed()
      .test(
        'fileCount',
        t('forms.minimumFileCount'),
        (value) => value?.length === 1
      )
      .test(
        'fileSize',
        t('forms.maximumFileSize', { fileSize: fileSizeLimit }),
        (value) => {
          const fileSizeBytes = value?.[0].size ?? 0;
          const fileSizeMB = fileSizeBytes / Math.pow(1024, 2);

          return fileSizeMB <= fileSizeLimit;
        }
      ),
    consent: boolean()
      .required()
      .oneOf([true], t('validation.required', { label: t('forms.consent') })),
  });

  return { validationSchema };
};
