import { useContextSelector } from 'use-context-selector';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { InAppBrowser, ToolBarType } from '@capgo/inappbrowser';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { AuthContext } from '../../contexts/Auth.context';

type UseInAppRedirectReturnType = {
  open: (url: string) => void;
};

/**
 * Opens url using in-app-browser then catches a redirect back to the App,
 * intercepts the redirect, navigates to a received url and closes the browser.
 *
 * https://github.com/Cap-go/capacitor-inappbrowser
 */
export const useInAppRedirect = (): UseInAppRedirectReturnType => {
  const host = useContextSelector(AuthContext, ({ webDomain }) => webDomain);
  const location = useHistory();

  const openInAppBrowserWithRedirect = useCallback<
    UseInAppRedirectReturnType['open']
  >(
    (url) => {
      // Handle web App open url
      if (!Capacitor.isNativePlatform()) {
        window.location.replace(url);
        return;
      }

      // Override user agent for Google Sign In to avoid disallowed_useragent error
      const isGoogleSignInUp = url.includes('google');
      const headers = isGoogleSignInUp
        ? {
            'User-Agent': 'AppleWebKit/537.36 Chrome/56.0.0.0 Mobile',
          }
        : {};

      InAppBrowser.addListener('urlChangeEvent', (event) => {
        if (event.url.startsWith(host)) {
          InAppBrowser.close();

          const { search, pathname } = new URL(event.url);
          location.push({ search, pathname });
        }
      }).then(() => {
        // Workaround for Android to emit "urlChangeEvent"
        if (isGoogleSignInUp && isPlatform('android')) {
          InAppBrowser.openWebView({
            url,
            headers,
            title: '',
            toolbarType: ToolBarType.ACTIVITY,
          });
        } else {
          InAppBrowser.open({ url, headers });
        }
      });
    },
    [host, location]
  );

  return { open: openInAppBrowserWithRedirect };
};
