import { FC, useState } from 'react';
import {
  Toolbar,
  ContentFixed,
  Subheader,
  Typography,
  Form,
  Searchbar,
  Footer,
  Button,
  useForm,
  Page,
  Header,
} from '@core/ui';
import { ADTO, useGetSystemsInfinite } from '@helpful/network';
import { useRoute } from '@core/navigation';
import {
  RoutePath,
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useMedical, usePatient } from '@common/hooks';
import { Skeleton, Content, InfiniteScroll } from '@wearehelpful/ui-kit';
import { MedicalConnectIndexForm } from './Index.types';
import { Link } from './Index.style';
import { useValidationSchema } from './Index.hooks';

export const Index: FC = () => {
  const [, { push }] = useRoute();
  const [query, setQuery] = useState<string>('');
  const form = useForm<MedicalConnectIndexForm>();
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const patientFirstName = patient.firstName;
  const { track } = useAnalytics();
  const { validationSchema } = useValidationSchema();
  const [{ connections }] = useMedical();

  const {
    isLoading,
    hasNextPage,
    fetchNextPage,
    data = { pages: [], pageParams: [] },
  } = useGetSystemsInfinite(
    {
      skip: 0,
      take: 100,
      filter: { isActive: 'true' },
      order: 'name',
      search: {
        synonym: query?.length >= 3 ? query : undefined,
      },
    },
    {
      query: {
        getNextPageParam: ({ meta: { total } }, all) => {
          const len = all.reduce((res, { data }) => res + data.length, 0);
          if (total > len) return all.length * 100;
        },
      },
    }
  );

  const systems = data.pages.reduce(
    (res, { data }) => [...res, ...data],
    [] as ADTO.SystemEntity[]
  );

  const handleOnSubmit = ({ system: id }: MedicalConnectIndexForm) => {
    const reconnect = connections?.find(({ system }) => system.id === id);
    const system = systems.find(({ id: systemId }) => systemId === id);
    track({
      id: AnalyticsTrackEvents.MEDICAL_CONNECT_PROVIDER_SELECTED,
      properties: {
        systemId: id,
        systemName: system.name,
        reconnect: !!reconnect,
      },
    }).finally(() => {
      push({
        path: RoutePath.CONNECT_REDIRECT,
        params: { id },
      });
    });
  };

  const handleSearch = (query: string) => {
    setQuery(query);
    track({
      id: AnalyticsTrackEvents.MEDICAL_SEARCH,
      properties: {
        query: query.toLowerCase(),
      },
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CONNECT_MEDICAL}
      name={AnalyticsPageCategory.CONNECT_MEDICAL}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <InfiniteScroll onScroll={fetchNextPage} disabled={!hasNextPage}>
            <Subheader divider gap="md">
              <Typography.Heading2>
                {t('subheadings.connectMedical.title')}
              </Typography.Heading2>
              <Typography.Body1>
                {t('subheadings.connectMedical.description', {
                  name: patientFirstName,
                })}
                &nbsp;
                <Link path={RoutePath.CONTACT_US}>
                  {t('actions.contactHelpful')}
                </Link>
              </Typography.Body1>
              <Searchbar
                value={query}
                onSearch={handleSearch}
                placeholder={t('placeholders.searchMedicalPlaceholder')}
              />
            </Subheader>
            <ContentFixed>
              <Skeleton variant="list" loading={isLoading}>
                <Form
                  form={form}
                  onSubmit={handleOnSubmit}
                  validationSchema={validationSchema}
                >
                  <Form.RadioGroup name="system">
                    {systems?.map(({ id, name }) => {
                      return (
                        <Form.RadioGroupItem key={id} value={id}>
                          {name}
                        </Form.RadioGroupItem>
                      );
                    })}
                  </Form.RadioGroup>
                </Form>
              </Skeleton>
            </ContentFixed>
          </InfiniteScroll>
        </Content>
        <Footer>
          <Toolbar padding>
            <Button onClick={() => form.current?.submitForm()}>
              {t('actions.next')}
            </Button>
          </Toolbar>
        </Footer>
      </Page>
    </TrackPageView>
  );
};
