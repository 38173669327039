import { FC, useRef } from 'react';
import { Header, Page, Toolbar } from '@core/ui';
import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Content } from '@wearehelpful/ui-kit';
import { Back } from './Details.style';
import {
  BenefitsDetails,
  BenefitsDetailsInstanse,
} from './partitions/BenefitsDetails/BenefitsDetails';

export const Details: FC = () => {
  const { t } = useTranslation();
  const benefit = useRef<BenefitsDetailsInstanse>();
  const [
    {
      params: { slug },
    },
  ] = useRoute<{ slug: string }, {}>();

  const [, { push }] = useRoute();

  const handleClick = () => {
    push({ path: RoutePath.ROOT_BENEFITS });
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back defaultHref={RoutePath.ROOT_BENEFITS} />
          <Toolbar.Buttons slot="end">
            <Toolbar.IconButton
              name="share"
              onClick={() => benefit.current?.onShare()}
            />
          </Toolbar.Buttons>
        </Toolbar>
      </Header>
      <Content>
        <BenefitsDetails
          slug={slug}
          ref={benefit}
          category={AnalyticsPageCategory.BENEFIT}
        >
          <Back variant="secondary" onClick={handleClick}>
            {t('actions.goToBenefits')}
          </Back>
        </BenefitsDetails>
      </Content>
    </Page>
  );
};
