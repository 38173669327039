import { FC, PropsWithChildren } from 'react';
import { Root, Content } from './CardContent.style';

type CardContentProps = PropsWithChildren<{
  truncate?: number;
  className?: string;
}>;

export const CardContent: FC<CardContentProps> = ({
  children,
  truncate,
  className,
}) => {
  return (
    <Root className={className}>
      <Content truncate={truncate}>{children}</Content>
    </Root>
  );
};
