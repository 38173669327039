import { Button } from '@wearehelpful/ui-kit';
import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(2)}
    ${({ theme: { spacing } }) => spacing(4)};
`;

export const Buttons = styled('div')`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  flex-wrap: wrap;
  ion-button {
    height: 34px;
    & + ion-button {
      margin-top: 0 !important;
    }
  }
`;

export const SignInButton = styled(Button)<{ color?: string }>`
  --color: ${({ color }) => color} !important;
`;
