import styled from 'styled-components';
import { UiKitSize } from '../../../types/uikit.types';
import { ICON_ROOT_SIZE_MAP } from './CardIcon.const';

export const Root = styled('div')<{ size: UiKitSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { spacing }, size }) => spacing(ICON_ROOT_SIZE_MAP[size])};
  height: ${({ theme: { spacing }, size }) =>
    spacing(ICON_ROOT_SIZE_MAP[size])};
  border-radius: ${({ theme: { spacing } }) => spacing(1)};
  background-color: ${({ theme }) => theme.palette.periwinkle300};
  position: absolute;
  left: ${({ theme: { spacing } }) => spacing(4)};
  top: ${({ theme: { spacing } }) => spacing(4)};
`;
