import { useMemo } from 'react';
import { useLocale } from '@wearehelpful/ui-localization';
import { CDTO, useGetGlobalSearchQuery, useGetSearch } from '@helpful/network';
import { usePatient } from '@common/hooks';

interface UseSearchState {
  loading: boolean;
  total?: number;
  query?: string;
  benefits: DeepPartial<CDTO.PlanBenefit>[];
  guides: DeepPartial<CDTO.Training>[];
  stacks: DeepPartial<CDTO.Stack>[];
}

type UseSearchReturnType = [UseSearchState];

interface UseSearchParams {
  query?: string;
}

export const useSearch = ({ query }: UseSearchParams): UseSearchReturnType => {
  const [{ lng }] = useLocale();
  const [{ plans }] = usePatient();

  const { data, isFetching } = useGetSearch(
    ['benefits', 'guides', 'stacks'],
    {
      plans,
      query,
      limit: 10,
      autocut: 1,
      locale: lng,
    },
    {
      query: {
        enabled: !!query,
        initialData: {
          data: {
            benefits: [],
            guides: [],
            stacks: [],
          },
        },
      },
    }
  );

  const guidesIds = useMemo(
    () => data.data.guides.map(({ contentId }) => contentId),
    [data]
  );
  const benefitsIds = useMemo(
    () => data.data.benefits.map(({ contentId }) => contentId),
    [data]
  );
  const stacksIds = useMemo(
    () => data.data.stacks.map(({ contentId }) => contentId),
    [data]
  );
  const ids = useMemo(
    () => [...benefitsIds, ...guidesIds, ...stacksIds],
    [benefitsIds, guidesIds, stacksIds]
  );

  const { isLoading, data: results } = useGetGlobalSearchQuery(
    {
      plans,
      ids: query ? ids : undefined,
    },
    {
      enabled: !!query,
      initialData: {
        planBenefitCollection: {
          items: [],
        },
        trainingCollection: {
          items: [],
        },
        stackCollection: {
          items: [],
        },
      },
    }
  );

  const benefits = useMemo(
    () =>
      results.planBenefitCollection.items.sort(
        ({ sys: { id: idA } }, { sys: { id: idB } }) => {
          return benefitsIds.indexOf(idA) - benefitsIds.indexOf(idB);
        }
      ),
    [results, benefitsIds]
  );

  const guides = useMemo(
    () =>
      results.trainingCollection.items.sort(
        ({ sys: { id: idA } }, { sys: { id: idB } }) => {
          return guidesIds.indexOf(idA) - guidesIds.indexOf(idB);
        }
      ),
    [results, guidesIds]
  );

  const stacks = useMemo(
    () =>
      results.stackCollection.items.sort(
        ({ sys: { id: idA } }, { sys: { id: idB } }) => {
          return stacksIds.indexOf(idA) - stacksIds.indexOf(idB);
        }
      ),
    [results, stacksIds]
  );

  return [
    { loading: isLoading || isFetching, query, benefits, guides, stacks },
  ];
};
