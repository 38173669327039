import { FC, PropsWithChildren, useState, useCallback, useMemo } from 'react';
import {
  CDTO,
  useGetSearch,
  useInfiniteGetGuidesQuery,
} from '@helpful/network';
import { useLocale } from '@wearehelpful/ui-localization';
import { GuidesContext } from '../../contexts/Guides.context';
import { useDefaultParams } from './GuidesProvider.hooks';
import { GetGuidesQuery } from './GuidesProvider.types';

type GuidesProviderProps = PropsWithChildren<unknown>;

export const GuidesProvider: FC<GuidesProviderProps> = ({ children }) => {
  const { defaultParams } = useDefaultParams();
  const [{ lng }] = useLocale();

  const [{ query, ...variables }, setVariables] =
    useState<GetGuidesQuery>(defaultParams);

  const { data, isFetching } = useGetSearch(
    ['guides', 'stacks'],
    {
      query,
      limit: 25,
      autocut: 1,
      locale: lng,
    },
    {
      query: {
        enabled: !!query,
        initialData: {
          data: {
            stacks: [],
            guides: [],
          },
        },
      },
    }
  );

  const guidesIds = useMemo(
    () => data.data.guides.map(({ contentId }) => contentId),
    [data]
  );
  const stacksIds = useMemo(
    () => data.data.stacks.map(({ contentId }) => contentId),
    [data]
  );
  const ids = useMemo(
    () => [...guidesIds, ...stacksIds],
    [guidesIds, stacksIds]
  );

  const {
    refetch,
    isLoading,
    hasNextPage,
    fetchNextPage,
    data: { pages = [] } = {},
  } = useInfiniteGetGuidesQuery(
    'skipTranings',
    { ...variables, ids: query ? ids : undefined },
    {
      getNextPageParam: ({ trainingCollection: { total } }, all) => {
        const skipTranings = all.reduce(
          (res, { trainingCollection: { items } }) => res + items.length,
          0
        );
        const skipStacks = all.reduce(
          (res, { stackCollection: { items } }) => res + items.length,
          0
        );
        if (total > skipTranings) return { skipTranings, skipStacks };
      },
    }
  );

  const guides = useMemo(() => {
    const items = pages.reduce((res, { trainingCollection: { items } }) => {
      return [...res, ...items];
    }, [] as DeepPartial<CDTO.Training>[]);
    if (!!query && guidesIds) {
      return items.sort(({ sys: { id: idA } }, { sys: { id: idB } }) => {
        return guidesIds.indexOf(idA) - guidesIds.indexOf(idB);
      });
    }
    return items;
  }, [pages, query, guidesIds]);

  const stacks = useMemo(() => {
    const items = pages.reduce((res, { stackCollection: { items } }) => {
      return [...res, ...items];
    }, [] as DeepPartial<CDTO.Stack>[]);
    if (!!query && stacksIds) {
      return items.sort(({ sys: { id: idA } }, { sys: { id: idB } }) => {
        return stacksIds.indexOf(idA) - stacksIds.indexOf(idB);
      });
    }
    return items;
  }, [pages, query, stacksIds]);

  const filter = useCallback((query: string) => {
    setVariables((prev) => ({ ...prev, query }));
  }, []);

  return (
    <GuidesContext.Provider
      value={{
        query,
        stacks,
        guides,
        filter,
        refetch,
        hasNextPage,
        fetchNextPage,
        loading: isLoading || isFetching,
        total: pages[0]?.trainingCollection.total,
      }}
    >
      {children}
    </GuidesContext.Provider>
  );
};
