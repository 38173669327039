import { FC } from 'react';
import { RoutePath, UserRole } from '@common/constants';
import { Redirect, Route, Tabs } from '@core/navigation';
import { Feature, FeatureName } from '@helpful/flags';
import { useRedirectAfterAuth } from '@common/features';
import { HomeProvider } from '../../features/HomeProvider/HomeProvider';
import { GuidesProvider } from '../../features/GuidesProvider/GuidesProvider';
import { BenefitsProvider } from '../../features/BenefitsProvider/BenefitsProvider';
import { StacksProvider } from '../../features/StacksProvider/StacksProvider';
import { RootProvider } from '../../features/RootProvider/RootProvider';
import { Guides } from './Guides/Guides';
import { Home } from './Home/Home';
import { Benefits } from './Benefits/Benefits';
import { Stacks } from './Stacks/Stacks';
import { Search } from './Search/Search';
import { Upload } from './Upload/Upload';
import { Unverified } from './Medical/Unverified/Unverified';
import { ROOT_ROUTES } from './Root.const';
import { NavTour } from './_partitions/NavTour/NavTour';
import { useRoot } from './Root.hooks';

export const Root: FC = () => {
  const [redirectAfterAuth] = useRedirectAfterAuth();
  const { tabs, onTabsDidChange } = useRoot();

  return (
    <RootProvider>
      <HomeProvider>
        <GuidesProvider>
          <BenefitsProvider>
            <StacksProvider>
              <NavTour>
                <Tabs navigation={tabs} onTabsDidChange={onTabsDidChange}>
                  {ROOT_ROUTES.map((props, index) => (
                    <Route key={index} {...props} />
                  ))}
                  <Route path={RoutePath.ROOT_HOME}>
                    {(roles) => {
                      if (roles.includes(UserRole.USER) && redirectAfterAuth) {
                        return <Redirect {...redirectAfterAuth} />;
                      }

                      return <Home />;
                    }}
                  </Route>
                  <Route
                    path={RoutePath.ROOT_MEDICAL_UNVERIFIED}
                    roles={[UserRole.UNVERIFIED]}
                    redirect={RoutePath.ROOT_MEDICAL}
                  >
                    <Unverified />
                  </Route>
                  <Route path={RoutePath.ROOT_GUIDES}>
                    <Guides />
                  </Route>
                  <Route path={RoutePath.ROOT_BENEFITS}>
                    <Benefits />
                  </Route>
                  <Route path={RoutePath.ROOT_STACKS}>
                    <Stacks />
                  </Route>
                  <Route path={RoutePath.ROOT_SEARCH}>
                    <Search />
                  </Route>
                  <Feature name={FeatureName.DMEUPLOAD}>
                    <Route path={RoutePath.ROOT_UPLOAD} roles={[UserRole.USER]}>
                      <Upload />
                    </Route>
                  </Feature>
                </Tabs>
              </NavTour>
            </StacksProvider>
          </BenefitsProvider>
        </GuidesProvider>
      </HomeProvider>
    </RootProvider>
  );
};
