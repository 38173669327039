import { FC, Fragment } from 'react';
import {
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  ContentFixed,
  List,
  useWindowSize,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { useGetStackQuery } from '@helpful/network';
import { useTranslation } from '@wearehelpful/ui-localization';
import { GuidePaper, BenefitPaper } from '@common/features';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { usePatient } from '@common/hooks';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { Skeleton, Content } from '@wearehelpful/ui-kit';
import { StacksDetailsRouteParams, HandleOnClickParams } from './Details.types';
import { ShortDescription } from './Details.style';

export const Details: FC = () => {
  const { t } = useTranslation();
  const [{ plans }] = usePatient();

  const [{ width }] = useWindowSize();
  const thumbW = width - 32;
  const thumbH = parseInt(`${thumbW * 0.55}`);

  const [
    {
      params: { slug },
    },
    { push },
  ] = useRoute<StacksDetailsRouteParams, {}>();

  const { isLoading, data: response = {} } = useGetStackQuery({
    slug,
    plans,
  });

  const stack = response?.stackCollection?.items?.[0];

  const length = Math.max(
    stack?.benefitsCollection?.total,
    stack?.guidesCollection?.total
  );

  const handleOnBenefitClick = ({ slug }: HandleOnClickParams) => {
    push({
      path: RoutePath.ROOT_BENEFITS_DETAILS,
      params: { slug },
    });
  };

  const handleOnGuideClick = ({ slug }: HandleOnClickParams) => {
    push({
      path: RoutePath.ROOT_GUIDES_DETAILS,
      params: { slug },
    });
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Content>
        {stack?.title && (
          <Subheader>
            <Typography.Heading1>{stack?.title}</Typography.Heading1>
          </Subheader>
        )}
        <ContentFixed>
          <Skeleton variant="article" loading={isLoading}>
            <TrackPageView
              category={AnalyticsPageCategory.STACK}
              name={stack?.title}
            >
              <List title={t('forms.whatIsIt')}>
                <ShortDescription>
                  {stack?.shortDescription.json}
                </ShortDescription>
              </List>
              <List title={t('forms.relatedGuidesAndBenefits')}>
                {Array.from({ length }).map((_, index) => {
                  return (
                    <Fragment key={index}>
                      {stack?.benefitsCollection.items[index] && (
                        <BenefitPaper
                          withIcon
                          onClick={() =>
                            handleOnBenefitClick({
                              slug: stack?.benefitsCollection.items[index].slug,
                            })
                          }
                          {...stack?.benefitsCollection.items[index]}
                        />
                      )}
                      {stack?.guidesCollection.items[index] && (
                        <GuidePaper
                          thumb={{ width: thumbW, height: thumbH }}
                          onClick={() =>
                            handleOnGuideClick({
                              slug: stack?.guidesCollection.items[index].slug,
                            })
                          }
                          {...stack?.guidesCollection.items[index]}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </List>
            </TrackPageView>
          </Skeleton>
        </ContentFixed>
      </Content>
    </Page>
  );
};
