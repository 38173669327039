import { createContext } from 'use-context-selector';
import { ADTO } from '@helpful/network';

interface ConnectContextProps {
  plans: ADTO.PlanEntity[];
}

export const ConnectContext = createContext<ConnectContextProps>({
  plans: [],
});
