import { FC } from 'react';
import { noop } from 'lodash';
import { CDTO } from '@helpful/network';
import { Root, Guide } from './GuidesCarousel.style';

interface GuidesCarouselProps {
  items: DeepPartial<CDTO.Training>[];
  onItemClick?: (item: DeepPartial<CDTO.Training>, order: number) => void;
}

export const GuidesCarousel: FC<GuidesCarouselProps> = ({
  items = [],
  onItemClick = noop,
}) => {
  return (
    <Root itemsCount={items.length}>
      {items.map((item, index) => (
        <Guide
          {...item}
          truncate={3}
          key={item.sys.id}
          thumb={{ width: 264, height: 149 }}
          onClick={() => onItemClick(item, index)}
        />
      ))}
    </Root>
  );
};
