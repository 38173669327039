/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAppointmentEntity,
  ICareplanEntity,
  IConditionEntity,
  IIdString,
  IList,
  IMedicationEntity,
  IPatientControllerGetAppointmentsParams,
  IPatientControllerGetCareplansParams,
  IPatientControllerGetConditionsParams,
  IPatientControllerGetMedicationsParams,
  IPatientControllerGetPatientParams,
  IPatientControllerGetPatientsParams,
  IPatientDto,
  IPatientEntity,
  IPatientPartialDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Patients<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description List patients
   *
   * @tags Patients
   * @name PatientControllerGetPatients
   * @request GET:/api/v1/patients
   * @secure
   */
  patientControllerGetPatients = (query: IPatientControllerGetPatientsParams, params: RequestParams = {}) =>
    this.http.request<
      IList & {
        data?: IPatientEntity[];
      },
      any
    >({
      path: `/api/v1/patients`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Create patient
   *
   * @tags Patients
   * @name PatientControllerPostPatient
   * @request POST:/api/v1/patients
   * @secure
   */
  patientControllerPostPatient = (data: IPatientDto, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/patients`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Read patient
   *
   * @tags Patients
   * @name PatientControllerGetPatient
   * @request GET:/api/v1/patients/{id}
   * @secure
   */
  patientControllerGetPatient = ({ id, ...query }: IPatientControllerGetPatientParams, params: RequestParams = {}) =>
    this.http.request<IPatientEntity, any>({
      path: `/api/v1/patients/${id}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Update patient fully
   *
   * @tags Patients
   * @name PatientControllerPutPatient
   * @request PUT:/api/v1/patients/{id}
   * @secure
   */
  patientControllerPutPatient = (id: string, data: IPatientDto, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/patients/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Update patient partially
   *
   * @tags Patients
   * @name PatientControllerPatchPatient
   * @request PATCH:/api/v1/patients/{id}
   * @secure
   */
  patientControllerPatchPatient = (id: string, data: IPatientPartialDto, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/patients/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Delete patient
   *
   * @tags Patients
   * @name PatientControllerDeletePatient
   * @request DELETE:/api/v1/patients/{id}
   * @secure
   */
  patientControllerDeletePatient = (id: string, params: RequestParams = {}) =>
    this.http.request<IIdString, any>({
      path: `/api/v1/patients/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description List patient's appointments
   *
   * @tags Patients
   * @name PatientControllerGetAppointments
   * @request GET:/api/v1/patients/{id}/appointments
   * @secure
   */
  patientControllerGetAppointments = (
    { id, ...query }: IPatientControllerGetAppointmentsParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IList & {
        data?: IAppointmentEntity[];
      },
      any
    >({
      path: `/api/v1/patients/${id}/appointments`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description List patient's careplans
   *
   * @tags Patients
   * @name PatientControllerGetCareplans
   * @request GET:/api/v1/patients/{id}/careplans
   * @secure
   */
  patientControllerGetCareplans = (
    { id, ...query }: IPatientControllerGetCareplansParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IList & {
        data?: ICareplanEntity[];
      },
      any
    >({
      path: `/api/v1/patients/${id}/careplans`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description List patient's medications
   *
   * @tags Patients
   * @name PatientControllerGetMedications
   * @request GET:/api/v1/patients/{id}/medications
   * @secure
   */
  patientControllerGetMedications = (
    { id, ...query }: IPatientControllerGetMedicationsParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IList & {
        data?: IMedicationEntity[];
      },
      any
    >({
      path: `/api/v1/patients/${id}/medications`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description List patient's conditions
   *
   * @tags Patients
   * @name PatientControllerGetConditions
   * @request GET:/api/v1/patients/{id}/conditions
   * @secure
   */
  patientControllerGetConditions = (
    { id, ...query }: IPatientControllerGetConditionsParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IList & {
        data?: IConditionEntity[];
      },
      any
    >({
      path: `/api/v1/patients/${id}/conditions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
