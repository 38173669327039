import { FC, PropsWithChildren, useEffect } from 'react';
import { useGetCommonQuery } from '@helpful/network';
import { useSplash } from '@wearehelpful/ui-kit';
import { CommonContext } from '../../../contexts/Common.context';
import { SPLASH } from '../../../constants';

type CommonBootstrapProps = PropsWithChildren<{
  build: string;
}>;

export const CommonBootstrap: FC<CommonBootstrapProps> = ({
  children,
  build,
}) => {
  const { data, isFetching: isCommonLoading } = useGetCommonQuery(
    {},
    {
      initialData: {
        welcomeCarouselCollection: { items: [] },
        medicalRedirectCollection: { items: [] },
        payorCollection: { items: [] },
        healthPlanCollection: { items: [] },
        uploadCollection: { items: [] },
        successCollection: { items: [] },
        globalSearchShortcutCollection: { items: [] },
      },
    }
  );

  const { hide } = useSplash();

  useEffect(hide, [hide]);

  return !isCommonLoading ? (
    <CommonContext.Provider
      value={{
        build,
        loading: isCommonLoading,
        payors: data.payorCollection.items,
        defaultPlans: data.healthPlanCollection.items,
        welcome: data.welcomeCarouselCollection.items,
        medicalConect: data.medicalRedirectCollection.items[0] || {},
        upload: data.uploadCollection.items,
        success: data.successCollection.items,
        searchShortcuts: data.globalSearchShortcutCollection.items,
      }}
    >
      {children}
    </CommonContext.Provider>
  ) : (
    SPLASH
  );
};
