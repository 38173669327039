import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { CDTO, useInfiniteGetGuidesQuery } from '@helpful/network';

export type FetchNextPageFunction = ReturnType<
  typeof useInfiniteGetGuidesQuery
>['fetchNextPage'];

export type RefetchFunction = ReturnType<
  typeof useInfiniteGetGuidesQuery
>['refetch'];

export interface GuidesContextProps {
  total?: number;
  query?: string;
  loading: boolean;
  hasNextPage: boolean;
  refetch: RefetchFunction;
  filter: (v: string) => void;
  stacks: DeepPartial<CDTO.Stack>[];
  guides: DeepPartial<CDTO.Training>[];
  fetchNextPage: FetchNextPageFunction;
}

export const GuidesContext = createContext<GuidesContextProps>({
  guides: [],
  stacks: [],
  filter: noop,
  loading: false,
  hasNextPage: false,
  refetch: () => Promise.resolve(null),
  fetchNextPage: () => Promise.resolve(null),
});
