import moment from 'moment';
import { padStart } from 'lodash';
import { PickerOptions } from '@ionic/react';
import { useField } from 'formik';
import { useTranslation } from '@wearehelpful/ui-localization';
import { OnHandleParams } from './FormDatePicker.types';

interface UseDatePickerParams {
  name: string;
  defaultValue?: string;
}

export const FORMAT = 'MM/DD/YYYY';

export const useDatePicker = ({ name, defaultValue }: UseDatePickerParams) => {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField({
    name,
  });

  const date = moment(value, FORMAT, true).isValid() ? value : defaultValue;

  const dates = Array.from({ length: 31 }).map((_, index) => ({
    text: padStart(`${index + 1}`, 2, '0'),
    value: index + 1,
  }));

  const months = moment
    .monthsShort()
    .map((text, index) => ({ text, value: index }));

  const years = Array.from({
    length: moment().diff('1899-01-01', 'years'),
  }).map((_, index) => ({
    text: `${index + 1900}`,
    value: index + 1900,
  }));

  const handleOnConfirm = ({ date, month, year }: OnHandleParams) => {
    const value = moment({
      year: year.value,
      month: month.value,
      date: date.value,
    }).format(FORMAT);
    setValue(value);
  };

  const options: PickerOptions = {
    columns: [
      {
        name: 'month',
        options: months,
        selectedIndex: moment(date).get('month'),
      },
      {
        name: 'date',
        options: dates,
        selectedIndex: dates.findIndex(
          ({ value }) => value === moment(date).get('date')
        ),
      },
      {
        name: 'year',
        options: years,
        selectedIndex: years.findIndex(
          ({ value }) => value === moment(date).get('year')
        ),
      },
    ],
    buttons: [
      {
        text: t('actions.cancel'),
        role: 'cancel',
      },
      {
        text: t('actions.confirm'),
        handler: handleOnConfirm,
        role: 'confirm',
      },
    ],
  };

  return { options };
};
