import styled from 'styled-components';

export const Root = styled('p')`
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(19)};
  letter-spacing: -0.01em;
`;
