import { FC, PropsWithChildren } from 'react';
import { AppProvider } from '@helpful/network';

export type NetworkProps = PropsWithChildren<{
  baseUrl: string;
}>;

export const Network: FC<NetworkProps> = ({ children, ...props }) => {
  return <AppProvider {...props}>{children}</AppProvider>;
};
