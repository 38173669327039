import { RoutePath, UserRole } from '@common/constants';
import { Route, Outlet } from '@core/navigation';
import { Page } from '@core/ui';
import { FC } from 'react';
import { Index } from './Index/Index';
import { Details } from './Details/Details';
import { Connect } from './Connect/Connect';

export const Insurance: FC = () => {
  return (
    <Page>
      <Outlet>
        <Route
          path={RoutePath.INSURANCE}
          roles={[UserRole.VERIFIED]}
          redirect={RoutePath.ROOT_MORE_INSURANCE_UNVERIFIED}
          exact
        >
          <Index />
        </Route>
        <Route
          roles={[UserRole.VERIFIED]}
          redirect={RoutePath.ROOT_MORE_INSURANCE_UNVERIFIED}
          path={RoutePath.INSURANCE_DETAILS}
        >
          <Details />
        </Route>
        <Route
          roles={[UserRole.VERIFIED]}
          redirect={RoutePath.ROOT_MORE_INSURANCE_UNVERIFIED}
          path={RoutePath.INSURANCE_CONNECT}
        >
          <Connect />
        </Route>
      </Outlet>
    </Page>
  );
};
