import { FC } from 'react';
import { Root } from './ProgressBar.style';

interface ProgressBarProps {
  value?: number;
  className?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({ value = 1, className }) => {
  return <Root reversed value={0} buffer={1 - value} className={className} />;
};
