import styled, { css } from 'styled-components';

interface RootProps {
  padding: boolean;
}

const RootWithPaddingMixin = css`
  padding: 0 ${({ theme: { spacing } }) => spacing(4)};
`;

export const Root = styled('div')<RootProps>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral300};
  ${({ padding }) => padding && RootWithPaddingMixin};
  padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  p {
    margin-top: 4px;
  }
`;
