import { PropsWithChildren, ReactElement, useState, useCallback } from 'react';
import { noop } from 'lodash';
import { CardHeader } from './CardHeader/CardHeader';
import { CardTitle } from './CardTitle/CardTitle';
import { CardContent } from './CardContent/CardContent';
import { CardThumb } from './CardThumb/CardThumb';
import { CardFooter } from './CardFooter/CardFooter';
import { CardIcon } from './CardIcon/CardIcon';
import { Root } from './Card.style';
import { CardContext } from './Card.context';
import { CardState } from './Card.types';

type CardProps = PropsWithChildren<{
  flat?: boolean;
  divider?: boolean;
  className?: string;
  onClick?: () => void;
}>;

interface CardComponent {
  (props: CardProps): ReactElement<any, any> | null;
  Header: typeof CardHeader;
  Title: typeof CardTitle;
  Content: typeof CardContent;
  Thumb: typeof CardThumb;
  Footer: typeof CardFooter;
  Icon: typeof CardIcon;
}

export const Card: CardComponent = ({
  children,
  className,
  flat = false,
  onClick = noop,
  divider = false,
}) => {
  const [{ icon }, setState] = useState<CardState>({});

  const setCardState = useCallback((v: Partial<CardState>) => {
    setState((prev) => ({ ...prev, ...v }));
  }, []);

  return (
    <CardContext.Provider value={{ setCardState }}>
      <Root
        icon={icon}
        flat={flat}
        onClick={onClick}
        divider={divider}
        className={className}
        clickable={onClick !== noop}
      >
        {children}
      </Root>
    </CardContext.Provider>
  );
};

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Content = CardContent;
Card.Thumb = CardThumb;
Card.Footer = CardFooter;
Card.Icon = CardIcon;
