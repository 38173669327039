import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

interface HeadingProps {
  bold?: boolean;
  align?: CSSProperties['textAlign'];
}

interface SubheadingProps {
  bold?: boolean;
  align?: CSSProperties['textAlign'];
}

interface BodyProps {
  bold?: boolean;
  align?: CSSProperties['textAlign'];
}

const CommonBody = css<BodyProps>`
  color: ${({ theme }) => theme.palette.brown400};
  text-align: ${({ align = 'left' }) => align};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  margin: 0;
  padding: 0;
`;

const CommonHeading = css<HeadingProps>`
  font-family: 'Lora';
  color: ${({ theme }) => theme.palette.brown400};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  text-align: ${({ align = 'left' }) => align};
  margin: 0;
  padding: 0;
`;

const CommonSubheading = css<HeadingProps>`
  color: ${({ theme }) => theme.palette.brown400} !important;
  font-weight: ${({ bold }) => (bold ? 700 : 400)} !important;
  text-align: ${({ align = 'left' }) => align} !important;
  margin: 0 !important;
  padding: 0 !important;
`;

export const Body1 = styled('p')<BodyProps>`
  ${CommonBody}
  font-size: ${({ theme }) => theme.fontSize(18)};
  line-height: ${({ theme }) => theme.fontSize(22)};
`;

export const Body2 = styled('p')<BodyProps>`
  ${CommonBody}
  font-size: ${({ theme }) => theme.fontSize(16)};
  line-height: ${({ theme }) => theme.fontSize(20)};
`;

export const Body3 = styled('p')<BodyProps>`
  ${CommonBody}
  font-size: ${({ theme }) => theme.fontSize(14)};
  line-height: ${({ theme }) => theme.fontSize(18)};
`;

export const Heading1 = styled('h1')<HeadingProps>`
  ${CommonHeading}
  font-size: ${({ theme }) => theme.fontSize(42)};
  line-height: ${({ theme }) => theme.fontSize(42)};
`;

export const Heading2 = styled('h2')<HeadingProps>`
  ${CommonHeading}
  font-size: ${({ theme }) => theme.fontSize(32)};
  line-height: ${({ theme }) => theme.fontSize(32)};
`;

export const Heading3 = styled('h3')<HeadingProps>`
  ${CommonHeading}
  font-size: ${({ theme }) => theme.fontSize(28)};
  line-height: ${({ theme }) => theme.fontSize(28)};
`;

export const Heading4 = styled('h4')<HeadingProps>`
  ${CommonHeading}
  font-size: ${({ theme }) => theme.fontSize(24)};
  line-height: ${({ theme }) => theme.fontSize(25)};
`;

export const Heading5 = styled('h4')<HeadingProps>`
  ${CommonHeading}
  font-size: ${({ theme }) => theme.fontSize(18)};
  line-height: ${({ theme }) => theme.fontSize(23)};
`;

export const Subheading1 = styled('h4')<SubheadingProps>`
  ${CommonSubheading}
  font-size: ${({ theme }) => theme.fontSize(18)} !important;
  line-height: ${({ theme }) => theme.fontSize(22)} !important;
`;
