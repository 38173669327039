import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { EmailVerificationClaim } from 'supertokens-auth-react/recipe/emailverification';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { AuthContext } from '../../../contexts/Auth.context';
import { ResponseErrorInterceptor } from '../../../types/http.types';

export type SessionManagerProps = PropsWithChildren<{
  webDomain: string;
  fallback: ReactNode;
  responseErrorInterceptor: ResponseErrorInterceptor;
}>;

export const SessionManager: FC<SessionManagerProps> = ({
  children,
  fallback,
  webDomain,
  responseErrorInterceptor,
}) => {
  const context = useSessionContext();
  const [ready, setReady] = useState(false);

  useUpdateEffect(() => setReady(true), [context.loading]);

  if (!ready && context.loading === true) {
    return <>{fallback}</>;
  }

  if (ready && context.loading === false) {
    const { doesSessionExist: isSessionExist } = context;

    const isUserVerified = !context.invalidClaims.some(
      ({ validatorId }) => validatorId === EmailVerificationClaim.id
    );

    return (
      <AuthContext.Provider
        value={{
          webDomain,
          isUserVerified,
          isSessionExist,
          responseErrorInterceptor,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  return null;
};
