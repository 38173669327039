import { FC } from 'react';
import { Typography } from '@core/ui';
import { Page, Content } from '@wearehelpful/ui-kit';
import { Root, Text, Img } from './NetworkFallback.style';
import SRC from './NetworkFallback.png';

const { Heading2, Body1 } = Typography;

export const NetworkFallback: FC = () => {
  return (
    <Page>
      <Content>
        <Root>
          <Text>
            <Heading2 align="center">
              Couldn’t connect to Helpful. Check your internet connection.
            </Heading2>
            <Body1>
              We’ll keep trying but there may be a problem with your internet
              connection
            </Body1>
          </Text>
          <Img src={SRC} />
        </Root>
      </Content>
    </Page>
  );
};
