import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  background-color: ${({ theme }) => theme.palette.periwinkle100};
`;

export const Reactions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${({ theme: { spacing } }) => spacing(7)};
  gap: ${({ theme: { spacing } }) => spacing(4)};
  ion-button {
    margin: 0 !important;
    height: ${({ theme: { spacing } }) => spacing(7)};
  }
`;
