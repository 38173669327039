import { type PropsWithChildren, forwardRef, useImperativeHandle } from 'react';
import { Card, Typography, Chips, Button } from '@core/ui';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useShare } from '@helpful/native';
import { generatePath, useRoute } from '@core/navigation';
import { useGetBenefitQuery, ContentfulDocument } from '@helpful/network';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  CallToActionRoutes,
  RoutePath,
} from '@common/constants';
import { useSession } from '@core/auth';
import { Feature, FeatureName } from '@helpful/flags';
import { usePatient } from '@common/hooks';
import { CtaList, type CtaListProps } from '@common/features';
import { useUtils, Skeleton, Content } from '@wearehelpful/ui-kit';
import {
  Subheader,
  ContentFixed,
  Info,
  Thumb,
  HowToRedeem,
  Tags,
  ButtonDME,
} from './BenefitsDetails.style';
import THUMB from './BenefitsDetails.png';

type BenefitsDetailsProps = PropsWithChildren<{
  slug: string;
  category: string;
}>;

export interface BenefitsDetailsInstanse {
  onShare: () => void;
}

export const BenefitsDetails = forwardRef<
  BenefitsDetailsInstanse,
  BenefitsDetailsProps
>(({ slug, children, category }, ref) => {
  const { t } = useTranslation();
  const [, share] = useShare();
  const { toast } = useUtils();
  const { track, trackClick } = useAnalytics();
  const [{ isSessionExist }] = useSession();

  const { data: response, isFetching } = useGetBenefitQuery({
    slug,
  });

  const planBenefit = response?.planBenefitCollection?.items?.[0];
  const [{ search }, { push }] = useRoute();
  const [{ payors }] = usePatient();
  const payor = payors[0]?.name;

  useImperativeHandle(ref, () => ({
    onShare: () => {
      share(
        {
          search: { utm_source: 'share' },
          title: planBenefit?.title,
          description: planBenefit?.shortDescription,
          pathname: generatePath(RoutePath.ROOT_BENEFITS_DETAILS, { slug }),
        },
        {
          onSuccess: ({ canShare }) => {
            trackClick({
              source: AnalyticsPageCategory.BENEFIT,
              element: AnalyticsTrackEvents.SHARE,
              properties: { benefit: planBenefit.title },
            });
            if (!canShare) {
              toast({
                variant: 'success',
                message: t('notifications.linkCopied'),
              });
            }
          },
        }
      );
    },
  }));

  const redirectToDMEUpload = () => {
    push({ path: RoutePath.ROOT_UPLOAD, params: { type: 'dme' } });
  };

  const handleOnCtaClick: CtaListProps['onClick'] = ({
    title,
    ctaUrl: path,
  }) => {
    track({
      id: AnalyticsTrackEvents.BENEFIT_CTA_CLICK,
      properties: { title, planBenefit: planBenefit?.title },
    }).finally(() => {
      push({ path });
    });
  };

  return (
    <Content>
      {planBenefit?.title && (
        <Subheader>
          <Typography.Heading3>{planBenefit?.title}</Typography.Heading3>
          <Typography.Body2>{planBenefit?.shortDescription}</Typography.Body2>
          {!planBenefit.entityInfo && (
            <Tags>
              {!isSessionExist && (
                <Chips.Item status="error">
                  {t('forms.noInsurancePlanConnected')}
                </Chips.Item>
              )}
              <Chips.Item>{planBenefit?.healthPlan.planName}</Chips.Item>
            </Tags>
          )}
          <Feature name={FeatureName.DMEUPLOAD}>
            {payor && planBenefit?.uploadType === 'dme' && (
              <ButtonDME onClick={redirectToDMEUpload}>
                {t('prompts.dmeUpload.message', { payor })}
              </ButtonDME>
            )}
          </Feature>
        </Subheader>
      )}
      <ContentFixed padding>
        <Skeleton variant="article" loading={isFetching}>
          <TrackPageView
            category={category}
            name={planBenefit?.title}
            properties={{ ...search }}
          >
            <CtaList
              route={CallToActionRoutes.BENEFITS_DETAILS}
              onClick={handleOnCtaClick}
            />
            <Card>
              <Card.Header>
                <Card.Title>{t('forms.cost')}</Card.Title>
              </Card.Header>
              <Card.Content>{planBenefit?.cost}</Card.Content>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>{t('forms.priorAuth')}</Card.Title>
              </Card.Header>
              <Card.Content>{planBenefit?.priorAuthRequired}</Card.Content>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>{t('forms.priorReferral')}</Card.Title>
              </Card.Header>
              <Card.Content>
                {planBenefit?.providerReferralOrOrderRequired ? 'Yes' : 'No'}
              </Card.Content>
            </Card>
            <Card>
              <Card.Thumb background="periwinkle300">
                <HowToRedeem>
                  <Typography.Subheading1 bold>
                    {t('forms.howToReceive')}
                  </Typography.Subheading1>
                  <Thumb src={THUMB} />
                </HowToRedeem>
              </Card.Thumb>
              <Card.Content>
                <Feature name={FeatureName.DMEUPLOAD}>
                  {payor && planBenefit?.uploadType === 'dme' && (
                    <>
                      <Button size="s" onClick={redirectToDMEUpload}>
                        {t('prompts.dmeUpload.message', { payor })}
                      </Button>
                      <br />
                    </>
                  )}
                </Feature>
                <ContentfulDocument
                  assets={planBenefit?.howToRedeem?.links.assets.block}
                >
                  {planBenefit?.howToRedeem?.json}
                </ContentfulDocument>
              </Card.Content>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>{t('forms.availability')}</Card.Title>
              </Card.Header>
              <Card.Content>{planBenefit?.availability}</Card.Content>
            </Card>
            <Info>
              <Typography.Subheading1 bold>
                {t('forms.moreDetails')}
              </Typography.Subheading1>
              <ContentfulDocument
                assets={
                  planBenefit?.benefitTemplate.longDescription.links.assets
                    .block
                }
              >
                {planBenefit?.benefitTemplate.longDescription.json}
              </ContentfulDocument>
            </Info>
            {children}
          </TrackPageView>
        </Skeleton>
      </ContentFixed>
    </Content>
  );
});
