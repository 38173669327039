import { FC } from 'react';
import { AnalyticsPageCategory } from '@common/constants';
import { useRoute } from '@core/navigation';
import { DTO, useSignIn } from '@core/auth';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { FederatedSignIn } from '@common/features';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Page, Header, Content, Typography, Form } from '@wearehelpful/ui-kit';
import { SignInRouteSearch } from './SignIn.types';
import { AuthActions } from './_partitions/AuthActions/AuthActions';

export const SignIn: FC = () => {
  const { t } = useTranslation();

  const [
    {
      search: { email },
    },
  ] = useRoute<{}, SignInRouteSearch>();

  const [{ loading }, signIn] = useSignIn();

  const handleOnSubmit = (signInRequest: DTO.ISignInRequest) => {
    signIn(signInRequest);
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.SIGN_IN}
    >
      <Page headerBackground="periwinkle300">
        <Header>
          <Header.Back />
          <Header.Logo />
        </Header>
        <Content>
          <Content.Subheader>
            <Typography variant="h2">
              {t('subheadings.signIn.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Form<DTO.ISignInRequest>
              onSubmit={handleOnSubmit}
              defaultValues={{ email, password: '' }}
            >
              <Form.Text
                name="email"
                type="email"
                label={t('forms.email')}
                autocomplete="email"
                enterKeyHint="next"
                validation={{ required: true, email: true }}
              />
              <Form.Password
                name="password"
                label={t('forms.password')}
                enterKeyHint="go"
                autocomplete="current-password"
                validation={{ required: true }}
              />
              <Form.Actions inset="sm">
                <Form.Submit loading={loading}>
                  {t('actions.login')}
                </Form.Submit>
                <AuthActions />
              </Form.Actions>
              <FederatedSignIn />
            </Form>
          </Content.Fixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
