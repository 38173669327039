import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
  KeyboardEventHandler,
  useLayoutEffect,
  useEffect,
} from 'react';
import { noop } from 'lodash';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import {
  IonSearchbarCustomEvent,
  SearchbarInputEventDetail,
} from '@ionic/core';
import { search, arrowBack } from 'ionicons/icons';
import { useIonViewDidEnter } from '@ionic/react';
import { Root } from './Searchbar.style';

export interface SearchbarProps {
  value?: string;
  debounce?: number;
  className?: string;
  autofocus?: boolean;
  placeholder?: string;
  onBack?: () => void;
  onFocus?: () => void;
  onChange?: (value: string) => void;
  onSearch?: (query: string) => void;
}

export interface SearchbarInstance {
  blur: () => void;
}

export const Searchbar = forwardRef<SearchbarInstance, SearchbarProps>(
  (
    {
      className,
      placeholder,
      onBack = noop,
      onFocus = noop,
      onChange = noop,
      onSearch = noop,
      autofocus = false,
      value: initialValue,
      debounce: delay = 0,
    },
    ref
  ) => {
    const [value, setValue] = useState<string>(initialValue);
    const control = useRef<HTMLIonSearchbarElement>();
    const icon = useRef<HTMLElement>();

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const blur = () => {
      Capacitor.isNativePlatform() && Keyboard.hide();
    };

    const handleOnClear = useCallback(() => {
      onSearch('');
      control.current?.setFocus();
    }, [onSearch]);

    const handleOnChange = useCallback(
      ({
        target: { value },
      }: IonSearchbarCustomEvent<SearchbarInputEventDetail>) => {
        setValue(value);
        onChange(value);

        if (value === '') {
          handleOnClear();
        }
      },
      [onChange, handleOnClear]
    );

    const handleOnKeyDown: KeyboardEventHandler<HTMLIonSearchbarElement> =
      useCallback(
        ({ currentTarget: { value }, key }) => {
          switch (key) {
            case 'Enter':
              if (value.trim()) {
                onSearch(value);
              }
              break;
            case 'Escape':
              handleOnClear();
              break;
            default:
              break;
          }
        },
        [onSearch, handleOnClear]
      );

    useImperativeHandle(ref, () => ({ blur }));

    useLayoutEffect(() => {
      const handler = () => {
        if (!control.current.value) {
          control.current?.setFocus();
        } else if (onBack === noop) {
          control.current?.dispatchEvent(
            new KeyboardEvent('keyDown', { key: 'Enter' })
          );
        } else {
          onBack();
          control.current?.setFocus();
        }
      };
      setTimeout(() => {
        icon.current = control.current?.querySelector('.searchbar-search-icon');
        if (icon.current) {
          icon.current.addEventListener('click', handler);
        }
      });
      return () => {
        if (icon.current) {
          icon.current.removeEventListener('click', handler);
        }
      };
    }, [onBack]);

    useIonViewDidEnter(() => {
      if (autofocus) {
        control.current?.setFocus();
      }
    });

    return (
      <Root
        value={value}
        ref={control}
        debounce={delay}
        onIonFocus={onFocus}
        enterkeyhint="search"
        className={className}
        placeholder={placeholder}
        onIonClear={handleOnClear}
        onKeyDown={handleOnKeyDown}
        onIonInput={handleOnChange}
        searchIcon={onBack !== noop ? arrowBack : search}
        onKeyUp={({ key }) => key === 'Enter' && blur()}
      />
    );
  }
);
