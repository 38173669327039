export enum AnalyticsPageCategory {
  ALLERGIES = 'Allergies',
  AUTH = 'Auth',
  BENEFIT = 'Benefit',
  BENEFITS = 'Benefits',
  CAREPLAN = 'Careplan',
  EXPERIENCE_SELECTION = 'Experience Selection',
  TODO = 'Todo',
  TODOS_SELECTION = 'Todos Selection',
  CAREPLANS = 'Careplans',
  CARETEAM = 'Careteam',
  CHANGE_PASSWORD = 'Change Password',
  CONDITION = 'Condition',
  CONDITIONS = 'Conditions',
  CONNECT_MEDICAL = 'Connect Medical',
  CONNECT_MEDICAL_LOADING = 'Connect Medical Loading',
  CONNECT_MEDICAL_REDIRECT = 'Connect Medical Redirect',
  CONTACT_US = 'Contact Us',
  CONTACT_US_SUCCESS = 'Contact Us Success',
  CREATE_ACCOUNT = 'Create Account',
  CREATE_PATIENT = 'Create Patient',
  ENTER_EMAIL = 'Enter Email',
  FAQ = 'FAQ',
  FEEDBACK = 'Feedback',
  FEEDBACK_SUCCESS = 'Feedback Success',
  FORGOT_PASSWORD = 'Forgot Password',
  GLOBAL_SEARCH = 'Global Search',
  GUIDE = 'Guide',
  GUIDES = 'Guides',
  HOME = 'Home',
  INSURANCE = 'Insurance',
  LANGUAGE = 'Language',
  MEDICAL = 'Medical',
  MEDICATION = 'Medication',
  MEDICATIONS = 'Medications',
  MORE = 'More',
  PATIENT = 'Patient',
  PAYOR = 'Payor',
  PROFILE = 'Profile',
  RESET_PASSWORD = 'Reset Password',
  ROOT = 'ROOT',
  SEARCH = 'Search',
  SELECT_PLAN = 'Select Plan',
  SHORTCURS = 'Shortcuts',
  SIGN_IN = 'Sign In',
  STACK = `Stack`,
  STACK_CAROUSEL = 'Stack Carousel',
  STACKS = 'Stacks',
  START = 'Start',
  UNVERIFIED = 'Unverified',
  UPLOAD = 'Upload',
  WAITLIST = 'Waitlist',
  WAITLIST_SUCCESS = 'Waitlist Success',
  WELCOME = 'Welcome',
}

export enum AnalyticsTrackEvents {
  BENEFITS_SEARCH = 'Benefits: Search',
  BENEFITS_CATEGORY_CLICK = 'Benefits: Category Click',
  BENEFITS_CTA_CLICK = 'Benefits: CTA Click',
  BENEFIT_CTA_CLICK = 'Benefit: CTA Click',
  EMAIL_INPUT = 'Email Input: Go to SignUp',
  EMAIL_INPUT_EMAIL_EXISTS = 'Email Input: Email Exists',
  FEDERATED_LOGIN = 'Federated Login',
  FEDERATED_SIGNUP = 'Federated Signup',
  FORGOT_PASSWORD = 'Forgot Password',
  GLOBAL_SEARCH_CTA_CLICK = 'Global Search: CTA Click',
  GUIDES_SEARCH = 'Guides: Search',
  GUIDES_CTA_CLICK = 'Guides: CTA Click',
  GUIDE_CTA_CLICK = 'Guide: CTA Click',
  MORE_CTA_CLICK = 'More: CTA Click',
  HOME_SCREEN_CTA_CLICK = 'Home Screen: CTA Click',
  MEDICAL_CONNECT_ALLERGIES = 'Medical Connect: Started - Allergies',
  MEDICAL_CONNECT_CARE_PLANS = 'Medical Connect: Started - Care Plans',
  MEDICAL_CONNECT_CARE_TEAM = 'Medical Connect: Started - Care Team',
  MEDICAL_CONNECT_CONDITIONS = 'Medical Connect: Started - Conditions',
  MEDICAL_CONNECT_MEDICATIONS = 'Medical Connect: Started - Medications',
  MEDICAL_CONNECT_PROVIDER_SELECTED = 'Medical Connect: Provider Selected',
  MEDICAL_CONNECT_REDIRECT_TO_LOGIN = 'Medical Connect: Redirecting to medical system login',
  MEDICAL_CONNECT_STARTED = 'Medical Connect: Started',
  MEDICAL_CONNECT_STARTED_HOME = 'Medical Connect: Started - Home',
  MEDICAL_SEARCH = 'Medical: Search',
  MEDICAL_UPDATE_CONNECTION = 'Medical: Update Connection',
  PLAN_SELECTED = 'Plan Selected',
  SELECT_PAYOR = 'Select Payor',
  SHARE = 'Share',
  SIGN_IN = 'SignIn',
  SIGN_UP_ACCOUNT_CREATED = 'SignUp: Account Created',
  SIGN_UP_PATIENT_CREATED = 'SignUp: Patient Created',
  UPLOAD_DME_SUCCESS = 'Upload: DME Success',
  UPLOAD_DME_ERROR = 'Upload: DME Error',
  CAREPLAN_EXPERIENCE_SELECTED = 'Careplan: Experience Selected',
  CAREPLAN_TODO_SELECTED = 'Careplan: Todo Selected',
  HELPFUL_TODO_COMPLETED = 'Todos: Helpful Todo Completed',
  HELPFUL_TODO_DELETED = 'Todos: Helpful Todo Deleted',
}
