import { FC } from 'react';
import {
  ContentFixed,
  Subheader,
  Typography,
  Button,
  Page,
  Header,
  Toolbar,
} from '@core/ui';
import { useRoute } from '@core/navigation';
import { ContactUs } from '@common/features';
import { usePatient, useCommon, useMedical } from '@common/hooks';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useInAppRedirect } from '@core/auth';
import {
  ContentfulDocument,
  CONTENTFUL_BLOCKS,
  ContentfulImage,
  useGetSystem,
  useGetFhirLaunch,
} from '@helpful/network';
import { useConfig } from '@bootstrap/hooks';
import { Icon, Content } from '@wearehelpful/ui-kit';
import {
  Info,
  Paragraph,
  CheckMark,
  Heading3,
  List,
  ListItem,
} from './Redirect.style';

export const Redirect: FC = () => {
  const [{ patient }] = usePatient();
  const { host } = useConfig();
  const { open } = useInAppRedirect();
  const { track } = useAnalytics();
  const [{ connections }] = useMedical();
  const [
    {
      medicalConect: {
        title,
        shortDescription,
        image,
        cta,
        secondaryCta,
        longDescription,
      },
    },
  ] = useCommon();

  const [
    {
      params: { id: systemId },
    },
  ] = useRoute<{ id: string }, {}>();

  const { data: systemData, isFetching: systemLoading } =
    useGetSystem(systemId);

  const { isFetching, refetch } = useGetFhirLaunch(
    systemId,
    patient?.id,
    {
      redirectUri: `${host}${RoutePath.ROOT_MEDICAL_CONNECT_CALLBACK}`,
    },
    {
      query: {
        enabled: false,
      },
    }
  );

  const handleOnConnect = () => {
    const reconnect = connections?.find(({ system }) => system.id === systemId);
    track({
      id: AnalyticsTrackEvents.MEDICAL_CONNECT_REDIRECT_TO_LOGIN,
      properties: {
        systemId: systemId,
        systemName: systemData.data.name,
        reconnect: !!reconnect,
      },
    }).finally(() => {
      refetch().then(
        ({
          data: {
            data: { url },
          },
        }) => open(url)
      );
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.CONNECT_MEDICAL}
      name={AnalyticsPageCategory.CONNECT_MEDICAL_REDIRECT}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content padding>
          <Subheader gap="md">
            <Typography.Heading2 align="center">{title}</Typography.Heading2>
            <Typography.Body1 align="center">
              {shortDescription}
            </Typography.Body1>
          </Subheader>
          <ContentFixed>
            {image && (
              <Info>
                <ContentfulImage
                  width={160}
                  height={126}
                  url={image.url}
                  alt={title}
                />
                <ContentfulDocument
                  render={() => ({
                    [CONTENTFUL_BLOCKS.PARAGRAPH]: (_, children) => (
                      <Paragraph>{children}</Paragraph>
                    ),
                    [CONTENTFUL_BLOCKS.UL_LIST]: (_, children) => (
                      <List>
                        <CheckMark>
                          <Icon name="check" />
                        </CheckMark>
                        {children}
                      </List>
                    ),
                    [CONTENTFUL_BLOCKS.HEADING_3]: (_, children) => (
                      <Heading3>{children}</Heading3>
                    ),
                    [CONTENTFUL_BLOCKS.LIST_ITEM]: (_, children) => (
                      <ListItem>{children}</ListItem>
                    ),
                  })}
                >
                  {longDescription.json}
                </ContentfulDocument>
              </Info>
            )}
            <Button
              loading={isFetching || systemLoading}
              onClick={handleOnConnect}
            >
              {cta}
            </Button>
            <ContactUs>{secondaryCta}</ContactUs>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
