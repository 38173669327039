import { createContext } from 'use-context-selector';
import { CDTO } from '@helpful/network';

interface DataContextProps {
  tours: CDTO.GetBootstrapQuery['tourCollection']['items'];
  stacks: CDTO.GetBootstrapQuery['stackCollection']['items'];
  policy: CDTO.GetBootstrapQuery['policyCollection']['items'];
}

export const DataContext = createContext<DataContextProps>({
  tours: [],
  stacks: [],
  policy: [],
});
