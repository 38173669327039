import {
  useCreatePatient,
  useUpdateCaregiver,
  useUpdatePatient,
} from '@helpful/network';
import { isEqual } from 'lodash';
import { useAsyncFn } from 'react-use';
import { useUser } from '@core/user';
import { usePatient } from '@common/hooks';
import { DetailsForm } from './Index.types';

type UseDetailsReturnType = [
  {
    value: boolean;
    loading: boolean;
    defaultValues: DetailsForm;
  },
  ({ patient, caregiver }: DetailsForm) => Promise<boolean>
];

export const useDetails = (): UseDetailsReturnType => {
  const [{ user }, refetch] = useUser();
  const [{ patient }] = usePatient();

  const { mutate: createPatient } = useCreatePatient();
  const { mutate: updateCaregiver } = useUpdateCaregiver();
  const { mutate: updatePatient } = useUpdatePatient();

  const defaultValues: DetailsForm = {
    caregiver: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
    caringForMyself: (() => {
      return isEqual(
        [user?.firstName, user?.lastName],
        [patient?.firstName, patient?.lastName]
      );
    })(),
    patient: {
      firstName: user?.patients[0]?.patient.firstName,
      lastName: user?.patients[0]?.patient.lastName,
      dateOfBirth: user?.patients[0]?.patient.dateOfBirth,
    },
  };

  const [, updateCaregiverAsync] = useAsyncFn(
    (form: DetailsForm['caregiver']) => {
      return new Promise((resolve, reject) => {
        updateCaregiver(
          {
            id: user.id,
            data: form,
          },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      });
    }
  );

  const [, updatePatientAsync] = useAsyncFn((form: DetailsForm['patient']) => {
    return new Promise((resolve, reject) => {
      if (patient?.id) {
        updatePatient(
          {
            id: patient.id,
            data: {
              ...form,
            },
          },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      } else {
        createPatient(
          {
            data: {
              caregivers: [
                {
                  caregiver: {
                    id: user.id,
                  },
                },
              ],
              ...form,
            },
          },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      }
    });
  });

  const [{ loading, value }, dispatch] = useAsyncFn(
    async ({ patient, caregiver }: DetailsForm) => {
      return Promise.all([
        updatePatientAsync(patient),
        updateCaregiverAsync(caregiver),
      ])
        .then(() => refetch())
        .then(() => true);
    },
    []
  );

  return [{ loading, value, defaultValues }, dispatch];
};
