import styled, { css } from 'styled-components';
import { IonFooter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

const RootWithPaddingMixin = css`
  padding-bottom: ${({ theme: { spacing } }) => spacing(10)};
`;

const RootWithPShadowMixin = css`
  padding-top: ${({ theme: { spacing } }) => spacing(4)};
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.07);
`;

export const Root = styled(IonFooter)<{ shadow: boolean }>`
  ${() => !Capacitor.isNativePlatform() && RootWithPaddingMixin}
  ${({ shadow }) => shadow && RootWithPShadowMixin}
`;
