import {
  HttpClient,
  RequestParams,
  ContentType,
} from '../__generated__/http-client';
import type {
  IChangePasswordDto,
  ICheckEmailDto,
} from '../types/custom-dto.type';
import type { SocialProvider } from '../components/AuthProvider/AuthProvider.types';

type ChangePasswordResponse =
  | {
      status: 'OK' | 'RESET_PASSWORD_INVALID_TOKEN_ERROR';
      fetchResponse: Response;
    }
  | {
      status: 'FIELD_ERROR';
      formFields: {
        id: string;
        error: string;
      }[];
      fetchResponse: Response;
    };

export type CheckEmailResponse = {
  status: 'OK';
  exists: boolean;
  provider?: SocialProvider;
};

export class Auth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  changePassword = (data: IChangePasswordDto, params: RequestParams = {}) =>
    this.http.request<ChangePasswordResponse, any>({
      path: `api/v1/auth/user/password/change`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  checkEmail = (data: ICheckEmailDto, params: RequestParams = {}) =>
    this.http.request<CheckEmailResponse, any>({
      path: `api/v1/auth/signup/email/exists`,
      query: data,
      ...params,
    });
}
