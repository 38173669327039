import { FormSelectOption } from '@core/ui';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useGetLocales } from '@helpful/network';

import { RELATIONSHIPS, SexAtBirth } from '@common/constants';

export const useStaticOptions = () => {
  const { t } = useTranslation();
  const relationships: FormSelectOption[] = [
    { label: t('options.mother'), value: RELATIONSHIPS.MOTHER },
    { label: t('options.father'), value: RELATIONSHIPS.FATHER },
    { label: t('options.motherInLaw'), value: RELATIONSHIPS.MOTHER_IN_LAW },
    { label: t('options.fatherInLaw'), value: RELATIONSHIPS.FATHER_IN_LAW },
    { label: t('options.sister'), value: RELATIONSHIPS.SISTER },
    { label: t('options.brother'), value: RELATIONSHIPS.BROTHER },
    { label: t('options.husband'), value: RELATIONSHIPS.HUSBAND },
    { label: t('options.wife'), value: RELATIONSHIPS.WIFE },
    { label: t('options.grandmother'), value: RELATIONSHIPS.GRANDMOTHER },
    { label: t('options.grandfather'), value: RELATIONSHIPS.GRANDFATHER },
    { label: t('options.son'), value: RELATIONSHIPS.SON },
    { label: t('options.daughter'), value: RELATIONSHIPS.DAUGHTER },
    { label: t('options.other'), value: RELATIONSHIPS.OTHER },
  ];

  const sexAtBirth: FormSelectOption[] = [
    {
      label: t('options.male'),
      value: SexAtBirth.MALE,
    },
    {
      label: t('options.female'),
      value: SexAtBirth.FEMALE,
    },
  ];

  const careGivingExperience: FormSelectOption[] = [
    {
      label: t('options.years', { number: '0-1' }),
      value: 1,
    },
    {
      label: t('options.years', { number: '2-4' }),
      value: 4,
    },
    {
      label: t('options.years', { number: '5+' }),
      value: 7,
    },
  ];

  const { data: locales } = useGetLocales();

  const locale: FormSelectOption[] = locales?.map((locale) => {
    return { label: locale.name, value: locale.code };
  });

  return [{ relationships, sexAtBirth, careGivingExperience, locale }];
};
