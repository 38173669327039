import { FC, PropsWithChildren } from 'react';
import { NavigationProvider, History } from '@core/navigation';
import { USER_DEFAULT_REDIRECT } from '../../../configs';
import { UserRole } from '../../../constants';
import { useUserRoles } from './NavigationBootstrap.hooks';

export type NavigationBootstrapProps = PropsWithChildren<{
  history: History;
}>;

export const NavigationBootstrap: FC<NavigationBootstrapProps> = ({
  history,
  children,
}) => {
  const roles = useUserRoles();

  const redirectRole = Object.keys(USER_DEFAULT_REDIRECT).filter((item) =>
    roles?.includes(item as UserRole)
  )[0] as UserRole;

  return (
    <NavigationProvider
      history={history}
      roles={roles}
      defaultRedirect={USER_DEFAULT_REDIRECT[redirectRole]}
    >
      {children}
    </NavigationProvider>
  );
};
