import { FC } from 'react';
import classNames from 'classnames';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { BaseFormItemProps } from '../Form.types';
import { FormItem } from '../FormItem/FormItem';
import { Checkbox, Label } from './FormCheckbox.style';

interface FormCheckboxProps extends BaseFormItemProps {
  multiline?: boolean;
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  label,
  multiline,
  name: initialName,
}) => {
  const [{ value, name, checked, onBlur, ...props }, , { setValue }] = useField(
    {
      type: 'checkbox',
      name: castArray(initialName).join('.'),
    }
  );

  return (
    <FormItem name={name} lines="none">
      <Checkbox
        {...props}
        name={name}
        slot="start"
        value={value}
        checked={checked}
        onIonBlur={onBlur}
        onIonChange={() => setValue(!checked)}
      />
      <Label className={classNames({ 'ion-text-wrap': multiline })}>
        {label}
      </Label>
    </FormItem>
  );
};
