import { FC } from 'react';
import { Root, Caption, Img } from './ContentfulImage.style';

interface ContentfulImageProps {
  url: string;
  className?: string;
  title?: string;
}

export const ContentfulImage: FC<ContentfulImageProps> = ({
  url,
  title,

  className,
}) => {
  return (
    <Root className={className}>
      <Img src={url} />
      {title && <Caption>{title}</Caption>}
    </Root>
  );
};
