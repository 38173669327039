import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { UiKitColor, UiKitSize } from '../../types/uikit.types';
import { Root, Content } from './Subheader.style';

type SubheaderProps = PropsWithChildren<{
  padding?: boolean;
  className?: string;
  background?: UiKitColor;
  divider?: boolean;
  gap?: UiKitSize;
}>;

export const Subheader: FC<SubheaderProps> = ({
  padding,
  className,
  background,
  divider,
  gap = 'sm',
  children,
}) => {
  return (
    <Root
      background={background}
      className={classNames(className, 'ion-no-border', {
        'ion-padding': padding,
      })}
      divider={divider}
    >
      <Content gap={gap}>{children}</Content>
    </Root>
  );
};
