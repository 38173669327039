import { FC } from 'react';
import {
  ContentFixed,
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  Button,
} from '@core/ui';
import { AnalyticsPageCategory } from '@common/constants';
import { useUser } from '@core/user';
import { useResendEmail } from '@core/auth';
import { useTranslation } from '@wearehelpful/ui-localization';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useUtils, Content } from '@wearehelpful/ui-kit';
import { Container, Title, Image, Description, Note } from './Unverified.style';
import IMAGE from './Unverified.png';

export const Unverified: FC = () => {
  const { toast } = useUtils();
  const [
    {
      user: { email },
    },
  ] = useUser();
  const { t } = useTranslation();
  const [{ loading }, resend] = useResendEmail();

  const handleOnResend = () => {
    resend(null, {
      onSuccess: () => {
        toast({
          variant: 'success',
          message: t('notifications.emailSuccessfullyResent', { email }),
        });
      },
    });
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICAL}
      name={AnalyticsPageCategory.UNVERIFIED}
    >
      <Page>
        <Header>
          <Toolbar>
            <Toolbar.Back />
          </Toolbar>
        </Header>
        <Content>
          <Subheader divider>
            <Typography.Heading1>
              {t('subheadings.medical.title')}
            </Typography.Heading1>
          </Subheader>
          <ContentFixed padding>
            <Container>
              <Title align="center">{t('prompts.verifyEmail.title')}</Title>
              <Image src={IMAGE} />
              <Description align="center">
                {t('prompts.verifyEmail.message', { email })}
              </Description>
              <Note align="center">{t('prompts.verifyEmail.note')}</Note>
            </Container>
            <Button loading={loading} onClick={handleOnResend}>
              {t('actions.resendEmail')}
            </Button>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
