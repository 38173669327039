import { IconName, Theme } from '@wearehelpful/ui-kit';
import { HomeMode } from '../../Home.types';

interface GetTodoIconParams {
  mode: HomeMode;
  template?: string;
  planBenefit: string[];
}

interface GetTodoIconColorParams {
  mode: HomeMode;
}

export const getTodoIcon = ({
  mode,
  planBenefit,
  template,
}: GetTodoIconParams): IconName => {
  if (mode === HomeMode.Reorder) return 'reorder-two';
  if (!template) return 'edit';
  if (!!planBenefit.length) return 'nav_benefits';
  return 'helpful';
};

export const getTodoIconColor = ({
  mode,
}: GetTodoIconColorParams): keyof Theme['palette'] => {
  if (mode === HomeMode.Reorder) return 'orange400';
  return undefined;
};
