import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { Button, Typography } from '@core/ui';
import { CDTO, ContentfulDocument, ContentfulImage } from '@helpful/network';
import { Root, Header, Content, Image } from './PromptPaper.style';

type PromptPaperProps = PropsWithChildren<{
  prompt: DeepPartial<CDTO.Prompt>;
  onCtaClick?: (prompt: DeepPartial<CDTO.Prompt>) => void;
}>;

export const PromptPaper: FC<PromptPaperProps> = ({
  prompt,
  children,
  onCtaClick = noop,
}) => {
  const { cta, title, image, image2, shortDescription, longDescription } =
    prompt;

  return (
    <Root>
      <Header padding>
        <Typography.Heading3 align="center">{title}</Typography.Heading3>
        <Typography.Body2 align="center">{shortDescription}</Typography.Body2>
      </Header>
      <Button variant="tertiary" onClick={() => onCtaClick(prompt)}>
        {cta}
      </Button>
      {image?.url && (
        <Image>
          <ContentfulImage url={image.url} width={284} />
        </Image>
      )}
      {(longDescription?.json || image2?.url) && (
        <Content>
          {longDescription?.json && (
            <ContentfulDocument>{longDescription.json}</ContentfulDocument>
          )}
          {image2?.url && (
            <Image>
              <ContentfulImage url={image2.url} width={151} />
            </Image>
          )}
        </Content>
      )}
      {children}
    </Root>
  );
};
