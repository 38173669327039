import { createContext } from 'use-context-selector';

export interface NavigationContextProps {
  roles: string[];
  defaultRedirect?: string;
}

export const NavigationContext = createContext<NavigationContextProps>({
  roles: [],
  defaultRedirect: '/',
});
