import styled from 'styled-components';

export const Root = styled('div')`
  margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(12)};
  }
`;

export const Description = styled('div')`
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
`;
