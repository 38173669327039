import { FC } from 'react';
import { Logo } from '@wearehelpful/ui-kit';
import { Root, Image } from './HeaderWithAuthLogo.style';
import IMG from './HeaderWithAuthLogo.png';

interface HeaderWithAuthLogoProps {
  logoVariant?: typeof Logo['defaultProps']['variant'];
}

export const HeaderWithAuthLogo: FC<HeaderWithAuthLogoProps> = ({
  logoVariant,
}) => {
  return (
    <Root>
      <Image src={IMG} />
      <Logo height={19} variant={logoVariant} />
    </Root>
  );
};
