import { useMutation } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { get } from 'lodash';
import {
  emailPasswordSignIn,
  UserType,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { ISignInRequest } from '../../types/dto.types';
import { AuthContext } from '../../contexts/Auth.context';

type UseSignInReturnType = [
  {
    data?: UserType;
    loading: boolean;
  },
  (v: ISignInRequest) => void
];

export const useSignIn = (): UseSignInReturnType => {
  const responseErrorInterceptor = useContextSelector(
    AuthContext,
    ({ responseErrorInterceptor }) => responseErrorInterceptor
  );

  const {
    data,
    isLoading: loading,
    mutate,
    // @ts-ignore
  } = useMutation<UserType, {}, ISignInRequest>(({ email, password }) => {
    return new Promise<UserType>((resolve, reject) => {
      emailPasswordSignIn({
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      })
        .then((data) => {
          if (data.status === 'OK') {
            resolve(data.user);
          }
          if (data.status === 'FIELD_ERROR') {
            reject(new Error('Something went wrong'));
          }
          if (data.status === 'WRONG_CREDENTIALS_ERROR') {
            reject(new Error('Invalid username or password'));
          }
        })
        .catch((err) => {
          err.json().then((data: any) => {
            reject(new Error(get(data, 'message')));
          });
        });
    }).catch(responseErrorInterceptor);
  });

  return [{ data, loading }, mutate];
};
