import { FC, PropsWithChildren, useMemo } from 'react';
import { NavigationPromptContext } from '../../contexts/NavigationPrompt.context';
import { NavigationPromptCallback } from '../../hooks/useNavigationPrompt/useNavigationPrompt.hook';
import { useNavigationPromptCallback } from './NavigationPromptProvider.hooks';

type NavigationPromptProviderProps = PropsWithChildren<{
  navigationPromptCallback?: NavigationPromptCallback;
}>;

export const NavigationPromptProvider: FC<NavigationPromptProviderProps> = ({
  children,
  navigationPromptCallback,
}) => {
  const { callback } = useNavigationPromptCallback({
    navigationPromptCallback,
  });

  const context = useMemo(
    () => ({
      navigationPromptCallback: callback,
    }),
    [callback]
  );

  return (
    <NavigationPromptContext.Provider value={context}>
      {children}
    </NavigationPromptContext.Provider>
  );
};
