import { FC } from 'react';
import { CDTO, ContentfulImage } from '@helpful/network';
import { Typography } from '@wearehelpful/ui-kit';
import { Root, Item } from './GlobalSearchShortcuts.style';

interface GlobalSearchShortcutProps {
  data: CDTO.GetCommonQuery['globalSearchShortcutCollection']['items'];
  onClick: (v: DeepPartial<CDTO.GlobalSearchShortcut>, index: number) => void;
}

export const GlobalSearchShortcuts: FC<GlobalSearchShortcutProps> = ({
  data = [],
  onClick,
}) => {
  const filtered = data.filter(({ system }) => !system);

  return (
    <Root count={filtered.length}>
      {filtered.map((item, index) => {
        const {
          image: { url },
          title,
          sys: { id },
        } = item;
        return (
          <Item key={id} onClick={() => onClick(item, index)}>
            <ContentfulImage url={url} height={40} />
            <Typography variant="p2" align="center">
              {title}
            </Typography>
          </Item>
        );
      })}
    </Root>
  );
};
