import styled from 'styled-components';
import { ContentFixed } from '../ContentFixed/ContentFixed';

export const Root = styled(ContentFixed)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`;
