import { UserRole } from '@common/constants';
import { useMemo } from 'react';
import { useUser } from '@core/user';
import { useSession } from '@core/auth';
import { useLocalStorage } from 'react-use';
import { UseRouteDispatchPushParams } from '@core/navigation';

type UseUserRoleReturnType = UserRole[];

export const useUserRoles = (): UseUserRoleReturnType => {
  const [{ user, connections }] = useUser();
  const [{ isUserVerified, isSessionExist }] = useSession();

  const roles: UserRole[] = useMemo(() => {
    const userRoles: UserRole[] = [];

    if (!isSessionExist || !user) {
      userRoles.push(UserRole.NONE);
    } else {
      const isPatientMissing = user.patients.length === 0;

      const isPlanMissing =
        user.patients.length > 0
          ? user.patients?.some(({ patient: { plans } }) => plans.length === 0)
          : true;

      userRoles.push(isPatientMissing ? UserRole.INITIAL : undefined);

      userRoles.push(
        !isPatientMissing && isPlanMissing ? UserRole.PARTIAL : undefined
      );

      userRoles.push(
        !userRoles.includes(UserRole.INITIAL) &&
          !userRoles.includes(UserRole.PARTIAL)
          ? UserRole.USER
          : undefined
      );

      userRoles.push(isUserVerified ? UserRole.VERIFIED : UserRole.UNVERIFIED);

      const isMedicalConnected = connections.length !== 0;

      if (!isMedicalConnected) {
        userRoles.push(UserRole.MEDICAL_DISCONNECTED);
      }
    }

    return userRoles.filter((v) => !!v);
  }, [user, isSessionExist, isUserVerified, connections.length]);

  return roles;
};

export const useRedirectAfterAuth = () =>
  useLocalStorage<UseRouteDispatchPushParams>('redirectAferAuth');
