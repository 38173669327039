import { FC, useRef, useState } from 'react';
import { castArray, noop } from 'lodash';
import { useField } from 'formik';
import { useUnmount } from 'react-use';
import { BaseFormItemProps } from '../Form.types';
import {
  FormItem,
  Item,
  ItemBody2,
  Prompt,
  Root,
  Thumbnail,
} from './FormFileUpload.style';
import FileUploadIcon from './file-upload.svg?raw';
import FilePreviewIcon from './file-preview.svg?url';

interface FormFileUploadProps extends BaseFormItemProps {
  accept?: string;
  prompt?: string;
  onFilesSelect?: (files: FileList) => void;
}

export const FormFileUpload: FC<FormFileUploadProps> = ({
  label,
  placeholder,
  disabled = false,
  name: initialName,
  accept,
  prompt,
  onFilesSelect = noop,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ name }, , { setValue }] = useField(castArray(initialName).join('.'));
  const [fileInfo, setFileInfo] = useState<{
    preview: string;
    name: string;
  }>(null);

  useUnmount(() => {
    URL.revokeObjectURL(fileInfo?.preview);
  });

  const triggerFileSelect = () => {
    inputRef.current?.click();
  };

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const selectedFiles = target.files;
    if (selectedFiles.length === 0) return;

    const isSelectedFileOfImageType =
      selectedFiles[0].type.indexOf('image') !== -1;

    let filePreview: string;
    if (isSelectedFileOfImageType) {
      filePreview = URL.createObjectURL(selectedFiles[0]);
    } else {
      filePreview = FilePreviewIcon;
    }

    const fileName = selectedFiles[0].name;
    setFileInfo({ preview: filePreview, name: fileName });
    onFilesSelect(selectedFiles);
    setValue(selectedFiles);
  };

  return (
    <>
      {label && (
        <Item>
          <ItemBody2>{label}</ItemBody2>
        </Item>
      )}
      <FormItem name={name}>
        <Root tabIndex={0} role="button" onClick={triggerFileSelect}>
          <input
            ref={inputRef}
            hidden
            type="file"
            accept={accept}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleFileSelect}
          />
          <Thumbnail
            src={fileInfo?.preview ?? FileUploadIcon}
            alt="File upload"
          />
          <Prompt areFilesSelected={Boolean(fileInfo)}>
            {fileInfo?.name || prompt}
          </Prompt>
        </Root>
      </FormItem>
    </>
  );
};
