import { FC, PropsWithChildren } from 'react';
import { IonRouterOutlet } from '@ionic/react';

type OutletProps = PropsWithChildren<{
  id?: string;
}>;

export const Outlet: FC<OutletProps> = ({ id, children }) => {
  return <IonRouterOutlet id={id}>{children}</IonRouterOutlet>;
};
