import { FC, ReactNode } from 'react';
import {
  Route as RouterRoute,
  RouteProps as RouterRouteProps,
} from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { useUser } from '../../../user';
import { NavigationContext } from '../../contexts/Navigation.context';
import { Redirect } from '../Redirect/Redirect';

export interface RouteProps extends Omit<RouterRouteProps, 'children'> {
  roles?: string[];
  redirect?: string;
  children?: ReactNode | ((roles: Array<string>) => ReactNode);
}

export const Route: FC<RouteProps> = ({
  roles,
  redirect,
  children,
  ...routeProps
}) => {
  const [{ roles: current }] = useUser();

  const redirectTo = useContextSelector(
    NavigationContext,
    ({ defaultRedirect }) => redirect || defaultRedirect
  );

  const isAllowed = current.filter((item) => roles?.includes(item)).length > 0;

  return (
    <RouterRoute {...routeProps}>
      {typeof children === 'function' ? (
        children(current)
      ) : (roles || redirect) && !isAllowed ? (
        <Redirect path={redirectTo} />
      ) : (
        <>{children}</>
      )}
    </RouterRoute>
  );
};
