import { FC, Fragment } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Badge, Card } from '@core/ui';
import { ADTO } from '@helpful/network';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Badges } from './VisitSummaryCard.style';

interface VisitSummaryCardProps {
  onClick?: () => void;
  item: ADTO.EncounterEntity;
}

export const VisitSummaryCard: FC<VisitSummaryCardProps> = ({
  onClick,
  item: { id, title, date, provider, location, connection, ...props },
}) => {
  const { t } = useTranslation();

  const description = (() => {
    return get(props, ['careplan', 'description']);
  })();

  const preferredTitle = (() => {
    return get(props, ['careplan', 'title']);
  })();

  return (
    <Card divider flat key={id} onClick={onClick}>
      <Card.Icon name="calendar" />
      <Card.Header>
        <Card.Title>{preferredTitle || title}</Card.Title>
      </Card.Header>
      <Card.Content>
        {date && (
          <Fragment>
            {moment(date).format('dddd, MMMM Do, YYYY')}
            <br />
          </Fragment>
        )}
        {provider && (
          <Fragment>
            {[
              provider?.prefix,
              provider?.firstName,
              provider?.lastName,
              provider?.suffix,
            ].join(' ')}
            <br />
          </Fragment>
        )}
        {location && (
          <Fragment>
            {location} <br />
          </Fragment>
        )}
      </Card.Content>
      <Card.Footer justifyContent="flex-start">
        <Badges>
          <Badge variant="primary">{connection?.system?.name}</Badge>
          {!!description && (
            <Badge variant="secondary">{t('forms.afterVisitSummary')}</Badge>
          )}
        </Badges>
      </Card.Footer>
    </Card>
  );
};
