import { useContextSelector } from 'use-context-selector';
import {} from '@ionic/react-router';
import { useBlocker } from '../useBlocker/useBlocker.hook';
import { NavigationPromptContext } from '../../contexts/NavigationPrompt.context';

type UseNavigationPromptWhen = () => boolean;

interface UsePromptCallbackParams {
  proceed: () => void;
  reset: () => void;
}

export type NavigationPromptCallback = (
  params: UsePromptCallbackParams
) => void;

export function useNavigationPrompt(
  when: UseNavigationPromptWhen,
  initialCallback?: NavigationPromptCallback
) {
  const callback = useContextSelector(
    NavigationPromptContext,
    ({ navigationPromptCallback }) =>
      initialCallback || navigationPromptCallback
  );

  useBlocker(({ proceed }) => {
    callback({
      proceed,
      reset: () => {},
    });
  }, when);
}
