import { FC, useRef } from 'react';
import { toString } from 'lodash';
import {
  Page,
  Form,
  Header,
  Content,
  useUtils,
  Skeleton,
  FormInstance,
  Footer,
} from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import {
  ADTO,
  useGetPatientToDo,
  useUpdatePatientToDo,
} from '@helpful/network';
import { usePatient } from '@common/hooks';
import { Redirect, useRoute, useNavigationPrompt } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { SHORT_DESCRIPTION_MAX_LENGTH } from '../ToDos.const';

export const Details: FC = () => {
  const [
    {
      params: { id: todoId },
    },
  ] = useRoute<{ id: string }, {}>();
  const { t } = useTranslation();
  const { toast } = useUtils();
  const [{ patient }] = usePatient();
  const form = useRef<FormInstance>();

  const { data, isFetching } = useGetPatientToDo(patient.id, todoId);

  const { isLoading, isSuccess, mutate } = useUpdatePatientToDo();

  const handleOnSubmit = ({ title, shortDescription }: ADTO.TodoPatientDto) => {
    mutate(
      {
        todoId,
        id: patient.id,
        data: {
          title,
          shortDescription,
        },
      },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            message: t('notifications.todoSuccessfullyCreated'),
          });
        },
      }
    );
  };

  const shortDescriptionHelp = (value: string = '') => {
    return t('hints.countOfCharactersRemaining', {
      value: SHORT_DESCRIPTION_MAX_LENGTH - value.length,
    });
  };

  useNavigationPrompt(() => form?.current?.hasUnsavedChanges());

  if (isSuccess) {
    return <Redirect path={RoutePath.ROOT_HOME} />;
  }

  return (
    <Page>
      <Form
        ref={form}
        onSubmit={handleOnSubmit}
        key={toString(isFetching)}
        defaultValues={data?.data}
      >
        <Header>
          <Header.Back defaultHref="/" />
          <Header.Actions>
            <Form.Submit checkValidity loading={isLoading}>
              {t('actions.save')}
            </Form.Submit>
          </Header.Actions>
        </Header>
        <Content>
          <Content.Fixed>
            <Skeleton variant="document" loading={isFetching}>
              <Form.Text
                autofocus
                name="title"
                label={t('forms.title')}
                validation={{ required: true }}
              />
              <Form.Textrea
                rows={18}
                enterKeyHint="go"
                name="shortDescription"
                validation={{ required: true }}
                label={t('forms.enterYourText')}
                help={({ value }) => shortDescriptionHelp(value)}
              />
            </Skeleton>
          </Content.Fixed>
        </Content>
        <Footer />
      </Form>
    </Page>
  );
};
