import { useContextSelector } from 'use-context-selector';
import { usePatient } from '@common/hooks';
import { ADTO, useGetPayors, useGetPatient } from '@helpful/network';
import { ConnectContext } from '../Connect.context';
import { PlanForm } from './Plan.types';

type UseVerifyEmailReturnType = [
  {
    loading: boolean;
    config: ADTO.PayorEntity;
    plans: ADTO.PlanEntity[];
    formInitialValues: PlanForm;
  }
];

interface UseVerifyEmailPlanParams {
  payor: string;
}

export const usePlans = ({
  payor,
}: UseVerifyEmailPlanParams): UseVerifyEmailReturnType => {
  const [
    {
      patient: { id },
    },
  ] = usePatient();

  const { data: patient, isFetching: isPatientFetching } = useGetPatient(id, {
    relations: 'plans',
  });

  const { data, isFetching: isPayorsFetching } = useGetPayors(
    {},
    {
      query: {
        initialData: {
          data: [],
          meta: {
            total: 0,
          },
        },
      },
    }
  );

  const config = data?.data.find(({ slug }) => slug === payor);

  const plans = useContextSelector(ConnectContext, ({ plans }) =>
    plans.filter(({ payor: { slug } }) => slug === payor)
  );

  const formInitialValues: PlanForm = {
    plan: (() => {
      if (!config?.isMultiSupplemental) {
        return plans?.find(({ isCompulsory }) => isCompulsory)?.id;
      }
    })(),
    plans: patient?.data?.plans.map(({ id }) => id) || [],
    supplementals: (() => {
      if (config?.isMultiSupplemental) {
        return plans
          ?.filter(({ isCompulsory }) => isCompulsory)
          .map(({ id }) => id);
      }
      return [];
    })(),
  };

  const loading = isPatientFetching || isPayorsFetching;

  return [
    {
      plans,
      config,
      loading,
      formInitialValues,
    },
  ];
};
