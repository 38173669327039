import { FC, PropsWithChildren, CSSProperties } from 'react';
import { UiKitColor, UiKitSize } from '../../../types/uikit.types';
import { Root } from './ListThumb.style';

type ListThumbProps = PropsWithChildren<{
  align?: CSSProperties['alignSelf'];
  color?: UiKitColor;
  size?: UiKitSize;
  disabled?: boolean;
}>;

export const ListThumb: FC<ListThumbProps> = ({
  children,
  size = 'md',
  align = 'unset',
  color = 'periwinkle300',
  disabled = false,
}) => {
  return (
    <Root size={size} color={color} align={align} disabled={disabled}>
      {children}
    </Root>
  );
};
