import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { AuthContext } from '../../contexts/Auth.context';
import { useInAppRedirect } from '../useInAppRedirect/useInAppRedirect.hook';

type UseAppleSignInReturnType = [
  {
    url?: string;
    error?: Error;
    loading: boolean;
  },
  () => void
];

export const useAppleSignIn = (): UseAppleSignInReturnType => {
  const host = useContextSelector(AuthContext, ({ webDomain }) => webDomain);
  const { open } = useInAppRedirect();

  const {
    data: url,
    error,
    isLoading: loading,
    mutate,
  } = useMutation<string, Error, void>(() => {
    return getAuthorisationURLWithQueryParamsAndSetState({
      providerId: 'apple',
      authorisationURL: `${host}/auth/callback/apple`,
    });
  });

  const signIn = useCallback(() => {
    mutate(null, {
      onSuccess: (url) => {
        open(url);
      },
    });
  }, [mutate, open]);

  return [{ url, error, loading }, signIn];
};
