import { MAX_CONTENT_WIDTH } from '@core/ui/constants';
import styled, { css } from 'styled-components';

export const widthConstrained = css`
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

export const Root = styled('div')`
  ${widthConstrained}
`;
