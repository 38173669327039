import { FC } from 'react';
import { Icon } from '@wearehelpful/ui-kit';
import { Root } from './BulletPointsItemCheckbox.style';

export const BulletPointsItemCheckbox: FC = () => {
  return (
    <Root>
      <Icon name="check" />
    </Root>
  );
};
