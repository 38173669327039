import { useCallback } from 'react';
import { uniq } from 'lodash';
import { useLocalStorage, useAsync } from 'react-use';
import { useBootstrapData } from '@bootstrap/hooks';
import { TourStep } from '@wearehelpful/ui-kit';
import { TourId, NAV_TOURS, LocalStorageKey } from '@common/constants';
import { useUpdateCaregiver, useGetCaregiver } from '@helpful/network';
import { useUser } from '@core/user';
import { useSession } from '@core/auth';

import { NavTourCard } from './NavTourCard/NavTourCard';

type UseTourOnNext = (params: { id: string }) => Promise<void>;

type UseTourOnClose = () => Promise<void>;

type UseTourReturnType = [
  {
    loading: boolean;
    steps: TourStep[];
  },
  {
    onNext: UseTourOnNext;
    onClose: UseTourOnClose;
  }
];

export const useTour = (): UseTourReturnType => {
  const { tours } = useBootstrapData();
  const [{ user }] = useUser();
  const [{ isSessionExist }] = useSession();

  const [onboardingLocal, setOnboarding] = useLocalStorage<string[]>(
    LocalStorageKey.Onboarding,
    []
  );

  const { mutateAsync: updateCaregiver } = useUpdateCaregiver();

  const { refetch: getCaregiver } = useGetCaregiver(
    user?.id,
    {},
    {
      query: {
        enabled: isSessionExist,
      },
    }
  );

  const { loading, value: steps = [] } = useAsync(
    async (): Promise<TourStep[]> => {
      const completed = await (async (): Promise<string[]> => {
        if (isSessionExist) {
          try {
            const { data } = await getCaregiver();
            return [...onboardingLocal, ...data.data.onboarding];
          } catch (_) {}
        } else {
          return onboardingLocal;
        }
      })();

      return tours
        .filter(
          ({ slug }) =>
            NAV_TOURS.includes(slug as TourId) && !completed.includes(slug)
        )
        .sort(
          (a, b) =>
            NAV_TOURS.indexOf(a.slug as TourId) -
            NAV_TOURS.indexOf(b.slug as TourId)
        )
        .map(
          (item): TourStep => ({
            id: item.slug,
            content: <NavTourCard {...item} />,
          })
        );
    }
  );

  const onNext: UseTourOnNext = useCallback(
    async ({ id: tour }) => {
      if (isSessionExist) {
        try {
          const {
            data: {
              data: { onboarding: onboardingUser },
            },
          } = await getCaregiver();

          setOnboarding(uniq([...onboardingUser, ...onboardingLocal, tour]));

          const { data: _ } = await updateCaregiver({
            id: user.id,
            data: {
              onboarding: uniq([...onboardingUser, ...onboardingLocal, tour]),
            },
          });
        } catch (e) {}
      } else {
        setOnboarding(uniq([...onboardingLocal, tour]));
      }
    },
    [
      user,
      getCaregiver,
      isSessionExist,
      updateCaregiver,
      onboardingLocal,
      setOnboarding,
    ]
  );

  const onClose = useCallback(async () => {
    const all = steps.map(({ id }) => id);

    if (isSessionExist) {
      const {
        data: {
          data: { onboarding: onboardingUser },
        },
      } = await getCaregiver();

      setOnboarding(uniq([...onboardingUser, ...all]));

      const { data: _ } = await updateCaregiver({
        id: user.id,
        data: {
          onboarding: uniq([...onboardingUser, ...onboardingLocal]),
        },
      });
    } else {
      setOnboarding(uniq([...onboardingLocal, ...all]));
    }
  }, [
    isSessionExist,
    onboardingLocal,
    setOnboarding,
    steps,
    getCaregiver,
    updateCaregiver,
    user?.id,
  ]);

  return [
    { loading, steps },
    { onNext, onClose },
  ];
};
