import { FC, PropsWithChildren, Key } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FormCheckboxGroupContext } from '../FormCheckboxGroup.context';
import { Root, Chackbox, Label } from './FormCheckboxGroupItem.style';

type FormCheckboxGroupItemProps = PropsWithChildren<{
  value: Key;
}>;

export const FormCheckboxGroupItem: FC<FormCheckboxGroupItemProps> = ({
  value,
  children,
}) => {
  const name = useContextSelector(FormCheckboxGroupContext, ({ name }) => name);

  const values = useContextSelector(
    FormCheckboxGroupContext,
    ({ value }) => value
  );

  const onChange = useContextSelector(
    FormCheckboxGroupContext,
    ({ value: current, setValue }) =>
      (chacked: boolean, value: Key) => {
        if (chacked && !values.includes(value)) {
          setTimeout(() => {
            setValue([...current, value]);
          }, 0);
        } else {
          setTimeout(() => {
            setValue(current.filter((v) => v !== value));
          }, 0);
        }
      }
  );

  return (
    <Root>
      <Chackbox
        slot="start"
        name={name}
        value={value}
        onIonChange={({ detail: { checked, value } }) =>
          onChange(checked, value)
        }
        checked={values.includes(value)}
      />
      <Label>{children}</Label>
    </Root>
  );
};
