import { FC } from 'react';
import { Content } from '@wearehelpful/ui-kit';
import { Page } from '../Page/Page';
import { Spinner } from '../Spinner/Spinner';
import { Root } from './SplashPage.style';

export const SplashPage: FC = () => {
  return (
    <Page>
      <Content>
        <Root>
          <Spinner />
        </Root>
      </Content>
    </Page>
  );
};
