import { RoutePath } from '@common/constants';
import { Outlet, Route } from '@core/navigation';
import { withErrorBoundary } from '@wearehelpful/ui-stats';
import { Page } from '@core/ui';
import { UploadSuccess } from './UploadSuccess/UploadSuccess';
import { Index } from './Index/Index';
import { UploadCallback } from './UploadCallback/UploadCallback';

export const Upload = withErrorBoundary(() => {
  return (
    <Page>
      <Outlet>
        <Route path={RoutePath.ROOT_UPLOAD} exact>
          <Index />
        </Route>
        <Route path={RoutePath.ROOT_UPLOAD_CALLBACK}>
          <UploadCallback />
        </Route>
        <Route path={RoutePath.ROOT_UPLOAD_SUCCESS}>
          <UploadSuccess />
        </Route>
      </Outlet>
    </Page>
  );
});
