import { FC, PropsWithChildren, useCallback } from 'react';
import { merge } from 'lodash';
import {
  RequestInterceptorFulfilledFn,
  RequestInterceptorRejectedFn,
  ApiProvider,
  ApiError,
} from '@helpful/network';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { useUtils } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';

export type ApiProps = PropsWithChildren<{
  baseUrl: string;
}>;

export const Api: FC<ApiProps> = ({ children, ...props }) => {
  const { getAnonymousId } = useAnalytics();
  const { toast } = useUtils();
  const { t } = useTranslation();

  const requestInterceptorFulfilled: RequestInterceptorFulfilledFn =
    useCallback(
      async (config) => {
        return merge({}, config, {
          headers: {
            'X-User-Id': getAnonymousId(),
          },
        });
      },
      [getAnonymousId]
    );

  const responseInterceptorRejected: RequestInterceptorRejectedFn<ApiError> =
    useCallback(
      (error) => {
        const message = (() => {
          if (!error || error.code === 'ERR_CANCELED') {
            return null;
          }
          if (
            error.code === 'ECONNABORTED' &&
            error.message.includes('timeout')
          ) {
            return t('errors.networkConnection');
          }
          return t(`errorCodes.${error.response?.data?.code}`, {
            defaultValue: error.response?.data?.message,
          });
        })();
        if (message) {
          toast({
            message,
            variant: 'error',
          });
        }
        return Promise.reject(null);
      },
      [t, toast]
    );

  return (
    <ApiProvider
      {...props}
      requestInterceptorFulfilled={requestInterceptorFulfilled}
      responseInterceptorRejected={responseInterceptorRejected}
    >
      {children}
    </ApiProvider>
  );
};
