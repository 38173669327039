import styled from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Body2 } = Typography;

export const Root = styled(Body2)`
  text-align: center;
  padding: 0 ${({ theme: { spacing } }) => spacing(4)};
  color: ${({ theme }) => theme.palette.neutral700};
  align-self: center;
`;
