export enum TourId {
  AskHelpfulsAssistantWhatToDoNext = 'ask-helpfuls-assistant-what-to-do-next',
  AutomaticallyCreateAPersonalizedCarePlan = 'automatically-create-a-personalized-care-plan',
  OrganizeMedicalRecordsAllInOnePlace = 'organize-medical-records-all-in-one-place',
  UnderstandYourLovedOnesInsuranceBenefits = 'understand-your-loved-ones-insurance-benefits',
}

export const NAV_TOURS = [
  TourId.AutomaticallyCreateAPersonalizedCarePlan,
  TourId.AskHelpfulsAssistantWhatToDoNext,
  TourId.OrganizeMedicalRecordsAllInOnePlace,
  TourId.UnderstandYourLovedOnesInsuranceBenefits,
];
