import { FC } from 'react';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useRoute } from '@core/navigation';
import { useUser } from '@core/user';
import { usePatient } from '@common/hooks';
import { useUpdatePatient } from '@helpful/network';
import { RoutePath } from '@common/constants';
import {
  useUtils,
  Icon,
  Content,
  Page,
  Header,
  Typography,
  Form,
} from '@wearehelpful/ui-kit';
import { Footer, List, Toolbar } from '@core/ui';
import { Action } from './index.const';
import { DetailsForm, DeletePayorParams } from './Index.types';

export const Index: FC = () => {
  const { t } = useTranslation();
  const [, refetch] = useUser();
  const [{ payors, patient }] = usePatient();
  const [{ params }, { push, replace }] = useRoute<{ id: string }, {}>();
  const { toast } = useUtils();

  const name = payors.find(({ id }) => id === params.id)?.name;

  const { isLoading, mutate } = useUpdatePatient();

  const deletePayor = ({ id, onSuccess }: DeletePayorParams) => {
    mutate(
      {
        id: patient.id,
        data: {
          plans: patient?.plans
            .filter(({ payor }) => (id ? payor.id !== id : true))
            .map(({ id }) => ({ id })),
        },
      },
      {
        onSuccess,
      }
    );
  };

  const handleOnSubmit = ({ action }: DetailsForm) => {
    if (action === Action.DELETE && payors.length > 1) {
      deletePayor({
        id: params.id,
        onSuccess: () => {
          refetch();
          toast({
            variant: 'success',
            message: t('notifications.yourInsuranceHasBeenDeleted'),
          });
          replace({ path: RoutePath.ROOT_MORE_INSURANCE });
        },
      });
    }
    if (action === Action.DELETE && payors.length === 1) {
      deletePayor({
        onSuccess: () => {
          toast({
            variant: 'error',
            message: t(
              'notifications.insuranceCannotBeDeletedMustHaveAtLeaveOne'
            ),
          });
        },
      });
    }
    if (action === Action.UPDATE) {
      push({ path: RoutePath.INSURANCE_DETAILS_UPDATE, params });
    }
  };

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back defaultHref={RoutePath.INSURANCE} />
        </Toolbar>
      </Header>
      <Form<DetailsForm> onSubmit={handleOnSubmit}>
        <Content>
          <Content.Subheader divider>
            <Typography variant="h2">
              {t('subheadings.insuranceDetails.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <List>
              <List.Item>
                <List.Thumb>
                  <Icon name="insurance" />
                </List.Thumb>
                <List.Label>{name}</List.Label>
              </List.Item>
            </List>
            <div style={{ height: 40 }} />
            <Form.RadioGroup
              name="action"
              label={t('forms.doYouWantTo')}
              validation={{ required: true }}
            >
              <Form.RadioGroup.Item value={Action.DELETE}>
                {t('options.deleteThisInsuranceProvider')}
              </Form.RadioGroup.Item>
              <Form.RadioGroup.Item value={Action.UPDATE}>
                {t('options.updateThisInsuranceProvider')}
              </Form.RadioGroup.Item>
            </Form.RadioGroup>
          </Content.Fixed>
        </Content>
        <Footer shadow>
          <Toolbar>
            <Form.Submit checkValidity loading={isLoading}>
              {t('actions.next')}
            </Form.Submit>
          </Toolbar>
        </Footer>
      </Form>
    </Page>
  );
};
