import styled from 'styled-components';
import { IonProgressBar } from '@ionic/react';

export const Root = styled(IonProgressBar)`
  height: ${({ theme: { spacing } }) => spacing(3.5)};
  --background: ${({ theme }) => theme.palette.periwinkle100};
  --progress-background: ${({ theme }) => theme.palette.periwinkle100};
  &::part(stream) {
    background-image: linear-gradient(
      65deg,
      ${({ theme }) => theme.palette.periwinkle100} 25%,
      ${({ theme }) => theme.palette.periwinkle400} 25%,
      ${({ theme }) => theme.palette.periwinkle400} 50%,
      ${({ theme }) => theme.palette.periwinkle100} 50%,
      ${({ theme }) => theme.palette.periwinkle100} 75%,
      ${({ theme }) => theme.palette.periwinkle400} 75%,
      ${({ theme }) => theme.palette.periwinkle400} 100%
    );
    background-size: 8.83px 18.93px;
  }
`;
