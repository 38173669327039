import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { CardState } from './Card.types';

interface CardContextProps {
  setCardState: (v: Partial<CardState>) => void;
}

export const CardContext = createContext<CardContextProps>({
  setCardState: noop,
});
