import { createContext } from 'react';
import { Caregivers } from '../__generated__/Caregivers';
import { Patients } from '../__generated__/Patients';
import { Auth } from '../services/Auth.service';

export interface HttpContextProps {
  Caregivers: Caregivers;
  Patients: Patients;
  Auth: Auth;
}

export const HttpContext = createContext<HttpContextProps>({
  Caregivers: null,
  Patients: null,
  Auth: null,
});
