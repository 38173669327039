import { FC, Fragment } from 'react';
import { noop } from 'lodash';
import { Loading, ActionSheet, useUtils } from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { ADTO, useDeletePatientToDo } from '@helpful/network';
import { useAnalytics } from '@wearehelpful/ui-stats';
import { usePatient } from '../../hooks';
import { AnalyticsTrackEvents } from '../../constants';

interface ToDoActionSheetProps extends ADTO.TodoLocalizedEntity {
  disabled?: boolean;
  onDeleted?: () => void;
}

export const ToDoActionSheet: FC<ToDoActionSheetProps> = ({
  id,
  title,
  disabled,
  onDeleted = noop,
}) => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const { track } = useAnalytics();
  const { toast } = useUtils();

  const { isLoading: isDeleting, mutate: remove } = useDeletePatientToDo();

  const handleOnRemove = (todoId: string) => {
    remove(
      {
        id: patient.id,
        todoId,
      },
      {
        onSuccess: () => {
          track({
            id: AnalyticsTrackEvents.HELPFUL_TODO_DELETED,
            properties: { title },
          }).finally(() => {
            toast({
              variant: 'success',
              message: t('notifications.todoSuccesfulyRemoved'),
            });
            onDeleted();
          });
        },
      }
    );
  };

  return (
    <Fragment>
      <Loading
        loading={isDeleting}
        message={t('notifications.todoIsBeingRemoved')}
      />
      <ActionSheet>
        <ActionSheet.Toggle
          size="xs"
          block={false}
          disabled={disabled}
          variant="secondary"
          icon="dots-three-horizontal"
        />
        <ActionSheet.Menu width={320}>
          <ActionSheet.Menu.Item
            icon="trash"
            onClick={() => handleOnRemove(id)}
          >
            {t('actions.removeFromHelpfulCarePlan')}
          </ActionSheet.Menu.Item>
        </ActionSheet.Menu>
      </ActionSheet>
    </Fragment>
  );
};
