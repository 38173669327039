import { createContext } from 'use-context-selector';
import { CDTO } from '@helpful/network';

interface RootContextProps {
  isRootLoading: boolean;
  prompts: DeepPartial<CDTO.PromptCollection>;
  faq: DeepPartial<CDTO.FrequentlyAskedQuestionCollection>;
  ctas: DeepPartial<CDTO.CallToActionCollection>;
}

export const RootContext = createContext<RootContextProps>({
  prompts: {
    items: [],
  },
  faq: {
    items: [],
  },
  ctas: {
    items: [],
  },
  isRootLoading: false,
});
