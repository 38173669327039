import { useRoute } from '@core/navigation';
import { AnalyticsPageCategory } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Button, Content, Footer, Typography } from '@wearehelpful/ui-kit';
import { Toolbar } from '@core/ui';
import { Container, Placeholder, Image } from './Success.style';
import PLACEHOLDER from './assets/pictographic.svg?url';

export const Success = () => {
  const [, { back }] = useRoute();
  const { t } = useTranslation();
  return (
    <TrackPageView
      category={AnalyticsPageCategory.CONTACT_US}
      name={AnalyticsPageCategory.CONTACT_US_SUCCESS}
    >
      <Content padding>
        <Container>
          <Content.Subheader>
            <Typography variant="h2" align="center">
              {t('subheadings.contactUsSucces.title')}
            </Typography>
            <Typography align="center">
              {t('notifications.contactUsSuccess')}
            </Typography>
          </Content.Subheader>
          <Placeholder>
            <Image src={PLACEHOLDER} alt="" />
          </Placeholder>
        </Container>
      </Content>
      <Footer>
        <Toolbar padding>
          <Button onClick={back}>{t('actions.back')}</Button>
        </Toolbar>
      </Footer>
    </TrackPageView>
  );
};
