import { RoutePath } from '@common/constants';
import { Outlet, Route } from '@core/navigation';
import { useGetPlans } from '@helpful/network';
import { Page } from '@core/ui';
import { Index } from './Index/Index';
import { Plan } from './Plan/Plan';
import { ConnectContext } from './Connect.context';

export const Connect = () => {
  const { data: plans } = useGetPlans(
    { take: 1000, relations: 'payor', order: 'name:ASC' },
    {
      query: {
        initialData: {
          data: [],
          meta: { total: 0 },
        },
      },
    }
  );

  return (
    <Page>
      <ConnectContext.Provider value={{ plans: plans.data }}>
        <Outlet>
          <Route path={RoutePath.INSURANCE_CONNECT} exact>
            <Index />
          </Route>
          <Route path={RoutePath.INSURANCE_CONNECT_PLAN}>
            <Plan />
          </Route>
        </Outlet>
      </ConnectContext.Provider>
    </Page>
  );
};
