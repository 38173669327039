import { FC } from 'react';
import { Content } from '@wearehelpful/ui-kit';
import { Typography } from '../Typography/Typography';
import { Page } from '../Page/Page';
import { Root } from './ErrorBoundary.style';
import IMAGE from './ErrorBoundary.png';

const { Heading2, Body1 } = Typography;

export const ErrorBoundary: FC = () => {
  return (
    <Page>
      <Content padding>
        <Root>
          <Heading2 align="center">Oops something went wrong</Heading2>
          <Body1 align="center">
            Please try to refresh the app by closing the app and relaunching or
            contact us at support@wearehelpful.com
          </Body1>
          <img src={IMAGE} width={140} alt="" />
        </Root>
      </Content>
    </Page>
  );
};
