import noop from 'lodash.noop';

import type {
  Notification as DefaultNotification,
  NotificationActionPerformedEvent,
  NotificationActionPerformedListener,
  NotificationReceivedEvent,
  NotificationReceivedListener,
} from '../../definitions';
import { FirebaseMessaging } from '../firebaseMessaging/firebaseMessaging';

interface Data {
  'CIO'?: {
    push: {
      link: string;
    };
  };
  'CIO-Delivery-ID': string;
  'CIO-Delivery-Token': string;
}

interface Notification extends DefaultNotification {
  data: Data;
}

type Props = {
  onReceived?: (
    event: NotificationReceivedEvent & { notification: Notification },
  ) => void;
  onActionPerformed?: (
    event: NotificationActionPerformedEvent & { notification: Notification },
  ) => void;
};

export const registerNotificationsListeners = async ({
  onReceived = noop,
  onActionPerformed = noop,
}: Props): Promise<void> => {
  await FirebaseMessaging.addListener(
    'notificationReceived',
    onReceived as NotificationReceivedListener,
  );

  await FirebaseMessaging.addListener(
    'notificationActionPerformed',
    onActionPerformed as NotificationActionPerformedListener,
  );
};
