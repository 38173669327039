import { FC } from 'react';
import { useUser } from '@core/user';
import { useRoute } from '@core/navigation';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useUpdatePatient } from '@helpful/network';
import { usePatient } from '@common/hooks';
import { RoutePath } from '@common/constants';
import {
  useUtils,
  Skeleton,
  Content,
  Page,
  Header,
  Typography,
  Form,
} from '@wearehelpful/ui-kit';
import { Footer, Toolbar } from '@core/ui';
import { usePlans } from './Plan.hooks';
import { PlanForm } from './Plan.types';

export const Plan: FC = () => {
  const { toast } = useUtils();
  const [{ patient }] = usePatient();
  const [, refetch] = useUser();
  const [
    {
      search: { payor },
    },
    { push },
  ] = useRoute<{}, { payor: string }>();

  const { t } = useTranslation();

  const [{ plans, config, loading, formInitialValues }] = usePlans({
    payor,
  });

  const { isLoading, mutate } = useUpdatePatient();

  const handleOnSubmit = ({
    plans = [],
    plan = null,
    supplemental = null,
    supplementals = [],
  }: PlanForm) => {
    mutate(
      {
        id: patient.id,
        data: {
          plans: patient?.plans
            .map(({ id }) => id)
            .concat([plan, supplemental, ...plans, ...supplementals])
            .filter((id) => !!id)
            .map((id) => ({ id })),
        },
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            variant: 'success',
            message: t('notifications.insuranceHasBeenSuccessfullyAdded'),
          });
          push({ path: RoutePath.ROOT_MORE_INSURANCE });
        },
      }
    );
  };

  const notSuplementalPlans = plans.filter(
    ({ isSupplemental, isHidden }) => !isSupplemental && !isHidden
  );

  const supplementalPlans = plans.filter(
    ({ isSupplemental }) => isSupplemental
  );

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back defaultHref={RoutePath.INSURANCE_CONNECT} />
        </Toolbar>
      </Header>
      <Form<PlanForm>
        onSubmit={handleOnSubmit}
        defaultValues={formInitialValues}
      >
        <Content>
          <Content.Subheader divider>
            <Typography variant="h2">
              {t('subheadings.insuranceConnectPlan.title')}
            </Typography>
          </Content.Subheader>
          <Content.Fixed>
            <Skeleton variant="list" loading={loading}>
              {config?.isMultiPlan && !!notSuplementalPlans.length && (
                <Form.CheckboxGroup
                  name="plans"
                  validation={{ required: true }}
                >
                  {notSuplementalPlans.map(({ id, name }) => {
                    return (
                      <Form.CheckboxGroup.Item key={id} value={id}>
                        {name}
                      </Form.CheckboxGroup.Item>
                    );
                  })}
                </Form.CheckboxGroup>
              )}
              {!config?.isMultiPlan && !!notSuplementalPlans.length && (
                <Form.RadioGroup name="plan" validation={{ required: true }}>
                  {notSuplementalPlans.map(({ id, name, isCompulsory }) => {
                    return (
                      <Form.RadioGroup.Item
                        disabled={isCompulsory}
                        key={id}
                        value={id}
                      >
                        {name}
                      </Form.RadioGroup.Item>
                    );
                  })}
                </Form.RadioGroup>
              )}
              {/* Supplementals */}
              {config?.isMultiSupplemental && !!supplementalPlans.length && (
                <Form.CheckboxGroup
                  name="supplementals"
                  validation={{ required: true }}
                >
                  {supplementalPlans.map(({ id, name }) => {
                    return (
                      <Form.CheckboxGroup.Item key={id} value={id}>
                        {name}
                      </Form.CheckboxGroup.Item>
                    );
                  })}
                </Form.CheckboxGroup>
              )}
              {!config?.isMultiSupplemental && !!supplementalPlans.length && (
                <Form.RadioGroup
                  name="supplemental"
                  validation={{ required: true }}
                >
                  {!config?.isSupplementalRequired && (
                    <Form.RadioGroup.Item value={null}>
                      {t('options.none')}
                    </Form.RadioGroup.Item>
                  )}
                  {supplementalPlans.map(({ id, name }) => {
                    return (
                      <Form.RadioGroup.Item key={id} value={id}>
                        {name}
                      </Form.RadioGroup.Item>
                    );
                  })}
                </Form.RadioGroup>
              )}
            </Skeleton>
          </Content.Fixed>
        </Content>
        <Footer shadow>
          <Toolbar>
            <Form.Submit checkValidity loading={isLoading}>
              {t('actions.next')}
            </Form.Submit>
          </Toolbar>
        </Footer>
      </Form>
    </Page>
  );
};
