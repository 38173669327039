import styled from 'styled-components';
import { ContentFixed, Swiper } from '@core/ui';
import { Link } from '@core/navigation';

export const Heading = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${({ theme: { spacing } }) => spacing(10)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(2)};
  gap: ${({ theme: { spacing } }) => spacing(1)};
`;

export const Container = styled(ContentFixed)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  gap: ${({ theme: { spacing } }) => spacing(10)};
`;

export const Slides = styled('div')`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  max-height: 100%;
`;

export const Slider = styled(Swiper)`
  flex: 1 1 auto;
`;

export const Actions = styled('div')`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const SignIn = styled(Link)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.brown400};
`;
