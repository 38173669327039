import styled from 'styled-components';
import { UiKitSize } from '../../types/uikit.types';
import { EMPTY_GAP_MAP } from './Empty.const';

interface RootProps {
  size: UiKitSize;
}

export const Root = styled('div')<RootProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing }, size }) => spacing(EMPTY_GAP_MAP[size])};
  padding-top: ${({ theme: { spacing } }) => spacing(9)};
`;
