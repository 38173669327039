import styled from 'styled-components';
import { IonList, IonListHeader, IonLabel } from '@ionic/react';

export const Root = styled(IonList)`
  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(9)};
  }
`;

export const Header = styled(IonListHeader)`
  --color: ${({ theme }) => theme.palette.brown400};
  --border-width: 0 0 1px 0;
  --border-color: ${({ theme }) => theme.palette.neutral300};
`;

export const Title = styled(IonLabel)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize(14)};
  line-height: ${({ theme }) => theme.fontSize(18)};
`;
