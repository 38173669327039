import { FC } from 'react';
import {
  Page,
  Header,
  Content,
  Typography,
  Skeleton,
  Icon,
  Button,
  useUtils,
} from '@wearehelpful/ui-kit';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  RoutePath,
} from '@common/constants';
import { Redirect, useRoute } from '@core/navigation';
import {
  ContentfulDocument,
  useGetPatientToDo,
  useGetToDoMetaQuery,
  useUpdatePatientToDo,
} from '@helpful/network';
import { usePatient } from '@common/hooks';
import { useTranslation } from '@wearehelpful/ui-localization';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { ToDoActionSheet } from '@common/features';
import { useHome } from '../../../../hooks/useHome/useHome.hook';
import { RoutePathParams } from './Details.types';
import { useContentfulDocumentRender } from './Details.hooks';
import { More, EntityLink, LinkIcon, ShortDescription } from './Details.style';

export const Details: FC = () => {
  const { t } = useTranslation();
  const { toast } = useUtils();
  const [{ patient, plans }] = usePatient();
  const [, { refetch }] = useHome();
  const [
    {
      params: { id: todoId },
    },
    { push },
  ] = useRoute<RoutePathParams, {}>();
  const { track } = useAnalytics();

  const { render } = useContentfulDocumentRender();

  const { data: todo, isFetching: isTodoFetching } = useGetPatientToDo(
    patient.id,
    todoId,
    {
      select: 'longDescription',
    }
  );

  const {
    isLoading: isCompleting,
    isSuccess: isCompleted,
    mutate,
  } = useUpdatePatientToDo();

  const { data: meta, isFetching: isMetaFetching } = useGetToDoMetaQuery(
    { plans, id: todo?.data.template },
    {
      enabled: !!todo?.data.template,
    }
  );

  const handleOnComplete = () => {
    mutate(
      {
        todoId,
        id: patient.id,
        data: {
          status: 'completed',
        },
      },
      {
        onSuccess: () => {
          track({
            id: AnalyticsTrackEvents.HELPFUL_TODO_COMPLETED,
            properties: { title: todo?.data?.title },
          }).finally(() => {
            toast({
              variant: 'success',
              message: t('notifications.todoSuccessfullyCompleted'),
            });
            refetch();
          });
        },
      }
    );
  };

  const handleOnEdit = () => {
    push({ path: RoutePath.TODOS_DETAILS, params: { id: todo.data.id } });
  };

  const handleOnDeleted = () => {
    refetch();
    push({ path: RoutePath.ROOT_HOME });
  };

  const loading = isTodoFetching || isMetaFetching;

  if (isCompleted) {
    return (
      <Redirect path={RoutePath.ROOT_HOME} search={{ completed: todoId }} />
    );
  }

  return (
    <>
      {todo?.data?.title && (
        <TrackPageView
          category={AnalyticsPageCategory.HOME}
          name={AnalyticsPageCategory.TODO}
          properties={{ title: todo.data.title }}
        />
      )}
      <Page>
        <Header>
          <Header.Back defaultHref={RoutePath.ROOT_HOME} />
          {todo?.data.status === null && (
            <Header.Actions>
              {!todo?.data.template && (
                <Button
                  size="xs"
                  block={false}
                  variant="secondary"
                  onClick={handleOnEdit}
                >
                  {t('actions.edit')}
                </Button>
              )}
              <Button
                size="xs"
                icon="check"
                block={false}
                variant="secondary"
                loading={isCompleting}
                onClick={handleOnComplete}
              >
                {t('actions.complete')}
              </Button>
              <ToDoActionSheet {...todo?.data} onDeleted={handleOnDeleted} />
            </Header.Actions>
          )}
        </Header>
        <Content>
          <Content.Subheader gap="md">
            <Typography variant="h2">{todo?.data.title}</Typography>
            <ShortDescription variant="p2">
              {todo?.data.shortDescription}
            </ShortDescription>
          </Content.Subheader>
          <Content.Fixed inset>
            <Skeleton variant="article" loading={loading}>
              <ContentfulDocument render={render}>
                {todo?.data.longDescription as any}
              </ContentfulDocument>
              {!!meta?.todoCard.planBenefitCollection.items.length && (
                <More>
                  <Typography bold variant="p2">
                    {t('forms.todoRaletedBenefits')}
                  </Typography>
                  {meta.todoCard.planBenefitCollection.items.map(
                    ({ sys: { id }, title, slug }) => {
                      return (
                        <EntityLink
                          key={id}
                          params={{ slug }}
                          path={RoutePath.ROOT_BENEFITS_DETAILS}
                        >
                          <LinkIcon>
                            <Icon name="nav_benefits" />
                          </LinkIcon>
                          <strong>{title}</strong>
                        </EntityLink>
                      );
                    }
                  )}
                </More>
              )}
            </Skeleton>
          </Content.Fixed>
        </Content>
      </Page>
    </>
  );
};
