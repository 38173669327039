import styled from 'styled-components';
import { Typography, Button, UiKitColor } from '@core/ui';

const { Heading4, Body2 } = Typography;

interface RootProps {
  background: UiKitColor;
}

export const Root = styled('div')<RootProps>`
  background: ${({ theme, background }) => theme.palette[background]};
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
  padding-top: ${({ theme: { spacing } }) => spacing(8)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Heading4)`
  text-align: center;
`;

export const Description = styled(Body2)`
  text-align: center;
  margin-top: ${({ theme: { spacing } }) => spacing(1)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(5)};
`;

export const Action = styled(Button)`
  align-self: stretch;
  margin-top: ${({ theme: { spacing } }) => spacing(5)};
`;
