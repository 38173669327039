import { useContext } from 'use-context-selector';
import { CDTO } from '@helpful/network';
import { useTranslation } from '@wearehelpful/ui-localization';
import {
  BenefitsContext,
  RefetchFunction,
  FetchNextPageFunction,
  GetBenefitsFilterParams,
} from '../../contexts/Benefits.context';
import { StackOption } from './useBenefits.types';

interface UseBenefitsState {
  stack?: string;
  total?: number;
  query?: string;
  loading: boolean;
  hasNextPage: boolean;
  stacks: StackOption[];
  benefits: DeepPartial<CDTO.PlanBenefit>[];
}

interface UseBenefitsDispatch {
  refetch: RefetchFunction;
  fetchNextPage: FetchNextPageFunction;
  filter: (v: GetBenefitsFilterParams) => void;
}

type UseBenefitsReturnType = [UseBenefitsState, UseBenefitsDispatch];

export const useBenefits = (): UseBenefitsReturnType => {
  const { t } = useTranslation();

  const {
    stack,
    query,
    total,
    filter,
    refetch,
    loading,
    benefits,
    hasNextPage,
    fetchNextPage,
    stacks: initialStacks,
  } = useContext(BenefitsContext);

  const stacks: StackOption[] = [
    {
      value: undefined,
      title: t('options.all'),
    },
  ].concat(
    initialStacks.map(({ title, sys: { id } }) => ({
      title,
      value: id,
    }))
  );

  return [
    { stack, stacks, total, hasNextPage, loading, query, benefits },
    { refetch, filter, fetchNextPage },
  ];
};
