import { FC } from 'react';
import {
  Page,
  Header,
  Content,
  Typography,
  Form,
  useUtils,
} from '@wearehelpful/ui-kit';
import { useTranslation } from '@wearehelpful/ui-localization';
import { useBootstrapData } from '@bootstrap/hooks';
import { RoutePath } from '@common/constants';
import { usePatient } from '@common/hooks';
import { useUser } from '@core/user';
import { ADTO, useUpdatePatient } from '@helpful/network';

export const Preferences: FC = () => {
  const { t } = useTranslation();
  const [{ patient }] = usePatient();
  const { stacks } = useBootstrapData();
  const [, refetch] = useUser();
  const { toast } = useUtils();

  const { isLoading, mutate } = useUpdatePatient();

  const handleOnSubmit = ({ experience }: ADTO.PatientEntity) => {
    mutate(
      {
        id: patient.id,
        data: { experience },
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            variant: 'success',
            message: t('notifications.profileSuccessfullyUpdated'),
          });
        },
      }
    );
  };

  return (
    <Page>
      <Header>
        <Header.Back defaultHref={RoutePath.ROOT_MORE} />
      </Header>
      <Content>
        <Content.Subheader>
          <Typography variant="h2">
            {t('subheadings.profilePreferences.title')}
          </Typography>
        </Content.Subheader>
        <Content.Fixed>
          <Form<ADTO.PatientEntity>
            defaultValues={patient}
            onSubmit={handleOnSubmit}
          >
            <Form.Section description={t('forms.profilePreferences')}>
              <Form.CheckboxToggle name="experience" spacing="sm">
                {stacks.map(({ sys: { id }, title }) => {
                  return (
                    <Form.CheckboxToggle.Item key={id} value={id}>
                      {title}
                    </Form.CheckboxToggle.Item>
                  );
                })}
              </Form.CheckboxToggle>
            </Form.Section>
            <Form.Actions>
              <Form.Submit loading={isLoading}>{t('actions.save')}</Form.Submit>
            </Form.Actions>
          </Form>
        </Content.Fixed>
      </Content>
    </Page>
  );
};
