import { FC, PropsWithChildren } from 'react';
import { History } from 'history';
import { IonReactRouter } from '@ionic/react-router';
import { NavigationContext } from '../../contexts/Navigation.context';

type NavigationProviderProps = PropsWithChildren<{
  history?: History;
  roles: string[];
  defaultRedirect?: string;
}>;

export const NavigationProvider: FC<NavigationProviderProps> = ({
  roles,
  defaultRedirect,
  history,
  children,
}) => {
  return (
    <NavigationContext.Provider value={{ roles, defaultRedirect }}>
      <IonReactRouter history={history}>{children}</IonReactRouter>;
    </NavigationContext.Provider>
  );
};
