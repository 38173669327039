import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { UiKitColor } from '@core/ui/types/uikit.types';
import { Root } from './ListItem.style';

type ListItemProps = PropsWithChildren<{
  disabled?: boolean;
  onClick?: () => void;
  lines?: 'full' | 'none';
  background?: UiKitColor;
  className?: string;
}>;

export const ListItem: FC<ListItemProps> = ({
  children,
  disabled,
  lines = 'full',
  background = `white100`,
  onClick = noop,
  className,
}) => {
  return (
    <Root
      lines={lines}
      onClick={onClick}
      disabled={disabled}
      className={className}
      background={background}
      button={onClick !== noop}
    >
      {children}
    </Root>
  );
};
