import { FC } from 'react';
import {
  Page,
  Header,
  Toolbar,
  Subheader,
  Typography,
  Form,
  ContentFixed,
} from '@core/ui';
import { DTO, useResetPassword } from '@core/auth';
import { AnalyticsPageCategory, RoutePath } from '@common/constants';
import { useRoute } from '@core/navigation';
import { TrackPageView } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Logo, useUtils, Content } from '@wearehelpful/ui-kit';
import { useValidationSchema } from './ResetPassword.hook';

export const ResetPassword: FC = () => {
  const [{ loading }, reset] = useResetPassword();
  const [, { replace }] = useRoute();
  const { t } = useTranslation();
  const { toast } = useUtils();
  const { validationSchema } = useValidationSchema();

  const handleOnSubmit = ({ password }: DTO.IResetPasswordRequest) => {
    reset(
      { password },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            message: t('notifications.passwordSuccessfullyUpdated'),
          });
          replace({ path: RoutePath.AUTH_SIGN_IN });
        },
      }
    );
  };

  return (
    <TrackPageView
      category={AnalyticsPageCategory.AUTH}
      name={AnalyticsPageCategory.RESET_PASSWORD}
    >
      <Page>
        <Header>
          <Toolbar background="periwinkle300">
            <Toolbar.Title center>
              <Logo />
            </Toolbar.Title>
          </Toolbar>
        </Header>
        <Content>
          <Subheader background="periwinkle300">
            <Typography.Heading2>
              {t('subheadings.resetPassword.title')}
            </Typography.Heading2>
          </Subheader>
          <ContentFixed>
            <Form<DTO.IResetPasswordRequest>
              padding
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              <Form.Password
                autofocus
                tabIndex={1}
                name="password"
                label={t('forms.newPassword')}
                enterKeyHint="next"
                help={[
                  t('hints.passwordHasMinCharacters'),
                  t('hints.passwordContainsNumber'),
                  t('hints.passwordContainsUppercaseLetter'),
                  t('hints.passwordContainsSpecialCharacter'),
                ]}
              />
              <Form.Password
                tabIndex={2}
                name="confirmPassword"
                label={t('forms.reEnterPassword')}
              />
              <Form.Submit loading={loading}>
                {t('actions.changePassword')}
              </Form.Submit>
            </Form>
          </ContentFixed>
        </Content>
      </Page>
    </TrackPageView>
  );
};
