import styled from 'styled-components';

export const Root = styled('h2')`
  width: 295px;
  height: 22px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize(180)};
  line-height: ${({ theme }) => theme.fontSize(22)};
  /* identical to box height */

  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.palette.brown400};

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;
