import { FC } from 'react';
import { Route, Outlet } from '@core/navigation';
import { RoutePath } from '@common/constants';
import { useGetPlans } from '@helpful/network';
import { Page } from '@core/ui';
import { Index } from './Index/Index';
import { Update } from './Update/Update';
import { DetailsContext } from './Details.context';

export const Details: FC = () => {
  const { data: plans } = useGetPlans(
    { take: 1000, relations: 'payor', order: 'name:ASC' },
    {
      query: {
        initialData: {
          data: [],
          meta: { total: 0 },
        },
      },
    }
  );

  return (
    <Page>
      <DetailsContext.Provider value={{ plans: plans.data }}>
        <Outlet>
          <Route path={RoutePath.INSURANCE_DETAILS} exact>
            <Index />
          </Route>
          <Route path={RoutePath.INSURANCE_DETAILS_UPDATE}>
            <Update />
          </Route>
        </Outlet>
      </DetailsContext.Provider>
    </Page>
  );
};
