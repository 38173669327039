import { FC, useMemo, useRef } from 'react';
import {
  AnalyticsPageCategory,
  AnalyticsTrackEvents,
  CallToActionRoutes,
  RoutePath,
} from '@common/constants';
import { BenefitPaper, CtaList, type CtaListProps } from '@common/features';
import { useRoute } from '@core/navigation';
import { TrackPageView, useAnalytics } from '@wearehelpful/ui-stats';
import { useTranslation } from '@wearehelpful/ui-localization';
import { usePatient, useCommon } from '@common/hooks';
import { useSession } from '@core/auth';
import {
  Skeleton,
  Content,
  Page,
  Header,
  Typography,
  InfiniteScroll,
} from '@wearehelpful/ui-kit';
import {
  Chips,
  Empty,
  RadioButtonGroup,
  SearchbarInstance,
  Toolbar,
} from '@core/ui';
import { useBenefits } from '../../../../hooks/useBenefits/useBenefits.hook';
import { SearchbarWithStateInURL } from '../../../../features/SearchbarWithStateInURL/SearchbarWithStateInURL';
import { Gutter, Tags } from './Index.style';

export const Index: FC = () => {
  const [{ isSessionExist }] = useSession();
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const [{ patient }] = usePatient();
  const searchbar = useRef<SearchbarInstance>();
  const { track, trackClick } = useAnalytics();
  const [{ defaultPlans }] = useCommon();

  const [
    { stack, stacks, hasNextPage, total, query, loading, benefits },
    { refetch, filter, fetchNextPage },
  ] = useBenefits();

  const pageRef = useRef<HTMLDivElement>(null);

  const handleOnClick = (slug: string, order: number) => {
    trackClick({
      source: AnalyticsPageCategory.BENEFITS,
      element: AnalyticsPageCategory.BENEFIT,
      properties: { order: order, benefit: slug },
    });
    push({ path: RoutePath.ROOT_BENEFITS_DETAILS, params: { slug } });
  };

  const handleOnSearch = (query: string) => {
    if (query) {
      track({
        id: AnalyticsTrackEvents.BENEFITS_SEARCH,
        properties: {
          query: query.toLowerCase(),
        },
      });
    }
    filter({ query });
  };

  const handleOnChange = (jsonValue: string) => {
    const { title, stack } = JSON.parse(jsonValue) as {
      title: string;
      stack: string;
    };

    track({
      id: AnalyticsTrackEvents.BENEFITS_CATEGORY_CLICK,
      properties: {
        name: title,
      },
    });
    filter({ stack });
  };

  const handleOnCtaClick: CtaListProps['onClick'] = ({
    title,
    ctaUrl: path,
  }) => {
    track({
      id: AnalyticsTrackEvents.BENEFITS_CTA_CLICK,
      properties: { title },
    }).finally(() => {
      push({ path });
    });
  };

  const currentStack = useMemo(
    () => stacks.find(({ value }) => value === stack),
    [stack, stacks]
  );

  return (
    <TrackPageView
      category={AnalyticsPageCategory.ROOT}
      name={AnalyticsPageCategory.BENEFITS}
    >
      <Page ref={pageRef}>
        <Header>
          <Toolbar padding>
            <SearchbarWithStateInURL
              value={query}
              ref={searchbar}
              onSearch={handleOnSearch}
              placeholder={t('placeholders.searchForBenefits')}
            />
          </Toolbar>
          <Toolbar padding>
            <RadioButtonGroup
              nowrap
              value={JSON.stringify({
                title: currentStack.title,
                stack: currentStack.value,
              })}
              onChange={handleOnChange}
            >
              {stacks.map(({ value, title }, index) => (
                <RadioButtonGroup.Button
                  key={index === 0 ? index : value}
                  value={JSON.stringify({ title, stack: value })}
                >
                  {title}
                </RadioButtonGroup.Button>
              ))}
            </RadioButtonGroup>
          </Toolbar>
        </Header>
        <Content onScroll={() => searchbar.current?.blur()}>
          <InfiniteScroll
            onRefresh={refetch}
            disabled={!hasNextPage}
            onScroll={fetchNextPage}
          >
            <Content.Subheader divider>
              <Typography variant="h1">
                {t('subheadings.benefits.title')}
              </Typography>
              <Typography variant="p2">
                {t('subheadings.benefits.description', {
                  name: patient.firstName,
                })}
              </Typography>
              <Tags>
                {!isSessionExist && (
                  <Chips.Item status="error">
                    {t('forms.noInsurancePlanConnected')}
                  </Chips.Item>
                )}
                {!isSessionExist &&
                  defaultPlans.map(({ planName }) => {
                    return (
                      <Chips.Item key={planName} status="success">
                        {t('system.displaying', {
                          label: planName,
                          interpolation: { escapeValue: false },
                        })}
                      </Chips.Item>
                    );
                  })}
                {isSessionExist &&
                  patient?.plans.map(({ name, id }) => {
                    return (
                      <Chips.Item key={id} status="success">
                        {name}
                      </Chips.Item>
                    );
                  })}
              </Tags>
            </Content.Subheader>
            <Content.Fixed>
              <CtaList
                route={CallToActionRoutes.BENEFITS}
                onClick={handleOnCtaClick}
              />
              <Skeleton variant="feed" loading={loading}>
                {benefits.map((item, index) => {
                  return (
                    <BenefitPaper
                      {...item}
                      key={item.sys.id}
                      onClick={() => handleOnClick(item.slug, index)}
                    />
                  );
                })}
                {total === 0 && (
                  <Empty padding>
                    <Empty.Message>{t('empty.noSearchResults')}</Empty.Message>
                    <Empty.Asset />
                  </Empty>
                )}
              </Skeleton>
            </Content.Fixed>
            <Gutter />
          </InfiniteScroll>
        </Content>
      </Page>
    </TrackPageView>
  );
};
