import { FC } from 'react';
import { ADTO } from '@helpful/network';
import { Form } from '@wearehelpful/ui-kit';

interface SinglePlanDefaultProps {
  plans: ADTO.PlanEntity[];
  config: ADTO.PayorEntity;
}

export const SinglePlanDefault: FC<SinglePlanDefaultProps> = ({
  config,
  plans,
}) => {
  const hasPlansSelection = plans.every(({ isHidden }) => {
    return isHidden;
  });

  const hasCompulsory = plans.some(({ isCompulsory }) => isCompulsory);

  if (!config?.isMultiSupplemental && hasPlansSelection && hasCompulsory) {
    return (
      <Form.RadioGroup
        name="plan"
        validation={{ required: config?.isSupplementalRequired }}
      >
        {plans
          .filter(({ isCompulsory }) => isCompulsory)
          .map(({ id, name }) => {
            return (
              <Form.RadioGroup.Item key={id} value={id}>
                {name}
              </Form.RadioGroup.Item>
            );
          })}
      </Form.RadioGroup>
    );
  }

  return null;
};
