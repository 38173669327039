import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Root } from './Footer.style';

type FooterProps = PropsWithChildren<{
  shadow?: boolean;
  className?: string;
}>;

export const Footer: FC<FooterProps> = ({
  children,
  className,
  shadow = false,
}) => {
  return (
    <Root
      shadow={shadow}
      collapse="fade"
      className={classNames(className, 'ion-no-border')}
    >
      {children}
    </Root>
  );
};
