import { AnalyticsPageCategory } from '@common/constants';
import { TrackPageView } from '@wearehelpful/ui-stats';
import {
  Badge,
  ContentFixed,
  Header,
  Page,
  Subheader,
  Toolbar,
  Typography,
} from '@core/ui';
import { FC } from 'react';
import { useGetPatientMedication } from '@helpful/network';
import { useRoute } from '@core/navigation';
import moment from 'moment-timezone';
import { useUser } from '@core/user';
import { useTranslation } from '@wearehelpful/ui-localization';
import { Content } from '@wearehelpful/ui-kit';
import { Item } from './Details.style';

export const Details: FC = () => {
  const [
    {
      params: { id },
    },
  ] = useRoute<{ id: string }, {}>();

  const { t } = useTranslation();
  const [{ user }] = useUser();
  const patientId = user.patients[0].patient.id;

  const { data, isSuccess } = useGetPatientMedication(patientId, id, {
    relations: 'provider, connection.system',
  });

  const content = (
    <TrackPageView
      category={AnalyticsPageCategory.MEDICATION}
      name={data?.data.id}
    >
      <Subheader>
        <Typography.Heading3>{data?.data.name}</Typography.Heading3>
      </Subheader>
      <ContentFixed padding>
        <Badge variant="primary">{data?.data.connection.system.name}</Badge>

        {data?.data.instruction && (
          <Item>
            <Typography.Body2 bold> {t('forms.directions')} </Typography.Body2>
            <Typography.Body2>{data.data.instruction}</Typography.Body2>
          </Item>
        )}

        {data?.data.route && (
          <Item>
            <Typography.Body2 bold>{t('forms.route')}</Typography.Body2>
            <Typography.Body2>{data.data.route}</Typography.Body2>
          </Item>
        )}

        {data?.data.duration && (
          <Item>
            <Typography.Body2 bold>{t('forms.duration')}</Typography.Body2>
            <Typography.Body2>{data.data.duration}</Typography.Body2>
          </Item>
        )}

        {data?.data.status && (
          <Item>
            <Typography.Body2 bold>{t('forms.status')}</Typography.Body2>
            <Typography.Body2>{data.data.status}</Typography.Body2>
          </Item>
        )}

        {data?.data.quantity && (
          <Item>
            <Typography.Body2 bold>{t('forms.quantity')}</Typography.Body2>
            <Typography.Body2>{data.data.quantity}</Typography.Body2>
          </Item>
        )}

        {data?.data.refills && (
          <Item>
            <Typography.Body2 bold>{t('forms.refills')}</Typography.Body2>
            <Typography.Body2>{data.data.refills}</Typography.Body2>
          </Item>
        )}

        {data?.data.provider && (
          <Item>
            <Typography.Body2 bold>{t('forms.prescribedBy')}</Typography.Body2>
            <Typography.Body2>{data.data.provider.firstName}</Typography.Body2>
          </Item>
        )}

        {data?.data.authoredOn && data?.data.provider && (
          <Item>
            <Typography.Body2 bold>{t('forms.prescribed')}</Typography.Body2>
            <Typography.Body2>
              {moment(data?.data.authoredOn).format('MMM D, YYYY').toString()}
            </Typography.Body2>
          </Item>
        )}
      </ContentFixed>
    </TrackPageView>
  );

  return (
    <Page>
      <Header>
        <Toolbar>
          <Toolbar.Back />
        </Toolbar>
      </Header>
      <Content padding>{!isSuccess ? null : content}</Content>
    </Page>
  );
};
