import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { Root, Label, Spinner } from './Button.style';
import { ButtonVariant, ButtonType, ButtonSize } from './Button.types';
import { BUTTON_TYPE_FILL, BUTTON_SIZE_MAP } from './Button.const';

export type ButtonProps = PropsWithChildren<{
  block?: boolean;
  loading?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?: ButtonVariant;
}>;

export const Button: FC<ButtonProps> = ({
  loading,
  children,
  disabled,
  className,
  size = 'm',
  block = true,
  type = 'button',
  onClick = noop,
  variant = 'primary',
}) => {
  return (
    <Root
      type={type}
      variant={variant}
      disabled={disabled}
      className={className}
      size={BUTTON_SIZE_MAP[size]}
      onClick={!loading && onClick}
      fill={BUTTON_TYPE_FILL[variant]}
      expand={block ? 'block' : undefined}
    >
      <Label loading={loading}>{children}</Label>
      {loading && <Spinner />}
    </Root>
  );
};
