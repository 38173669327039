import styled from 'styled-components';
import { Content, Typography } from '@wearehelpful/ui-kit';

export const ButtonHint = styled(Typography)`
  margin-top: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Subheader = styled(Content.Subheader)`
  & > div {
    overflow: hidden;
  }
`;

export const Heading = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({ theme: { spacing } }) => spacing(8)};
`;

export const Image = styled('img')`
  margin-right: -36px;
`;

export const ContentFixed = styled(Content.Fixed)`
  & > div {
    padding-top: 0;
  }
`;

export const Description = styled(Typography)`
  white-space: break-spaces;
`;
