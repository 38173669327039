import { FC } from 'react';
import { noop } from 'lodash';
import { Card, Badge } from '@core/ui';
import { CDTO } from '@helpful/network';

interface BenefitPaperProps extends DeepPartial<CDTO.PlanBenefit> {
  truncate?: number;
  className?: string;
  withIcon?: boolean;
  onClick?: () => void;
}

export const BenefitPaper: FC<BenefitPaperProps> = ({
  title,
  className,
  healthPlan,
  entityInfo,
  truncate = 3,
  onClick = noop,
  withIcon = false,
  shortDescription,
}) => {
  return (
    <Card flat divider onClick={onClick} className={className}>
      {withIcon && <Card.Icon name="nav_benefits" />}
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Content truncate={truncate}>{shortDescription}</Card.Content>
      {healthPlan?.planName && !entityInfo && (
        <Card.Footer justifyContent="flex-start">
          <Badge variant="warning">{healthPlan?.planName}</Badge>
        </Card.Footer>
      )}
    </Card>
  );
};
