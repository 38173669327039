import { FC, PropsWithChildren } from 'react';
import { Root } from './ListLabel.style';

type ListLabelProps = PropsWithChildren<{
  wrap?: boolean;
}>;

export const ListLabel: FC<ListLabelProps> = ({ children, wrap = false }) => {
  return <Root wrap={wrap}>{children}</Root>;
};
