import styled from 'styled-components';
import { Chips as UiChips } from '@core/ui';

export const Gutter = styled('div')`
  height: ${({ theme: { spacing } }) => spacing(15)};
`;

export const Tags = styled(UiChips)`
  margin-top: ${({ theme: { spacing } }) => spacing(5)};
`;
