import { FC, PropsWithChildren, useCallback } from 'react';
import { useLocale, useTranslation } from '@wearehelpful/ui-localization';
import {
  ContentfulProviderProps,
  ContentfulProvider,
  RequestInterceptorFulfilledFn,
  ResponseInterceptorRejectedFn,
  ContentfulError,
} from '@helpful/network';
import { useUtils } from '@wearehelpful/ui-kit';
import { useConfig } from '../../hooks';
import { RENDER_NODE } from './Contentful.const';

export type ContentfulProps = PropsWithChildren<ContentfulProviderProps>;

export const Contentful: FC<ContentfulProps> = ({ children, ...props }) => {
  const [{ lng }] = useLocale();
  const { t } = useTranslation();
  const { token } = props;
  const { toast } = useUtils();

  const { supportedLanguages } = useConfig();

  const locale = supportedLanguages.find(
    ({ code }) => code === lng
  )?.contentful;

  const requestInterceptorFulfilled: RequestInterceptorFulfilledFn =
    useCallback(
      async (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      [token]
    );

  const responseInterceptorRejected: ResponseInterceptorRejectedFn<ContentfulError> =
    useCallback(
      (error) => {
        const message = (() => {
          if (
            error.code === 'ECONNABORTED' &&
            error.message.includes('timeout')
          ) {
            return t('errors.networkConnection');
          }
          return error.response.data?.errors
            .map(({ message }) => message)
            .join('. ');
        })();
        toast({
          message,
          variant: 'error',
        });
        return Promise.reject(null);
      },
      [t, toast]
    );

  return (
    <ContentfulProvider
      {...props}
      locale={locale}
      render={RENDER_NODE}
      responseInterceptorRejected={responseInterceptorRejected}
      requestInterceptorFulfilled={requestInterceptorFulfilled}
    >
      {children}
    </ContentfulProvider>
  );
};
