import { forwardRef, useImperativeHandle, useRef } from 'react';
import { castArray } from 'lodash';
import { useField } from 'formik';
import { IonInput } from '@ionic/react';
import { BaseFormItemProps } from '../Form.types';
import { FormItem } from '../FormItem/FormItem';
import { Root } from './FormTextarea.style';

interface FormTextareaProps extends BaseFormItemProps {
  maxlength?: number;
  className?: string;
  autocapitalize?: Parameters<typeof IonInput>['0']['autoCapitalize'];
}

export interface FormTextareaInstance {
  focus: () => void;
}

export const FormTextarea = forwardRef<FormTextareaInstance, FormTextareaProps>(
  function FormInput(
    {
      label,
      placeholder,
      name: initialName,
      disabled = false,
      maxlength,
      autocapitalize,
      className,
    },
    ref
  ) {
    const input = useRef<HTMLIonTextareaElement>();

    const [{ name, value, onChange, onBlur }] = useField(
      castArray(initialName).join('.')
    );

    useImperativeHandle(ref, () => ({
      focus: () => input.current?.setFocus(),
    }));

    return (
      <FormItem
        name={name}
        label={label}
        counter={!!maxlength}
        className={className}
      >
        <Root
          rows={3}
          ref={input}
          name={name}
          value={value}
          onIonBlur={onBlur}
          disabled={disabled}
          maxlength={maxlength}
          onIonInput={onChange}
          placeholder={placeholder}
          autoCapitalize={autocapitalize}
        />
      </FormItem>
    );
  }
);
